import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import I1868CatDestinos from '../../../../Components/Clientes/ZincInternacional/Interfaces/I1868CatDestinos';
const Cat1868Destinos: I1868CatDestinos[] = [{id:0, lugar:'- Seleccione destino -', direccion:''}]
const initialState = { Cat1868Destinos }

export const Cat1868DestinosSlice = createSlice({
  name: 'CatDestinos',
  initialState: initialState,
  reducers: {
    populateCatDestinos : (state, action: PayloadAction<I1868CatDestinos[]>) => {
      state.Cat1868Destinos=[]
      state.Cat1868Destinos.push(...action.payload)
    },
    addCatDestinos : (state, action: PayloadAction<I1868CatDestinos>) => {
      var Existe = state.Cat1868Destinos.find(function(item) {
        return item.id === action.payload.id;
      });
     if (!Existe) state.Cat1868Destinos.push(action.payload)
    },
    updateCatDestinos : (state, action: PayloadAction<I1868CatDestinos>) => {
      const i = state.Cat1868Destinos.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.Cat1868Destinos[i] = action.payload;
      else state.Cat1868Destinos.push(action.payload);
    },
    deleteCatDestinos : (state, action: PayloadAction<number>) => {
      const newArr = state.Cat1868Destinos.filter(data => data.id != action.payload);
      state.Cat1868Destinos=newArr
    },
  },
})

export const { addCatDestinos, populateCatDestinos, updateCatDestinos, deleteCatDestinos } = Cat1868DestinosSlice.actions;
export default Cat1868DestinosSlice.reducer;

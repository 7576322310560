import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { logued } from '../../store/features/userStatusSlice/userStatusSlice'
import ILogin from '../../Interfaces/Ilogin'
import authDataService from '../../Services/Auth/Auth.Service'
import '../../css/login.css'
import jwt_decode from 'jwt-decode'
import IjwtStructure from '../../Interfaces/IjwtStructure'
import { RootState } from '../../store/store'
import { AxiosError } from 'axios'
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import logo from '../../images/GEMCO_mini.png'
import Dashboard from '../Dashboard/Dashboard'
import { Nav } from 'react-bootstrap'
import Info from '../../../package.json'

export const Login: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userLogued = useSelector((state: RootState) => state.userStatus.value)
  const [show, setShow] = React.useState(false)
  const [msg, setMsg] = React.useState('')
  const [header, setHeader] = React.useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const initialValues: ILogin = { Usuario: '', Contrasena: '' }

  return (
    <div>
      {!userLogued ? (
        <div>
        <div id='login'>
          <div className='container'>
            <div
              id='login-row'
              className='row justify-content-center align-items-center'
            >
              <div id='login-column' className='col-md-4'>
                <div id='login-box' className='col-md-12'>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values: any, actions: any) => {
                      authDataService
                        .create(values)
                        .then((response: any) => {
                          localStorage.setItem(
                            'menu',
                            JSON.stringify(response.data.menu)
                          )
                          localStorage.setItem('token', response.data.token)
                          localStorage.setItem(
                            'tokenInfo',
                            JSON.stringify(jwt_decode(response.data.token))
                          )
                          var values: IjwtStructure = jwt_decode(
                            response.data.token
                          )
                          localStorage.setItem(
                            'UserId',
                            JSON.stringify(values.UserId)
                          )
                          localStorage.setItem(
                            'User',
                            JSON.stringify(values.Usuario)
                          )
                          localStorage.setItem(
                            'Departamento',
                            JSON.stringify(values.Departamento)
                          )
                          localStorage.setItem(
                            'UserType',
                            JSON.stringify(values.UserType)
                          )
                          localStorage.setItem(
                            'Perfil',
                            JSON.stringify(values.Perfil)
                          )
                          dispatch(logued(true))
                        })
                        .catch((e: AxiosError) => {
                          localStorage.setItem('menu', '')
                          localStorage.setItem('token', '')
                          setHeader('Error')
                          if (e.message.includes('400')) {
                            setMsg('Credenciales invalidas!')
                          } else if (e.message.includes('401')) {
                            setMsg(
                              'Debere cambiar la contraseña, para poder entrar a este sitio'
                            )
                          }
                          setShow(true)
                        })
                    }}
                  >
                    <Form id='login-form' className='form'>
                      <div className='text-center'>
                        <img
                          src={logo}
                          alt='GEMCO'
                          width='100px'
                          height='100px'
                        ></img>
                      </div>
                      <br />
                      {/* <h3 className='text-center text-info'>Bienvenido</h3> */}
                      <div className='form-group'>
                        <label htmlFor='username'>Usuario</label>
                        <Field
                          id='Usuario'
                          name='Usuario'
                          className='form-control'
                          placeholder='Usuario'
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='password'>Contraseña</label>
                        <Field
                          id='Contrasena'
                          name='Contrasena'
                          className='form-control'
                          placeholder='Contraseña'
                          type='password'
                        />
                      </div>
                      <span style={{textAlign:'center'}}><Nav.Link style={{color: '#002f87'}}  href='/#/forgotPassword'>¿Olvido contraseña?</Nav.Link></span>
                      <br />
                      <div className='text-center'>
                        <button
                          type='submit'
                          className='btn btn-primary btn-lg btn-block'
                        >
                          login
                        </button>
                      </div>
                      <div style={{textAlign:'right'}}>v{Info.version}</div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <MsgInformativo
            show={show}
            msg={msg}
            header={header}
            msgColor={msgColor}
            closeToast={function (arg: boolean): void {
              setShow(false)
            }}
          />
        </div>
        </div>
      ) : (
        <div><Dashboard/></div>
      )}
    </div>
  )
}

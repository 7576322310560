import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import I2096NoParte from '../../../../Components/Clientes/Amazon/Interfaces/I2096NoParte';
const NoPartes: I2096NoParte[] = []
const initialState = { NoPartes }

export const AmazonNoPartesSlice = createSlice({
  name: 'AmazonNoPartes',
  initialState: initialState,
  reducers: {
    populateNoPartes : (state, action: PayloadAction<I2096NoParte[]>) => {
      const newArr = state.NoPartes.filter(data => data.id === 0);
      state.NoPartes=newArr
    action.payload.forEach(newItem => {
      var Existe = state.NoPartes.find(function(onMemoryItem) {
          return onMemoryItem.id === newItem.id;
        });
       if (!Existe) state.NoPartes.push(newItem)
    })
    },
    addNoPartes : (state, action: PayloadAction<I2096NoParte>) => {
      var Existe = state.NoPartes.find(function(item) {
        return item.id === action.payload.id;
      });
     if (!Existe) state.NoPartes.push(action.payload)
    },
    updateNoPartes : (state, action: PayloadAction<I2096NoParte>) => {
      const i = state.NoPartes.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.NoPartes[i] = action.payload;
      else state.NoPartes.push(action.payload);
    },
    deleteNoPartes : (state, action: PayloadAction<number>) => {
      const newArr = state.NoPartes.filter(data => data.id != action.payload);
      state.NoPartes=newArr
    },
  },
})

export const { addNoPartes, populateNoPartes, updateNoPartes, deleteNoPartes } = AmazonNoPartesSlice.actions;
export default AmazonNoPartesSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import I2096Headers from '../../../../Components/Clientes/Amazon/Interfaces/I2096Header'
const Invoice: I2096Headers[] = []
const initialState = { Invoice }

export const AmazonInvoicesSlice = createSlice({
  name: 'AmazonInvoices',
  initialState: initialState,
  reducers: {
    initializeInvoice: (state, action: PayloadAction<I2096Headers[]>) => {
      state.Invoice = action.payload
    },
    populateInvoices: (state, action: PayloadAction<I2096Headers[]>) => {
      action.payload.forEach((newItem) => {
        var Existe = state.Invoice.find(function (onMemoryItem) {
          return onMemoryItem.id === newItem.id
        })
        if (!Existe) state.Invoice.push(newItem)
      })
    },
    addInvoice: (state, action: PayloadAction<I2096Headers>) => {
      var Existe = state.Invoice.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.Invoice.push(action.payload)
    },
    updateInvoice: (state, action: PayloadAction<I2096Headers>) => {
      const i = state.Invoice.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.Invoice[i] = action.payload
      else state.Invoice.push(action.payload)
    },
    updateInvoiceDetail: (state, action: PayloadAction<I2096Headers>) => {
      const i = state.Invoice.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.Invoice[i] = action.payload
      else state.Invoice.push(action.payload)
    },
    deleteInvoice: (state, action: PayloadAction<number>) => {
      const newArr = state.Invoice.filter((data) => data.id != action.payload)
      state.Invoice = newArr
    }
  }
})

export const { initializeInvoice, addInvoice, populateInvoices, updateInvoice, deleteInvoice } =
  AmazonInvoicesSlice.actions
export default AmazonInvoicesSlice.reducer

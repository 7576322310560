import React, { useEffect, useState } from 'react'
//Bootstrap components
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { BsCloudDownload, BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
// Interfaces
import { IRptDetalleAnexo24 } from '../../Interfaces/Reportes/RptDetalleAnexo24'
import IClientes from '../../Interfaces/Catalogos/IClientes'
import IArchivoElectronico from '../../Interfaces/IArchivoElectronico'
// DTOs
import DTOReporte from '../../DTO/DTOReporte'
import DTOgetThesePedimentos from '../../DTO/DTOgetThesePedimentos'
// Services
import reportsDataService from '../../Services/Reportes/reportes.services'
import ClientesDataService from '../../Services/Catalogos/Clientes.Services'
import AEODataServices from '../../Services/Catalogos/ArchivoElectronico.Services'
// GUI components
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import { AgGridReact } from 'ag-grid-react'
import { verPDF } from './customCells/verPDF'
import { RowClickedEvent } from 'ag-grid-community'
//Other
import moment from 'moment'
import * as XLSX from 'xlsx'
import { TargetURL } from '../../Constants/TargetURL'
const FileSaver = require('file-saver')
const URL = new TargetURL()

export default function RptPedimentosPagados() {
  const [curURL, setCurURL] = useState(URL.get())
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [InfoLoaded, setInfoLoaded] = useState(0)
  const [Data, setData] = useState<Array<IRptDetalleAnexo24>>([])
  const [filteredData, setFilteredData] = useState<Array<IRptDetalleAnexo24>>([])
  const [Inicio, setInicio] = useState(currentDate())
  const [Fin, setFin] = useState(currentDate())
  const [TipoOperacion, setTipoOperacion] = useState(1)
  const [Cliente, setCliente] = useState(0)
  const [Clientes, setClientes] = useState<Array<IClientes>>()
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [filtro, setFiltro] = useState('')
  const [columnaVerPDF, setcolumnaVerPDF] = useState(false)
  const gridRef = React.useRef<any>(null)
  const [columnDefs] = useState([
    { field: 'referencia', sortable: true, filter: true },
    { field: 'ver pedimento', sortable: true, cellRenderer: verPDF },
    {
      field: 'pedimento',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      sortable: true,
      filter: true,
    },
    { headerName: 'Aduana', field: 'aduanaSeccionDespacho', sortable: true, filter: true },
    { field: 'patente', sortable: true, filter: true },
    {
      field: 'fechaPago',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.value.substring(0, 9)
      },
    },
    { headerName: 'Fecha Entrada', field: 'fechaEntradaPresentracion', sortable: true, filter: true },
    { field: 'claveDocto', sortable: true, filter: true },
    { field: 'esRectificacion', sortable: true, filter: true },
    { field: 'tipoCambio', sortable: true, filter: true },
    {
      field: 'valorDls',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value, 2)
      },
    },
    {
      field: 'valorAduana',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value, 2)
      },
    },
    { field: 'numeroFactura', sortable: true, filter: true },
    { field: 'fechaFactura', sortable: true, filter: true },
    { field: 'proveedorFactura', sortable: true, filter: true },
    { field: 'incrementablesFact', sortable: true, filter: true },
    { field: 'fraccion', sortable: true, filter: true },
    { field: 'subdivNICO', sortable: true, filter: true },
    { field: 'descripcion', sortable: true, filter: true },
    { field: 'paisOrigen', sortable: true, filter: true },
    { field: 'paisVendedor', sortable: true, filter: true },
    { field: 'tasaDTA', sortable: true, filter: true },
    { field: 'tasaIGI', sortable: true, filter: true },
    { field: 'numeroParte', sortable: true, filter: true },
    { field: 'cantidadComercial', sortable: true, filter: true },
    { field: 'tlPais', sortable: true, filter: true },
    { field: 'tipoTasa', sortable: true, filter: true },
    { field: 'unidadComercial', sortable: true, filter: true },
    {
      field: 'valorFacturaItem',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value, 4)
      },
    },
    {
      field: 'valorComercialPed',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value, 4)
      },
    },
    {
      field: 'valorFacturaItemMn',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value, 4)
      },
    },
  ])

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const closeToast = (show: boolean): void => {
    setShowMsg(false)
  }

  const generaReporte = () => {
    if (Cliente === 0) {
      setHeader('Error')
      setMsg('Seleccion el cliente')
      setShowMsg(true)
      return
    }
    const data: DTOReporte = {
      Inicio: moment(Inicio).format('YYYY-MM-DD'),
      Fin: moment(Fin).format('YYYY-MM-DD'),
      TipoOperacion: TipoOperacion,
      NoCliente: Cliente,
      IdUsuario: UserId,
    }
    reportsDataService
      .getRptDetalleAnexo24(data)
      .then((response) => {
        setData(response.data)
        setFilteredData(response.data)
        setHeader('Informativo')
        setMsg('Se encontro la siguiente informacion...')
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    ClientesDataService.getAllClientes(parseInt(UserId))
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }, [])

  const downloadExcel = () => {
    exportExcel(Data, 'DetalleAnexo24')
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    let Heading = [
      [
        { title: 'Aduana Seccion Despacho', style: { font: { sz: '18', bold: true } } },
        'Patente',
        'Referencia',
        'Pedimento',
        'Fecha Pago',
        'Fecha Entrada Presentacion',
        'Clave Docto',
        'Es rectificacion',
        'Tipo Cambio',
        'Valor Dls',
        'Valor Aduana',
        'Numero Factura',
        'Fecha Factura',
        'Proveedor Factura',
        'Incrementables Fact',
        'Fraccion',
        'Subdiv NICO',
        'Descripcion',
        'Pais Origen',
        'Pais Vendedor',
        'Tasa DTA',
        'Tasa IGI',
        'Numero Parte',
        'Cantidad Comercial',
        'TL Pais',
        'Tipo Tasa',
        'Unidad Comercial',
        'Valor Factura Item',
        'Valor Comercial Ped',
        'Valor Factura Item MN',
      ],
    ]
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    gridRef.current.api.setQuickFilter(e.target.value)
  }

  function getParams(e: RowClickedEvent) {
    const data: IArchivoElectronico = {
      Referencia: e.data.referencia,
      PedimentoLargo: e.data.pedimento,
      NoCliente: Cliente,
      IdUsuario: 0,
      Archivo: '',
    }
    if (columnaVerPDF) {
      let P: string = e.data.pedimento
      const arrPed = P.split(' ')
      P = arrPed[1] + arrPed[0] + arrPed[2]
      window.open(
        `${curURL}/ArchivoElectronico/getFile?Referencia=${e.data.referencia}&PedimentoLargo=${P}&Nocliente=${Cliente}&IdUsuario=${UserId}`
      )
    }
  }

  const downloadPDFs = () => {
    if (Cliente === 0) {
      setMsg('Seleccione el cliente')
      setHeader('Para poder continuar, favor de: ')
      setShowMsg(true)
    }
    let selectedNodes = gridRef.current.api.getSelectedNodes()
    let selectedData = selectedNodes.map((node: any) => node.data)
    interface IPedimentos {
      pedimento: string
    }
    const Pedimentos = selectedData.map((item: IPedimentos) => item.pedimento)
    const data: DTOgetThesePedimentos = {
      Inicio: Inicio,
      Fin: Fin,
      NoCliente: Cliente,
      IdUsuario: UserId,
      TipoOperacion: TipoOperacion,
      Pedimentos: Pedimentos,
    }
    AEODataServices.getThesePDFs(data)
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Pedimentos.zip')
        document.body.appendChild(link)
        link.click()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  async function getAE(e: any) {
    if (e.column.colId === 'ver pedimento') {
      setcolumnaVerPDF(true)
    } else {
      setcolumnaVerPDF(false)
    }
  }

  const formatNumber = (number: string, digits: number) => {
    number = parseFloat(number).toFixed(digits)
    let value = Number(number).toLocaleString('en')
    if (!value.indexOf('NaN')) return '0.00'
    return value
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-2'>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-2'>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1 right-label'>
              <Form.Label>Tipo oper</Form.Label>
            </div>
            <div className='col-md-1'>
              <Form.Control
                as='select'
                onChange={(e) => setTipoOperacion(parseInt(e.target.value))}
                className='form-select form-select-sm'
              >
                <option value='1'>Importacion</option>
                <option value='2'>Exportacion</option>
              </Form.Control>
            </div>
            <div className='col-md-1 right-label'>
              <Form.Label>Cliente</Form.Label>
            </div>
            <div className='col-md-4'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  setCliente(parseInt(e.target.value))
                }}
                className='form-select form-select-sm'
              >
                <option value='0'>-SELECCIONE-</option>
                {Clientes
                  ? Clientes.map((c) => {
                      return c.agrupado === 1 ? <option value={c.sClave}>{c.sRazonSocial}</option> : ''
                    })
                  : null}
              </Form.Control>
            </div>
            <div className='col-md-1 right-label'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
          </div>
          <div className='row' style={{ paddingTop: 5 }}>
            <div className='col'>&nbsp;</div>
            <div className='col-4'>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className='col'>
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp; Excel
              </Button>
              &nbsp; &nbsp;
              <Button
                variant='danger'
                size='sm'
                onClick={() => {
                  downloadPDFs()
                }}
              >
                <BsCloudDownload />
                &nbsp;PDF
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        <AgGridReact
          rowData={filteredData}
          columnDefs={columnDefs}
          pagination={true}
          paginationAutoPageSize={true}
          ref={gridRef}
          rowSelection={'multiple'}
          rowMultiSelectWithClick={true}
          onCellClicked={(e) => getAE(e)}
          onRowClicked={(e) => getParams(e)}
        ></AgGridReact>
      </div>
      <MsgInformativo show={show} msg={msg} header={header} msgColor={msgColor} closeToast={closeToast} />
    </div>
  )
}

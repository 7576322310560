import React, { FC, useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import DashboardDS from '../../Services/Dashboard/Dashboard.Direccion.Services'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import DTOPedimentosCruzadosListaCliente from '../../DTO/Dashboard/DTOPedimentosCruzadosListaCliente'
import DTOPedimentosCruzadosData from '../../DTO/Dashboard/DTOPedimentosCruzadosData'
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Bar
} from 'recharts'
import { Chart } from 'react-google-charts'

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend as Legend6,
  Tooltip as Tooltip6,
  LineController,
  BarController
} from 'chart.js'
import { Chart as Chart6 } from 'react-chartjs-2'

interface IProps {}

interface IRechartsData {
  name: string
  uv: number
  pv: number
  amt: number
}

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend6,
  Tooltip6,
  LineController,
  BarController
)

const DashboardDireccion: FC<IProps> = (props) => {
  const [Clientes, setClientes] = useState<DTOPedimentosCruzadosListaCliente[]>(
    []
  )
  const [Data, setData] = useState<DTOPedimentosCruzadosData[]>([])
  const [Cliente, setCliente] = useState('')
  const [IDCliente, setIDCliente] = useState(0)
  const [Pedimentos, setPedimentos] = useState<number[]>([])
  const [Cruces, setCruces] = useState<number[]>([])
  const [series, setSeries] = useState([
    {
      type: 'line', //render a line chart for this data
      name: 'Pedimentos',
      data: [19, 22, 20, 26]
    },
    {
      type: 'column', //use column chart here.
      name: 'Cruces',
      data: [103, 105, 98, 83]
    }
  ])
  const [options, setOptions] = useState<ApexOptions>({
    xaxis: {
      categories: ['2022', '20022', '2023', '2023']
    },
    chart: {
      height: 100,
      type: 'line',
      zoom: {
        enabled: false
      }
    }
  })
  const [series2, setSeries2] = useState([
    {
      name: 'series1',
      data: [31, 40, 28, 51, 42, 109, 100]
    },
    {
      name: 'series2',
      data: [11, 32, 45, 32, 34, 52, 41]
    }
  ])
  const [options2, setOptions2] = useState<ApexOptions>({
    chart: {
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: ['Ene', 'Feb', 'Mar', 'Abr', 'Marzo', 'Abr', 'May']
    }
  })
  const [series3, setSeries3] = useState([
    {
      name: 'series1',
      data: [31, 40, 28, 51, 42, 109, 100]
    },
    {
      name: 'series2',
      data: [11, 32, 45, 32, 34, 52, 41]
    }
  ])
  const [options3, setOptions3] = useState<ApexOptions>({
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: [
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct'
      ]
    },
    yaxis: {
      title: {
        text: '$ (thousands)'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '$ ' + val + ' thousands'
        }
      }
    }
  })
  const [data4, setData4] = useState<IRechartsData[]>([
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290
    }
  ])
  const [data5, setData5] = useState([
    ['Mes', 'Pedimentos', 'Cruces'],
    ['2004/05', 165, 938],
    ['2005/06', 135, 1120],
    ['2006/07', 157, 1167],
    ['2007/08', 139, 1110],
    ['2008/09', 136, 691]
  ])
  const options5 = {
    title: '',
    vAxis: { title: '' },
    hAxis: { title: 'Meses' },
    seriesType: 'bars',
    series: { 1: { type: 'line' } },
    colors: ['#1850D0', '70D63E']
  }
  const [data6, setData6] = useState({
    labels: ['January', 'February', 'March'],
    datasets: [
      {
        type: 'line' as const,
        label: 'Pedimentos',
        borderColor: '#1850D0',
        borderWidth: 2,
        fill: false,
        data: [1, 2, 3]
      },
      {
        type: 'bar' as const,
        label: 'Cruces',
        backgroundColor: '#70D63E',
        data: [8, 9, 10],
        borderColor: 'white',
        borderWidth: 2
      }
    ]
  })

  const options6 = {
    indexAxis: 'x' as const,
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    responsive: true
  }

  useEffect(() => {
    DashboardDS.GETPedimentosCruzadosListaCliente()
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        return
      })
  }, [])

  useEffect(() => {
    if (Clientes.length > 0) setCustomer()
  }, [Clientes])

  useEffect(() => {
    DashboardDS.GETPedimentosCruzadosData(IDCliente)
      .then((response) => {
        setData(response.data)
        let Pedimentos: number[] = []
        let Cruces: number[] = []
        let Categorias: string[] = []
        let RechartsData: IRechartsData[] = []
        let RGoogleChartData: Array<Array<string | number>> = []
        RGoogleChartData.push(['Mes', 'Pedimentos', 'Cruces'])
        response.data.forEach(function (item) {
          Pedimentos.push(item.pedimentos)
          Cruces.push(item.despachos)
          Categorias.push(item.sMes)
          RechartsData.push({
            name: item.sMes,
            pv: item.pedimentos,
            uv: item.despachos,
            amt: 1
          })
          RGoogleChartData.push([item.sMes, item.pedimentos, item.despachos])
        })
        setData6({
          labels: Categorias,
          datasets: [
            {
              type: 'line' as const,
              label: 'Pedimentos',
              borderColor: '#1850D0',
              borderWidth: 2,
              fill: false,
              data: Pedimentos
            },
            {
              type: 'bar' as const,
              label: 'Cruces',
              backgroundColor: '#70D63E',
              data: Cruces,
              borderColor: 'white',
              borderWidth: 2
            }
          ]
        })
        setData5(RGoogleChartData)
        setData4(RechartsData)
        setSeries([
          {
            type: 'line', //render a line chart for this data
            name: 'Pedimentos',
            data: Pedimentos
          },
          {
            type: 'column', //use column chart here.
            name: 'Cruces',
            data: Cruces
          }
        ])
        setOptions({
          xaxis: {
            categories: Categorias
          },
          chart: {
            height: 100,
            type: 'line',
            zoom: {
              enabled: false
            }
          }
        })

        setSeries2([
          {
            name: 'Pedimentos',
            data: Pedimentos
          },
          {
            name: 'Cruces',
            data: Cruces
          }
        ])
        setOptions2({
          xaxis: {
            categories: Categorias
          },
          chart: {
            height: 100,
            type: 'line',
            zoom: {
              enabled: false
            }
          }
        })
      })
      .catch((e: Error) => {
        return
      })
  }, [IDCliente])

  const setCustomer = () => {
    console.log('entro al proceso de set customer')
    let idx = Math.floor(Math.random() * Clientes.length)
    for (var c = 0; c < Clientes.length; c++) {
      if (idx == c) {
        setIDCliente(Clientes[idx].id)
        setCliente(Clientes[idx].razonSocial)
        break
      }
    }
  }

  return (
    <>
      <Col>
        <Card style={{ width: '18rem' }} className="dashboardComponentFont">
          <Card.Header style={{ backgroundColor: '#1D6DC2', color: '#ffffff' }}>
            Dirección
          </Card.Header>
          <Card.Body style={{ paddingBottom: '0px' }}>
            <Card.Title>
              {Cliente}
              <br />
              <br />
            </Card.Title>
            <div style={{ fontSize: '6em' }} className="text-center">
              <Chart6
                type="bar"
                data={data6}
                options={options6}
                width="30%"
                height="25%"
              />
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to="../RptPedimentosCruces01"
              style={{
                textDecoration: 'none',
                float: 'right',
                paddingRight: '10px'
              }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card style={{ width: '18rem' }} className="dashboardComponentFont">
          <Card.Header style={{ backgroundColor: '#1D6DC2', color: '#ffffff' }}>
            Dirección
          </Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>
              {Cliente}
              <br />
              <br />
            </Card.Title>
            <div style={{ fontSize: '6em' }} className="text-center">
              <ReactApexChart options={options} series={series} />
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to="../RptPedimentosCruces02"
              style={{
                textDecoration: 'none',
                float: 'right',
                paddingRight: '10px'
              }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card style={{ width: '18rem' }} className="dashboardComponentFont">
          <Card.Header style={{ backgroundColor: '#1D6DC2', color: '#ffffff' }}>
            Dirección
          </Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>
              {Cliente}
              <br />
              <br />
            </Card.Title>
            <div style={{ fontSize: '6em' }} className="text-center">
              <ReactApexChart options={options2} series={series2} type="area" />
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to="../RptPedimentosCruces03"
              style={{
                textDecoration: 'none',
                float: 'right',
                paddingRight: '10px'
              }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card style={{ width: '18rem' }} className="dashboardComponentFont">
          <Card.Header style={{ backgroundColor: '#1D6DC2', color: '#ffffff' }}>
            Dirección
          </Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>
              {Cliente}
              <br />
              <br />
            </Card.Title>
            <div style={{ fontSize: '6em' }} className="text-center">
              <ReactApexChart options={options2} series={series2} type="bar" />
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to="../RptPedimentosCruces04"
              style={{
                textDecoration: 'none',
                float: 'right',
                paddingRight: '10px'
              }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card style={{ width: '18rem' }} className="dashboardComponentFont">
          <Card.Header style={{ backgroundColor: '#1D6DC2', color: '#ffffff' }}>
            Dirección
          </Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>
              {Cliente}
              <br />
              <br />
            </Card.Title>
            <ComposedChart
              width={250}
              height={170}
              data={data4}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" barSize={20} fill="#1850D0" name="Pedimentos" />
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#70D63E"
                name="Cruces"
              />
            </ComposedChart>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to="../RptPedimentosCruces05"
              style={{
                textDecoration: 'none',
                float: 'right',
                paddingRight: '10px'
              }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card style={{ width: '18rem' }} className="dashboardComponentFont">
          <Card.Header style={{ backgroundColor: '#1D6DC2', color: '#ffffff' }}>
            Dirección
          </Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>
              {Cliente}
              <br />
              <br />
            </Card.Title>
            <Chart
              chartType="ComboChart"
              width="100%"
              height="170px"
              data={data5}
              options={options5}
            />
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to="../RptPedimentosCruces06"
              style={{
                textDecoration: 'none',
                float: 'right',
                paddingRight: '10px'
              }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
    </>
  )
}

export default DashboardDireccion

import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import '../../../../css/printableArea.css'
import I325Pedidos from '../Interfaces/I325Pedidos'
import PedDataService from '../Services/CasaCuervo.Services'

interface IProps {}

interface IMedidaCaja {
  id: number
  Medida: string
}

export default function FormatoRevision (props: IProps) {
  const { id } = useParams()
  const [ID, setID] = useState<number>(0)
  const [Data, setData] = useState<I325Pedidos>()

  const mCatMedidas: IMedidaCaja[] = [
    { id: 0, Medida: 'SELECCIONE' },
    { id: 1, Medida: 'Caja 53 pies' },
    { id: 2, Medida: 'Caja 48 pies' },
    { id: 3, Medida: 'Refrigerada 53 pies' },
    { id: 4, Medida: 'Pipa' }
  ]

  const Print = () => {
    let printContents: string = (
      document.getElementById('printableDiv') as HTMLInputElement
    ).innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
  }

  const GetID = () => {
    let Id: string = id ?? '0'
    return parseInt(Id)
  }

  const loadData = () => {
    PedDataService.GetById(GetID())
      .then((response) => {
        setData(response.data)
        Print()
      })
      .catch((e: Error) => {
        return false
      })
  }

  /*  useEffect(() => {
    Print()
  }, []) */

  const GetCurrentDateTime = () => {
    var today = new Date()
    var date =
      today.getDate().toString().padStart(2, '0') +
      '-' +
      (today.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      today.getFullYear()
    var time =
      today.getHours() +
      ':' +
      today.getMinutes().toString().padStart(2, '0') +
      ':' +
      today.getSeconds().toString().padStart(2, '0')
    var dateTime = date + ' ' + time
    return dateTime
  }

  useEffect(() => {
    loadData()
  }, [id])

  return (
    <div id="printableDiv">
      <div className="printableDiv">
        <table>
          <tr>
            <th
              colSpan={6}
              style={{ textAlign: 'center', backgroundColor: '#FFFFFF' }}
            >
              Nota de revision de mercancia y sellos de seguridad: Casa Cuervo
            </th>
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>
              <span style={{ fontWeight: 'bold' }}>
                Fecha y hora de impresion:&nbsp;
              </span>
              {GetCurrentDateTime()}
            </td>
          </tr>

          <tr>
            <td>
              Sr Despachador / Tramitador
              <br />
              1. Tomar sellos de seguridad
              <br />
              2. Romper los sellos se seguridad de origen
              <br />
              3. Abrir caja trailer y tomar fotos de la mercancia
              <br />
              4. Vertificar numeros de lote contra factura
              <br />
              5. Contar la cantidad de bultos
              <br />
              6. Colocar la caja trailer y colocar nuevos sellos
            </td>
          </tr>
          <tr>
            <td>
              Si no se cuentra con sellos del cliente, poner sellos de GEMCO
              previa autorizacion del cliente
            </td>
          </tr>
          <tr style={{ width: '100%' }}>
            <th colSpan={6} style={{ backgroundColor: '#FFFFFF' }}>
              &nbsp;
            </th>
          </tr>
          <tr>
            <td>
              <table style={{ width: '100%' }}>
                <tr>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                  >
                    No. de pedido
                  </td>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                  >
                    No. de factura
                  </td>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                  >
                    Cartones
                  </td>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                  >
                    Bultos
                  </td>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                  >
                    No. de Caja
                  </td>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                  >
                    Medida de la caja
                  </td>
                </tr>
                <tr>
                  <td>{Data?.po}</td>
                  <td>{Data?.factura}</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>{Data?.truckNumber}</td>
                  <td>
                    {mCatMedidas
                      ? mCatMedidas.map((a) => {
                          return Data?.medidaCaja === a.id.toString()
                            ? a.Medida
                            : ''
                        })
                      : null}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>Nombre de la linea: </span>
              {Data?.forwarder}
            </td>
          </tr>
          <tr>
            <td>
              <table style={{ width: '100%' }}>
                <tr>
                  <th
                    colSpan={6}
                    style={{ textAlign: 'center', backgroundColor: '#FFFFFF' }}
                  >
                    Numero de sellos
                  </th>
                </tr>
                <tr>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                    width="30%"
                  >
                    Origen
                  </td>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                    width="10%"
                  >
                    Izquierdo
                  </td>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                    width="10%"
                  >
                    Derecho
                  </td>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                    width="30%"
                  >
                    Nuevos despues de la revision
                  </td>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                    width="10%"
                  >
                    Izquierdo
                  </td>
                  <td
                    style={{ backgroundColor: '#FFFFFF', fontWeight: 'bold' }}
                    width="10%"
                  >
                    Derecho
                  </td>
                </tr>
                <tr>
                  <td width="30%">{Data?.sello1}</td>
                  <td width="10%" style={{ backgroundColor: '#FFFFFF' }}>
                    &nbsp;
                  </td>
                  <td width="10%">&nbsp;</td>
                  <td width="30%" style={{ backgroundColor: '#FFFFFF' }}>
                    &nbsp;
                  </td>
                  <td width="10%">&nbsp;</td>
                  <td width="10%" style={{ backgroundColor: '#FFFFFF' }}>
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td width="30%">{Data?.sello2}</td>
                  <td width="10%">&nbsp;</td>
                  <td width="10%">&nbsp;</td>
                  <td width="30%">&nbsp;</td>
                  <td width="10%">&nbsp;</td>
                  <td width="10%">&nbsp;</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <th colSpan={6} style={{ backgroundColor: '#FFFFFF' }}>
              Numero de placa y estado de Origen
            </th>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <th colSpan={6} style={{ backgroundColor: '#FFFFFF' }}>
              Condiciones de la caja o pipa
            </th>
          </tr>
          <tr style={{ height: '90px' }}>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <th colSpan={6} style={{ backgroundColor: '#FFFFFF' }}>
              Resultado de la revision de la mercancia
            </th>
          </tr>
          <tr style={{ height: '90px' }}>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <table style={{ width: '100%' }}>
                <tr>
                  <td>&nbsp;</td>
                  <td style={{ backgroundColor: '#FFFFFF' }}>&nbsp;</td>
                </tr>
                <tr>
                  <th style={{ textAlign: 'center' }}>
                    Nombre y firma del revisor
                  </th>
                  <th style={{ textAlign: 'center' }}>
                    Fecha y hora de la revision
                  </th>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  )
}

import http from '../../common/http-common'
import IAnexoFacturacionMission from '../../../Interfaces/Facturacion/Anexo/IAnexoFacturacionMission'
import DTOAnexoFacturacionMission from '../../../DTO/Facturacion/Anexo/DTOAnexoFacturacionMission'
import IAnexoFacturacionAlen from '../../../Interfaces/Facturacion/Anexo/IAnexoFacturacionAlen'
import DTOAnexoFacturacionAlen from '../../../DTO/Facturacion/Anexo/DTOAnexoFacturacionAlen'

class AnexoFacturacionDataService {
  GetMission(data: DTOAnexoFacturacionMission) {
    return http.post<IAnexoFacturacionMission[]>(
      `/AnexoFacturacion/GetRptAnexoFacturacionMission`,
      data
    )
  }
  GetAlen(data: DTOAnexoFacturacionAlen) {
    return http.post<IAnexoFacturacionAlen[]>(
      `/AnexoFacturacion/GetRptAnexoFacturacionAlen`,
      data
    )
  }
}
export default new AnexoFacturacionDataService()

import React, { FC, useEffect, useState } from 'react'

import ITabulador from '../../../../Interfaces/Catalogos/ITabulador'
import ITabuladorConceptos from '../../../../Interfaces/Catalogos/ITabuladorConceptos'
import DTOConceptos from '../../../../DTO/Utils/DTOConceptos'

import CTabDataService from '../../../../Services/Corresponsalias/Corresponsales.Tabuladores.Services'
//import CTabDetDataService from '../../../../Services/Corresponsalias/Corresponsales.Tabuladores.Detalle.Sevices'
import AmazonSrvDataService from '../Services/Amazon.Services'

import ClientesDataService from '../../../../Services/Catalogos/Clientes.Services'

import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import { ControlledInput } from '../../../Utils/ControlledInput/ControlledInput'
import DataTable from 'react-data-table-component'
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import 'react-data-table-component-extensions/dist/index.css'
import {
  BsCheckCircleFill,
  BsPlusSquareFill,
  BsXCircleFill
} from 'react-icons/bs'
import IClientes from '../../../../Interfaces/Catalogos/IClientes'
import { FcTimeline } from 'react-icons/fc'
import { FaEraser, FaTimesCircle } from 'react-icons/fa'
import ICat2096CatConceptosConsulta from '../Interfaces/I2096CatConceptosConsulta'
import { populateCatConcetosConsultas, updateCatConcetosConsulta } from '../../../../store/features/Clientes/2096/AmazonCatConceptosConsulta'
import { useDispatch } from 'react-redux'

export interface ICatConceptosConsultaProps {}

export default function CatConceptosConsulta(
  props: ICatConceptosConsultaProps
) {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const dispatch = useDispatch()
  const [Tabulador, setTabulador] = useState(0)
  const [Id, setId] = useState(0)
  const [Concepto, setConcepto] = useState(0)
  const [Cliente, setCliente] = useState(0)
  const [Activo, setActivo] = useState(0)
  const [Clientes, setClientes] = useState<Array<IClientes>>()
  const [Siglas, setSiglas] = useState('')
  const [ShortDescription, setShortDescription] = useState('')
  const [SpanishDescription, setSpanishDescription] = useState('')
  const [SpanishDescriptionWide, setSpanishDescriptionWide] = useState('')
  const [DetalleConceptos, setDetalleConceptos] = useState<
    ITabuladorConceptos[]
  >([])
  const [CatConceptos, setCatConceptos] = useState<
    ICat2096CatConceptosConsulta[]
  >([])
  const [Tabuladores, setTabuladores] = useState<ITabulador[]>([])
  const [AllTabuladores, setAllTabuladores] = useState<ITabulador[]>([])
  const [Costo, setCosto] = useState('')
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [DialogTabs, setDialogTabs] = useState(false)

  const colConcepts = [
    {
      name: 'Activo',
      width: '80px',
      cell: (row: ICat2096CatConceptosConsulta) => (
        <div
          onClick={() => {
            loadData(row)
          }}
        >
          <IconContext.Provider
            value={{ color: row.activo ? 'green' : 'red', size: '20px' }}
          >
            {row.activo ? <BsCheckCircleFill /> : <BsXCircleFill />}
          </IconContext.Provider>
        </div>
      )
    },
    {
      name: 'Siglas',
      width: '80px',
      selector: (row: ICat2096CatConceptosConsulta) => row.siglas,
      sortable: true
    },
    {
      name: 'Descripcion corta',
      width: '300px',
      selector: (row: ICat2096CatConceptosConsulta) => row.shortDescription,
      wrap: true,
      sortable: true
    },
    {
      name: 'Descripcion español',
      width: '450px',
      selector: (row: ICat2096CatConceptosConsulta) => row.spanishDescription,
      wrap: true,
      sortable: true
    },
    {
      name: 'Descripcion amplia',
      width: '450px',
      selector: (row: ICat2096CatConceptosConsulta) =>
        row.spanishDescriptionWide,
      wrap: true,
      sortable: true
    }
  ]

  const loadData = (data: ICat2096CatConceptosConsulta) => {
    setId(data.id)
    setSiglas(data.siglas)
    setShortDescription(data.shortDescription)
    setSpanishDescription(data.spanishDescription)
    setSpanishDescriptionWide(data.spanishDescriptionWide)
    setActivo(data.activo ? 1 : 0)
  }

  const deleteTabulador = () => {
    CTabDataService.Delete(Tabulador)
      .then((response) => {
        if (response.status === 200) {
          setHeader('Informativo')
          setMsg(response.data.respuesta)
          setShowMsg(true)
          setDialogTabs(false)
          setTabuladores(Tabuladores.filter((data) => data.id != Tabulador))
          setAllTabuladores(Tabuladores)
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatalog = () => {
    AmazonSrvDataService.CatConceptosConsultaGET()
      .then((response) => {
        dispatch(populateCatConcetosConsultas(response.data))
        setCatConceptos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    loadCatalog()
  }, [])

  useEffect(() => {
    if (Cliente > 0) {
      setSiglas('')
      setTabuladores([])
      setDetalleConceptos([])
      CTabDataService.GetByCustomer(Cliente)
        .then((response) => {
          setTabuladores(response.data)
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }
  }, [Cliente])

  const filtraTabulador = (e: any) => {
    if (e.target.value !== '') {
      setTabuladores(
        AllTabuladores.filter(function (el) {
          return el.nombre
            .toLocaleLowerCase()
            .includes(e.target.value.toLocaleLowerCase())
        })
      )
    } else {
      setTabuladores(AllTabuladores)
    }
  }

  const save = () => {
    const data: ICat2096CatConceptosConsulta = {
      id: Id,
      siglas: Siglas,
      shortDescription: ShortDescription,
      spanishDescription: SpanishDescription,
      spanishDescriptionWide: SpanishDescriptionWide,
      activo: Activo==1
    }
    AmazonSrvDataService.CatConceptosConsultaAppend(data)
      .then((response) => {
        if (response.status === 200) {
          loadCatalog()
          setHeader('Informativo')
          setMsg('La informacion se guardo correctamente')
          setShowMsg(true)
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Card style={{ height: '100%' }}>
            <Card.Header as="h4">Catalogo de consultas para Amazon</Card.Header>
            <Card.Body>
              <Container fluid>
                <Alert variant="primary">
                  <Row>
                    <Col xs={1}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Siglas"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={Siglas}
                          maxLength={4}
                          onChange={(e) => {
                            setSiglas(e.target.value)
                          }}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={8}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Descripcion corta"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Descripcion corta"
                          value={ShortDescription}
                          onChange={(e) => {
                            setShortDescription(e.target.value)
                          }}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col
                      xs={1}
                      style={{
                        textAlign: 'left',
                        paddingTop: '5px',
                        cursor: 'pointer',
                        paddingLeft: '0px'
                      }}
                      onClick={() => {
                        setTabulador(0)
                        setSiglas('')
                      }}
                    >
                      <IconContext.Provider
                        value={{ color: 'orange', size: '25px' }}
                      >
                        <FaEraser />
                      </IconContext.Provider>
                    </Col>
                    <Col></Col>
                    <Col style={{ paddingTop: '20px' }}>
                      <Button
                        variant="primary"
                        size="sm"
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                        onClick={() => save()}
                      >
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Descripcion en español"
                        className="mb-3"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder=""
                          style={{ height: '100px' }}
                          value={SpanishDescription}
                          onChange={(e) => {
                            setSpanishDescription(e.target.value)
                          }}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={5}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Descripcion amplia"
                        className="mb-3"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Descripcion amplia"
                          style={{ height: '100px' }}
                          value={SpanishDescriptionWide}
                          onChange={(e) => {
                            setSpanishDescriptionWide(e.target.value)
                          }}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col style={{ paddingTop: '20px' }}>
                      <FloatingLabel controlId="floatingSelect" label="Activo">
                        <Form.Select
                          aria-label="Activo"
                          value={Activo}
                          onChange={(e) => {
                            setActivo(parseInt(e.target.value))
                          }}
                        >
                          <option value="1">Si</option>
                          <option value="0">No</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col></Col>
                  </Row>
                </Alert>
                <Row>
                  {/*                   <Col xs={6}>
                    <Card style={{}}>
                      <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">
                          <Row>
                            <Col xs={2} style={{ paddingTop: '8px' }}>
                              Tabulador
                            </Col>
                            <Col xs={10}>
                              <Form.Control
                                type="text"
                                size="sm"
                                placeholder="Busqueda de tabulador..."
                                onChange={(e) => {
                                  filtraTabulador(e)
                                }}
                              />
                            </Col>
                          </Row>
                        </Card.Subtitle>
                       
                      </Card.Body>
                    </Card>
                  </Col> */}
                  <Col xs={12}>
                    <Card style={{}}>
                      <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">
                          <Row>
                            <Col xs={12} style={{ paddingTop: '8px' }}>
                              <DataTable
                                noHeader
                                defaultSortFieldId={'id'}
                                defaultSortAsc={true}
                                pagination
                                highlightOnHover
                                columns={colConcepts}
                                data={CatConceptos}
                              />
                            </Col>
                            <Col xs={9}>
                              {/*  <Form.Control
                                as="select"
                                onChange={(e) =>
                                  setConcepto(parseInt(e.target.value))
                                }
                                className="form-select form-select-sm"
                                style={{ fontSize: '17px' }}
                              >
                                <option value="0">- Seleccione -</option>
                                {CatConceptos
                                  ? CatConceptos.map((c) => {
                                      return (
                                        <option value={c.id} key={c.id}>
                                          {c.concepto}
                                        </option>
                                      )
                                    })
                                  : null}
                              </Form.Control> */}
                            </Col>
                            <Col style={{ paddingTop: '5px' }}>
                              {/* <Form.Label
                                onClick={() => {
                                  addConcept()
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'green', size: '30px' }}
                                >
                                  <BsPlusSquareFill />
                                </IconContext.Provider>
                              </Form.Label> */}
                            </Col>
                          </Row>
                        </Card.Subtitle>
                        {/*   <DataTable
                          noHeader
                          defaultSortFieldId={'id'}
                          defaultSortAsc={true}
                          pagination
                          highlightOnHover
                          columns={columnsConcepts}
                          data={DetalleConceptos}
                        /> */}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
      <Modal
        show={DialogTabs}
        onHide={() => {
          setDialogTabs(false)
        }}
        size="sm"
      >
        <Modal.Body>
          ¿Esta seguro de eliminar:
          <br /> {Siglas}?
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6} style={{ textAlign: 'left', paddingRight: '100px' }}>
              <Button
                variant="danger"
                onClick={deleteTabulador}
                size="sm"
                style={{ paddingRight: '20px', paddingLeft: '20px' }}
              >
                Si
              </Button>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant="secondary"
                onClick={() => {
                  setDialogTabs(false)
                }}
                size="sm"
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import http from "../../common/http-common";
import INotificacionesContactos from "../../../Interfaces/Utils/INotificacionesContactos";
import INotificacionesGrupos from "../../../Interfaces/Utils/INotificacionesGrupos";
import INotificacionesContactosGrupos from "../../../Interfaces/Utils/INotificacionesContactosGrupos";
import DTONotificacionesContactoGrupo from "../../../DTO/Utils/Notificaciones/DTONotificacionesContactoGrupo";
import INotificacionesConceptos from "../../../Interfaces/Utils/INotificacionesConceptos";
import { consumers } from "stream";
import INotificacionesLog from "../../../Interfaces/Utils/INotificacionesLog";

class CorresponsalesDataService {
  SendWhattsapp(IdUsuario: number, Concepto: string, IdGrupo: number) {
    return http.get<INotificacionesGrupos[]>(`/Notificaciones/SendWhattsapp?IdUsuario=${IdUsuario}&Concepto=${Concepto}&IdGrupo=${IdGrupo}`);
  }
  Log$Get() {
    return http.get<INotificacionesLog[]>(`/Notificaciones/Log/Get`);
  }
  ContactosAppend(data: INotificacionesContactos) {
    return http.post<INotificacionesContactos>(`/Notificaciones/Contacto/Append`, data);
  }
  ContactosGet() {
    return http.get<INotificacionesContactos[]>(`/Notificaciones/Contacto/Get`);
  }
  GruposAppend(data: INotificacionesGrupos) {
    return http.post<INotificacionesGrupos>(`/Notificaciones/Grupos/Append`, data);
  }
  GruposGet() {
    return http.get<INotificacionesGrupos[]>(`/Notificaciones/Grupos/Get`);
  }
  AsignacionContactosGrupos(data: DTONotificacionesContactoGrupo) {
    return http.post<DTONotificacionesContactoGrupo>(`/Notificaciones/Asignacion/Contactos/Grupos`, data);
  }
  ContactosdelGrupoGet(IdGrupo: number) {
    return http.get<INotificacionesContactos[]>(`/Notificaciones/ContactosdelGrupo/Get?idGrupo=${IdGrupo}`);
  }
  Conceptos$Get() {
    return http.get<INotificacionesConceptos[]>(`/Notificaciones/Catalogo/Conceptos/Get`);
  }
  Conceptos$Append(data: INotificacionesConceptos) {
    return http.post<INotificacionesConceptos>(`/Notificaciones/Catalogo/Conceptos/Append`, data);
  }

}
export default new CorresponsalesDataService();
import http from "../common/http-common";
import IClientes from "../../Interfaces/Catalogos/IClientes";
import { IPerfil } from "../../Interfaces/Catalogos/IPerfiles";
import ITransportistas from '../../Interfaces/Catalogos/ITransportitas'
import DTOUsuarioCliente from '../../DTO/DTOUsuarioCliente'
import DTOasignaClienteProveedor from "../../DTO/DTOasignaClienteProveedor";
import DTOClienteTransportista from "../../DTO/DTOClienteTransportita";
import { IRegister } from "../../Interfaces/IRegister";


class ClientesDataService {
  /**
   *
   */
  constructor() {
    type returnData = [IClientes[]]    
  }

  getAllClientes(id:number) {
    return http.get<IClientes[]>(`/Clientes/getAllClientes?id=${id}`);
  }
  getUsuariosAsignados(id: number) {
    return http.get<IClientes[]>(`/Clientes/getClientesAsignados?id=${id}`);
  }
  create(data: DTOUsuarioCliente) {
    return http.post<IClientes[]>("/Clientes/addCliente", data);
  }
  asignaClienteProveedor(data:DTOasignaClienteProveedor) {
    return http.post<DTOasignaClienteProveedor[]>("/Clientes/asignaClienteProveedor", data);
  }
  asignaClienteTransportista(data:DTOClienteTransportista) {
    return http.post<ITransportistas[]>("/Clientes/asignaClienteTransportista", data);
  }
}
export default new ClientesDataService();
import * as React from 'react'
import { useEffect, useState } from 'react'
import { RootState } from '../../../../../store/store'
import DSAmazon from '../../Services/Amazon.Services'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import { MsgInformativo } from '../../../../Utils/Toast/msgInformativo'
import { AiFillFileExcel } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import { BsFilePdfFill } from 'react-icons/bs'
import { FaAmazon } from 'react-icons/fa'
import loadingImg from '../../../../../images/ajaxloader.gif'
import { MFileManager } from '../../../../Utils/MFileManager/MFileManager'
import DTO2096RptPayedOperations from '../../DTO/DTO2096RptPayedOperations'
import DTO2096InvoiceStatus from '../../DTO/DTO2096InvoiceStatus'
import IFileManager from '../../../../../Interfaces/Utils/IFileManager'
import * as XLSX from 'xlsx'
import { BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
export interface IRptAmazonInvoiceStatusProps {}

export default function RptAmazonInvoiceStatus(
  props: IRptAmazonInvoiceStatusProps
) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [header, setHeader] = useState('')
  const [Referencia, setReferencia] = useState('')
  const [Factura, setFactura] = useState('')
  const [Inicio, setInicio] = useState(currentDate())
  const [Fin, setFin] = useState(currentDate())
  const [Data, setData] = useState<DTO2096InvoiceStatus[]>([])
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [WaitingDialog, setWaitingDialog] = useState(false)
  const [WaitingMessage, setWaitingMessage] = useState('')
  const [msg, setMsg] = useState('')
  const [ShowModalAnswerFile, setShowModalAnswerFile] = useState(false)
  const [IDProcess, setIDProcess] = useState(25)
  const [IDFactura, setIDFactura] = useState(0)
  const [Invoices, setInvoices] = useState<number[]>([])
  const msgColor = 'primary'

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const loadReport = () => {
    let InicioValue
    let FinValue

    // Verifica si se proporcionaron valores para Inicio y Fin.
    if (Factura) {
      InicioValue = ''
      FinValue = ''
      setFactura('')
    } else {
      InicioValue = Inicio
      FinValue = Fin
    }

    DSAmazon.AmazonInvoiceStatusGET(Factura, InicioValue, FinValue)
      .then((response) => {
        setData(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrió un error: ' + e.message.toString())
      })
  }

  const handleFacturaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFactura(event.target.value)
  }

  const downloadPDF = (id: number, InvoiceNumber: string) => {
    setWaitingDialog(true)
    setWaitingMessage(' archivo PDF ')
    DSAmazon.PDFAmazonInvoiceGET(id)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'FAC_' + InvoiceNumber + '.pdf')
          document.body.appendChild(link)
          link.click()
          setWaitingDialog(false)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const downloadExcelInvoices = (Referencia: string) => {
    setWaitingMessage(' archivo Excel ')
    DSAmazon.GETAmazonFacturasByReference(Referencia)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', Referencia + '_FACTURAS_MODIFICA.xls')
          document.body.appendChild(link)
          link.click()
          // loadReport()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const downloadExcelPartidas = (Referencia: string) => {
    setWaitingMessage(' archivo Excel ')
    DSAmazon.GETAmazonPartidasByReference(Referencia)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', Referencia + '_PARTIDAS_MODIFICA.xls')
          document.body.appendChild(link)
          link.click()
          // loadReport()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const downloadNoPartes = (id: number, Factura: string) => {
    DSAmazon.NoPartesAmazonFinal(id)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'Plantilla de partes ' + Factura + '.xls'
          )
          document.body.appendChild(link)
          link.click()
          setWaitingDialog(false)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const downloadACKFile = (id: number, filename: string) => {
    DSAmazon.GETACKFileById(id)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          //loadReport()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de EstatusFacturas')
  }

  function exportExcel(
    jsonData: DTO2096InvoiceStatus[],
    fileName: string
  ): void {
    let Heading = [
      [
        'Id',
        'Referencia',
        'ComercialInvoiceDate',
        'InvoiceNumber',
        'Incoterms',
        'HAWB',
        'QtyUnitoOfMeasure',
        'totQty',
        'weightUnitOfMeasure',
        'TotWeight',
        'TotChargeorAllowance',
        'TotCurrencyIsoCode',
        'TotMonetaryAmout',
        'FechaPago',
        'Patente',
        'Aduana',
        'Pedimentos',
        'Estatus',
        'ACK',
        'Clasificador',
        'FechaRecepcion',
        'CantidadASIN'
      ]
    ]
    const dataOnly = jsonData.map(
      ({
        id,
        referencia,
        commercialInvoiceDate,
        invoiceNumber,
        incoterms,
        hawb,
        totalInvoiceQuantityUnitOfMeasure,
        totalInvoiceQuantity,
        totalInvoiceWeightUnitOfMeasure,
        totalInvoiceWeight,
        totalInvoiceValueChargeOrAllowance,
        totalInvoiceValueCurrencyISOCode,
        totalInvoiceValueMonetaryAmount,
        fPago,
        patente,
        aduana,
        pedimento,
        estatus,
        archivoACK,
        clasificador,
        fechaRecepcion,
        cantidadASIN
      }) => {
        return {
          id,
          referencia,
          commercialInvoiceDate,
          invoiceNumber,
          incoterms,
          hawb,
          totalInvoiceQuantityUnitOfMeasure,
          totalInvoiceQuantity,
          totalInvoiceWeightUnitOfMeasure,
          totalInvoiceWeight,
          totalInvoiceValueChargeOrAllowance,
          totalInvoiceValueCurrencyISOCode,
          totalInvoiceValueMonetaryAmount,
          fPago,
          patente,
          aduana,
          pedimento,
          estatus,
          archivoACK,
          clasificador,
          fechaRecepcion,
          cantidadASIN
        }
      }
    )
    const Report = dataOnly.map(
      ({
        id,
        referencia,
        commercialInvoiceDate,
        invoiceNumber,
        incoterms,
        hawb,
        totalInvoiceQuantityUnitOfMeasure,
        totalInvoiceQuantity,
        totalInvoiceWeightUnitOfMeasure,
        totalInvoiceWeight,
        totalInvoiceValueChargeOrAllowance,
        totalInvoiceValueCurrencyISOCode,
        totalInvoiceValueMonetaryAmount,
        fPago,
        patente,
        aduana,
        pedimento,
        estatus,
        archivoACK,
        clasificador,
        fechaRecepcion,
        cantidadASIN
      }) => {
        return {
          id,
          referencia,
          commercialInvoiceDate,
          invoiceNumber,
          incoterms,
          hawb,
          totalInvoiceQuantityUnitOfMeasure,
          totalInvoiceQuantity,
          totalInvoiceWeightUnitOfMeasure,
          totalInvoiceWeight,
          totalInvoiceValueChargeOrAllowance,
          totalInvoiceValueCurrencyISOCode,
          totalInvoiceValueMonetaryAmount,
          fPago,
          patente,
          aduana,
          pedimento,
          estatus,
          archivoACK,
          clasificador,
          fechaRecepcion,
          cantidadASIN
        }
      }
    )
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, Report, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c + 1; C <= range.e.c + 1; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const getOnlyDate = (dt: string) => {
    return dt ? dt.substring(0, 16) : ''
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={2}>
              <Card.Title>
                <IconContext.Provider value={{ color: '#F6C34F', size: '8%' }}>
                  <FaAmazon />
                </IconContext.Provider>
                Estatus Facturas Recibidas
              </Card.Title>
            </Col>
            <Col style={{ textAlign: 'right' }}>Inicio</Col>
            <Col xs={0}>
              <Form.Control
                defaultValue={Inicio}
                type="date"
                name="Inicio"
                placeholder="Inicio"
                title="Inicio"
                alt="Inicio"
                data-date-form="YYYY-mm-dd"
                onChange={(e) => setInicio(e.target.value)}
                size="sm"
              />
            </Col>
            <Col style={{ textAlign: 'right' }}>Fin</Col>
            <Col xs={0}>
              <Form.Control
                defaultValue={Fin}
                type="date"
                name="Fin"
                placeholder="Fin"
                title="Fin"
                alt="Fin"
                data-date-form="YYYY-mm-dd"
                onChange={(e) => setFin(e.target.value)}
                size="sm"
              />
            </Col>
            <Col style={{ textAlign: 'right' }}>Factura</Col>
            <Col xs={3}>
              <Form.Control
                style={{ height: '20px' }}
                // defaultValue={Factura}
                value={Factura}
                name="Factura"
                placeholder="Factura"
                title="Factura"
                alt="Factura"
                // onChange={(e) => setFactura(e.target.value)}
                onChange={handleFacturaChange}
                size="sm"
              />
            </Col>
            <Col
              style={{ textAlign: 'center' }}
              onClick={() => {
                loadReport()
              }}
            >
              <Button variant="primary">
                {' '}
                <BsSearch />
                Buscar
              </Button>
            </Col>
            <Col>
              <Button
                variant="success"
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel /> Excel
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="MDcontainer">
            <Table className="MDTable" hover>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr>
                  <th style={{ width: '50px' }}>id</th>
                  <th style={{ width: '50px' }}>PDF</th>
                  <th style={{ width: '50px' }}>Facturas</th>
                  <th style={{ width: '50px' }}>Partidas</th>
                  <th style={{ width: '50px' }}>Arch Excep.</th>
                  <th style={{ width: '50px' }}>No Partes</th>
                  <th style={{ width: '100px' }}>Referencia</th>
                  <th style={{ width: '80px' }}>Commercial Invoice Date</th>
                  <th style={{ width: '250px' }}>Invoice Number</th>
                  <th style={{ width: '100px' }}>Incoterms</th>
                  <th style={{ textAlign: 'center' }}>HAWB</th>
                  <th>Qty Unit of measure</th>
                  <th>Tot Qty</th>
                  <th>Weight unit of measure</th>
                  <th>Tot weight</th>
                  <th>Tot Charge or allowance</th>
                  <th>Tot currency ISO code</th>
                  <th>Tot Monetary amount</th>
                  <th>Fecha pago</th>
                  <th>Patente</th>
                  <th>Aduana</th>
                  <th>Pedimento</th>
                  <th>Estatus</th>
                  <th style={{ width: '100px' }}>ACK</th>
                  <th style={{ width: '100px' }}>Clasificador</th>
                  <th style={{ width: '80px' }}>Fecha Recepcion</th>
                  <th style={{ width: '80px' }}>Cantidad ASIN</th>
                </tr>
              </thead>
              <tbody>
                {Data
                  ? Data.map((MasterData) => {
                      return (
                        <>
                          <tr
                            style={{ cursor: 'pointer', height: '25px' }}
                            className={'normalSelected'}
                          >
                            <td
                              style={{
                                textAlign: 'right',
                                paddingRight: '10px'
                              }}
                            >
                              {MasterData.id}
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                              <div
                                onClick={() => {
                                  downloadPDF(
                                    MasterData.id,
                                    MasterData.invoiceNumber
                                  )
                                }}
                                style={{
                                  visibility: MasterData.estatus.includes(
                                    'Clasificacion'
                                  )
                                    ? 'hidden'
                                    : 'visible'
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'red', size: '20px' }}
                                >
                                  <BsFilePdfFill />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                textAlign: 'center',
                                visibility: MasterData.referencia
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <div
                                onClick={() => {
                                  downloadExcelInvoices(MasterData.referencia)
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'green', size: '20px' }}
                                >
                                  <AiFillFileExcel />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                textAlign: 'center',
                                visibility: MasterData.referencia
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <div
                                onClick={() => {
                                  downloadExcelPartidas(MasterData.referencia)
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'green', size: '20px' }}
                                >
                                  <AiFillFileExcel />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                textAlign: 'center',
                                visibility: MasterData.referencia
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <div
                                onClick={() => {
                                  setIDFactura(MasterData.id)
                                  setShowModalAnswerFile(true)
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'orange', size: '20px' }}
                                >
                                  <AiFillFileExcel />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                textAlign: 'center',
                                visibility: MasterData.referencia
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <div
                                onClick={() => {
                                  downloadNoPartes(
                                    MasterData.id,
                                    MasterData.invoiceNumber
                                  )
                                }}
                                style={{
                                  visibility: MasterData.estatus.includes(
                                    'Clasificacion'
                                  )
                                    ? 'hidden'
                                    : 'visible'
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '20px' }}
                                >
                                  <AiFillFileExcel />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td style={{ width: '100px', textAlign: 'center' }}>
                              {MasterData.referencia}
                            </td>
                            <td style={{ width: '80px', textAlign: 'center' }}>
                              {MasterData.commercialInvoiceDate}
                            </td>
                            <td style={{ width: '300px' }}>
                              {MasterData.invoiceNumber}
                            </td>
                            <td style={{ width: '100px', textAlign: 'center' }}>
                              {MasterData.incoterms}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.hawb}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantityUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantity}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeightUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeight}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueChargeOrAllowance}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueCurrencyISOCode}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueMonetaryAmount}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.fPago.substring(0, 10)}
                            </td>
                            <td style={{ textAlign: 'center', width: '100px' }}>
                              {MasterData.patente}
                            </td>
                            <td style={{ textAlign: 'center', width: '60px' }}>
                              {MasterData.aduana}
                            </td>
                            <td style={{ textAlign: 'center', width: '100px' }}>
                              {MasterData.pedimento}
                            </td>
                            <td style={{ textAlign: 'center', width: '200px' }}>
                              {MasterData.estatus}
                            </td>
                            <td
                              style={{ textAlign: 'center', width: '100px' }}
                              onClick={() => {
                                downloadACKFile(
                                  MasterData.idArchivoACK,
                                  MasterData.archivoACK
                                )
                              }}
                            >
                              {getOnlyDate(MasterData.fhArchivoACK)}
                            </td>
                            <td style={{ width: '200px', textAlign: 'center' }}>
                              {MasterData.clasificador}
                            </td>
                            <td style={{ width: '80px', textAlign: 'center' }}>
                              {MasterData.fechaRecepcion}
                            </td>
                            <td style={{ width: '80px', textAlign: 'center' }}>
                              {MasterData.cantidadASIN}
                            </td>
                          </tr>
                        </>
                      )
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>

      <Modal
        show={WaitingDialog}
        onHide={() => {
          setWaitingDialog(false)
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        dialogClassName={'modal-50w'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={loadingImg} style={{ height: '150px' }} alt="proccessing" />
          Generando {WaitingMessage}...
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={ShowModalAnswerFile}
        onHide={() => {
          setShowModalAnswerFile(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '330px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col></Col>
              <Col xs={10} style={{ textAlign: 'center' }}>
                <MFileManager
                  IDTrafico={IDFactura}
                  Proceso={IDProcess}
                  showPreview={3}
                  canEdit={false}
                  Leyenda="Lista de archivos de respuesta de Amazon"
                  onAppendMFM={function (Files: IFileManager[]): void {}}
                />
              </Col>
              <Col></Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

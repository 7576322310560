import { useEffect, useState } from 'react'
import { Card, Col, ProgressBar, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { FaAmazon } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import DashboardDataService from '../../Services/Dashboard/Dashboard.Clasificacion.Services'

export interface IDashboardClasificacionProps {}

export function DashboardClasificacion(props: IDashboardClasificacionProps) {
  const [Terminadas, setTerminadas] = useState(0)
  const [Pendientes, setPendientes] = useState(0)
  const [PendientesRespuesta, setPendientesRespuesta] = useState(0)
  const [Total, setTotal] = useState(0)

  useEffect(() => {
    DashboardDataService.DashboardInvoices(1)
      .then((response) => {
        setPendientes(response.data.total)
      })
      .catch((e: Error) => {
        return
      })
    DashboardDataService.DashboardInvoices(2)
      .then((response) => {
        setTerminadas(response.data.total)
      })
      .catch((e: Error) => {
        return
      })
      DashboardDataService.DashboardInvoices(-1)
      .then((response) => {
        setPendientesRespuesta(response.data.total)
      })
      .catch((e: Error) => {
        return
      })      
  }, [])

  useEffect(() => {
    setTotal(Pendientes + Terminadas)
  }, [Pendientes, Terminadas])

  return (
    <>
      <Col>
        <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
          <Card.Header>Clasificación</Card.Header>
          <Card.Body>
            <Card.Title>
              Facturas {' '}
              <IconContext.Provider value={{ color: '#F6C34F', size:'20%' }}>
                <FaAmazon />
              </IconContext.Provider>
            </Card.Title>
            <Row>
              <Col xs={6} style={{}}>
                <Link
                  to="../RptAmazonFinishedClasificationInvoices"
                  style={{
                    textDecoration: 'none',
                    float: 'left',
                    paddingRight: '10px'
                  }}
                >
                  Terminadas
                </Link>
              </Col>
              <Col xs={6} className="text-center">
                <Link
                  to="../RptAmazonPendingClasificationInvoices"
                  style={{
                    textDecoration: 'none',
                    float: 'right',
                    paddingRight: '10px'
                  }}
                >
                  Pendientes
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ProgressBar style={{ height: '10px' }}>
                  <ProgressBar
                    striped
                    animated
                    variant="success"
                    now={(Terminadas / Total) * 100}
                    key={1}
                  />
                  <ProgressBar
                    striped
                    variant="warning"
                    now={(Pendientes / Total) * 100}
                    key={2}
                  />
                </ProgressBar>
              </Col>
            </Row>
            <Row>
              <Col xs={6} style={{ fontSize: '4em' }} className="text-center">
                {Terminadas}
              </Col>
              <Col xs={6} style={{ fontSize: '4em' }} className="text-center">
                {Pendientes}
              </Col>
            </Row>
            <Row>
              <Col xs={12}><Link
          to='../RptPendingAnswerFromAmazon'
          style={{ textDecoration: 'none', float: 'left' }}
        >
          Facturas con excepciones <span style={{fontSize:'50px', color: 'red'}}>({PendientesRespuesta})</span>
        </Link></Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  )
}

import * as React from 'react'
import { useEffect, useState } from 'react'
import { RootState } from '../../../../../store/store'
import I2096Headers from '../../Interfaces/I2096Header'
import DSAmazon from '../../Services/Amazon.Services'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import {
  initializeInvoice,
  populateInvoices,
  updateInvoice
} from '../../../../../store/features/Clientes/2096/AmazonInvoices'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import { MsgInformativo } from '../../../../Utils/Toast/msgInformativo'
import { AiFillFileExcel } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import { BsChevronDown, BsChevronRight, BsFilePdfFill } from 'react-icons/bs'
import { FaAmazon } from 'react-icons/fa'
import loadingImg from '../../../../../images/ajaxloader.gif'
import { MFileManager } from '../../../../Utils/MFileManager/MFileManager'
import DTO2096RptPayedOperations from '../../DTO/DTO2096RptPayedOperations'
import { GoGear } from "react-icons/go";
import IFileManager from '../../../../../Interfaces/Utils/IFileManager'

export interface IRptAmazonPayedOpereationsProps {}

export default function RptAmazonPayedOpereations(
  props: IRptAmazonPayedOpereationsProps
) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [header, setHeader] = useState('')
  const [Referencia, setReferencia] = useState('')
  const [Inicio, setInicio] = useState(currentDate())
  const [Fin, setFin] = useState(currentDate())
  const [Data, setData] = useState<DTO2096RptPayedOperations[]>([])
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [WaitingDialog, setWaitingDialog] = useState(false)
  const [WaitingMessage, setWaitingMessage] = useState('')
  const [msg, setMsg] = useState('')
  const [ShowModalAnswerFile, setShowModalAnswerFile] = useState(false)
  const [IDProcess, setIDProcess] = useState(25)
  const [IDFactura, setIDFactura] = useState(0)
  const [Invoices, setInvoices] = useState<number[]>([])
  const msgColor = 'primary'

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const loadReport = () => {
    DSAmazon.AmazonPayedOperationsGET(Inicio, Fin)
      .then((response) => {
        setData(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const downloadPDF = (id: number, InvoiceNumber: string) => {
    setWaitingDialog(true)
    setWaitingMessage(' archivo PDF ')
    DSAmazon.PDFAmazonInvoiceGET(id)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'FAC_' + InvoiceNumber + '.pdf')
          document.body.appendChild(link)
          link.click()
          setWaitingDialog(false)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const downloadExcelInvoices = (Referencia: string) => {
    setWaitingMessage(' archivo Excel ')
    DSAmazon.GETAmazonFacturasByReference(Referencia)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', Referencia + '_FACTURAS_MODIFICA.xls')
          document.body.appendChild(link)
          link.click()
          loadReport()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const downloadExcelPartidas = (Referencia: string) => {
    setWaitingMessage(' archivo Excel ')
    DSAmazon.GETAmazonPartidasByReference(Referencia)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', Referencia + '_PARTIDAS_MODIFICA.xls')
          document.body.appendChild(link)
          link.click()
          loadReport()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const downloadNoPartes = (id: number, Factura: string) => {
    DSAmazon.NoPartesAmazon2SIRGET(id)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'Plantilla de partes ' + Factura + '.xls'
          )
          document.body.appendChild(link)
          link.click()
          setWaitingDialog(false)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const getOnlyDate = (dt: string) => {
    return dt ? dt.substring(0, 16) : ''
  }

  const downloadACKFile = (id: number, filename: string) => {
    DSAmazon.GETACKFileById(id)
    .then((response: any) => {
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download',filename)
        document.body.appendChild(link)
        link.click()
        //loadReport()
      }
    })
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Ocurrio un error')
      setShowMsg(true)
      return
    })
  }

  const createACKFile = (referencia: string) => {
    DSAmazon.CreateACKFile(referencia)
    .then((response: any) => {
      DSAmazon.UploadACKFile2Amazon(referencia)
      .then((response: any) => {
        setHeader('Informativo')
        setMsg('Se envio el archivo ACK a Amazon')
        setShowMsg(true)
        loadReport()
        return
      })
      .catch((e: Error) => {
        console.log(JSON.stringify(e))
        setHeader('Error')
        setMsg('Ocurrio un error '+e)
        setShowMsg(true)
        return
      })
    })
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Ocurrio un error')
      setShowMsg(true)
      return
    })
  }

  useEffect(() => {
    loadReport()
  }, [])

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={5}>
              <Card.Title>
                <IconContext.Provider value={{ color: '#F6C34F', size: '8%' }}>
                  <FaAmazon />
                </IconContext.Provider>
                Operaciones Pagadas
              </Card.Title>
            </Col>
            <Col></Col>
            <Col style={{ textAlign: 'right' }}>Desde</Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Inicio}
                type="date"
                name="Inicio"
                placeholder="Inicio"
                title="Inicio"
                alt="Inicio"
                data-date-format="YYYY-mm-dd"
                onChange={(e) => setInicio(e.target.value)}
                size="sm"
              />
            </Col>
            <Col style={{ textAlign: 'right' }}>Hasta</Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Fin}
                type="date"
                name="Fin"
                placeholder="Fin"
                title="Fin"
                alt="Fin"
                onChange={(e) => setFin(e.target.value)}
                size="sm"
              />
            </Col>
            <Col
              style={{ textAlign: 'right' }}
              onClick={() => {
                loadReport()
              }}
            >
              <Button variant="primary">Buscar</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="MDcontainer">
            <Table className="MDTable" hover>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr>
                  <th style={{ width: '50px' }}>id</th>
                  <th style={{ width: '50px' }}>PDF</th>
                  <th style={{ width: '50px' }}>Facturas</th>
                  <th style={{ width: '50px' }}>Partidas</th>
                  <th style={{ width: '50px' }}>Arch Excep.</th>
                  <th style={{ width: '50px' }}>No Partes</th>
                  <th style={{ width: '100px' }}>Referencia</th>
                  <th style={{ width: '80px' }}>Commercial Invoice Date</th>
                  <th style={{ width: '250px' }}>Invoice Number</th>
                  <th style={{ width: '100px' }}>Incoterms</th>
                  <th style={{ textAlign: 'center' }}>HAWB</th>
                  <th>Qty Unit of measure</th>
                  <th>Tot Qty</th>
                  <th>Weight unit of measure</th>
                  <th>Tot weight</th>
                  <th>Tot Charge or allowance</th>
                  <th>Tot currency ISO code</th>
                  <th>Tot Monetary amount</th>
                  <th>Fecha pago</th>
                  <th>Patente</th>
                  <th>Aduana</th>
                  <th>Pedimento</th>
                  <th style={{ width: '150px' }}>ACK</th>
                </tr>
              </thead>
              <tbody>
                {Data
                  ? Data.map((MasterData) => {
                      return (
                        <>
                          <tr
                            style={{ cursor: 'pointer', height: '25px' }}
                            className={'normalSelected'}
                          >
                            <td
                              style={{
                                textAlign: 'right',
                                paddingRight: '10px'
                              }}
                            >
                              {MasterData.id}
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                              <div
                                onClick={() => {
                                  downloadPDF(
                                    MasterData.id,
                                    MasterData.invoiceNumber
                                  )
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'red', size: '20px' }}
                                >
                                  <BsFilePdfFill />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                textAlign: 'center',
                                visibility: MasterData.referencia
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <div
                                onClick={() => {
                                  downloadExcelInvoices(MasterData.referencia)
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'green', size: '20px' }}
                                >
                                  <AiFillFileExcel />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                textAlign: 'center',
                                visibility: MasterData.referencia
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <div
                                onClick={() => {
                                  downloadExcelPartidas(MasterData.referencia)
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'green', size: '20px' }}
                                >
                                  <AiFillFileExcel />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                textAlign: 'center',
                                visibility: MasterData.referencia
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <div
                                onClick={() => {
                                  setIDFactura(MasterData.id)
                                  setShowModalAnswerFile(true)
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'orange', size: '20px' }}
                                >
                                  <AiFillFileExcel />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                textAlign: 'center',
                                visibility: MasterData.referencia
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <div
                                onClick={() => {
                                  downloadNoPartes(
                                    MasterData.id,
                                    MasterData.invoiceNumber
                                  )
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '20px' }}
                                >
                                  <AiFillFileExcel />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td style={{ width: '100px', textAlign: 'center' }}>
                              {MasterData.referencia}
                            </td>
                            <td style={{ width: '80px', textAlign: 'center' }}>
                              {MasterData.commercialInvoiceDate}
                            </td>
                            <td style={{ width: '300px' }}>
                              {MasterData.invoiceNumber}
                            </td>
                            <td style={{ width: '100px', textAlign: 'center' }}>
                              {MasterData.incoterms}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.hawb}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantityUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantity}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeightUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeight}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueChargeOrAllowance}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueCurrencyISOCode}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueMonetaryAmount}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.fPago.substring(0, 10)}
                            </td>
                            <td style={{ textAlign: 'center', width: '100px' }}>
                              {MasterData.patente}
                            </td>
                            <td style={{ textAlign: 'center', width: '60px' }}>
                              {MasterData.aduana}
                            </td>
                            <td style={{ textAlign: 'center', width: '100px' }}>
                              {MasterData.pedimento}
                            </td>
                            {MasterData.archivoACK==='' ?
                            <td style={{textAlign:'center'}}>
                              <div
                                onClick={() => { createACKFile(MasterData.referencia) }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'orange', size: '22px' }}
                                >
                                  <GoGear />
                                </IconContext.Provider>
                              </div>
                            </td>
                            :
                            <td
                              style={{ textAlign: 'center', width: '100px' }}
                              onClick={() => {
                                downloadACKFile(MasterData.idArchivoACK, MasterData.archivoACK)
                              }}
                            >
                              {getOnlyDate(MasterData.fhArchivoACK)}
                            </td>
                  }

                          </tr>
                        </>
                      )
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={WaitingDialog}
        onHide={() => {
          setWaitingDialog(false)
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        dialogClassName={'modal-50w'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={loadingImg} style={{ height: '150px' }} alt="proccessing" />
          Generando {WaitingMessage}...
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={ShowModalAnswerFile}
        onHide={() => {
          setShowModalAnswerFile(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '330px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col></Col>
              <Col xs={10} style={{ textAlign: 'center' }}>
                <MFileManager
                  IDTrafico={IDFactura}
                  Proceso={IDProcess}
                  showPreview={3}
                  canEdit={false}
                  Leyenda="Lista de archivos de respuesta de Amazon" onAppendMFM={function (Files: IFileManager[]): void {
                  } }                />
              </Col>
              <Col></Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

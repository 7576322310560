import http from '../../../../Services/common/http-common';
import DTO1868Clientes from '../DTO/DTO1868Clientes';
import DTO1868Factura from '../DTO/DTO1868Factura';
import DTO1868FacturaDetalle from '../DTO/DTO1868FacturaDetalle';
import DTO1868ReporteFactura from '../DTO/DTO1868ReporteFactura';
import DTO1868Transportista from '../DTO/DTO1868Transportista';
import DTO1868SCACCAAT from '../DTO/DTO1868SCACCAAT';
import DTORespuesta from '../DTO/DTORespuesta';
import I1868CatClaveSAT from '../Interfaces/I1868CatClaveSAT';
import I1868CatDestinos from '../Interfaces/I1868CatDestinos';
import I1868CatReponsables from '../Interfaces/I1868CatResponsables';
import I1868Factura from "../Interfaces/I1868Factura";
import I1868FacturaDetalle from '../Interfaces/I1868FacturaDetalle';

class C1868FacturasDataService {
  getAll(Inicio: string, Fin: string, UserId: number) {
    return http.get<DTO1868ReporteFactura[]>(`/Clientes/1868/C1868Factura/getAll?Inicio=${Inicio}&Fin=${Fin}&UserId=${UserId}`);
  }
  getAllDetail(Inicio: string, Fin: string) {
    return http.get<I1868FacturaDetalle[]>(`/Clientes/1868/C1868Factura/getAllDetail?Inicio=${Inicio}&Fin=${Fin}`);
  }
  getClientes() {
    return http.get<DTO1868Clientes[]>(`/Clientes/1868/C1868Factura/getClientes`);
  }
  getCatClaveSAT() {
    return http.get<I1868CatClaveSAT[]>(`/Clientes/1868/C1868Factura/getCatClaveSAT`);
  }
  getCatDestinos() {
    return http.get<I1868CatDestinos[]>(`/Clientes/1868/C1868Factura/getCatDestinos`);
  }
  getCatResponsables() {
    return http.get<I1868CatReponsables[]>(`/Clientes/1868/C1868Factura/getCatResponsables`);
  }
  appendCatDestino(data: I1868CatDestinos) {
    return http.post<I1868CatDestinos>(`/Clientes/1868/C1868Factura/appendCatDestinos`, data);
  }
  appendCatResponsables(data: I1868CatReponsables) {
    return http.post<I1868CatReponsables>(`/Clientes/1868/C1868Factura/appendCatResponsables`, data);
  }
  getRptTransportista(Inicio: string, Fin: string, UserId: number) {
    return http.get<DTO1868Transportista[]>(`/Clientes/1868/C1868Factura/getAll?Inicio=${Inicio}&Fin=${Fin}&UserId=${UserId}`);
  }
  Append(id: number, Proceso: number) {
    return http.post<I1868Factura[]>(`/Clientes/1868/C1868Factura/UploadFile?id=${id}&Proceso=${Proceso}`);
  }
  RelateByProcess(id: number, idFile: number, Proceso: number) {
    return http.post<I1868Factura[]>(`/Clientes/1868/C1868Factura/RelateByProcess?id=${id}&idFile=${idFile}&Proceso=${Proceso}`);
  }
  Update(data: DTO1868Factura) {
    return http.put<DTORespuesta>(`/Clientes/1868/C1868Factura/UpdateMaster/${data.id}`,data);
  }
  UpdateDetail(data: DTO1868FacturaDetalle) {
    return http.put<I1868FacturaDetalle>(`/Clientes/1868/C1868Factura/UpdateDetail/${data.id}`,data);
  }
  getFileContent(id: number, Proceso: number) {
    return http.get<ArrayBuffer>(`/Clientes/1868/C1868Factura/getFileContent?id=${id}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  } 
  AppendSCACCAAT(data: DTO1868SCACCAAT) {
    return http.put<DTO1868SCACCAAT>(`/Clientes/1868/C1868Factura/AppendSCACCAAT/${data.id}`, data);
  }
}
export default new C1868FacturasDataService();
import { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import DTOPedimentosCruzadosListaCliente from '../../../DTO/Dashboard/DTOPedimentosCruzadosListaCliente'
import DTOPedimentosCruzadosData from '../../../DTO/Dashboard/DTOPedimentosCruzadosData'
import DashboardDS from '../../../Services/Dashboard/Dashboard.Direccion.Services'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { Bar, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts'

export interface IRptPedimentosCruces05Props {
}

interface IRechartsData {
    name: string
    uv: number
    pv: number
    amt: number
  }

export default function RptPedimentosCruces05 (props: IRptPedimentosCruces05Props) {
    const [Clientes, setClientes] = useState<DTOPedimentosCruzadosListaCliente[]>(
        []
      )
      const [Data, setData] = useState<DTOPedimentosCruzadosData[]>([])
      const [Cliente, setCliente] = useState('')
      const [IDCliente, setIDCliente] = useState(0)
      const [Pedimentos, setPedimentos] = useState<number[]>([])
      const [Cruces, setCruces] = useState<number[]>([])
      const [data4, setData4] = useState<IRechartsData[]>([
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 1398,
          amt: 2210
        },
        {
          name: 'Page C',
          uv: 2000,
          pv: 9800,
          amt: 2290
        }
      ])
    
      useEffect(() => {
        DashboardDS.GETPedimentosCruzadosListaCliente()
          .then((response) => {
            setClientes(response.data)
          })
          .catch((e: Error) => {
            return
          })
      }, [])
    
      useEffect(() => {
        DashboardDS.GETPedimentosCruzadosData(IDCliente)
          .then((response) => {
            setData(response.data)
            let Pedimentos: number[] = []
            let Cruces: number[] = []
            let Categorias: string[] = []
            let RechartsData: IRechartsData[] = []
            let RGoogleChartData: Array<Array<string | number>> = []
            RGoogleChartData.push(['Mes', 'Pedimentos', 'Cruces'])
            response.data.forEach(function (item) {
              Pedimentos.push(item.pedimentos)
              Cruces.push(item.despachos)
              Categorias.push(item.sMes)
              RechartsData.push({
                name: item.sMes,
                pv: item.pedimentos,
                uv: item.despachos,
                amt: 1
              })
              RGoogleChartData.push([item.sMes, item.pedimentos, item.despachos])
            })
            setData4(RechartsData)
            var arrClientes = Clientes.filter((item) => {
              return item.id == IDCliente
            })
            setCliente(arrClientes[0].razonSocial)
          })
          .catch((e: Error) => {
            return
          })
      }, [IDCliente])
    
      return (
        <div>
          <Card>
            <Card.Body>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setIDCliente(parseInt(e.target.value))
                }}
                className="form-select form-select-sm"
              >
                <option value="0">Seleccion el cliente</option>
                {Clientes
                  ? Clientes.map((c) => {
                      return <option value={c.id}>{c.razonSocial}</option>
                    })
                  : null}
              </Form.Control>
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                {Cliente}
                <br />
                <br />
              </Card.Title>
              <ComposedChart
              width={1500}
              height={600}
              data={data4}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" barSize={20} fill="#1850D0" name="Pedimentos" />
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#70D63E"
                name="Cruces"
              />
            </ComposedChart>
            </Card.Body>
          </Card>
        </div>
      )
    }
    
import { FC, useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import {
  BsCloudArrowUpFill,
  BsFileEarmarkExcel,
  BsFillPencilFill,
  BsSearch
} from 'react-icons/bs'
import I325Pedidos from '../Interfaces/I325Pedidos'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import PedDataService from '../Services/CasaCuervo.Services'
import DataTable from 'react-data-table-component'
import DTO325AduanasPedidos from '../../../../DTO/Clientes/CasaCuervo/DTO325AduanasPedidos'
import * as XLSX from 'xlsx'
import { IconContext } from 'react-icons'
import FileManager from '../../../Utils/FileManager/FileManager'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import DTO325UpdateFromWeb from '../../../../DTO/Clientes/CasaCuervo/DTO325UpdateFromWeb'
import I325RptCOVEs from '../Interfaces/I325RptCOVEs'


interface IMedidaCaja {
  id: number
  Medida: string
}

export interface IRptCOVEsProps {
}

export default function RptCasaCuervoCOVEs (props: IRptCOVEsProps) {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [UserType, setUserType] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserType')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const queryParams = new URLSearchParams(window.location.search)
  const [Search, setSearch] = useState('')
  const [DoneColor, setDoneColor] = useState('#CCD1D1')
  const [CurrentTime2Number, setCurrentTime2Number] = useState(0)
  const [Data, setData] = useState<I325RptCOVEs[]>([])
  const [DataOriginal, setDataOriginal] = useState<I325RptCOVEs[]>([])
  const [Aduanas, setAduanas] = useState<DTO325AduanasPedidos[]>([])
  const [Aduana, setAduana] = useState('Aduanas...')
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-30))
  const [Fin, setFin] = useState(currentDate(0))
  const [filtro, setFiltro] = useState('')
  const [tipoReporte, setTipoReporte] = useState(0)
  const [msgColor, setMsgColor] = useState('primary')
  const [modalFileManager, setModalFileManager] = useState(false)
  const [modalFileManagerCorresponsal, setModalFileManagerCorresponsal] =
    useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [ComentarioGEMCO, setComentarioGEMCO] = useState('')
  const [fechaCompromiso, setFechaCompromiso] = useState<Date | null>()
  const [fechaCruce, setFechaCruce] = useState<Date | null>()
  const [DTOfechaCompromiso, setDTOFechaCompromiso] = useState<string>('')
  const [DTOfechaCruce, setDTOFechaCruce] = useState<string>('')
  const [PO, setPO] = useState('')
  const [Carrier, setCarrier] = useState('')
  const [IDPedido, setIDPedido] = useState(0)
  const [Factura, setFactura] = useState('')
  const [MedidaCaja, setMedidaCaja] = useState('')
  const [Sello1, setSello1] = useState('')
  const [Sello2, setSello2] = useState('')
  const [UUID, setUUID] = useState('')
  const [Trafico, setTrafico] = useState('')
  const [Pedimento, setPedimento] = useState('')
  const [Patente, setPatente] = useState('')
  const [ModalTmpPDF, setModalTmpPDF] = useState(false)
  const [NoPedido, setNoPedido] = useState('')
  const [NoCaja, setNoCaja] = useState('')

  const columnsConcepts = [
    {
      name: 'Factura',
      width: '120px',
      selector: (row: I325RptCOVEs) => row.factura,
      sortable: true
    },
    {
      name: 'COVE',
      width: '150px',
      selector: (row: I325RptCOVEs) => row.cove,
      sortable: true
    },
    {
      name: 'Pedimento',
      width: '120px',
      selector: (row: I325RptCOVEs) => row.pedimento,
      sortable: true
    },
    {
      name: 'Clave',
      width: '80px',
      selector: (row: I325RptCOVEs) => row.clave,
      sortable: true
    },
    {
      name: 'Fecha pago',
      width: '170px',
      selector: (row: I325RptCOVEs) => row.fechaPago,
      sortable: true
    },
    {
      name: 'Vehiculo',
      width: '150px',
      selector: (row: I325RptCOVEs) => row.vehiculo,
      sortable: true
    }
  ]

  const mCatMedidas: IMedidaCaja[] = [
    { id: 0, Medida: 'SELECCIONE' },
    { id: 1, Medida: 'Caja 53 pies' },
    { id: 2, Medida: 'Caja 48 pies' },
    { id: 3, Medida: 'Refrigerada 53 pies' },
    { id: 4, Medida: 'Pipa' }
  ]

  const adjustTimeZone = (str: string): string => {
    if (str) {
      const original: any = new Date(str)
      const real = new Date(original - original.getTimezoneOffset() * 60 * 1000)
      return real.toISOString()
    } else return ''
  }

  const StrtoDate = (str: string): Date | null => {
    if (str) {
      const original: any = new Date(str)
      return original
    } else return null
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const generaReporte = () => {
    setTipoReporte(0)
    PedDataService.GetRptCOVEs(
      Inicio,
      Fin
    )
      .then((response) => {
        setData(response.data)
        setDataOriginal(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const populateAduanas = () => {
    PedDataService.getAduanas(UserId, UserType)
      .then((response) => {
        console.log(
          '...........................................Aduanas ' + response.data
        )
        setAduanas(response.data)
        setAduana(response.data[0].value)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

 
  const uploadExcel = () => {
    const d1 = new Date()
    setCurrentTime2Number(d1.getTime())
    setModalFileManager(true)
  }

  const uploadExcelCorresponsal = () => {
    const d1 = new Date()
    setCurrentTime2Number(d1.getTime())
    setModalFileManagerCorresponsal(true)
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de COVEs de Casa Cuervo')
  }

  function exportExcel(jsonData: I325RptCOVEs[], fileName: string): void {
    let Heading = [
      [
        'Factura',
        'COVE',
        'Pedimento',
        'Clave',
        'Fecha pago',
        'Vehiculo'
      ]
    ]
    const dataOnly = jsonData.map(
      ({
        factura,
        cove,
        pedimento,
        clave,
        fechaPago,
        vehiculo
      }) => {
        return {
          factura,
        cove,
        pedimento,
        clave,
        fechaPago,
        vehiculo
        }
      }
    )
   /*  dataOnly.forEach((row, index, array) => {
      if (row.fechaCruce.length === 0 && row.modulacion.length > 0) {
        dataOnly[index].fechaCruce = row.modulacion
      }
    }) */
    /* const Report = dataOnly.map(
      ({
        po,
        aduana,
        destination,
        truckNumber,
        forwarder,
        carrier,
        loadDate,
        prioridad,
        estatus,
        comentarioGEMCO,
        fechaCompromiso,
        fechaCruce,
        factura,
        uuid,
        trafico,
        pedimento,
        patente,
        actualizacion
      }) => {
        return {
          po,
          aduana,
          destination,
          truckNumber,
          forwarder,
          carrier,
          loadDate,
          prioridad,
          estatus,
          comentarioGEMCO,
          fechaCompromiso,
          fechaCruce,
          factura,
          uuid,
          trafico,
          pedimento,
          patente,
          actualizacion
        }
      }
    ) */
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, dataOnly, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c + 1; C <= range.e.c + 1; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const uploadFile = (idFile: number, Process: number) => {
    PedDataService.Append(idFile, Process)
      .then((response) => {
        console.log(response.data)
        if (!response.data) {
          setHeader('Error')
          setMsg(
            'Ocurrio un error, favor de verificar el formato de la fecha, debe ser: MM/dd/yyyy '
          )
          setShowMsg(true)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    populateAduanas()
  }, [])

  useEffect(() => {
    if (fechaCompromiso) setDTOFechaCompromiso(fechaCompromiso.toString())
    if (fechaCruce) setDTOFechaCruce(fechaCruce.toString())
  }, [fechaCompromiso, fechaCruce])

  let handleColor = (time: Date) => {
    return time.getHours() > 12 ? 'text-success' : 'text-error'
  }

  /* const saveInfo = (mode: number) => {
    const data: DTO325UpdateFromWeb = {
      id: IDPedido,
      ComentarioGEMCO: ComentarioGEMCO,
      FechaCompromiso: fechaCompromiso
        ? adjustTimeZone(DTOfechaCompromiso)
        : '',
      FechaCruce: fechaCruce ? adjustTimeZone(DTOfechaCruce) : '',
      Factura: Factura,
      MedidaCaja: MedidaCaja,
      Sello1: Sello1,
      Sello2: Sello2,
      UUID: UUID,
      Trafico: Trafico,
      Pedimento: Pedimento,
      Patente: Patente
    }
    PedDataService.UpdateInfo(data)
      .then((response) => {
        const idx = Data.findIndex((obj) => obj.id == IDPedido)
        Data[idx].comentarioGEMCO = ComentarioGEMCO
        Data[idx].fechaCompromiso = fechaCompromiso
          ? adjustTimeZone(DTOfechaCompromiso)
          : ''
        Data[idx].fechaCruce = fechaCruce ? adjustTimeZone(DTOfechaCruce) : ''
        Data[idx].factura = Factura
        Data[idx].sello1 = Sello1
        Data[idx].sello2 = Sello2
        Data[idx].trafico = Trafico
        Data[idx].uuid = UUID
        Data[idx].pedimento = Pedimento
        Data[idx].patente = Patente
        setDataOriginal(Data)
        if (mode === 2) createPDF()
        setModalEdit(false)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        setModalEdit(false)
      })
  } */

  useEffect(() => {
    setData(DataOriginal)
    /* if (tipoReporte === 0) setData(DataOriginal)
    else if (tipoReporte === 1) {
      setData(
        DataOriginal.filter(function (row) {
          if (row.fechaCruce.length === 0 && row.modulacion.length === 0)
            return row
        })
      )
    } else if (tipoReporte === 2) {
      setData(
        DataOriginal.filter(function (row) {
          if (row.fechaCruce.length > 0 || row.modulacion.length > 0) return row
        })
      )
    } */
  }, [tipoReporte])

  const createPDF = () => {
    PedDataService.GeneratePDF4CC(IDPedido)
      .then((response: any) => {
        if (response.status === 200) {
          console.log(response.data)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado PDF')
        setShowMsg(true)
        return
      })
  }

  const generateTempPDF = () => {
    PedDataService.GenerateTempPDF(
      NoPedido ? NoPedido : '',
      NoCaja ? NoCaja : '',
      MedidaCaja ? MedidaCaja : '0',
      Factura ? Factura : '',
      Sello1 ? Sello1 : '.',
      Sello2 ? Sello2 : '.',
      Carrier ? Carrier : '.'
    )
      .then((response: any) => {
        if (response.status === 200) {
          console.log(response.data)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg(e.message.toString())
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className="row">
            <div className="col-md-1">
              <Form.Control
                defaultValue={Inicio}
                type="date"
                name="Inicio"
                placeholder="Inicio"
                title="Inicio"
                alt="Inicio"
                data-date-format="YYYY-mm-dd"
                onChange={(e) => setInicio(e.target.value)}
                size="sm"
              />
            </div>
            <div className="col-md-1">
              <Form.Control
                defaultValue={Fin}
                type="date"
                name="Fin"
                placeholder="Fin"
                title="Fin"
                alt="Fin"
                onChange={(e) => setFin(e.target.value)}
                size="sm"
              />
            </div>
            <div className="col-md-6">&nbsp;</div>
            <div className="col-md-1 right-label">
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
            <div className="col-md-2">&nbsp;</div>
            <div className="col">
              <Button
                size="sm"
                variant="success"
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp; Descarga
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className="ag-theme-alpine" style={{ height: 500, width: '100%' }}>
        {
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            striped={true}
            dense={true}
            paginationPerPage={10}
            pagination
            highlightOnHover
            columns={columnsConcepts}
            data={Data}
            pointerOnHover
          />
        }
      </div>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

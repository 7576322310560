import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsChevronDown, BsChevronRight, BsFilePdfFill } from 'react-icons/bs'
import { FaAmazon } from 'react-icons/fa'
import I2096Headers from '../../../Interfaces/I2096Header'
import DSAmazon from '../../../Services/Amazon.Services'
import { MsgInformativo } from '../../../../../Utils/Toast/msgInformativo'
import { RootState } from '../../../../../../store/store'
import {
  initializeInvoice,
  populateInvoices,
  updateInvoice
} from '../../../../../../store/features/Clientes/2096/AmazonInvoices'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import DTO2096LinkInvoice2Reference from '../../../DTO/DTO2096LinkInvoice2Reference'
import { AiFillFileExcel } from 'react-icons/ai'
import RptAmazonLinkInvoice2TrafficDetail from './RptAmazonLinkInvoice2TrafficDetail'
import loadingImg from '../../../../../../images/ajaxloader.gif'
import * as XLSX from 'xlsx'
import I2096PaisClave from '../../../Interfaces/I2096PaisClave'

export interface IRptAmazonLinkInvoice2TrafficProps {}

export default function RptAmazonLinkInvoice2Traffic(
  props: IRptAmazonLinkInvoice2TrafficProps
) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [header, setHeader] = useState('')
  const [Referencia, setReferencia] = useState('')
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [ShowModalUnlink, setShowModalUnlink] = useState(false)
  const [WaitingDialog, setWaitingDialog] = useState(false)
  const [WaitingMessage, setWaitingMessage] = useState('')
  const [msg, setMsg] = useState('')
  const [Invoices, setInvoices] = useState<number[]>([])
  const [PaisClaves, setPaisClaves] = useState<I2096PaisClave[]>([])
  const msgColor = 'primary'

  const loadReport = () => {
    DSAmazon.AmazonLinkInvoice2TrafficGet()
      .then((response) => {
        dispatch(initializeInvoice([]))
        dispatch(populateInvoices(response.data))
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const loadPaisesClaves = () => {
    DSAmazon.PaisClavesGET()
      .then((response) => {
        setPaisClaves(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const downloadPDF = (id: number, InvoiceNumber: string) => {
    setWaitingDialog(true)
    setWaitingMessage(' archivo PDF ')
    DSAmazon.PDFAmazonInvoiceGET(id)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'FAC_' + InvoiceNumber + '.pdf')
          document.body.appendChild(link)
          link.click()
          setWaitingDialog(false)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const downloadExcelInvoices = (Referencia: string) => {
    setWaitingMessage(' archivo Excel ')
    DSAmazon.GETAmazonFacturasByReference(Referencia)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', Referencia + '_FACTURAS_MODIFICA.xls')
          document.body.appendChild(link)
          link.click()
          loadReport()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const downloadExcelPartidas = (Referencia: string) => {
    setWaitingMessage(' archivo Excel ')
    DSAmazon.GETAmazonPartidasByReference(Referencia)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', Referencia + '_PARTIDAS_MODIFICA.xls')
          document.body.appendChild(link)
          link.click()
          loadReport()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    loadReport()
    loadPaisesClaves()
  }, [])

  const check4Updates = (IDInvoice: number) => {
    DSAmazon.SETInvoiceDetailRevalidateParts(IDInvoice)
      .then((response) => {
        DSAmazon.AmazonInvoiceGET(IDInvoice)
          .then((response) => {
            dispatch(updateInvoice(response.data[0]))
            let Invoice: I2096Headers[] = response.data.filter(
              (item) => item.id === IDInvoice
            )
            const Obj = { ...Invoice[0] }
            Obj.max = !Obj.max
            dispatch(updateInvoice(Obj))
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error')
            return
          })
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        return
      })
  }

  const changeToggle = (row: I2096Headers) => {
    let Invoice: I2096Headers[] = mInvoices.filter((item) => item.id === row.id)
    const Obj = { ...Invoice[0] }
    Obj.max = !Obj.max
    dispatch(updateInvoice(Obj))
    if (Obj.max) check4Updates(row.id)
  }

  const updateDataGrid = (IDInvoice: number) => {
    //toggleInvoice(IDInvoice)
    let Invoice: I2096Headers[] = mInvoices.filter(
      (item) => item.id === IDInvoice
    )
    const Obj = { ...Invoice[0] }
    Obj.max = !Obj.max
    dispatch(updateInvoice(Obj))
    if (Obj.max) check4Updates(IDInvoice)
  }

  const toggleInvoice = (id: number) => {
    if (!Invoices.includes(id)) {
      Invoices.push(id)
    } else {
      setInvoices(
        Invoices.filter(function (el) {
          return el != id
        })
      )
    }
  }

  const processInvoices = () => {
    if (Invoices.length === 0) {
      setHeader('Error')
      setMsg(
        'Para poder continuar es necesario que seleccione por lo menos 1 factura'
      )
      setShowMsg(true)
      return
    }
    setShowModal(true)
  }

  const unlinkInvoices = () => {
    if (Invoices.length === 0) {
      setHeader('Error')
      setMsg(
        'Para poder continuar es necesario que seleccione por lo menos 1 factura'
      )
      setShowModalUnlink(true)
      return
    }
    setShowModalUnlink(true)
  }

  const asociaFacturasReferencia = () => {
    const data: DTO2096LinkInvoice2Reference = {
      referencia: Referencia,
      facturas: Invoices
    }
    DSAmazon.AsignaFacturasReferencia(data)
      .then((response: any) => {
        setHeader('Confirmacion')
        setMsg('Las facturas se asociaron correctamente con la referencia')
        //setShowMsg(true)
        setInvoices([])
        setShowModal(false)
        loadReport()
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const desasociaFacturasReferencia = () => {
    const data: DTO2096LinkInvoice2Reference = {
      referencia: Referencia,
      facturas: Invoices
    }
    DSAmazon.AsignaFacturasReferencia(data)
      .then((response: any) => {
        setHeader('Confirmacion')
        setMsg('Las facturas se desasociaron correctamente')
        // setShowMsg(true)
        setInvoices([])
        setShowModalUnlink(false)
        loadReport()
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  function getReceiver(Cadena: string, char1: string[1], char2: string[1]) {
    return Cadena.slice(Cadena.indexOf(char1) + 1, Cadena.lastIndexOf(char2))
  }

  const downloadExcel = () => {
    interface IRptExcel {
      id: number
      referencia: string
      commercialInvoiceDate: string
      invoiceNumber: string
      receiver: string
      incoterms: string
      hawb: string
      totalInvoiceQuantityUnitOfMeasure: string
      totalInvoiceQuantity: number
      totalInvoiceWeightUnitOfMeasure: string
      totalInvoiceWeight: string
      totalInvoiceValueChargeOrAllowance: string
      totalInvoiceValueCurrencyISOCode: string
      totalInvoiceValueMonetaryAmount: string
      totalPartidas: number
    }

    const RptExcel: IRptExcel[] = []
    mInvoices.forEach(function (invoice) {
      var data: IRptExcel = {
        id: invoice.id,
        referencia: invoice.referencia,
        commercialInvoiceDate: invoice.commercialInvoiceDate,
        invoiceNumber: invoice.invoiceNumber,
        receiver: getRec(invoice.id),
        incoterms: invoice.incoterms,
        hawb: invoice.hawb,
        totalInvoiceQuantityUnitOfMeasure:
          invoice.totalInvoiceQuantityUnitOfMeasure,
        totalInvoiceQuantity: invoice.totalInvoiceQuantity,
        totalInvoiceWeightUnitOfMeasure:
          invoice.totalInvoiceWeightUnitOfMeasure,
        totalInvoiceWeight: invoice.totalInvoiceWeight,
        totalInvoiceValueChargeOrAllowance:
          invoice.totalInvoiceValueChargeOrAllowance,
        totalInvoiceValueCurrencyISOCode:
          invoice.totalInvoiceValueCurrencyISOCode,
        totalInvoiceValueMonetaryAmount:
          invoice.totalInvoiceValueMonetaryAmount,
        totalPartidas: getTotPartidas(invoice.id)
      }
      RptExcel.push(data)
    })
    //console.log(RptExcel)
    exportExcel(RptExcel, 'Reporte de trafico - facturas terminadas')
  }

  const getRec: any = (IDInvoice: number) => {
    const result = mInvoices
      .filter((value) => value.id === IDInvoice)
      .map((row) => {
        return getReceiver(row.detail[0].amazonShipmentReferenceId, '_', '_')
      })
    return result[0]
  }

  const getTotPartidas: any = (IDInvoice: number) => {
    let Tot = 0
    const result = mInvoices
      .filter((value) => value.id === IDInvoice)
      .map((row) => {
        return row.detail.length
      })
    return result[0]
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    let Heading = [
      [
        'id',
        'Referencia',
        'Commercial invoice date',
        'Invoice number',
        'Receiver',
        'Incoterms',
        'HAWB',
        'Qty Unit of measure',
        'Tot qty',
        'Weight unit of measure',
        'Tot weight',
        'Tot charge or allowance',
        'Tot currency ISO code',
        'Tot Monetary amount',
        'Total de partidas'
      ]
    ]
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={6}>
              <Card.Title>
                <IconContext.Provider value={{ color: '#F6C34F', size: '8%' }}>
                  <FaAmazon />
                </IconContext.Provider>{' '}
                TRAFICO : facturas terminadas
              </Card.Title>
            </Col>
            <Col>
              <Button
                variant="danger"
                onClick={() => {
                  unlinkInvoices()
                }}
              >
                Desasociar
              </Button>
            </Col>
            <Col></Col>
            <Col>
              <Button
                variant="success"
                onClick={() => {
                  downloadExcel()
                }}
              >
                Excel
              </Button>
            </Col>
            <Col></Col>
            <Col>
              <Button
                onClick={() => {
                  processInvoices()
                }}
              >
                Procesa
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <div className="MDcontainer">
            <Table className="MDTable" hover>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr>
                  <th></th>
                  <th style={{ width: '50px' }}>id</th>
                  <th style={{ width: '50px' }}>[...]</th>
                  <th style={{ width: '50px' }}>PDF</th>
                  <th style={{ width: '50px' }}>Facturas</th>
                  <th style={{ width: '50px' }}>Partidas</th>
                  <th style={{ width: '100px' }}>Referencia</th>
                  <th style={{ width: '80px' }}>Commercial Invoice Date</th>
                  <th style={{ width: '250px' }}>Invoice Number</th>
                  <th style={{ width: '100px' }}>Receiver</th>
                  <th style={{ width: '100px' }}>Incoterms</th>
                  <th style={{ textAlign: 'center' }}>HAWB</th>
                  <th>Qty Unit of measure</th>
                  <th>Tot Qty</th>
                  <th>Weight unit of measure</th>
                  <th>Tot weight</th>
                  <th>Tot Charge or allowance</th>
                  <th>Tot currency ISO code</th>
                  <th>Tot Monetary amount</th>
                  <th>Total de partidas</th>
                </tr>
              </thead>
              <tbody>
                {mInvoices
                  ? mInvoices.map((MasterData) => {
                      return (
                        <>
                          <tr
                            style={{ cursor: 'pointer', height: '25px' }}
                            className={
                              MasterData.max === true
                                ? 'masterSelected'
                                : 'normalSelected'
                            }
                          >
                            <td
                              style={{ textAlign: 'left' }}
                              className={
                                MasterData.max === true
                                  ? 'masterSelected'
                                  : 'normalSelected'
                              }
                              key={MasterData.id}
                              onClick={() => {
                                changeToggle(MasterData)
                              }}
                            >
                              {MasterData.max === true ? (
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '15px' }}
                                >
                                  <BsChevronDown />
                                </IconContext.Provider>
                              ) : (
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '15px' }}
                                >
                                  <BsChevronRight />
                                </IconContext.Provider>
                              )}
                            </td>
                            <td>{MasterData.id}</td>
                            <td style={{ textAlign: 'center' }}>
                              <Form.Check
                                aria-label="ChkRow"
                                onClick={() => toggleInvoice(MasterData.id)}
                              />
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                              <div
                                onClick={() => {
                                  downloadPDF(
                                    MasterData.id,
                                    MasterData.invoiceNumber
                                  )
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'red', size: '20px' }}
                                >
                                  <BsFilePdfFill />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                textAlign: 'center',
                                visibility: MasterData.referencia
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <div
                                onClick={() => {
                                  downloadExcelInvoices(MasterData.referencia)
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'green', size: '20px' }}
                                >
                                  <AiFillFileExcel />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                textAlign: 'center',
                                visibility: MasterData.referencia
                                  ? 'visible'
                                  : 'hidden'
                              }}
                            >
                              <div
                                onClick={() => {
                                  downloadExcelPartidas(MasterData.referencia)
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'green', size: '20px' }}
                                >
                                  <AiFillFileExcel />
                                </IconContext.Provider>
                              </div>
                            </td>
                            <td style={{ width: '100px', textAlign: 'center' }}>
                              {MasterData.referencia}
                            </td>
                            <td style={{ width: '80px', textAlign: 'center' }}>
                              {MasterData.commercialInvoiceDate}
                            </td>
                            <td style={{ width: '300px' }}>
                              {MasterData.invoiceNumber}
                            </td>
                            <td
                              style={{
                                width: '100px',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: '13px'
                              }}
                            >
                              {mInvoices
                                .filter((value) => value.id === MasterData.id)
                                .map((row) => {
                                  return getReceiver(
                                    row.detail[0].amazonShipmentReferenceId,
                                    '_',
                                    '_'
                                  )
                                })}
                            </td>
                            <td style={{ width: '100px', textAlign: 'center' }}>
                              {MasterData.incoterms}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.hawb}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantityUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantity}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeightUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeight}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueChargeOrAllowance}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueCurrencyISOCode}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueMonetaryAmount}
                            </td>
                            <td
                              style={{
                                fontWeight: 'bold',
                                textAlign: 'center'
                              }}
                            >
                              {mInvoices
                                .filter((value) => value.id === MasterData.id)
                                .map((row) => {
                                  return row.detail.length
                                })}
                            </td>
                          </tr>
                          {MasterData.max ? (
                            <tr
                              className={
                                MasterData.max === true
                                  ? 'masterSelected2'
                                  : 'normalSelected2'
                              }
                            >
                              <th colSpan={18}>
                                <table
                                  className="childTable"
                                  style={{ width: '1650px' }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          width: '50px',
                                          backgroundColor: '#FFFFFF',
                                          color: '#346288',
                                          textAlign: 'right'
                                        }}
                                      ></th>
                                      <th
                                        style={{
                                          width: '100px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Item Id
                                      </th>
                                      <th
                                        style={{
                                          width: '400px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Item Description
                                      </th>
                                      <th
                                        style={{
                                          width: '120px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Fraccion GEMCO
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Descripcion GEMCO
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Country of Origin
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        ProductGroup
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Brand
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Model
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Measure
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Qty Shipped
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit of measure
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Net Weight
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Cost
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Total value
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Sideline
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      ></th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      ></th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {mInvoices.map((MD) => {
                                      return (
                                        <>
                                          {MD.detail
                                            .filter(
                                              (detail) =>
                                                MasterData.id ===
                                                detail.idHeader
                                            ) //.sort((a,b) => a.itemId.localeCompare(b.itemId))
                                            .sort((a, b) =>
                                              a.partida > b.partida ? 1 : -1
                                            )
                                            .map((detail) => {
                                              return (
                                                <RptAmazonLinkInvoice2TrafficDetail
                                                  IDMaster={MasterData.id}
                                                  detail={detail}
                                                  Paises={PaisClaves}
                                                  Habilitado={
                                                    MasterData.estatus <= 1
                                                  }
                                                  onDataChange={function (
                                                    IDMaster: number
                                                  ): void {
                                                    updateDataGrid(IDMaster)
                                                  }}
                                                />
                                              )
                                            })}
                                        </>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          ) : null}
                        </>
                      )
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '180px' }}>
            <Alert key={'danger'} variant={'danger'}>
              <Row style={{ paddingTop: '15px' }}>
                <Col xs={9}>
                  Para asociar estar facturas proporcione la referencia de SIR
                </Col>
                <Col xs={3}>
                  <Form.Control
                    type="text"
                    id="Referencia"
                    size="sm"
                    value={Referencia}
                    onChange={(e) => setReferencia(e.target.value)}
                  />
                </Col>
              </Row>
            </Alert>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  &nbsp;&nbsp;Cancelar&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    asociaFacturasReferencia()
                  }}
                >
                  &nbsp;&nbsp;Asociar referencia&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ShowModalUnlink}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '180px' }}>
            <Alert key={'danger'} variant={'danger'}>
              <Row style={{ paddingTop: '15px' }}>
                <Col xs={12}>
                  <h1>¿Esta seguro de desasociar esta referencia?</h1>
                </Col>
              </Row>
            </Alert>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModalUnlink(false)
                  }}
                >
                  &nbsp;&nbsp;Cancelar&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    desasociaFacturasReferencia()
                  }}
                >
                  &nbsp;&nbsp;DESASOCIAR&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={WaitingDialog}
        onHide={() => {
          setWaitingDialog(false)
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        dialogClassName={'modal-50w'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={loadingImg} style={{ height: '150px' }} alt="proccessing" />
          Generando {WaitingMessage}...
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

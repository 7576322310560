import React, { useEffect, useState } from 'react'
// Bootstrap components
import { Button, Card, Form } from 'react-bootstrap'
import { BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
// Interfaces
import IClientes from '../../Interfaces/Catalogos/IClientes'
import IArchivoElectronico from '../../Interfaces/IArchivoElectronico'
// DTOs
import DTOReporte from '../../DTO/DTOReporte'
// Services
import reportsDataService from '../../Services/Reportes/reportes.services'
import ClientesDataService from '../../Services/Catalogos/Clientes.Services'
// Redux
import { useDispatch } from 'react-redux'
import { setArchivoElectronico } from '../../store/features/Reportes/ArchivoElectronico'
// GUI components
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import { AgGridReact } from 'ag-grid-react'
import { RowClickedEvent } from 'ag-grid-community'
import { TargetURL } from '../../Constants/TargetURL'
//Other
import moment from 'moment'
import * as XLSX from 'xlsx'
import IRptPedimentosConsolidados from '../../Interfaces/Reportes/IRptPedimentosConsolidados'
import IRptOperacionesConsolidadas from '../../Interfaces/Reportes/IRptOperacionesConsolidadas'
const URL = new TargetURL()

type Props = {}

export default function RptOperacionesConsolidadas({}: Props) {
  const [curURL, setCurURL] = useState(URL.get())
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Data, setData] = useState<Array<IRptOperacionesConsolidadas>>([])
  const [filteredData, setFilteredData] = useState<Array<IRptOperacionesConsolidadas>>([])
  const [Inicio, setInicio] = useState(currentDate())
  const [Fin, setFin] = useState(currentDate())
  const [TipoOperacion, setTipoOperacion] = useState(1)
  const [Cliente, setCliente] = useState(0)
  const [Clientes, setClientes] = useState<Array<IClientes>>()
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [filtro, setFiltro] = useState('')
  const gridRef = React.useRef<any>(null)
  const [columnaVerPDF, setcolumnaVerPDF] = useState(false)
  const dispatch = useDispatch()
  const [columnDefs] = useState([
    { field: 'sReferencia', headerName: 'Referencia', sortable: true, filter: true },
    { field: 'sPedimento', headerName: 'Pedimento', sortable: true, filter: true },
    { field: 'sClave', headerName: 'Clave', sortable: true, filter: true },
    { field: 'fechaPago', sortable: true, filter: true },
    { field: 'nTipoCambio', headerName: 'Tipo cambio', sortable: true, filter: true },
    { field: 'nPesoBruto', headerName: 'Peso bruto', sortable: true, filter: true },
    { field: 'paisVendedor', sortable: true, filter: true },
    { field: 'paisOrigen', sortable: true, filter: true },
    { field: 'numFacturas', sortable: true, filter: true },
    { field: 'factura', sortable: true, filter: true },
    { field: 'valorFactura', sortable: true, filter: true },
    { field: 'fraccion', sortable: true, filter: true },
    { field: 'descripcion', sortable: true, filter: true },
    { field: 'nClave', headerName: 'Clave', sortable: true, filter: true },
    { field: 'cantFacturas', sortable: true, filter: true },
    { field: 'valorAduana', sortable: true, filter: true },
    { field: 'incrementables', sortable: true, filter: true },
    { field: 'adValorem', sortable: true, filter: true },
    { field: 'dta', sortable: true, filter: true },
    { field: 'iva', sortable: true, filter: true },
    { field: 'efectivo', sortable: true, filter: true },
    { field: 'total', sortable: true, filter: true },
    { field: 'destino', sortable: true, filter: true },
    { field: 'cruce', sortable: true, filter: true },
    { field: 'remitente', sortable: true, filter: true },
    { field: 'caja', sortable: true, filter: true },
    { field: 'valorComercial', sortable: true, filter: true },
    { field: 'aduana', sortable: true, filter: true },
    { field: 'patente', sortable: true, filter: true },
    { field: 'incoterm', sortable: true, filter: true },
  ])

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const closeToast = (show: boolean): void => {
    setShowMsg(false)
  }

  const generaReporte = () => {
    if (Cliente === 0) {
      setHeader('Error')
      setMsg('Seleccion el cliente')
      setShowMsg(true)
      return
    }
    const data: DTOReporte = {
      Inicio: moment(Inicio).format('YYYY-MM-DD'),
      Fin: moment(Fin).format('YYYY-MM-DD'),
      TipoOperacion: TipoOperacion,
      NoCliente: Cliente,
      IdUsuario: UserId,
    }
    reportsDataService
      .getRptOperacionesConsolidadadas(data)
      .then((response) => {
        setData(response.data)
        setFilteredData(response.data)
        setHeader('Informativo')
        setMsg('Se encontro la siguiente informacion...')
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    ClientesDataService.getAllClientes(parseInt(UserId))
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }, [])

  const downloadExcel = () => {
    exportExcel(Data, 'Operaciones Consolidadas')
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    let Heading = [
      [
        'Referencia',
        'Pedimento',
        'Clave',
        'FechaPago',
        'TipoCambio',
        'PesoBruto',
        'PaisVendedor',
        'PaisOrigen',
        'NumFacturas',
        'Factura',
        'ValorFactura',
        'Fraccion',
        'Descripcion',
        'Clave',
        'CantFacturas',
        'ValorAduana',
        'Incrementables',
        'AdValorem',
        'DTA',
        'IVA',
        'Efectivo',
        'Total',
        'Destino',
        'Cruce',
        'Remitente',
        'Caja',
        'ValorComerncial',
        'Aduana',
        'Patente',
        'Incoterm'
      ],
    ]
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    gridRef.current.api.setQuickFilter(e.target.value)
  }

  function getParams(e: RowClickedEvent) {
    const data: IArchivoElectronico = {
      Referencia: e.data.referencia,
      PedimentoLargo: '',
      NoCliente: Cliente,
      IdUsuario: 0,
      Archivo: '',
    }
    dispatch(setArchivoElectronico(data))
    if (columnaVerPDF) {
      let P: string = e.data.pedimento
      P = P.substring(6)
      P = P.replace(' ', e.data.aduanaSeccionEntrada)
      window.open(
        `${curURL}/ArchivoElectronico/getFile?Referencia=${e.data.referencia}&PedimentoLargo=${P}&Nocliente=${Cliente}&IdUsuario=${UserId}`
      )
    }
  }

  async function getAE(e: any) {
    if (e.column.colId === 'ver pedimento') {
      setcolumnaVerPDF(true)
    } else {
      setcolumnaVerPDF(false)
    }
  }

  const formatNumber = (number: string, digits: number) => {
    number = parseFloat(number).toFixed(digits)
    let value = Number(number).toLocaleString('en')
    if (!value.indexOf('NaN')) return '0.00'
    return value
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-2'>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-2'>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1 right-label'>
              <Form.Label size='sm'>Tipo oper</Form.Label>
            </div>
            <div className='col-md-1'>
              <Form.Control
                as='select'
                onChange={(e) => setTipoOperacion(parseInt(e.target.value))}
                className='form-select form-select-sm'
              >
                <option value='1'>Importacion</option>
                <option value='2'>Exportacion</option>
              </Form.Control>
            </div>
            <div className='col-md-1 right-label'>
              <Form.Label size='sm'>Cliente</Form.Label>
            </div>
            <div className='col-md-4'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  setCliente(parseInt(e.target.value))
                }}
                className='form-select form-select-sm'
              >
                <option value='0'>-SELECCIONE-</option>
                {Clientes
                  ? Clientes.map((c) => {
                      return c.agrupado === 1 ? <option value={c.sClave}>{c.sRazonSocial}</option> : ''
                    })
                  : null}
              </Form.Control>
            </div>
            <div className='col-md-1 right-label'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
          </div>
          <div className='row' style={{ paddingTop: 5 }}>
            <div className='col'>&nbsp;</div>
            <div className='col-4'>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className='col'>
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp;Excel
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        <AgGridReact
          rowData={filteredData}
          columnDefs={columnDefs}
          pagination={true}
          paginationAutoPageSize={true}
          ref={gridRef}
          rowSelection={'multiple'}
          rowMultiSelectWithClick={true}
          onCellClicked={(e) => getAE(e)}
          onRowClicked={(e) => getParams(e)}
        ></AgGridReact>
      </div>
      <MsgInformativo show={show} msg={msg} header={header} msgColor={msgColor} closeToast={closeToast} />
    </div>
  )
}
import http from '../../../../Services/common/http-common'
import IPedidosCasaCuervo from '../Interfaces/I325Pedidos'
import DTO325AduanasPedidos from '../../../../DTO/Clientes/CasaCuervo/DTO325AduanasPedidos'
import DTO325UpdateFromWeb from '../../../../DTO/Clientes/CasaCuervo/DTO325UpdateFromWeb'
import { IServerSideTransactionManager } from 'ag-grid-community'
import I325RptCOVEs from '../Interfaces/I325RptCOVEs'

class CasaCuervoDataService {
  getAll(Inicio: string, Fin: string, Aduana: string) {
    return http.get<IPedidosCasaCuervo[]>(
      `/Clientes/CasaCuervo/Pedidos/getAll?Inicio=${Inicio}&Fin=${Fin}&Aduana=${Aduana}`
    )
  }
  GetById(Id: number) {
    return http.get<IPedidosCasaCuervo>(
      `/Clientes/CasaCuervo/Pedidos/GetById?Id=${Id}`
    )
  }
  getAduanas(Usuario: number, TipoUsuario: number) {
    return http.get<DTO325AduanasPedidos[]>(
      `/Clientes/CasaCuervo/Pedidos/getAduanas?Usuario=${Usuario}&TipoUsuario=${TipoUsuario}`
    )
  }
  Update(id: number, Campo: string, Valor: string) {
    return http.put<IPedidosCasaCuervo[]>(
      `/Clientes/CasaCuervo/Pedidos/Update/${id}/${Campo}/${Valor}`
    )
  }
  UpdateInfo(data: DTO325UpdateFromWeb) {
    return http.put<IPedidosCasaCuervo>(
      `/Clientes/CasaCuervo/Pedidos/UpdateInfo/${data.id}`,
      data
    )
  }
  Append(id: number, Proceso: number) {
    return http.get<IPedidosCasaCuervo[]>(
      `/Clientes/CasaCuervo/Pedidos/UploadExcel?id=${id}&Proceso=${Proceso}`
    )
  }
  GeneratePDF4CC(id: number) {
    return http
      .get<ArrayBuffer>(
        `/Clientes/CasaCuervo/Pedidos/GeneratePDF4CC?id=${id}`,
        { responseType: 'arraybuffer' }
      )
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  GenerateTempPDF(
    NoPedido: string,
    NoCaja: string,
    nMedidaCaja: string,
    Factura: string,
    Sello1: string,
    Sello2: string,
    Carrier: string
  ) {
    return http
      .get<ArrayBuffer>(
        `/Clientes/CasaCuervo/Pedidos/GenerateTempPDF?NoPedido=${NoPedido}&NoCaja=${NoCaja}&nMedidaCaja=${nMedidaCaja}&Factura=${Factura}&Sello1=${Sello1}&Sello2=${Sello2}&Carrier=${Carrier}`,
        { responseType: 'arraybuffer' }
      )
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  GetRptCOVEs(Inicio: string, Fin: string) {
    return http.get<I325RptCOVEs[]>(
      `/Clientes/CasaCuervo/Pedidos/GetRptCOVEs?Inicio=${Inicio}&Fin=${Fin}`
    )
  }
}
export default new CasaCuervoDataService()

import React, { FC } from 'react'
import { Button } from 'react-bootstrap'

interface IProps {}

export const verPDF: FC<IProps> = (props) => {
  const showPDF = () => {}

  return (
    <div>
      <span>
        <Button onClick={() => showPDF()} variant='primary'>
          ver Pedimento
        </Button>
      </span>
    </div>
  )
}

import { FC, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DashboardDataService from '../../Services/Dashboard/Dashboard.Corresponsales.Services';
import { useNavigate } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface IProps {}

export default function DashboardCorresponsales(props: IProps) {
  let navigate = useNavigate();
  const [TotalCorresponsales, setTotalCorresponsales] = useState(0);
  const [labels, setlabels] = useState<string[]>([]);
  const [series, setseries] = useState<number[]>([]);
  const [CircleOptions, setCirleOpcions] = useState<ApexOptions>({
    labels: [
      '(2) Rechazos para el corresponsal',
      ' (0) Rechazados x Contabilidad',
      '(10) Pendientes por terminar',
    ],
    colors: ['#EB984E', '#E52626', '#ABEB4E', '#2288D3'],
    legend: { position: 'bottom' },
    plotOptions: {
      pie: {
        donut: {
          size: '55%',
        },
      },
    },
  });

  useEffect(() => {
    DashboardDataService.getCorresponsales()
      .then((response) => {
        console.log(response.data);
        let labels: string[] = [];
        let series: number[] = [];
        response.data.forEach((element) => {
          if (element.descripcion.indexOf('Total') === -1) {
            labels.push('(' + element.total + ') : ' + element.descripcion);
            series.push(element.total);
          } else {
            setTotalCorresponsales(element.total);
          }
        });
        setlabels(labels);
        setseries(series);

        setCirleOpcions({
          labels: labels,
          colors: ['#EB984E', '#E52626', '#ABEB4E'],
          legend: { position: 'bottom' },
          plotOptions: {
            pie: {
              donut: {
                size: '55%',
                labels: {
                  show: true,
                  total: {
                    showAlways: true,
                    show: true,
                    fontSize: '15px',
                  },
                  value: {
                    show: true,
                    fontSize: '43px',
                    fontWeight: 'bold',
                  },
                },
              },
            },
          },
        });
      })
      .catch((e: Error) => {
        return;
      });
  }, []);


  return (
    <>
      <Col>
        <Card style={{ width: '19rem' }} className='dashboardComponentFont'>
          <Card.Header>
            <b>Corresponsales:</b> Traficos pendientes
          </Card.Header>
          <Card.Body style={{ paddingBottom: '10px', paddingTop: '0px' }}>
            <Card.Img variant='top' src='' onClick={() => {}} />
            {labels ? (
              <ReactApexChart
                type='donut'
                options={CircleOptions}
                series={series}
                width={250}
                height={500}
              />
            ) : (
              ''
            )}
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs={6} style={{ paddingRight: '5px' }}>
                {/*                 <Link
                  to={`../RptCorresponsalesTraficosHst?proc=0&status=0`}
                  style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
                >
                  Buscar
                </Link> */}
                {/* <span style={{ fontWeight: 'bold' }}>Tipo cambio: ${TipoCambio}</span> */}
              </Col>
              {/*  <Col xs={3}></Col> */}
              <Col xs={6} style={{ paddingRight: '5px' }}>
                <Link
                  to={`../RptCorresponsalesTraficos/proc=1/modo=1`}
                  style={{
                    textDecoration: 'none',
                    float: 'right',
                    paddingRight: '10px',
                  }}
                >
                  Ver mas...
                </Link>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Col>
    </>
  );
};

import { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsCloudArrowUpFill, BsFileEarmarkExcel, BsFilePdfFill, BsFillPencilFill, BsSearch } from 'react-icons/bs'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import { FaPeopleArrows, FaRegFile, FaWarehouse } from 'react-icons/fa'
import I1868CatClaveSAT from './Interfaces/I1868CatClaveSAT'
import I1868CatDestinos from './Interfaces/I1868CatDestinos'
import I1868FacturaDetalle from './Interfaces/I1868FacturaDetalle'
import DTO1868Factura from './DTO/DTO1868Factura'
import DTO1868ReporteFactura from './DTO/DTO1868ReporteFactura'
import DTO1868Clientes from './DTO/DTO1868Clientes'
import FService from './Services/Facturas.Services'
import FileManager from '../../Utils/FileManager/FileManager'
import { Cat1868Destinos } from './Catalogos/Cat1868Destinos'
import { Cat1868Responsables } from './Catalogos/Cat1868Responsables'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { populateCatResponsables } from '../../../store/features/Clientes/1868/Cat1868ResponsablesSlice'
import { populateCatClaveSAT } from '../../../store/features/Clientes/1868/Cat1868ClaveSATSlice'
import { populateCatDestinos } from '../../../store/features/Clientes/1868/Cat1868DestinosSlice'
import { populateFacturaDetalle, toggleRow } from '../../../store/features/Clientes/1868/Data1868FacturaDetalleSlice'
import * as XLSX from 'xlsx'
import '../../../css/Company.css'
import { Rpt1868FacturasDetalle } from './Rpt1868FacturasDetalle'
import { MdCloudUpload } from 'react-icons/md'

interface IProps {}

export default function Rpt1868Facturas (props:IProps) {
  const dispatch = useDispatch()
  const mCatDestinos = useSelector((state: RootState) => state.Cat1868Destinos.Cat1868Destinos)
  const mCatResponsables = useSelector((state: RootState) => state.Cat1868Responsables.Cat1868Responsables)
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [UserType, setUserType] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserType')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [CurrentTime2Number, setCurrentTime2Number] = useState(0)
  const [Cliente, setCliente] = useState('')
  const [Clientes, setClientes] = useState<DTO1868Clientes[]>([])
  const [Data, setData] = useState<DTO1868ReporteFactura[]>([])
  const [DataDetalle, setDataDetalle] = useState<I1868FacturaDetalle[]>([])
  const [DataOriginal, setDataOriginal] = useState<DTO1868ReporteFactura[]>([])
  const [DataCatSAT, setDataCatSAT] = useState<I1868CatClaveSAT[]>([])
  const [DataCatDestinos, setDataCatDestinos] = useState<I1868CatDestinos[]>([])
  const [ID, setID] = useState(0)
  const [IDDetail, setIDDetail] = useState(0)
  const [Invoice, setInvoice] = useState('')
  const [Sellos, setSellos] = useState('')
  const [Placas, setPlacas] = useState('')
  const [ClaveSAT, setClaveSAT] = useState('-SELECCIONE-')
  const [Empaque, setEmpaque] = useState('')
  const [IDDestino, setIDDestino] = useState(0)
  const [IDResponsable, setIDResponsable] = useState(0)
  const [Responsable, setResponsable] = useState('')
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-30))
  const [Fin, setFin] = useState(currentDate(0))
  const [filtro, setFiltro] = useState('')
  const [tipoReporte, setTipoReporte] = useState(0)
  const [msgColor, setMsgColor] = useState('primary')
  const [modalFileManagerXML, setModalFileManagerXML] = useState(false)
  const [modalFileManagerPDF, setModalFileManagerPDF] = useState(false)
  const [modalEditMaster, setModalEditMaster] = useState(false)
  const [modalEditDetail, setModalEditDetail] = useState(false)
  const [ModalCatalogos, setModalCatalogos] = useState(false)
  const [Proceso, setProceso] = useState(0)
  const [ModalFileManager, setModalFileManager] = useState(false)
  const [Entregar, setEntregar] = useState('')
  const [Catalogo, setCatalogo] = useState(0)

  const EditMaster = (row: DTO1868ReporteFactura) => {
    setID(row.id)
    let Ubicacion = row.entregar ? row.entregar : ''
    Ubicacion = Ubicacion.replaceAll(' ', '').replaceAll('\r', '').replaceAll('\n', '')
    setSellos(row.sello)
    setPlacas(row.placas)
    setEntregar(row.entregar)
    setInvoice(row.invoice)
    setIDDestino(row.idDestino)
    setResponsable(row.responsableCruce)
    setIDResponsable(row.idResponsableCruce)
    setModalEditMaster(true)
  }

  const showPDF = (id: number) => {
    FService.getFileContent(id, 8)
      .then((response: any) => {
        if (response.status === 200) {
          console.log(response.data)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado PDF')
        setShowMsg(true)
        return
      })
  }

  const showXML = (id: number, Invoice: string) => {
    FService.getFileContent(id, 7)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', Invoice + '.xml')
          document.body.appendChild(link)
          link.click()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado XML')
        setShowMsg(true)
        return
      })
  }

  function exportExcel(jsonData: DTO1868ReporteFactura[], fileName: string): void {
    let Heading = [
      [
        'Fecha factura',
        'Invoice',
        'Ruta',
        'Cliente',
        'Pedido cliente',
        'Transportista',
        'Responsable cruce',
        'Sellos',
        'Placas',
        'Pallets',
        'Carga',
        'Tipo pedimento',
        'Aduana',
      ],
    ]
    const dataOnly = jsonData.map(
      ({
        fechaFactura,
        invoice,
        ruta,
        cliente,
        pedidoCliente,
        transportista,
        responsableCruce,
        sello,
        placas,
        pallets,
        carga,
        tipoPedimento,
        aduana,
      }) => {
        return {
          fechaFactura,
          invoice,
          ruta,
          cliente,
          pedidoCliente,
          transportista,
          responsableCruce,
          sello,
          placas,
          pallets,
          carga,
          tipoPedimento,
          aduana,
        }
      }
    )
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, dataOnly, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c + 1; C <= range.e.c + 1; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const generaReporte = () => {
    setTipoReporte(0)
    FService.getAll(Inicio, Fin, UserId)
      .then((response) => {
        loadCustomers()
        loadCatDestinos()
        setData(response.data)
        setDataOriginal(response.data)
        FService.getAllDetail(Inicio, Fin)
          .then((responsed) => {
            setDataDetalle(responsed.data)
            dispatch(populateFacturaDetalle(responsed.data))
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
          })
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const filtraReporte = (e: any) => {
    let value = e.target.value
    value = value.toUpperCase()
    setFiltro(value)
    if (value.length > 0) {
      console.log(value)
      setData(
        Data.filter(function (row: DTO1868ReporteFactura) {
          return (
            row.fechaFactura.includes(value) ||
            row.invoice.includes(value) ||
            row.ruta.includes(value) ||
            row.cliente.includes(value) ||
            row.pedidoCliente.includes(value) ||
            row.transportista.includes(value) ||
            row.responsableCruce.includes(value) ||
            row.carga.includes(value) ||
            row.tipoPedimento.includes(value) ||
            row.aduana.includes(value)
          )
        })
      )
      console.log(Data)
    } else {
      setData(DataOriginal)
    }
  }

  const uploadXML = () => {
    const d1 = new Date()
    setCurrentTime2Number(d1.getTime())
    setModalFileManagerXML(true)
  }

  const uploadPDF = () => {
    const d1 = new Date()
    setCurrentTime2Number(d1.getTime())
    setModalFileManagerPDF(true)
  }

  const saveMaster = () => {
    const data: DTO1868Factura = {
      id: ID,
      sello: Sellos ? Sellos : '',
      placas: Placas ? Placas : '',
      idResponsable: IDResponsable ? IDResponsable : 0,
      responsable: Responsable ? Responsable : '',
      idDestino: IDDestino ? IDDestino : 0,
      entregar: Entregar ? Entregar : '',
    }
    FService.Update(data)
      .then((response) => {
        setHeader(response.data ? 'Informativo' : 'Error')
        setMsg(
          response.data ? 'La informacion se guardo correctamente' : 'Ocurrio un error, no se guardo la informacion'
        )
        const idx = Data.findIndex((obj) => obj.id == ID)
        Data[idx].sello = Sellos
        Data[idx].placas = Placas
        Data[idx].empaqueDescripcion = Empaque
        Data[idx].idResponsableCruce = IDResponsable
        Data[idx].responsableCruce = Responsable
        Data[idx].idDestino = IDDestino
        setDataOriginal(Data)
        setModalEditMaster(false)
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const uploadFile = (idFile: number, Process: number) => {
    FService.Append(idFile, Process)
      .then((response) => {
        console.log(response.data)
        if (!response.data) {
          setHeader('Error')
          setMsg('Ocurrio un error, favor de verificar el formato de la fecha, debe ser: MM/dd/yyyy ')
          setShowMsg(true)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCustomers = () => {
    setTipoReporte(0)
    FService.getClientes()
      .then((response) => {
        console.log(response.data)
        setClientes(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatClaveSAT = () => {
    FService.getCatClaveSAT()
      .then((response) => {
        dispatch(populateCatClaveSAT(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatDestinos = () => {
    FService.getCatDestinos()
      .then((response) => {
        dispatch(populateCatDestinos(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatResponsables = () => {
    FService.getCatResponsables()
      .then((response) => {
        dispatch(populateCatResponsables(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatalog = (cat: number) => {
    setCatalogo(cat)
    setModalCatalogos(true)
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de Facturas de Zinc Internacional')
  }

  const uploadFileByProcess = (idFile: number, Process: number) => {
    FService.RelateByProcess(ID, idFile, Proceso)
      .then((response) => {
        if (!response.data) {
          console.log('Ocurrio un error, esto es lo que regreso del backend: ' + response.data)
          setHeader('Error')
          setMsg('Ocurrio un error, no se guardo correctamente el archivo')
          setShowMsg(true)
        }
        generaReporte()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    loadCustomers()
    loadCatClaveSAT()
    loadCatDestinos()
    loadCatResponsables()
  }, [])

  useEffect(() => {
    mCatDestinos.filter((a) => {
      if (a.id == IDDestino) {
        setEntregar(a.direccion)
        return a
      }
    })
  }, [IDDestino])

  useEffect(() => {
    if (Cliente.includes('SELECCIONE')) setData(DataOriginal)
    else
      setData(
        DataOriginal.filter(function (row: DTO1868ReporteFactura) {
          return row.cliente === Cliente
        })
      )
  }, [Cliente])

  const changeDestino = (value: string) => {
    setIDDestino(parseInt(value))
    var result = mCatDestinos.filter((row) => row.id === parseInt(value))
    setEntregar(result[0] ? result[0].direccion : '')
  }

  const changeResponsable = (e: any) => {
    setIDResponsable(parseInt(e.target.value))
    var result = mCatResponsables.filter((row) => row.id === parseInt(e.target.value))
    setResponsable(result[0] ? result[0].responsable : '')
  }

  const changeToggle = (id: number) => {
    dispatch(toggleRow(id))
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-1'>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1'>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-2'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  setCliente(e.target.value)
                }}
                className='form-select form-select-sm'
              >
                {Clientes
                  ? Clientes.map((a) => {
                      return (
                        <option value={a.cliente} key={a.cliente}>
                          {a.cliente}
                        </option>
                      )
                    })
                  : null}
              </Form.Control>
            </div>
            <div className='col-md-3'>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className='col-md-1 right-label'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
            <div className='col'>
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp; Descarga
              </Button>
            </div>
            <div className='col'>
              {UserType === '4' || UserType === '2' ? (
                <Button
                  size='sm'
                  variant='uploadExcel'
                  style={{ textAlign: 'left' }}
                  onClick={() => {
                    uploadXML()
                  }}
                >
                  <IconContext.Provider value={{ color: '#A7DDD1', size: '15px' }}>
                    XML <BsCloudArrowUpFill />
                  </IconContext.Provider>
                </Button>
              ) : (
                ''
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {UserType === '4' || UserType === '2' ? (
                <Button
                  size='sm'
                  variant='uploadPDF'
                  style={{ textAlign: 'right' }}
                  onClick={() => {
                    uploadPDF()
                  }}
                >
                  <IconContext.Provider value={{ color: '#F8F686', size: '15px' }}>
                    PDF <BsCloudArrowUpFill />
                  </IconContext.Provider>{' '}
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className='MDcontainer'>
        <Table className='MDTable' hover>
          <thead style={{ position: 'sticky', top: '0' }}>
            <tr>
              <th style={{ width: '50px', textAlign: 'center' }}>PDF</th>
              <th style={{ width: '50px', textAlign: 'center' }}>XML</th>
              <th style={{ width: '50px', textAlign: 'center' }}>DODA</th>
              <th style={{ width: '50px', textAlign: 'center' }}>Prefile</th>
              <th style={{ width: '50px', textAlign: 'center' }}>BL</th>
              <th style={{ width: '100px', textAlign: 'center' }}>Fecha factura</th>
              <th style={{ width: '100px' }}>Invoice #</th>
              <th style={{ width: '70px' }}>Ruta</th>
              <th style={{ width: '270px' }}>Cliente</th>
              <th style={{ width: '120px' }}>Pedido cliente</th>
              <th style={{ width: '50px' }}>Edita</th>
              <th style={{ width: '270px' }}>Transportista</th>
              <th style={{ width: '270px' }}>Responsable cruce</th>
              <th style={{ width: '100px' }}>SCAC</th>
              <th style={{ width: '100px' }}>CAAT</th>
              <th style={{ width: '150px' }}>Sello</th>
              <th style={{ width: '150px' }}>Placas</th>
              <th style={{ width: '150px' }}>Carga</th>
              <th style={{ width: '100px' }}>Tipo pedimento</th>
              <th style={{ width: '550px' }}>Entregar en</th>
              <th style={{ width: '100px' }}>Aduana</th>
            </tr>
          </thead>
          <tbody>
            {Data
              ? Data.map((MasterData) => {
                  return (
                    <>
                      <tr
                        key={MasterData.id}
                        onClick={() => {
                          changeToggle(MasterData.id)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>
                          {MasterData.idpdf > 0 ? (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                showPDF(MasterData.idpdf)
                              }}
                            >
                              <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                                <BsFilePdfFill />
                              </IconContext.Provider>
                            </div>
                          ) : (
                            ''
                          )}
                        </td>
                        <td>
                          <div
                            style={{ textAlign: 'center' }}
                            onClick={() => {
                              showXML(MasterData.idxml, MasterData.invoice)
                            }}
                          >
                            <IconContext.Provider value={{ color: 'blue', size: '20px' }}>
                              <FaRegFile />
                            </IconContext.Provider>
                          </div>
                        </td>
                        <td>
                          {MasterData.idDoda > 0 ? (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                showPDF(MasterData.idDoda)
                                //  downloadFile(MasterData.idDoda, 23)
                              }}
                            >
                              <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                                <BsFilePdfFill />
                              </IconContext.Provider>
                            </div>
                          ) : (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                // downloadFile(MasterData.idDODA, 23)
                                setID(MasterData.id)
                                setProceso(23)
                                setModalFileManager(true)
                              }}
                            >
                              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                                <MdCloudUpload />
                              </IconContext.Provider>
                            </div>
                          )}
                        </td>
                        <td>
                          {MasterData.idPrefile > 0 ? (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                showPDF(MasterData.idPrefile)
                              }}
                            >
                              <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                                <BsFilePdfFill />
                              </IconContext.Provider>
                            </div>
                          ) : (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                // downloadFile(MasterData.idPrefile, 24)
                                setID(MasterData.id)
                                setProceso(24)
                                setModalFileManager(true)
                              }}
                            >
                              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                                <MdCloudUpload />
                              </IconContext.Provider>
                            </div>
                          )}
                        </td>
                        <td>
                          {MasterData.idbl > 0 ? (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                showPDF(MasterData.idbl)
                              }}
                            >
                              <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                                <BsFilePdfFill />
                              </IconContext.Provider>
                            </div>
                          ) : (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                setID(MasterData.id)
                                setProceso(29)
                                setModalFileManager(true)
                              }}
                            >
                              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                                <MdCloudUpload />
                              </IconContext.Provider>
                            </div>
                          )}
                        </td>                        
                        <td style={{ textAlign: 'center' }}>
                          {MasterData.fechaFactura ? MasterData.fechaFactura.substring(0, 10) : ''}
                        </td>
                        <td style={{}}>{MasterData.invoice}</td>
                        <td style={{}}>{MasterData.ruta}</td>
                        <td style={{}}>{MasterData.cliente}</td>
                        <td style={{}}>{MasterData.pedidoCliente}</td>
                        <td>
                          <Button
                            size='sm'
                            variant='light'
                            style={{
                              textAlign: 'right',
                              visibility: UserType === '4' || UserType === '2' ? 'visible' : 'hidden',
                            }}
                            onClick={() => {
                              EditMaster(MasterData)
                            }}
                          >
                            <IconContext.Provider value={{ color: 'blue', size: '15px' }}>
                              <BsFillPencilFill />
                            </IconContext.Provider>
                          </Button>
                        </td>
                        <td style={{}}>{MasterData.transportista}</td>
                        <td style={{}}>{MasterData.responsableCruce}</td>
                        <td style={{}}>{MasterData.scac}</td>
                        <td style={{}}>{MasterData.caat}</td>
                        <td>{MasterData.sello}</td>
                        <td>{MasterData.placas}</td>
                        <td>{MasterData.carga}</td>
                        <td>{MasterData.tipoPedimento}</td>
                        <td>{MasterData.entregar}</td>
                        <td>{MasterData.aduana}</td>
                      </tr>
                      <tr style={{ height: '0px' }}>
                        <th colSpan={2} style={{ backgroundColor: '#F8F9FE', height: '0px' }}></th>
                        <th colSpan={14} style={{ backgroundColor: '#F8F9FE', height: '0px' }}>
                          <Rpt1868FacturasDetalle DataMaster={MasterData} key={MasterData.id} />
                        </th>
                      </tr>
                    </>
                  )
                })
              : null}
          </tbody>
        </Table>
      </div>
      <Row>
        <Col xs={11}></Col>
        <Col style={{ fontWeight: 'bold' }}>Total: {Data.length}</Col>
      </Row>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
      <Modal show={modalFileManagerXML} onHide={() => setModalFileManagerXML(false)} size='lg'>
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={7}
            IdFile={CurrentTime2Number}
            IDUser={UserId}
            FileName={''}
            canDelete={false}
            FileType={['xml']}
            Leyenda={
              'Estimado cliente: ZINC INTERNACIONAL, favor de seleccionar el archivo xml, arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...'
            }
            onAppendFM={function (idFile: number): void {
              uploadFile(idFile, 7)
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button variant='secondary' onClick={() => setModalFileManagerXML(false)} size='sm'>
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={modalFileManagerPDF} onHide={() => setModalFileManagerPDF(false)} size='lg'>
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={8}
            IdFile={CurrentTime2Number}
            IDUser={UserId}
            FileName={''}
            canDelete={false}
            FileType={['pdf']}
            Leyenda={
              'Estimado cliente: ZINC INTERNACIONAL, favor de seleccionar el archivo PDF, arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...'
            }
            onAppendFM={function (idFile: number): void {
              uploadFile(idFile, 8)
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button variant='secondary' onClick={() => setModalFileManagerPDF(false)} size='sm'>
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={modalEditMaster} onHide={() => setModalEditMaster(false)} size='lg'>
        <Modal.Body>
          <Card className='labelSize13px'>
            <Card.Body>
              <Card>
                <Row>
                  <Col xs={12}>
                    <Alert variant='primary' style={{ textAlign: 'center' }}>
                      <h6>
                        Favor de proporcionar la siguiente informacion para complementar el reporte [{Invoice}]
                        <br />
                      </h6>
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}></Col>
                  <Col xs={1} style={{ paddingLeft: '15px' }}>
                    Sellos
                  </Col>
                  <Col xs={4}>
                    <Form.Control
                      type='text'
                      id='Sellos'
                      value={Sellos}
                      size='sm'
                      onChange={(e) => {
                        setSellos(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xs={1}>Placas</Col>
                  <Col xs={3}>
                    <Form.Control
                      type='text'
                      id='Placas'
                      value={Placas}
                      size='sm'
                      onChange={(e) => {
                        setPlacas(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={1}>
                    <div
                      style={{ cursor: 'pointer', paddingLeft: '10px' }}
                      onClick={() => {
                        loadCatalog(1)
                      }}
                      title='Catalogo de reponsables'
                    >
                      <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
                        <FaPeopleArrows />
                      </IconContext.Provider>
                    </div>
                  </Col>
                  <Col xs={11}>
                    <Form.Control
                      as='select'
                      value={IDResponsable}
                      onChange={(e) => {
                        changeResponsable(e)
                      }}
                      className='form-select form-select-sm'
                    >
                      <option value='0'>Responsables...</option>
                      {mCatResponsables
                        ? mCatResponsables.map((a) => {
                            return (
                              <option value={a.id} key={a.id}>
                                {a.responsable}
                              </option>
                            )
                          })
                        : null}
                    </Form.Control>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={1}>
                    <div
                      style={{ cursor: 'pointer', paddingLeft: '10px' }}
                      onClick={() => {
                        loadCatalog(2)
                      }}
                      title='Catalogo de destinos'
                    >
                      <IconContext.Provider value={{ color: 'orange', size: '25px' }}>
                        <FaWarehouse />
                      </IconContext.Provider>
                    </div>
                  </Col>
                  <Col xs={4} style={{ paddingLeft: '20px' }} size='sm'>
                    <Form.Control
                      as='select'
                      value={IDDestino}
                      onChange={(e) => {
                        changeDestino(e.target.value)
                      }}
                      className='form-select form-select-sm'
                    >
                      <option value='0'>DESTINOS...</option>
                      {mCatDestinos
                        ? mCatDestinos.map((a) => {
                            return (
                              <option value={a.id} key={a.id}>
                                {a.lugar}
                              </option>
                            )
                          })
                        : null}
                    </Form.Control>
                  </Col>
                  <Col xs={7}>
                    <Form.Control
                      rows={4}
                      as='textarea'
                      id='Entregar'
                      disabled={true}
                      value={Entregar}
                      size='sm'
                      onChange={(e) => {
                        setEntregar(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={10}>&nbsp;</Col>
            <Col xs={2}>
              <Button variant='primary' onClick={() => saveMaster()} size='sm'>
                Guardar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={ModalCatalogos} onHide={() => setModalCatalogos(false)} size='lg'>
        <Modal.Body>
          <Card className='labelSize13px'>
            <Card.Body>
              <Card>
                <Row>
                  <Col xs={12}>
                    {/*  <Alert variant='primary' style={{ textAlign: 'center' }}>
                      <h6>
                        Favor de proporcionar la siguiente informacion para complementar la factura [{Invoice}]
                        <br />
                      </h6>
                    </Alert> */}
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  {Catalogo === 1 ? <Cat1868Responsables /> : null}
                  {Catalogo === 2 ? <Cat1868Destinos /> : null}
                </Row>
              </Card>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

      <Modal show={ModalFileManager} onHide={() => setModalFileManager(false)} size='lg'>
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={Proceso}
            IdFile={ID}
            IDUser={UserId}
            FileName={''}
            canDelete={true}
            FileType={['pdf']}
            Leyenda={`Estimado colaborador, favor de seleccionar el archivo${
              Proceso === 23 ? 'DODA' : 'Prefile'
            }, arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...`}
            onAppendFM={function (idFile: number): void {
              uploadFileByProcess(idFile, Proceso)
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button variant='secondary' onClick={() => setModalFileManager(false)} size='sm'>
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

import * as React from 'react'
import { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DTO2096PerfilesParecidos from '../../../DTO/DTO2096PerfilesParecidos'
import { RootState } from '../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import I2096Headers from '../../../Interfaces/I2096Header'
import { updateInvoice } from '../../../../../../store/features/Clientes/2096/AmazonInvoices'
import DSAmazon from '../../../Services/Amazon.Services'

export interface IClasificatorAssignProps {
  Invoice: I2096Headers
  Disabled: boolean
}

export default function ClasificatorAssign(props: IClasificatorAssignProps) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [IDClasificador, setIDClasificador] = useState(
    props.Invoice.clasificador ? props.Invoice.clasificador : 0
  )
  const [Clasificador, setClasificador] = useState('')
  const [Data, setData] = useState<DTO2096PerfilesParecidos[]>([])
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    DSAmazon.PerfilesParecidosGet('Clasificador')
      .then((response) => {
        setData(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }, [])

  useEffect(() => {
    setClasificador(getUser(IDClasificador))
  }, [IDClasificador])

  const getUser = (Id: number) => {
    const NoAsignado = ' - NO ASIGNADO -'
    if (Id === 0) return NoAsignado
    else if (Data.length) {
      const Usuario = Data.filter((item) => item.idUsuario === Id)
      return Usuario[0].nombre ? Usuario[0].nombre : NoAsignado
    }
    return NoAsignado
  }

  const UpdateInfo = (updatedInvoice: I2096Headers) => {
    dispatch(updateInvoice(updatedInvoice))
  }

  const editArray = () => {
    const Invoice = mInvoices
      .filter((el) => el.id === props.Invoice.id)
      .map((el) => {
        return {
          ...el,
          clasificador: IDClasificador
        }
      })
    UpdateInfo(Invoice[0])
  }

  const saveAsignation = () => {
    DSAmazon.AsignaFacturaClasificador(IDClasificador, props.Invoice.id)
      .then((response) => {
        setShowModal(false)
        editArray()
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  return (
    <div>
      <Form.Control
        as="select"
        disabled={props.Disabled}
        className="form-select form-select-sm"
        onChange={(e) => {
          setIDClasificador(parseInt(e.target.value))
          setShowModal(true)
        }}
        value={IDClasificador}
      >
        <option value="0">- NO ASIGNADO -</option>
        {Data
          ? Data.map((item) => {
              return <option value={item.idUsuario}>{item.nombre}</option>
            })
          : ''}
      </Form.Control>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Alert variant="danger">
                <Alert.Heading>
                  ¿Esta seguro de asignar esta factura Amazon a: {Clasificador}?
                </Alert.Heading>
              </Alert>
            </Col>
          </Row>
          <Row style={{ paddingTop: '10px' }}>
            <Col XS={9}></Col>
            <Col xs={3}>
              <Button
                onClick={() => {
                  saveAsignation()
                }}
              >
                Asignar
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
//boostrap componentes
import { Button, Card, Form } from 'react-bootstrap'
import { BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'

//interfaces

import { IRptFacturasFraccion } from '../../Interfaces/Reportes/IRptContsFacturasFraccion'

import reportsDataService from '../../Services/Reportes/reportes.services'
import { useDispatch } from 'react-redux'

// Gui Components
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import { TargetURL } from '../../Constants/TargetURL'
// import DataTable from 'react-data-table-component'
import DataTable, { TableColumn } from 'react-data-table-component'

//Other
import moment from 'moment'
import * as XLSX from 'xlsx'
import { string } from 'yup'
const URL = new TargetURL()

export default function RptPFacturasFraccion() {
  const [curURL, setCurURL] = useState(URL.get())
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Data, setData] = useState<Array<IRptFacturasFraccion>>([])
  const [filteredData, setFilteredData] = useState<Array<IRptFacturasFraccion>>(
    []
  )

  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [filtro, setFiltro] = useState('')
  // const gridRef = React.useRef<any>(null)
  const [referencia, setReferencia] = useState('')

  const columnDefs = [
    {
      name: <div style={{ textAlign: 'center' }}>Factura</div>,
      width: '220px',
      selector: (row: IRptFacturasFraccion) => row.factura,
      sortable: true,
      filter: true,
      center: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Almacen</div>,
      width: '100px',
      selector: (row: IRptFacturasFraccion) => row.almacen,
      sortable: true,
      filter: true,
      center: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Orden</div>,
      width: '100px',
      selector: (row: IRptFacturasFraccion) => row.orden,
      sortable: true,
      filter: true,
      center: true,
      texAlign: 'center'
    },
    {
      name: <div style={{ textAlign: 'center' }}>Parte</div>,
      width: '150px',
      selector: (row: IRptFacturasFraccion) => row.parte,
      sortable: true,
      filter: true,
      center: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Pais</div>,
      width: '120px',
      selector: (row: IRptFacturasFraccion) => row.pais,
      sortable: true,
      filter: true,
      center: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Fraccion Factura XML</div>,
      width: '200px',
      selector: (row: IRptFacturasFraccion) => row.fraccionFacturaXML,
      sortable: true,
      filter: true,
      center: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Fraccion GEMCO</div>,
      width: '150px',
      selector: (row: IRptFacturasFraccion) => row.fraccionGEMCO,
      sortable: true,
      filter: true,
      center: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Nico GEMCO</div>,
      width: '120px',
      selector: (row: IRptFacturasFraccion) => row.nicoGEMCO,
      sortable: true,
      filter: true,
      center: true
    }
  ]

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const closeToast = (show: boolean): void => {
    setShowMsg(false)
  }

  const generaReporte = () => {
    if (!referencia) {
      setHeader('Error')
      setMsg('La referencia no puede estar vacía')
      setShowMsg(true)
      return
    }
    reportsDataService
      .getRptContsFacturasFraccion(referencia) // Usa la variable 'referencia' en lugar de 'Referencia'
      .then((response) => {
        setData(response.data)
        setFilteredData(response.data)

        setHeader('Informativo')
        setMsg('Se encontró la siguiente información...')
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrió un error: ' + e.message)
        setShowMsg(true)
        return
      })
  }

  const downloadExcel = () => {
    console.log(Data)
    exportExcel(Data, 'Facturas Fraccion')
  }

  function exportExcel(
    jsonData: IRptFacturasFraccion[],
    fileName: string
  ): void {
    let Heading = [
      [
        'Factura',
        'Almacen',
        'Orden',
        'Parte',
        'Pais',
        'Fraccion Factura XML',
        'Fraccion GEMCO',
        'Nico GEMCO'
      ]
    ]
    /* jsonData.forEach(function (v) {
        delete v.tipodeCambiodePedimento
      }) */
    /*     jsonData.map(function (item) {
        delete item.tipodeCambiodePedimento
        return item
      }) */
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const filtraReporte = (e: any) => {
    const searchText = e.target.value.toLowerCase()

    setFiltro(searchText)

    const filtered = Data.filter((item) => {
      return (
        (item.factura && item.factura.toLowerCase().includes(searchText)) ||
        (item.almacen && item.almacen.toLowerCase().includes(searchText)) ||
        (item.orden && item.orden.toString().includes(searchText)) ||
        (item.parte && item.parte.toLowerCase().includes(searchText)) ||
        (item.pais && item.pais.toLowerCase().includes(searchText)) ||
        (item.fraccionFacturaXML &&
          item.fraccionFacturaXML.toLowerCase().includes(searchText)) ||
        (item.fraccionGEMCO &&
          item.fraccionGEMCO.toLowerCase().includes(searchText)) ||
        (item.nicoGEMCO && String(item.nicoGEMCO).includes(searchText))
      )
    })

    // console.log('Filtered Data:', filtered)

    setFilteredData(filtered)
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className="row">
            <div className="col-md-1" style={{ textAlign: 'right' }}>
              <Form.Label size="sm">Referencia</Form.Label>
            </div>
            <div className="col-md-1" style={{ width: '130px' }}>
              <Form.Control
                type="text"
                size="sm"
                value={referencia}
                onChange={(e) => setReferencia(e.target.value)}
              />
            </div>

            <div className="col-md-1" style={{ width: '5px' }}></div>
            <div className="col-md-4" style={{ width: '150px ' }}>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  generaReporte()
                }}
              >
                Buscar <BsSearch />
              </Button>
            </div>

            <div className="col-md-1" style={{ width: '50px' }}></div>

            <div className="col-md-4">
              <Form.Control
                type="text"
                size="sm"
                placeholder="Search..."
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className="col-md-1" style={{ width: '260px' }}></div>
            <div className="col-md-1">
              <Button
                size="sm"
                variant="success"
                onClick={() => {
                  downloadExcel()
                }}
              >
                Excel <BsFileEarmarkExcel />
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div
            className="ag-theme-alpine"
            style={{ height: 400, width: '100%', textAlign: 'center' }}
          >
            <DataTable
              columns={columnDefs}
              data={filteredData}
              defaultSortAsc={true}
              striped={true}
              dense={true}
              paginationPerPage={10}
              pagination
              highlightOnHover
              pointerOnHover
              style={{ height: 300, width: '100%', textAlign: 'center' }}
              noHeader // Para evitar que se muestre el encabezado del DataTable
              onRowClicked={(row) => {
                // Aquí puedes manejar el clic en una fila si es necesario
              }}
            />
          </div>
        </Card.Body>
      </Card>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={closeToast}
      />
    </div>
  )
}

import { useEffect, useState } from 'react'
import DTOPedimentosCruzadosListaCliente from '../../../DTO/Dashboard/DTOPedimentosCruzadosListaCliente'
import DTOPedimentosCruzadosData from '../../../DTO/Dashboard/DTOPedimentosCruzadosData'
import DashboardDS from '../../../Services/Dashboard/Dashboard.Direccion.Services'
import { Card, Form } from 'react-bootstrap'
import { Chart } from 'react-chartjs-2'

export interface IRptPedimentosCruces01Props {}

export default function RptPedimentosCruces01(
  props: IRptPedimentosCruces01Props
) {
  const [Clientes, setClientes] = useState<DTOPedimentosCruzadosListaCliente[]>(
    []
  )
  const [Data, setData] = useState<DTOPedimentosCruzadosData[]>([])
  const [Cliente, setCliente] = useState('')
  const [IDCliente, setIDCliente] = useState(0)
  const [Pedimentos, setPedimentos] = useState<number[]>([])
  const [Cruces, setCruces] = useState<number[]>([])
  const [data6, setData6] = useState({
    labels: ['January', 'February', 'March'],
    datasets: [
      {
        type: 'line' as const,
        label: 'Pedimentos',
        borderColor: '#1850D0',
        borderWidth: 2,
        fill: false,
        data: [1, 2, 3]
      },
      {
        type: 'bar' as const,
        label: 'Cruces',
        backgroundColor: '#70D63E',
        data: [8, 9, 10],
        borderColor: 'white',
        borderWidth: 2
      }
    ]
  })

  const options6 = {
    indexAxis: 'x' as const,
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    responsive: true
  }

  useEffect(() => {
    DashboardDS.GETPedimentosCruzadosListaCliente()
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        return
      })
  }, [])

  /*   useEffect(() => {
    if (Clientes.length > 0) setCustomer(0)
  }, [Clientes]) */

  useEffect(() => {
    DashboardDS.GETPedimentosCruzadosData(IDCliente)
      .then((response) => {
        setData(response.data)
        let Pedimentos: number[] = []
        let Cruces: number[] = []
        let Categorias: string[] = []
        let RGoogleChartData: Array<Array<string | number>> = []
        RGoogleChartData.push(['Mes', 'Pedimentos', 'Cruces'])
        response.data.forEach(function (item) {
          Pedimentos.push(item.pedimentos)
          Cruces.push(item.despachos)
          Categorias.push(item.sMes)
          RGoogleChartData.push([item.sMes, item.pedimentos, item.despachos])
        })
        setData6({
          labels: Categorias,
          datasets: [
            {
              type: 'line' as const,
              label: 'Pedimentos',
              borderColor: '#1850D0',
              borderWidth: 2,
              fill: false,
              data: Pedimentos
            },
            {
              type: 'bar' as const,
              label: 'Cruces',
              backgroundColor: '#70D63E',
              data: Cruces,
              borderColor: 'white',
              borderWidth: 2
            }
          ]
        })
        var arrClientes = Clientes.filter((item) => {
          return item.id == IDCliente
        })
        setCliente(arrClientes[0].razonSocial)
      })
      .catch((e: Error) => {
        return
      })
  }, [IDCliente])

  return (
    <div>
      <Card>
        <Card.Body>
          <Form.Control
            as="select"
            onChange={(e) => {
              setIDCliente(parseInt(e.target.value))
            }}
            className="form-select form-select-sm"
          >
            <option value="0">Seleccion el cliente</option>
            {Clientes
              ? Clientes.map((c) => {
                  return <option value={c.id}>{c.razonSocial}</option>
                })
              : null}
          </Form.Control>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>
            {Cliente}
            <br />
            <br />
          </Card.Title>
          <Chart
            type="bar"
            data={data6}
            options={options6}
            width="100%"
            height="40%"
          />
        </Card.Body>
      </Card>
    </div>
  )
}

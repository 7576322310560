import * as React from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import I2096NoParte from '../Interfaces/I2096NoParte'
import AmazonDS from '../Services/Amazon.Services'
import {
  deleteNoPartes,
  populateNoPartes,
  updateNoPartes
} from '../../../../store/features/Clientes/2096/AmazonNoPartes'
import { RootState } from '../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import { IconContext } from 'react-icons'
import { BsFillPencilFill, BsFillXCircleFill } from 'react-icons/bs'

import I2096PaisClave from '../Interfaces/I2096PaisClave'
import DTO2096PerfilesParecidos from '../DTO/DTO2096PerfilesParecidos'
import DSAmazon from '../Services/Amazon.Services'
import DTO2096NoParte from '../DTO/DTO2096NoParte'
import I2096NoParteLog from '../Interfaces/I2096NoParteLog'
import { HiClipboardCheck } from 'react-icons/hi'
import DTO2096NoParteVerificacion from '../DTO/DTO2096NoParteVerificacion'
export interface IAmazonNoPartesProps {}

export default function AmazonNoPartes(props: IAmazonNoPartesProps) {
  const dispatch = useDispatch()
  const mNoPartes = useSelector(
    (state: RootState) => state.AmazonNoPartes.NoPartes
  )
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [NoParteLOG, setNoParteLOG] = useState<I2096NoParteLog[]>([])
  const [PaisClaves, setPaisClaves] = useState<I2096PaisClave[]>([])
  const [Data, setData] = useState<DTO2096PerfilesParecidos[]>([])
  const [DataNoParteVerificacion, setDataNoParteVeriifcacion] = useState<
    DTO2096NoParteVerificacion[]
  >([])
  const [ShowModal, setShowModal] = useState(false)
  const [ShowModalLog, setShowModalLog] = useState(false)
  const [ShowModalNoParteVerificacion, setShowModalNoParteVerificacion] =
    useState(false)
  const [ShowModalDelete, setShowModalDelete] = useState(false)
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [MsgTime, setMsgTime] = useState(3000)
  const [IDParte, setIDParte] = useState(0)
  const [Parte, setParte] = useState('')
  const [TipoOperacion, setTipoOperacion] = useState(0)
  const [Fraccion, setFraccion] = useState('')
  const [Subdivision, setSubdivision] = useState('')
  const [DescripcionFactura, setDescripcionFactura] = useState('')
  const [DescripcionAgenteAduanal, setDescripcionAgenteAduanal] = useState('')
  const [PaisOrigen, setPaisOrigen] = useState('')
  const [UMF, setUMF] = useState('')
  const [Search, setSearch] = useState('')
  const [FechaInsercion, setFechaInsercion] = useState('')
  const [Clasificador, setClasificador] = useState('')

  const colsNoPartes = [
    {
      name: 'id',
      width: '80px',
      cell: (row: I2096NoParte) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            loadNoPartesLOG(row)
          }}
        >
          {row.id}
        </div>
      ),
      sortable: true
    },
    {
      name: '',
      width: '60px',
      cell: (row: I2096NoParte) => (
        <Row>
          <Col>
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                setIDParte(row.id)
                setShowModalDelete(true)
              }}
            >
              <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                <BsFillXCircleFill />
              </IconContext.Provider>
            </div>
          </Col>
        </Row>
      ),
      sortable: true
    },
    {
      name: '',
      width: '60px',
      cell: (row: I2096NoParte) => (
        <Row>
          <Col>
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                loadDataNoParteVerificacion(row)
                setShowModalNoParteVerificacion(true)
              }}
            >
              <IconContext.Provider value={{ color: '#60DD0E', size: '30px' }}>
                <HiClipboardCheck />
              </IconContext.Provider>
            </div>
          </Col>
        </Row>
      ),
      sortable: true
    },
    {
      name: '',
      width: '50px',
      cell: (row: I2096NoParte) => (
        <Row>
          <Col>
            <div
              style={{
                textAlign: 'left',
                cursor: 'pointer'
              }}
              onClick={() => {
                loadData(row)
                setShowModal(true)
              }}
            >
              <IconContext.Provider value={{ color: 'blue', size: '20px' }}>
                <BsFillPencilFill />
              </IconContext.Provider>
            </div>
          </Col>
        </Row>
      )
    },
    {
      name: 'Cliente',
      width: '90px',
      selector: (row: I2096NoParte) => row.claveCliente,
      sortable: true
    },
    {
      name: 'Parte',
      width: '110px',
      cell: (row: I2096NoParte) => (
        <div style={{ cursor: 'pointer' }} onClick={() => openLink(row.parte)}>
          {row.parte}
        </div>
      ),
      sortable: true
    },
    {
      name: 'Proveedor',
      width: '110px',
      selector: (row: I2096NoParte) => row.claveProveedor,
      sortable: true
    },
    {
      name: 'Tipo Oper',
      width: '110px',
      selector: (row: I2096NoParte) =>
        row.tipoOperacion === 1 ? 'IMPO' : 'EXPO',
      sortable: true
    },
    {
      name: 'Fraccion',
      width: '110px',
      selector: (row: I2096NoParte) => row.fraccion,
      sortable: true
    },
    {
      name: 'Subdivision',
      width: '110px',
      selector: (row: I2096NoParte) => row.subdivision,
      sortable: true
    },
    {
      name: 'UMF',
      width: '80px',
      selector: (row: I2096NoParte) =>
        row.unidadMedidaFactura ? row.unidadMedidaFactura : '',
      sortable: true
    },
    {
      name: 'UMC',
      width: '80px',
      selector: (row: I2096NoParte) =>
        row.unidadMedidaCOVE ? row.unidadMedidaCOVE : '',
      sortable: true
    },
    {
      name: 'Descripcion factura',
      width: '400px',
      selector: (row: I2096NoParte) => row.descripcionFactura,
      wrap: true,
      sortable: true
    },
    {
      name: 'Descripcion Agente Aduanal',
      width: '300px',
      selector: (row: I2096NoParte) => row.descripcionAgenteAduanal,
      wrap: true,
      sortable: true
    },
    {
      name: 'Fecha / Hora',
      width: '220px',
      selector: (row: I2096NoParte) => row.fhActualizacion,
      wrap: true,
      sortable: true
    },
    {
      name: 'Usuario',
      width: '300px',
      selector: (row: I2096NoParte) => getUser(row.usuarioActualizacion),
      wrap: true,
      sortable: true
    }
  ]

  const colsNoPartesLOG = [
    {
      name: 'Fracc GEMCO',
      width: '120px',
      cell: (row: I2096NoParteLog) => row.fraccionGEMCO,
      sortable: true
    },
    {
      name: 'Fracc Original',
      width: '130px',
      cell: (row: I2096NoParteLog) =>
        row.fraccionOriginal ? row.fraccionOriginal : '',
      sortable: true
    },
    {
      name: 'NICO GEMCO',
      width: '130px',
      cell: (row: I2096NoParteLog) => row.subdivisionGEMCO,
      sortable: true,
      center: true
    },
    {
      name: 'NICO Original',
      width: '130px',
      cell: (row: I2096NoParteLog) =>
        row.subdivisionOriginal ? row.subdivisionOriginal : '',
      sortable: true,
      center: true
    },
    {
      name: 'Desc A. A.',
      width: '250px',
      cell: (row: I2096NoParteLog) => row.descripcionAgenteAduanal,
      wrap: true,
      sortable: true
    },
    {
      name: 'Desc Original',
      width: '250px',
      cell: (row: I2096NoParteLog) => row.descripcionOriginal,
      wrap: true,
      sorteable: true
    },

    {
      name: 'Fecha / Hora',
      width: '200px',
      cell: (row: I2096NoParteLog) => row.fhCreacion,
      sortable: true
    },
    {
      name: 'Usuario',
      width: '300px',
      cell: (row: I2096NoParteLog) => (row.sUsuario ? row.sUsuario : ''),
      wrap: true,
      sortable: true
    }
  ]

  const colsNoPartesVerificacion = [
    {
      name: 'Factura',
      width: '350px',
      cell: (row: DTO2096NoParteVerificacion) => row.factura,
      sortable: true
    },
    {
      name: 'Fecha factura',
      width: '130px',
      cell: (row: DTO2096NoParteVerificacion) => row.fFactura,
      sortable: true
    },
    {
      name: 'Referencia',
      width: '140px',
      cell: (row: DTO2096NoParteVerificacion) => row.referencia,
      sortable: true,
      center: true
    },
    {
      name: 'Patente',
      width: '110px',
      cell: (row: DTO2096NoParteVerificacion) => row.patente,
      sortable: true,
      center: true
    },
    {
      name: 'Aduana',
      width: '100px',
      cell: (row: DTO2096NoParteVerificacion) => row.aduana,
      wrap: true,
      sortable: true
    },
    {
      name: 'Pedimento',
      width: '150px',
      cell: (row: DTO2096NoParteVerificacion) => row.pedimento,
      wrap: true,
      sorteable: true
    }
  ]

  const openLink = (item: string) => {
    window.open('https://www.amazon.com/dp/' + item)
  }

  const getUser = (Id: number) => {
    const NoAsignado = 'NO ASIGNADO'
    if (Id === 0) return NoAsignado
    else if (Data.length) {
      const Usuario = Data.filter((item) => item.idUsuario === Id)
      return Usuario.length ? Usuario[0].nombre : NoAsignado
    }
    return NoAsignado
  }

  const loadProfiles = () => {
    DSAmazon.PerfilesParecidosGet('_')
      .then((response) => {
        setData(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const loadNoPartesLOG = (row: I2096NoParte) => {
    loadData(row)
    setShowModalLog(true)
    DSAmazon.NoPartesLogGET(row.id)
      .then((response) => {
        setNoParteLOG(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const loadData = (row: I2096NoParte) => {
    setIDParte(row.id)
    setParte(row.parte)
    setTipoOperacion(row.tipoOperacion)
    setFraccion(row.fraccion.substring(0, 8))
    setSubdivision(row.subdivision)
    setPaisOrigen(row.paisOrigen ? row.paisOrigen : '')
    setDescripcionFactura(row.descripcionFactura)
    setDescripcionAgenteAduanal(row.descripcionAgenteAduanal)
    setUMF(row.unidadMedidaFactura ? row.unidadMedidaFactura : '')
    setFechaInsercion(row.fhActualizacion)
    setClasificador(getUser(row.usuarioActualizacion))
  }

  const loadDataNoParteVerificacion = (row: I2096NoParte) => {
    AmazonDS.NoParteVerificacionGET(row.parte)
      .then((response) => {
        setDataNoParteVeriifcacion(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadPaisesClaves = () => {
    AmazonDS.PaisClavesGET()
      .then((response) => {
        setPaisClaves(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    loadPaisesClaves()
    loadProfiles()
  }, [])

  const loadInfo = () => {
    if (Search.length < 9) {
      setHeader('Error')
      setMsg('Proporcione el numero de parte, para poder continuar')
      setShowMsg(true)
      return
    }
    AmazonDS.NoPartesGet(Search)
      .then((response) => {
        console.log(response.data)
        dispatch(populateNoPartes(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveInfo = () => {
    const data: DTO2096NoParte = {
      id: IDParte,
      fraccionGEMCO: Fraccion,
      subdivisionGEMCO: Subdivision,
      descripcionAgenteAduanal: DescripcionAgenteAduanal,
      usuario: UserId
    }
    AmazonDS.NoPartesUpdate(data)
      .then((response) => {
        dispatch(updateNoPartes(response.data))
        setHeader('Informativo')
        setMsg('La informacion se guardo correctamente')
        setShowMsg(true)
      })
      .catch(function (error) {
        console.log(error.toJSON())
        if (error.message == 'Request failed with status code 400') {
          setHeader('Error')
          setMsg('Fraccion no valida!')
          setShowMsg(true)
        }
        return
      })
  }

  const deleteNoParte = () => {
    AmazonDS.DeleteNoParte(IDParte)
      .then((response) => {
        dispatch(deleteNoPartes(IDParte))
        setHeader('Informativo')
        setMsg('La informacion se guardo correctamente')
        setShowMsg(true)
      })
      .catch(function (error) {
        console.log(error.toJSON())
        if (error.message == 'Request failed with status code 400') {
          setHeader('Error')
          setMsg('Fraccion no valida!')
          setShowMsg(true)
        }
        return
      })
  }

  return (
    <div>
      <Card>
        <Row style={{ paddingTop: '15px' }}>
          <Col xs={8}></Col>
          <Col xs={1} style={{ textAlign: 'right' }}>
            No parte
          </Col>
          <Col xs={1}>
            <Form.Control
              type="text"
              id="Search"
              size="sm"
              value={Search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col xs={1}>
            <Button
              onClick={() => {
                loadInfo()
              }}
            >
              Busca
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card.Body>
              <DataTable
                noHeader
                defaultSortFieldId={'id'}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                columns={colsNoPartes}
                data={mNoPartes.filter(function (el) {
                  return el.id > 0
                })}
              />
            </Card.Body>
          </Col>
        </Row>
      </Card>
      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            No Parte: {Parte} ................ insertado : [ {FechaInsercion} ]
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: '400px', overflow: 'scroll' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={2}>T. Operacion</Col>
              <Col xs={3}>
                <Form.Control
                  disabled={true}
                  as="select"
                  onChange={(e) => setTipoOperacion(parseInt(e.target.value))}
                  className="form-select form-select-sm"
                >
                  <option value="1">Importacion</option>
                  <option value="2">Exportacion</option>
                </Form.Control>
              </Col>
              <Col xs={1}>Fraccion</Col>
              <Col xs={3}>
                <Form.Control
                  defaultValue={Fraccion}
                  type="text"
                  placeholder="Fraccion"
                  onChange={(e) => setFraccion(e.target.value)}
                  size="sm"
                />
              </Col>
              <Col>Sub</Col>
              <Col xs={2}>
                <Form.Control
                  defaultValue={Subdivision}
                  type="text"
                  placeholder="Subdivicion"
                  onChange={(e) => setSubdivision(e.target.value)}
                  size="sm"
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={2}>
                <Form.Label>Descripcion factura</Form.Label>
              </Col>
              <Col xs={10}>
                <Form.Group className="mb-3">
                  <Form.Control
                    disabled={true}
                    as="textarea"
                    rows={3}
                    value={DescripcionFactura}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={2}>
                <Form.Label>Descripcion agente aduanal</Form.Label>
              </Col>
              <Col xs={10}>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={DescripcionAgenteAduanal}
                    onChange={(e) =>
                      setDescripcionAgenteAduanal(e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={2}>Pais Origen</Col>
              <Col xs={4}>
                <Form.Control
                  as="select"
                  value={PaisOrigen}
                  disabled={true}
                  onChange={(e) => setPaisOrigen(e.target.value)}
                  className="form-select form-select-sm"
                >
                  {' '}
                  {PaisClaves
                    ? PaisClaves.map((row) => {
                        return (
                          <option value={row.clave_a3}>{row.paisNom}</option>
                        )
                      })
                    : ''}
                </Form.Control>
              </Col>
              <Col xs={4}>Unidad medida factura</Col>
              <Col xs={1}>
                <Form.Control
                  disabled={true}
                  defaultValue={UMF}
                  type="text"
                  placeholder="Unidad de medida factura"
                  onChange={(e) => setUMF(e.target.value)}
                  size="sm"
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={2}>Clasificador</Col>
              <Col xs={4} style={{ textAlign: 'left' }}>
                <Form.Control
                  defaultValue={Clasificador}
                  disabled={true}
                  type="text"
                  placeholder="Clasificador"
                  size="sm"
                />
              </Col>
              <Col xs={4}></Col>
              <Col xs={1}>
                <Button onClick={() => saveInfo()}>Guarda</Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ShowModalLog}
        onHide={() => {
          setShowModalLog(false)
        }}
        size={'sm'}
        dialogClassName={'modal-90w'}
      >
        <Modal.Header closeButton>
          <Modal.Title>No Parte: {Parte}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*  <div style={{ height: '400px', overflow: 'scroll' }}> */}
          <Row style={{ paddingTop: '15px' }}>
            <Col xs={12}>
              <DataTable
                noHeader
                fixedHeaderScrollHeight="400px"
                defaultSortFieldId={'id'}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                striped={true}
                dense={true}
                columns={colsNoPartesLOG}
                data={NoParteLOG.filter(function (el) {
                  return el.id > 0
                })}
              />
            </Col>
          </Row>
          {/*   </div> */}
        </Modal.Body>
      </Modal>

      <Modal
        show={ShowModalNoParteVerificacion}
        onHide={() => {
          setShowModalNoParteVerificacion(false)
        }}
        size={'sm'}
        dialogClassName={'modal-90w'}
      >
        <Modal.Header closeButton>
          <Modal.Title>No Parte: {Search}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ paddingTop: '15px' }}>
            <Col xs={12}>
              <DataTable
                noHeader
                fixedHeaderScrollHeight="400px"
                defaultSortFieldId={'id'}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                striped={true}
                dense={true}
                columns={colsNoPartesVerificacion}
                data={DataNoParteVerificacion.filter(function (el) {
                  return el
                })}
              />
            </Col>
          </Row>
          {/*   </div> */}
        </Modal.Body>
      </Modal>

      <Modal
        show={ShowModalDelete}
        onHide={() => {
          setShowModalDelete(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Header>
          <Modal.Title>
            <Row>
              <Col xs={12}>
                <Alert variant="warning">
                  <h3>¿Esta seguro de eliminar el no parte: {Parte}</h3>
                </Alert>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ paddingTop: '15px' }}>
            <Col></Col>
            <Col xs={5}>
              <Button variant={'danger'} onClick={()=>{deleteNoParte()}}>Elimina</Button>
            </Col>
            <Col xs={5}>
              <Button variant={'secondary'} onClick={()=>{setShowModalDelete(false)}}>Salir</Button>
            </Col>
            <Col></Col>
          </Row>
          {/*   </div> */}
        </Modal.Body>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        time={MsgTime}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatFraccionesVulnerables from '../../../Interfaces/Catalogos/ICatFraccionesVulnerables';
const CatFraccionesVulnerables: ICatFraccionesVulnerables[] = [{id:0, fraccion:0 , nico:''  , activo:0  }]
const initialState = { CatFraccionesVulnerables }

export const CatFraccionesVulnerablesSlice = createSlice({
  name: 'CatCorresponsales',
  initialState: initialState,
  reducers: {
    populateCatFraccionesVulnerables : (state, action: PayloadAction<ICatFraccionesVulnerables[]>) => {
      state.CatFraccionesVulnerables = []
      state.CatFraccionesVulnerables.push(... action.payload)
    },
    addCatFraccionesVulnerables : (state, action: PayloadAction<ICatFraccionesVulnerables>) => {
      var Existe = state.CatFraccionesVulnerables.find(function(item) {
        return item.id === action.payload.id;
      });
     if (!Existe) state.CatFraccionesVulnerables.push(action.payload)
    },
    updateCatFraccionesVulnerables : (state, action: PayloadAction<ICatFraccionesVulnerables>) => {
      const i = state.CatFraccionesVulnerables.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.CatFraccionesVulnerables[i] = action.payload;
      else state.CatFraccionesVulnerables.push(action.payload);

    },
    deleteCatFraccionesVulnerables : (state, action: PayloadAction<number>) => {
      const newArr = state.CatFraccionesVulnerables.filter(data => data.id != action.payload);
      state.CatFraccionesVulnerables=newArr
    },
    InitCatFraccionesVulnerables : (state, action: PayloadAction<number>) => {
      state.CatFraccionesVulnerables.splice(0,state.CatFraccionesVulnerables.length-1)
     // state.CatCorresponsales = [{id:0, factura:'-Seleccione-', idTrafico:0, valorFacturaDls:0, proveedor:0}]
    },
  },
})

export const { addCatFraccionesVulnerables, 
  populateCatFraccionesVulnerables, 
  updateCatFraccionesVulnerables, 
  deleteCatFraccionesVulnerables,
  InitCatFraccionesVulnerables } = CatFraccionesVulnerablesSlice.actions;
export default CatFraccionesVulnerablesSlice.reducer;

import http from "../common/http-common";
import ICatFraccionesVulnerables from '../../Interfaces/Catalogos/ICatFraccionesVulnerables'



class FraccionesVulnerablesDataService {
  

  constructor()
  {
    type returnData = [ICatFraccionesVulnerables[]]
  }

  getAll() {
    return http.get<ICatFraccionesVulnerables[]>(`/Catalogos/CatFraccionesVulnerables/getAll`);
  }

  Append(data: ICatFraccionesVulnerables) {
    return http.post<ICatFraccionesVulnerables>(`/Catalogos/CatFraccionesVulnerables/Append`, data);
  }
  Delete(id: number) {
    return http.delete<ICatFraccionesVulnerables>(`/Catalogos/CatFraccionesVulnerables/Delete/${id}`);
  }

  
}
export default new FraccionesVulnerablesDataService();
import { FC, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { BsFileEarmarkExcel, BsFilePdfFill, BsFillPencilFill, BsSearch } from 'react-icons/bs'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import FService from '../Services/Facturas.Services'
import * as XLSX from 'xlsx'
import DTO1868Transportista from '../DTO/DTO1868Transportista'
import FileManager from '../../../Utils/FileManager/FileManager'
import FileManagerDS from '../../../../Services/Utils/FileManager.Services'
import { IconContext } from 'react-icons'
import DTO1868SCACCAAT from '../DTO/DTO1868SCACCAAT'

interface IProps {}

export default function Rpt1868Transportista (props:IProps) {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [UserType, setUserType] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserType')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Data, setData] = useState<DTO1868Transportista[]>([])
  const [DataOriginal, setDataOriginal] = useState<DTO1868Transportista[]>([])
  const [ID, setID] = useState(0)
  const [Process, setProcess] = useState(9)
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-30))
  const [Fin, setFin] = useState(currentDate(0))
  const [modalFileManagerPDF, setModalFileManagerPDF] = useState(false)
  const [ModalData, setModalData] = useState(false)
  const [filtro, setFiltro] = useState('')
  const [SCAC, setSCAC] = useState('')
  const [CAAT, setCAAT] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const columnsConcepts = [
    {
      name: 'Factura',
      width: '85px',
      cell: (row: DTO1868Transportista) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            showPDF(row.idpdf,8)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <BsFilePdfFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'DODA',
      width: '70px',
      cell: (row: DTO1868Transportista) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            showPDF(row.idDoda, 23)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <BsFilePdfFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Prefile',
      width: '70px',
      cell: (row: DTO1868Transportista) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            showPDF(row.idPrefile, 24)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <BsFilePdfFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'BL',
      width: '70px',
      cell: (row: DTO1868Transportista) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            showPDF(row.idbl, 29)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <BsFilePdfFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'SCAC',
      width: '100px',
      selector: (row: DTO1868Transportista) => row.scac,
      sortable: true,
    },
    {
      name: 'CAAT',
      width: '100px',
      selector: (row: DTO1868Transportista) => row.caat,
      sortable: true,
    },
    {
      name: '',
      width: '55px',
      cell: (row: DTO1868Transportista) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setID(row.id)
            setModalData(true)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '15px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Responsable cruce',
      width: '250px',
      selector: (row: DTO1868Transportista) => row.responsableCruce,
      sortable: true,
    },
    {
      name: 'Sello',
      width: '100px',
      selector: (row: DTO1868Transportista) => (row.sello ? row.sello : ''),
      sortable: true,
    },
    {
      name: 'Placas',
      width: '190px',
      selector: (row: DTO1868Transportista) => row.placas,
      sortable: true,
    },
    {
      name: 'Pallets',
      width: '100px',
      selector: (row: DTO1868Transportista) => row.pallets,
      sortable: true,
    },
    {
      name: 'Peso Bruto',
      width: '150px',
      selector: (row: DTO1868Transportista) => row.pesoBruto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      sortable: true,
    },
    {
      name: 'Carga',
      width: '100px',
      selector: (row: DTO1868Transportista) => row.carga,
      sortable: true,
    },
    {
      name: 'Tipo pedimento',
      width: '135px',
      selector: (row: DTO1868Transportista) => row.tipoPedimento,
      sortable: true,
    },
    {
      name: 'Entregar en',
      width: '850px',
      selector: (row: DTO1868Transportista) => row.entregar,
      sortable: true,
    },
    {
      name: 'Aduana',
      width: '150px',
      selector: (row: DTO1868Transportista) => row.aduana,
      sortable: true,
    },
    {
      name: 'Producto/Materia prima',
      width: '350px',
      selector: (row: DTO1868Transportista) => row.productoMateriaPrima,
      sortable: true,
    },
    {
      name: 'Fraccion arancelaria',
      width: '150px',
      selector: (row: DTO1868Transportista) => row.fraccionArancelaria,
      sortable: true,
    },
    {
      name: 'Clave SAT',
      width: '150px',
      selector: (row: DTO1868Transportista) => row.claveSAT1,
      sortable: true,
    },
    {
      name: 'Concepto Producto / MP',
      width: '250px',
      selector: (row: DTO1868Transportista) => row.conceptoProductoMP,
      sortable: true,
    },
    {
      name: 'Empaque (descripcion)',
      width: '550px',
      selector: (row: DTO1868Transportista) => row.empaqueDescripcion,
      sortable: true,
    },
    {
      name: 'Clave SAT',
      width: '120px',
      selector: (row: DTO1868Transportista) => row.claveSAT2,
      sortable: true,
    },
  ]


  const showPDF = (id: number, Proceso: number) => {
    FService.getFileContent(id, Proceso)
      .then((response: any) => {
        if (response.status === 200) {
          console.log(response.data)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado PDF')
        setShowMsg(true)
        return
      })
  }


  const choosePDF = (row: DTO1868Transportista) => {
    setID(row.id)
    FileManagerDS.getFileContent(row.id, Process)
      .then((response: any) => {
        if (response.status === 200) {
          console.log(response.data)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setModalFileManagerPDF(true)
        return
      })
  }

  function exportExcel(jsonData: DTO1868Transportista[], fileName: string): void {
    let Heading = [
      [
        'Responsable cruce',
        'Sellos',
        'Placas',
        'Pallets',
        'Peso bruto',
        'Carga',
        'Tipo pedimento',
        'Entregar en',
        'Aduana',
        'Producto materia prima',
        'Fraccion arancelaria',
        'Clave SAT',
        'Concepto producto MP',
        'Empaque descripcion',
        'Clave SAT',
      ],
    ]
    const dataOnly = jsonData.map(
      ({
        responsableCruce,
        sello,
        placas,
        pallets,
        pesoBruto,
        carga,
        tipoPedimento,
        entregar,
        aduana,
        productoMateriaPrima,
        fraccionArancelaria,
        claveSAT1,
        conceptoProductoMP,
        empaqueDescripcion,
        claveSAT2,
      }) => {
        return {
          responsableCruce,
          sello,
          placas,
          pallets,
          pesoBruto,
          carga,
          tipoPedimento,
          entregar,
          aduana,
          productoMateriaPrima,
          fraccionArancelaria,
          claveSAT1,
          conceptoProductoMP,
          empaqueDescripcion,
          claveSAT2,
        }
      }
    )
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, dataOnly, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c + 1; C <= range.e.c + 1; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const generaReporte = () => {
    FService.getRptTransportista(Inicio, Fin, UserId)
      .then((response) => {
        setData(response.data)
        setDataOriginal(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const filtraReporte = (e: any) => {
    let value = e.target.value
    value = value.toUpperCase()
    setFiltro(value)
    if (value.length > 0) {
      console.log(value)
      setData(
        Data.filter(function (row: DTO1868Transportista) {
          return (
            row.responsableCruce.includes(value) ||
            row.pallets.toString().includes(value) ||
            row.pesoBruto.toString().includes(value) ||
            row.carga.includes(value) ||
            row.tipoPedimento.includes(value) ||
            row.aduana.includes(value) ||
            row.fraccionArancelaria.includes(value)
          )
        })
      )
      console.log(Data)
    } else {
      setData(DataOriginal)
    }
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de Facturas de Zinc Internacional')
  }

  const uploadPDF = (idFile: number, Process: number) => {
    FService.Append(idFile, Process)
      .then((response) => {
        if (!response.data) {
          console.log('Ocurrio un error, esto es lo que regreso del backend: ' + response.data)
          setHeader('Error')
          setMsg('Ocurrio un error, no se guardo correctamente el archivo')
          setShowMsg(true)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveSCACCAAT = () => {
    const data: DTO1868SCACCAAT = {
      id: ID,
      scac: SCAC,
      caat: CAAT,
    }
    FService.AppendSCACCAAT(data)
      .then((response) => {
        generaReporte()
        setHeader('Informativo')
        setMsg('La informacion se guardo existosamente')
        setModalData(false)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setModalData(false)
        return
      })
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-1'>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1'>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-2'></div>
            <div className='col-md-3'>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className='col-md-1 right-label'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
            <div className='col'>
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp; Descarga
              </Button>
            </div>
            <div className='col'></div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        {
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            striped={true}
            dense={true}
            paginationPerPage={10}
            pagination
            highlightOnHover
            columns={columnsConcepts}
            data={Data}
            pointerOnHover
          />
        }
      </div>
      <Modal show={modalFileManagerPDF} onHide={() => setModalFileManagerPDF(false)} size='lg'>
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={Process}
            IdFile={ID}
            IDUser={UserId}
            FileName={''}
            canDelete={false}
            FileType={['pdf']}
            Leyenda={
              'Estimado colaborador, favor de seleccionar el archivo PDF, arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...'
            }
            onAppendFM={function (idFile: number): void {
              uploadPDF(idFile, Process)
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button variant='secondary' onClick={() => setModalFileManagerPDF(false)} size='sm'>
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal show={ModalData} onHide={() => setModalData(false)} size='lg'>
        <Row style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Col xs={2} style={{ textAlign: 'right' }}>
            <Button variant='secondary' onClick={() => setModalData(false)} size='sm'>
              Cerrar
            </Button>
          </Col>
          <Col xs={1}>{ID}</Col>
          <Col xs={1}>SCAC</Col>
          <Col xs={2}>
            <Form.Control
              type='text'
              id='SCAC'
              value={SCAC}
              size='sm'
              onChange={(e) => {
                setSCAC(e.target.value)
              }}
            />
          </Col>
          <Col xs={1}>CAAT</Col>
          <Col xs={2}>
            <Form.Control
              type='text'
              id='CAAT'
              value={CAAT}
              size='sm'
              onChange={(e) => {
                setCAAT(e.target.value)
              }}
            />
          </Col>
          <Col xs={1}></Col>
          <Col xs={2}>
            <Button variant='primary' onClick={() => saveSCACCAAT()} size='sm'>
              Guardar
            </Button>
          </Col>
        </Row>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import React, { FC, useState } from 'react'
import { Button } from 'react-bootstrap'
import { DialogAE } from '../ArchivoElectronico/DialogAE'
interface IProps {}

export const VerDocumentos: FC<IProps> = (props) => {
  const [showDialog, setShowDialog] = useState(false)
  const showDocumentos = () => {
    setShowDialog(true)
  }

  const closeDialog = () => {
    setShowDialog(false)
  }

  return (
    <div>
      <span>
        <Button onClick={() => showDocumentos()} variant='secondary'>
          ver Documentos
        </Button>
      </span>
      <DialogAE showDialog={showDialog} header={''} closeDialog={closeDialog} />
    </div>
  )
}

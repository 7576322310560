import * as React from 'react'
import { IconContext } from 'react-icons'
import { MdInfo } from 'react-icons/md'
import DSAmazon from '../../../Services/Amazon.Services'
import { MsgInformativo } from '../../../../../Utils/Toast/msgInformativo'
import { RootState } from '../../../../../../store/store'
import {
  initializeInvoice,
  populateInvoices,
  updateInvoice
} from '../../../../../../store/features/Clientes/2096/AmazonInvoices'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import I2096Headers from '../../../Interfaces/I2096Header'

export interface IPrioritySelectorProps {
  IDInvoice: number,
  Priority: boolean,
  canChange: boolean
}

export function PrioritySelector(props: IPrioritySelectorProps) {
  const dispatch = useDispatch()
  /* const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  ) */
  const [Prioridad, setPrioridad] = React.useState(props.Priority)
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const msgColor = 'primary'

  React.useEffect(() => {}, [])

  const togglePriority = () => {
    if (!props.canChange) return
    const newPrority=!Prioridad
    DSAmazon.AmazonInvoicePrioritySET(props.IDInvoice, newPrority)
    .then((response) => {
      if (response.data) {
        setPrioridad(newPrority)
        let Invoice: I2096Headers[] = response.data.filter(
          (item) => item.id === props.IDInvoice
        )
        dispatch(updateInvoice(Invoice[0]))
        setHeader('Importante')
        setMsg('Se le ha cambiado la priodidad a esta factura')
        return
      }
    })
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Ocurrio un error')
      return
    })
  }

  return (
    <div
      onClick={() => {
        togglePriority()
      }}
      style={{ width: '50px' }}
    >
      {Prioridad ? (
        <IconContext.Provider value={{ color: '#DD0E0E', size: '25px' }}>
          <MdInfo />
        </IconContext.Provider>
      ) : (
        <img
          src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          width="0"
          height="0"
          alt=""
        />
      )}
       <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

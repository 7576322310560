import http from "../common/http-common";
import DTOPedimentosCruzadosListaCliente from "../../DTO/Dashboard/DTOPedimentosCruzadosListaCliente";
import DTOPedimentosCruzadosData from "../../DTO/Dashboard/DTOPedimentosCruzadosData";

class Dashboard$Direccion$DataService {
  GETPedimentosCruzadosData(id: number) {
    return http.get<DTOPedimentosCruzadosData[]>(`/Direccion/GetPedimentosCrucesData?id=${id}`);
  }
  GETPedimentosCruzadosListaCliente() {
    return http.get<DTOPedimentosCruzadosListaCliente[]>(`/Direccion/GetPedimentosCrucesListaCliente`);
  }
}
export default new Dashboard$Direccion$DataService();
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatCorresponsales from '../../../Interfaces/Catalogos/ICatCorresponsales';
const CatCorresponsales: ICatCorresponsales[] = [{id:0, nombre:'-Seleccione-', patente:0, aduana:0, correos:''}]
const initialState = { CatCorresponsales }

export const CatCorresponsalesSlice = createSlice({
  name: 'CatCorresponsales',
  initialState: initialState,
  reducers: {
    populateCatCorresponsales : (state, action: PayloadAction<ICatCorresponsales[]>) => {
      state.CatCorresponsales = []
      state.CatCorresponsales.push(... action.payload)
    },
    addCatCorresponsales : (state, action: PayloadAction<ICatCorresponsales>) => {
      var Existe = state.CatCorresponsales.find(function(item) {
        return item.id === action.payload.id;
      });
     if (!Existe) state.CatCorresponsales.push(action.payload)
    },
    updateCatCorresponsales : (state, action: PayloadAction<ICatCorresponsales>) => {
      const i = state.CatCorresponsales.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.CatCorresponsales[i] = action.payload;
      else state.CatCorresponsales.push(action.payload);

    },
    deleteCatCorresponsales : (state, action: PayloadAction<number>) => {
      const newArr = state.CatCorresponsales.filter(data => data.id != action.payload);
      state.CatCorresponsales=newArr
    },
    InitCatCorresponsales : (state, action: PayloadAction<number>) => {
      state.CatCorresponsales.splice(0,state.CatCorresponsales.length-1)
     // state.CatCorresponsales = [{id:0, factura:'-Seleccione-', idTrafico:0, valorFacturaDls:0, proveedor:0}]
    },
  },
})

export const { addCatCorresponsales, 
  populateCatCorresponsales, 
  updateCatCorresponsales, 
  deleteCatCorresponsales,
  InitCatCorresponsales } = CatCorresponsalesSlice.actions;
export default CatCorresponsalesSlice.reducer;

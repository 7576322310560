import { useCallback, useEffect, useState } from 'react'
import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import * as CurrencyFormat from 'react-currency-format'
import { MsgInformativo } from '../../../../Utils/Toast/msgInformativo'
import { RootState } from '../../../../../store/store'
import { updateInvoice } from '../../../../../store/features/Clientes/2096/AmazonInvoices'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import DTO2096Fraccion from '../../DTO/DTO2096Fraccion'
import DSAmazon from '../../Services/Amazon.Services'
import I2096Headers from '../../Interfaces/I2096Header'
import I2096Detail from '../../Interfaces/I2096Detail'
import DTO2096FraccionDescripcion from '../../DTO/DTO2096FraccionDescripcion'

export interface IComboFraccionProps {
  IDInvoice: number
  IDDetail: number
  Prioridad?: boolean
  FraccionOriginal: string
  Deshabilitado: boolean
  ConfirmaFraccion: number
  Detail: I2096Detail
}

export function ComboFraccion(props: IComboFraccionProps) {
  const dispatch = useDispatch()
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const mCatTipoConsultas = useSelector(
    (state: RootState) => state.AmazonCatConceptosConsulta.CatConcetosConsulta
  )
  const [Validacion, setValidacion] = useState(props.ConfirmaFraccion)
  const [UserChanged, setUserChanged] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [Fraccion, setFraccion] = useState(
    props.Detail.validaFraccionOriginal == 0 ? '' : props.FraccionOriginal
  )
  const [header, setHeader] = useState('')
  const [DescripcionGEMCO, setDescripcionGEMCO] = useState(
    props.Detail.pedimentoDescription
  )
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [CumplimientoNormativo, setCumplimientoNormativo] = useState(
    props.Detail.cumplimientoNormativo ? props.Detail.cumplimientoNormativo : ''
  )
  const [Comentarios, setComentarios] = useState(
    props.Detail.comentarios
      ? props.Detail.comentarios
      : ''
  )
  const [TipoConsulta, setTipoConsulta] = useState(0)
  const [TipoConsultaTxt, setTipoConsultaTxt] = useState('')
  const msgColor = 'primary'

  const refreshStore = (Invoice: I2096Headers, Detail: I2096Detail[]) => {
    const updatedInvoice: I2096Headers = {
      id: props.IDInvoice,
      referencia: Invoice.referencia,
      commercialInvoiceDate: Invoice.commercialInvoiceDate,
      invoiceNumber: Invoice.invoiceNumber,
      name: Invoice.name,
      trailerNumber: Invoice.trailerNumber,
      trailerId: Invoice.trailerId,
      porOfLoading: Invoice.porOfLoading,
      portOfEntry: Invoice.portOfEntry,
      paymentsTerms: Invoice.paymentsTerms,
      incoterms: Invoice.incoterms,
      hawb: Invoice.hawb,
      totalInvoiceQuantityUnitOfMeasure:
        Invoice.totalInvoiceQuantityUnitOfMeasure,
      totalInvoiceQuantity: Invoice.totalInvoiceQuantity,
      totalInvoiceWeightUnitOfMeasure: Invoice.totalInvoiceWeightUnitOfMeasure,
      totalInvoiceWeight: Invoice.totalInvoiceWeight,
      totalInvoiceValueChargeOrAllowance:
        Invoice.totalInvoiceValueChargeOrAllowance,
      totalInvoiceValueCurrencyISOCode:
        Invoice.totalInvoiceValueCurrencyISOCode,
      totalInvoiceValueMonetaryAmount: Invoice.totalInvoiceValueMonetaryAmount,
      footNote: Invoice.footNote,
      archivo: Invoice.archivo,
      fCreacion: Invoice.fCreacion,
      clasificador: Invoice.clasificador,
      noVuelta: Invoice.noVuelta,
      estatus: Invoice.estatus,
      max: true,
      urgente: Invoice.urgente,
      detail: Detail
    }
    dispatch(updateInvoice(updatedInvoice))
  }

  const UpdateInfo = (newData: I2096Detail) => {
    console.log('new data= ' + JSON.stringify(newData))
    const Invoice = mInvoices
      .filter((el) => el.id === props.IDInvoice)
      .map((el) => {
        return el
      })
    const newDetail = Invoice[0].detail.map((el) => {
      if (el.id === props.IDDetail) {
        return {
          ...el,
          fraccionGEMCO: newData.fraccionGEMCO,
          descripcionGEMCO: newData.descripcionGEMCO,
          autorizado: newData.autorizado,
          confirmaDescripcion: newData.confirmaDescripcion,
          cumplimientoNormativo: newData.cumplimientoNormativo,
          comentarios: newData.comentarios,
          confirmaFraccion: newData.confirmaFraccion
        }
      }
      return el
    })
    refreshStore(Invoice[0], newDetail)
  }

  const changeValidacion = (e: number) => {
    setValidacion(e)
    setUserChanged(true)
  }

  useEffect(() => {
    if (UserChanged) {
      if (Validacion === 2) {
        setShowModal(true)
      } else if (Validacion === 1) {
        setFraccion(props.Detail.destinationHTSCode)
        saveFraccion(props.Detail.destinationHTSCode)
      }
    }
  }, [Validacion, UserChanged])

  useEffect(() => {
    let selected = mCatTipoConsultas.filter((a)=>{if(a.id==TipoConsulta){return a}})
    if (selected.length) {
      setTipoConsultaTxt(selected[0].shortDescription+' '+selected[0].spanishDescription)
    }
  }, [TipoConsulta])

  const saveFraccion = (Fracc: string) => {
    Fracc = Fracc.replaceAll('.', '').replaceAll(' ', '')
    if (Validacion === 2) {
      if (Comentarios.length === 0) {
        setHeader('Error')
        setMsg(
          'Antes de poder guardar la informacion es necesario que proporcione los comentarios'
        )
        setShowMsg(true)
        return
      }
      if (DescripcionGEMCO.length === 0) {
        setHeader('Error')
        setMsg(
          'Antes de poder guardar la informacion es necesario que proporcione la descripcion GEMCO'
        )
        setShowMsg(true)
        return
      }
    }
    if (!Fracc) {
      setFraccion(Fracc)
      alert('La fraccion no puede estar vacia')
      return false
    }
    DSAmazon.ValidaFraccionGET(Fracc)
      .then((response) => {
        if (response.data.respuesta === 'Si') {
          const data: DTO2096FraccionDescripcion = {
            id: props.IDDetail,
            fraccion: Fracc,
            tipoConsulta: TipoConsulta,
            cumplimientoNormativo: CumplimientoNormativo,
            comentarios: Comentarios,
            descripcionGEMCO: DescripcionGEMCO
          }
          DSAmazon.SETFraccionDescripcion(data)
            .then((response) => {
              UpdateInfo(response.data)
              setShowModal(false)
              return
            })
            .catch((e: Error) => {
              alert('Ocurrio un error' + e.message.toString())
            })
        } else {
          alert(
            'Esa fraccion no existe, favor de verificarla antes de poder guardar...'
          )
          setValidacion(0)
          return
        }
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  return (
    <div>
      <Form.Control
        as="select"
        className="form-select form-select-sm"
        disabled={
          ((props.Deshabilitado || props.Detail.validaFraccionOriginal === 3) &&
            Perfil !== 'Clasificador Lider') ||
          (props.Detail.validaFraccionOriginal === 4 && !props.Prioridad)
        }
        onChange={(e) => changeValidacion(parseInt(e.target.value))}
        value={Validacion}
      >
        {props.Detail.validaFraccionOriginal == 0 ? (
          <>
            <option value="0"> </option>
            <option value="1"> = </option>
            <option value="2"> != </option>
            {/*   <option value="5">BOT</option> */}
          </>
        ) : (
          ''
        )}
        {props.Detail.validaFraccionOriginal == 1 ? (
          <>
            <option value="0"> </option>
            <option value="1"> = </option>
            <option value="2"> != </option>
          </>
        ) : (
          ''
        )}
        {props.Detail.validaFraccionOriginal === 3 ? (
          <option value="3"> Ok </option>
        ) : (
          ''
        )}
        {props.Detail.validaFraccionOriginal === 4 && props.Prioridad ? (
          <>
            <option value="0"> </option>
            <option value="1"> = </option>
            <option value="2"> != </option>
          </>
        ) : (
          ''
        )}
      </Form.Control>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <Row>
            <Col xs={3}>
              <Badge bg="primary">
                <h6>Fraccion GEMCO</h6>
              </Badge>
            </Col>
            <Col xs={3}>
              <CurrencyFormat
                onValueChange={(values: any) => {
                  const { value } = values
                  setFraccion(value)
                }}
                format={'####.##.####'}
                value={Fraccion}
                displayType={'input'}
                style={{
                  fontSize: '18px',
                  backgroundColor: '#F1EEF9',
                  border: '2px solid #5923F6',
                  color: '#5923F6',
                  width: '120px',
                  textAlign: 'right',
                  borderRadius: '10px'
                }}
              />
            </Col>
            <Col xs={6}></Col>
          </Row>
          <Row style={{ paddingTop: '15px' }}>
            <Col xs={1}>
            <Badge bg="primary">
                <h6>Tipo</h6>
              </Badge>
            </Col>
            <Col xs={2}>
              <Form.Control
                as="select"
                className="form-select form-select-sm"
                onChange={(e) => setTipoConsulta(parseInt(e.target.value))}
                value={TipoConsulta}
              >  <option value='0'> ??? </option>
                {mCatTipoConsultas.map((tipo) => {
                  return <option value={tipo.id}>{tipo.siglas}</option>
                })}
              </Form.Control>
            </Col>
            <Col xs={9}>
            <Form.Control
                as="textarea"
                id="TipoConsultaTxt"
                size="sm"
                disabled={true}
                value={TipoConsultaTxt}
                style={{height:'100px', fontSize:'13px'}}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: '15px' }}>
            <Col xs={3}>
              <Badge bg="primary">
                <h6>
                  Cumplimiento&nbsp;&nbsp;&nbsp;&nbsp;
                  <br />
                  normativo
                </h6>
              </Badge>
            </Col>
            <Col xs={9}>
              <Form.Control
                as="textarea"
                id="CumplimientoNormativo"
                size="sm"
                value={CumplimientoNormativo}
                onChange={(e) => setCumplimientoNormativo(e.target.value)}
              />
            </Col>
            <Col>&nbsp;</Col>
          </Row>
          <Row style={{ paddingTop: '15px' }}>
            <Col xs={3}>
              <Badge bg="primary">
                <h6>Comentarios&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
              </Badge>
            </Col>
            <Col xs={9}>
              {' '}
              <Form.Control
                as="textarea"
                id="Comentarios"
                size="sm"
                value={
                  Comentarios.length ? Comentarios : ''
                }
                onChange={(e) => setComentarios(e.target.value)}
              />
            </Col>
            <Col>&nbsp;</Col>
          </Row>
          <Row style={{ paddingTop: '15px' }}>
            <Col xs={3}>
              <Badge bg="primary">
                <h6>Descripcion GEMCO</h6>
              </Badge>
            </Col>
            <Col xs={9}>
              <Form.Control
                as="textarea"
                id="PedimentoDescripcion"
                size="sm"
                value={DescripcionGEMCO}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: '10px' }}>
            <Col XS={9}></Col>
            <Col xs={3}>
              <Button
              style={{visibility: (Comentarios.length && TipoConsulta>0) ? 'visible' : 'hidden'}}
                onClick={() => {
                  saveFraccion(Fraccion)
                }}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import DTOPedimentosCruzadosListaCliente from '../../../DTO/Dashboard/DTOPedimentosCruzadosListaCliente'
import DTOPedimentosCruzadosData from '../../../DTO/Dashboard/DTOPedimentosCruzadosData'
import DashboardDS from '../../../Services/Dashboard/Dashboard.Direccion.Services'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

export interface IRptPedimentosCruces03Props {}

export default function RptPedimentosCruces03(
  props: IRptPedimentosCruces03Props
) {
  const [Clientes, setClientes] = useState<DTOPedimentosCruzadosListaCliente[]>(
    []
  )
  const [Data, setData] = useState<DTOPedimentosCruzadosData[]>([])
  const [Cliente, setCliente] = useState('')
  const [IDCliente, setIDCliente] = useState(0)
  const [Pedimentos, setPedimentos] = useState<number[]>([])
  const [Cruces, setCruces] = useState<number[]>([])
  const [series2, setSeries2] = useState([
    {
      name: 'series1',
      data: [31, 40, 28, 51, 42, 109, 100]
    },
    {
      name: 'series2',
      data: [11, 32, 45, 32, 34, 52, 41]
    }
  ])
  const [options2, setOptions2] = useState<ApexOptions>({
    chart: {
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: ['Ene', 'Feb', 'Mar', 'Abr', 'Marzo', 'Abr', 'May']
    }
  })

  useEffect(() => {
    DashboardDS.GETPedimentosCruzadosListaCliente()
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        return
      })
  }, [])

  useEffect(() => {
    DashboardDS.GETPedimentosCruzadosData(IDCliente)
      .then((response) => {
        setData(response.data)
        let Pedimentos: number[] = []
        let Cruces: number[] = []
        let Categorias: string[] = []
        response.data.forEach(function (item) {
          Pedimentos.push(item.pedimentos)
          Cruces.push(item.despachos)
          Categorias.push(item.sMes)
        })
        setSeries2([
            {
              name: 'Pedimentos',
              data: Pedimentos
            },
            {
              name: 'Cruces',
              data: Cruces
            }
          ])
          setOptions2({
            xaxis: {
              categories: Categorias
            },
            chart: {
              height: 100,
              type: 'line',
              zoom: {
                enabled: false
              }
            }
          })
        var arrClientes = Clientes.filter((item) => {
          return item.id == IDCliente
        })
        setCliente(arrClientes[0].razonSocial)
      })
      .catch((e: Error) => {
        return
      })
  }, [IDCliente])

  return (
    <div>
      <Card>
        <Card.Body>
          <Form.Control
            as="select"
            onChange={(e) => {
              setIDCliente(parseInt(e.target.value))
            }}
            className="form-select form-select-sm"
          >
            <option value="0">Seleccion el cliente</option>
            {Clientes
              ? Clientes.map((c) => {
                  return <option value={c.id}>{c.razonSocial}</option>
                })
              : null}
          </Form.Control>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Card.Title>
            {Cliente}
            <br />
            <br />
          </Card.Title>
          <ReactApexChart
            options={options2}
            series={series2}
            width="100%"
            height="600px"
          />
        </Card.Body>
      </Card>
    </div>
  )
}

import * as React from 'react'
import { useEffect, useState } from 'react'
import { RootState } from '../../../../../store/store'
import I2096Headers from '../../Interfaces/I2096Header'
import DSAmazon from '../../Services/Amazon.Services'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import {
  initializeInvoice,
  populateInvoices,
  updateInvoice
} from '../../../../../store/features/Clientes/2096/AmazonInvoices'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import { MsgInformativo } from '../../../../Utils/Toast/msgInformativo'
import { AiFillFileExcel } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import { BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
import { BsChevronDown, BsChevronRight, BsFilePdfFill } from 'react-icons/bs'
import { FaAmazon } from 'react-icons/fa'
import loadingImg from '../../../../../images/ajaxloader.gif'
import { MFileManager } from '../../../../Utils/MFileManager/MFileManager'
import DTO2096RptPayedOperations from '../../DTO/DTO2096RptPayedOperations'
import { GoGear } from 'react-icons/go'
import IFileManager from '../../../../../Interfaces/Utils/IFileManager'
import DTO2096RptFacturasPagadas from '../../DTO/DTO2096RptFacturasPagadas'
import DataTable from 'react-data-table-component'
import * as XLSX from 'xlsx'

export interface IRptAmazonFacturasPagadasProps {}

export default function RptAmazonFacturasPagadas(
  props: IRptAmazonFacturasPagadasProps
) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [header, setHeader] = useState('')
  const [Referencia, setReferencia] = useState('')
  const [Inicio, setInicio] = useState(currentDate())
  const [Fin, setFin] = useState(currentDate())
  const [Data, setData] = useState<DTO2096RptFacturasPagadas[]>([])
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [WaitingDialog, setWaitingDialog] = useState(false)
  const [WaitingMessage, setWaitingMessage] = useState('')
  const [msg, setMsg] = useState('')
  const [ShowModalAnswerFile, setShowModalAnswerFile] = useState(false)
  const [IDProcess, setIDProcess] = useState(25)
  const [IDFactura, setIDFactura] = useState(0)
  const [Invoices, setInvoices] = useState<number[]>([])
  const msgColor = 'primary'
  const columnsConcepts = [
    {
      name: <div style={{ textAlign: 'center' }}>Factura</div>,

      width: '300px',
      selector: (row: DTO2096RptFacturasPagadas) => row.factura,
      sortable: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Pedimento</div>,

      width: '120px',
      selector: (row: DTO2096RptFacturasPagadas) => row.pedimento,
      sortable: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Fecha Pago</div>,

      width: '120px',
      selector: (row: DTO2096RptFacturasPagadas) =>
        row.fechaPago.substring(0, 10),
      sortable: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Clave</div>,

      width: '80px',
      selector: (row: DTO2096RptFacturasPagadas) => row.clave,
      sortable: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Tipo Operacion</div>,

      width: '120px',
      selector: (row: DTO2096RptFacturasPagadas) => row.tipoOp,
      sortable: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Valor Dolares</div>,

      width: '130px',
      selector: (row: DTO2096RptFacturasPagadas) => row.valorDolares,
      sortable: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Valor Aduana </div>,

      width: '130px',
      selector: (row: DTO2096RptFacturasPagadas) => row.valorAduana,
      sortable: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Razon Social</div>,

      width: '350px',
      selector: (row: DTO2096RptFacturasPagadas) => row.razonSocial,
      sortable: true
    },
    {
      name: <div style={{ textAlign: 'center' }}>Aduana</div>,

      width: '100px',
      selector: (row: DTO2096RptFacturasPagadas) => row.aduana,
      sortable: false
    },
    {
      name: <div style={{ textAlign: 'center' }}>Piezas</div>,

      width: '100px',
      selector: (row: DTO2096RptFacturasPagadas) => row.piezas,
      sortable: false
    }
  ]

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const loadReport = () => {
    DSAmazon.AmazonFacturasPagadasGET(Inicio, Fin)
      .then((response) => {
        setData(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de facturas pagadas de Amazon')
  }

  function exportExcel(
    jsonData: DTO2096RptFacturasPagadas[],
    fileName: string
  ): void {
    let Heading = [
      [
        'Factura',
        'Pedimento',
        'Fecha Pago',
        'Clave',
        'Tipo Op',
        'Valor Dolares',
        'Valor Aduana',
        'Razon Social',
        'Aduana',
        'Piezas'
      ]
    ]
    const dataOnly = jsonData.map(
      ({
        factura,
        pedimento,
        fechaPago,
        clave,
        tipoOp,
        valorDolares,
        valorAduana,
        razonSocial,
        aduana,
        piezas
      }) => {
        return {
          factura,
          pedimento,
          fechaPago,
          clave,
          tipoOp,
          valorDolares,
          valorAduana,
          razonSocial,
          aduana,
          piezas
        }
      }
    )
    const Report = dataOnly.map(
      ({
        factura,
        pedimento,
        fechaPago,
        clave,
        tipoOp,
        valorDolares,
        valorAduana,
        razonSocial,
        aduana,
        piezas
      }) => {
        return {
          factura,
          pedimento,
          fechaPago,
          clave,
          tipoOp,
          valorDolares,
          valorAduana,
          razonSocial,
          aduana,
          piezas
        }
      }
    )
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, Report, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c + 1; C <= range.e.c + 1; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  useEffect(() => {
    loadReport()
  }, [])

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={5}>
              <Card.Title>
                <IconContext.Provider value={{ color: '#F6C34F', size: '8%' }}>
                  <FaAmazon />
                </IconContext.Provider>
                Facturas Pagadas
              </Card.Title>
            </Col>
            <Col>
              <Col style={{ textAlign: 'center' }}>
                <Button
                  variant="success"
                  onClick={() => {
                    downloadExcel()
                  }}
                >
                  <BsFileEarmarkExcel />
                  Excel
                </Button>
              </Col>
            </Col>

            <Col style={{ textAlign: 'right' }}>Desde</Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Inicio}
                type="date"
                name="Inicio"
                placeholder="Inicio"
                title="Inicio"
                alt="Inicio"
                data-date-format="YYYY-mm-dd"
                onChange={(e) => setInicio(e.target.value)}
                size="sm"
              />
            </Col>
            <Col style={{ textAlign: 'right' }}>Hasta</Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Fin}
                type="date"
                name="Fin"
                placeholder="Fin"
                title="Fin"
                alt="Fin"
                onChange={(e) => setFin(e.target.value)}
                size="sm"
              />
            </Col>
            <Col
              style={{ textAlign: 'right' }}
              onClick={() => {
                loadReport()
              }}
            >
              {/* <div className="col-md-3 d-flex aling-items-center"> */}

              <Button variant="primary">
                Buscar <BsSearch />
              </Button>
              {/* </div> */}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div
            className="ag-theme-alpine"
            style={{ height: 500, width: '100%' }}
          >
            <DataTable
              noHeader
              defaultSortFieldId={'id'}
              defaultSortAsc={true}
              striped={true}
              dense={true}
              paginationPerPage={10}
              pagination
              highlightOnHover
              columns={columnsConcepts}
              data={Data}
              pointerOnHover
            />
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={WaitingDialog}
        onHide={() => {
          setWaitingDialog(false)
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        dialogClassName={'modal-50w'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={loadingImg} style={{ height: '150px' }} alt="proccessing" />
          Generando {WaitingMessage}...
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={ShowModalAnswerFile}
        onHide={() => {
          setShowModalAnswerFile(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '330px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col></Col>
              <Col xs={10} style={{ textAlign: 'center' }}>
                <MFileManager
                  IDTrafico={IDFactura}
                  Proceso={IDProcess}
                  showPreview={3}
                  canEdit={false}
                  Leyenda="Lista de archivos de respuesta de Amazon"
                  onAppendMFM={function (Files: IFileManager[]): void {}}
                />
              </Col>
              <Col></Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Form, Table } from 'react-bootstrap'
import DTOReporte from '../../DTO/DTOReporte'
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import search from '../../images/search01.png'
import moment from 'moment'
import { AgGridReact } from 'ag-grid-react'
import * as XLSX from 'xlsx'
import { VerDocumentos } from './customCells/verDocumentos'
import reportsDataService from '../../Services/Reportes/reportes.services'
import IRptBattery from '../../Interfaces/Reportes/IRptBattery'

const FileSaver = require('file-saver')

interface Blob {
  arrayBuffer(): Promise<ArrayBuffer>
}

export default function RptBattery() {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Inicio, setInicio] = useState(currentDate())
  const [Fin, setFin] = useState(currentDate())
  const [Data, setData] = useState<Array<IRptBattery>>([])
  const [filteredData, setFilteredData] = useState<Array<IRptBattery>>([])
  const [TipoOperacion, setTipoOperacion] = useState(1)
  const [Cliente, setCliente] = useState(0)
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [filtro, setFiltro] = useState('')
  const gridRef = React.useRef<any>(null)
  const [columnDefs] = useState([
    { field: 'trailer', sortable: true, filter: true },
    { field: 'idPallet', sortable: true, filter: true },
    { field: 'weight', sortable: true, filter: true },
  ])

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return mm + '/' + dd + '/' + yyyy
  }

  const closeToast = (show: boolean): void => {
    setShowMsg(false)
  }

  const generaReporte = () => {
    /*  if (Cliente === 0) {
      setHeader('Error')
      setMsg('Seleccion el cliente')
      setShowMsg(true)
      return
    } */
    const data: DTOReporte = {
      Inicio: moment(Inicio).format('YYYY-MM-DD'),
      Fin: moment(Fin).format('YYYY-MM-DD'),
      TipoOperacion: TipoOperacion,
      NoCliente: 0,
      IdUsuario: UserId,
    }
    reportsDataService
      .getRptBattery(data)
      .then((response) => {
        setData(response.data)
        setFilteredData(response.data)
        setHeader('Informativo')
        setMsg('Se encontro la siguiente informacion...')
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const onButtonClick = () => {
    gridRef.current.api.setQuickFilter('8U7')
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Warehouse battery report')
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    let Heading = [
      ['Trailer', 'ID Pallet', 'Weight', 'Entry Current Time', 'Departure Current Time', 'Departure Trailer'],
    ]
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  function saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    })
    FileSaver.saveAs(data, fileName + '.xlsx')
  }

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    gridRef.current.api.setQuickFilter(e.target.value)
  }

  const dataURItoBlob = (dataURI: any) => {
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    const bb = new Blob([ab], { type: mimeString })
    return bb
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-2'>
              <Form.Control
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
              />
            </div>
            <div className='col-md-2'>
              <Form.Control
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
              />
            </div>
            <div className='col-md-1 right-label'>
              <Form.Label>Status</Form.Label>
            </div>
            <div className='col-md-1'>
              <Form.Control as='select' onChange={(e) => setTipoOperacion(parseInt(e.target.value))}>
                <option value='1'>Entry</option>
                <option value='2'>Departure</option>
                <option value='0'>In warehouse</option>
              </Form.Control>
            </div>
            <div className='col-md-1 right-label'></div>
            <div className='col-md-4'></div>
            <div className='col-md-1 right-label'>
              <img
                src={search}
                alt='Buscar'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  generaReporte()
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>&nbsp;</div>
            <div className='col-4'>
              <Form.Control
                type='text'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className='col'>
              <Button
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                Excel
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        {/* <button onClick={onButtonClick}>Get selected rows</button> */}
        <AgGridReact
          rowData={filteredData}
          columnDefs={columnDefs}
          pagination={true}
          paginationAutoPageSize={true}
          ref={gridRef}
          rowSelection='single'
        ></AgGridReact>
      </div>
      <MsgInformativo show={show} msg={msg} header={header} msgColor={msgColor} closeToast={closeToast} />
    </div>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import DTO2096TraficoConsultaAmazonHeader from '../../../../Components/Clientes/Amazon/DTO/DTO2096TraficoConsultaAmazonHeader'
const Consulta: DTO2096TraficoConsultaAmazonHeader[] = []
const initialState = { Consulta }

export const AmazonTraficoConsultaSlice = createSlice({
  name: 'AmazonTraficoConsulta',
  initialState: initialState,
  reducers: {
    initializeTraficoConsulta: (state, action: PayloadAction<DTO2096TraficoConsultaAmazonHeader[]>) => {
      state.Consulta = action.payload
    },
    populateTraficoConsulta: (state, action: PayloadAction<DTO2096TraficoConsultaAmazonHeader[]>) => {
      action.payload.forEach((newItem) => {
        var Existe = state.Consulta.find(function (onMemoryItem) {
          return onMemoryItem.id === newItem.id
        })
        if (!Existe) state.Consulta.push(newItem)
      })
    },
    addTraficoConsulta: (state, action: PayloadAction<DTO2096TraficoConsultaAmazonHeader>) => {
      var Existe = state.Consulta.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.Consulta.push(action.payload)
    },
    updateTraficoConsulta: (state, action: PayloadAction<DTO2096TraficoConsultaAmazonHeader>) => {
      const i = state.Consulta.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.Consulta[i] = action.payload
      else state.Consulta.push(action.payload)
    },
    updateTraficoConsultaDetail: (state, action: PayloadAction<DTO2096TraficoConsultaAmazonHeader>) => {
      const i = state.Consulta.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.Consulta[i] = action.payload
      else state.Consulta.push(action.payload)
    },
    deleteTraficoConsulta: (state, action: PayloadAction<number>) => {
      const newArr = state.Consulta.filter((data) => data.id != action.payload)
      state.Consulta = newArr
    }
  }
})

export const { initializeTraficoConsulta, addTraficoConsulta, populateTraficoConsulta, updateTraficoConsulta, deleteTraficoConsulta } =
  AmazonTraficoConsultaSlice.actions
export default AmazonTraficoConsultaSlice.reducer

import React, { FC, useEffect, useState } from 'react'
import DashboardCorresponsales from './DashboardCorresponsales'
import { DashboardContabilidad } from './DashboardContabilidad'
import { Col, Container, Row } from 'react-bootstrap'
import { Heineken1 } from './Heineken1'
import { DashboardFacturacion } from './DashboardFacturacion'
import { DashboardClasificacion } from './DashboardClasificacion'
import DashboardTrafico from './DashboardTrafico'
import DashboardDireccion from './DashboardDireccion'

interface IProps {}

export default function Dashboard(props: IProps) {
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [User, setUser] = useState(() => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })

  return (
    <div>
      <br />
      <Container>
        <Row xs={1} md={3} className="g-4">
          {[
            ['Direcccion', 'Sistemas', 'Operaciones', 'Clasificacion'].includes(
              Depto
            ) ? (
              <DashboardClasificacion />
            ) : (
              ''
            )
          ].map((item) => {
            return <React.Fragment>{item}</React.Fragment>
          })}
          {[
            ['Direcccion', 'Sistemas', 'Operaciones', 'Trafico'].includes(
              Depto
            ) ? (
              <DashboardTrafico />
            ) : (
              ''
            )
          ].map((item) => {
            return <React.Fragment>{item}</React.Fragment>
          })}
          {/* {[['Direccion', 'Sistemas'].includes(Depto) ? <DashboardDireccion /> : ''].map((item) => {
            return <React.Fragment>{item}</React.Fragment>
          })} */}
        </Row>
      </Container>
    </div>
  )
}

import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsChevronDown, BsChevronRight, BsFilePdfFill } from 'react-icons/bs'
import { FaAmazon } from 'react-icons/fa'
import DSAmazon from '../../Services/Amazon.Services'
import { MsgInformativo } from '../../../../Utils/Toast/msgInformativo'
import { RootState } from '../../../../../store/store'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { AiFillFileExcel } from 'react-icons/ai'
import RptConsultaTraficoDetalle from './RptConsultaTraficoDetalle'
import loadingImg from '../../../../../images/ajaxloader.gif'
import * as XLSX from 'xlsx'
import DTO2096TraficoConsultaAmazonHeader from '../../DTO/DTO2096TraficoConsultaAmazonHeader'
import {
  initializeTraficoConsulta,
  populateTraficoConsulta,
  updateTraficoConsulta
} from '../../../../../store/features/Clientes/2096/AmazonTraficoConsulta'
import {SiMicrosoftexcel} from 'react-icons/si'
import I2096CatGrupoRegulatorio from '../../Interfaces/I2096CatGrupoRegulatorio'

export interface IRptConsultaTraficoProps {}

export default function RptConsultaTrafico(props: IRptConsultaTraficoProps) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonTraficoConsulta.Consulta
  )
  const [header, setHeader] = useState('')
  const [Referencia, setReferencia] = useState('')
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [ShowModalUnlink, setShowModalUnlink] = useState(false)
  const [WaitingDialog, setWaitingDialog] = useState(false)
  const [WaitingMessage, setWaitingMessage] = useState('')
  const [msg, setMsg] = useState('')
  const [Invoices, setInvoices] = useState<number[]>([])
  const [Data, setData] = useState<DTO2096TraficoConsultaAmazonHeader[]>([])
  const [CatGrupoRegulario, setCatGrupoRegulatorio] = useState<I2096CatGrupoRegulatorio[]>([])
  const msgColor = 'primary'

  const loadReport = () => {
    DSAmazon.TransporteConsultaAmazon()
      .then((response) => {
        dispatch(initializeTraficoConsulta([]))
        dispatch(populateTraficoConsulta(response.data))
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const loadCatalogos = () => {
    DSAmazon.CatGrupoRegulatorioGET()
      .then((response) => {
        setCatGrupoRegulatorio(response.data)
        console.log(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const downloadExcel = (id: number, Invoice: string) => {
    DSAmazon.TraficoConsultasAmazonGenerateExcel(id)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Broker File '+Invoice+'.xls')
          document.body.appendChild(link)
          link.click()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    loadCatalogos()
    loadReport()
  }, [])

  const changeToggle = (row: DTO2096TraficoConsultaAmazonHeader) => {
    let Invoice: DTO2096TraficoConsultaAmazonHeader[] = mInvoices.filter(
      (item) => item.id === row.id
    )
    const Obj = { ...Invoice[0] }
    Obj.max = !Obj.max
    dispatch(updateTraficoConsulta(Obj))
  }

  const check4Updates = (IDInvoice: number) => {
    DSAmazon.TraficoConsultasAmazonById(IDInvoice)
      .then((response) => {
        DSAmazon.TraficoConsultasAmazonById(IDInvoice)
          .then((response) => {
            dispatch(updateTraficoConsulta(response.data))
            console.log(response.data)
            /* let Invoice: DTO2096TraficoConsultaAmazonHeader[] = response.data.filter(
              (item) => item.id === IDInvoice
            )
            const Obj = { ...Invoice[0] }
            Obj.max = !Obj.max
            dispatch(updateTraficoConsulta(Obj)) */
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error')
            return
          })
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        return
      })
  }

  const updateDataGrid = (IDInvoice: number) => {
    /* let Invoice: DTO2096TraficoConsultaAmazonHeader[] = mInvoices.filter(
      (item) => item.id === IDInvoice
    )
    const Obj = { ...Invoice[0] }
    Obj.max = !Obj.max
    dispatch(updateTraficoConsulta(Obj))
    if (Obj.max) */ check4Updates(IDInvoice)
  }

  function getReceiver(Cadena: string, char1: string[1], char2: string[1]) {
    return Cadena.slice(Cadena.indexOf(char1) + 1, Cadena.lastIndexOf(char2))
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={6}>
              <Card.Title>
                <IconContext.Provider value={{ color: '#F6C34F', size: '8%' }}>
                  <FaAmazon />
                </IconContext.Provider>{' '}
                TRAFICO : Consulta Amazon
              </Card.Title>
            </Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="MDcontainer">
            <Table className="MDTable" hover>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr>
                  <th></th>
                  <th style={{ width: '50px' }}>id</th>
                  <th style={{ width: '50px' }}></th>
                  <th style={{ width: '100px' }}>Referencia</th>
                  <th style={{ width: '80px' }}>Commercial Invoice Date</th>
                  <th style={{ width: '250px' }}>Invoice Number</th>
                  <th style={{ width: '100px' }}>Receiver</th>
                  <th style={{ width: '100px' }}>Incoterms</th>
                  <th style={{ textAlign: 'center' }}>HAWB</th>
                  <th>Qty Unit of measure</th>
                  <th>Tot Qty</th>
                  <th>Weight unit of measure</th>
                  <th>Tot weight</th>
                  <th>Tot Charge or allowance</th>
                  <th>Tot currency ISO code</th>
                  <th>Tot Monetary amount</th>
                  <th>Total de partidas</th>
                </tr>
              </thead>
              <tbody>
                {mInvoices
                  ? mInvoices.map((MasterData) => {
                      return (
                        <>
                          <tr
                            style={{ cursor: 'pointer', height: '25px' }}
                            className={
                              MasterData.max === true
                                ? 'masterSelected'
                                : 'normalSelected'
                            }
                          >
                            <td
                              style={{ textAlign: 'left' }}
                              className={
                                MasterData.max === true
                                  ? 'masterSelected'
                                  : 'normalSelected'
                              }
                              key={MasterData.id}
                              onClick={() => {
                                changeToggle(MasterData)
                              }}
                            >
                              {MasterData.max === true ? (
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '15px' }}
                                >
                                  <BsChevronDown />
                                </IconContext.Provider>
                              ) : (
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '15px' }}
                                >
                                  <BsChevronRight />
                                </IconContext.Provider>
                              )}
                            </td>
                            <td>{MasterData.id}</td>
                            <td  onClick={()=>{downloadExcel(MasterData.id, MasterData.invoiceNumber)}}>
                              <IconContext.Provider
                                value={{ color: 'green', size: '20px' }}
                              >
                                <SiMicrosoftexcel />
                              </IconContext.Provider>
                            </td>
                            <td style={{ width: '100px', textAlign: 'center' }}>
                              {MasterData.referencia}
                            </td>
                            <td style={{ width: '80px', textAlign: 'center' }}>
                              {MasterData.commercialInvoiceDate}
                            </td>
                            <td style={{ width: '300px' }}>
                              {MasterData.invoiceNumber}
                            </td>
                            <td
                              style={{
                                width: '100px',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: '13px'
                              }}
                            >
                              {mInvoices
                                .filter((value) => value.id === MasterData.id)
                                .map((row) => {
                                  return getReceiver(
                                    row.detail[0].amazonShipmentReferenceId,
                                    '_',
                                    '_'
                                  )
                                })}
                            </td>
                            <td style={{ width: '100px', textAlign: 'center' }}>
                              {MasterData.incoterms}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.hawb}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantityUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantity}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeightUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeight}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueChargeOrAllowance}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueCurrencyISOCode}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueMonetaryAmount}
                            </td>
                            <td
                              style={{
                                fontWeight: 'bold',
                                textAlign: 'center'
                              }}
                            >
                              {mInvoices
                                .filter((value) => value.id === MasterData.id)
                                .map((row) => {
                                  return row.detail.length
                                })}
                            </td>
                          </tr>
                          {MasterData.max ? (
                            <tr
                              className={
                                MasterData.max === true
                                  ? 'masterSelected2'
                                  : 'normalSelected2'
                              }
                            >
                              <th colSpan={18}>
                                <table
                                  className="childTable"
                                  style={{ width: '1650px' }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          width: '50px',
                                          backgroundColor: '#FFFFFF',
                                          color: '#346288',
                                          textAlign: 'right'
                                        }}
                                      ></th>
                                      <th
                                        style={{
                                          width: '100px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Item Id
                                      </th>
                                      <th
                                        style={{
                                          width: '400px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Item Description
                                      </th>
                                      <th
                                        style={{
                                          width: '120px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Fraccion GEMCO
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Descripcion GEMCO
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Country of Origin
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        ProductGroup
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Brand
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Model
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Measure
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Qty Shipped
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit of measure
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Net Weight
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Cost
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Total value
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Consulta
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {mInvoices.map((MD) => {
                                      return (
                                        <>
                                          {MD.detail
                                            .filter(
                                              (detail) =>
                                                MasterData.id ===
                                                detail.idHeader
                                            ) //.sort((a,b) => a.itemId.localeCompare(b.itemId))
                                            .sort((a, b) =>
                                              a.partida > b.partida ? 1 : -1
                                            )
                                            .map((detail) => {
                                              return (
                                                <RptConsultaTraficoDetalle
                                                  IDMaster={MasterData.id}
                                                  detail={detail}
                                                  Habilitado={
                                                    /*  MasterData.estatus <= 1 */
                                                    1 == 1
                                                  }
                                                  Catalogo = {CatGrupoRegulario ? CatGrupoRegulario : []}
                                                  onDataChange={function (
                                                    IDMaster: number
                                                  ): void {
                                                    updateDataGrid(IDMaster)
                                                  }}
                                                />
                                              )
                                            })}
                                        </>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          ) : null}
                        </>
                      )
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>

      <Modal
        show={WaitingDialog}
        onHide={() => {
          setWaitingDialog(false)
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        dialogClassName={'modal-50w'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={loadingImg} style={{ height: '150px' }} alt="proccessing" />
          Generando {WaitingMessage}...
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import React, { FC, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import { BsEraserFill, BsFillPencilFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { updateCatResponsables } from '../../../../store/features/Clientes/1868/Cat1868ResponsablesSlice'
import { RootState } from '../../../../store/store'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import I1868CatResponsables from '../Interfaces/I1868CatResponsables'
import FService from '../Services/Facturas.Services'

interface IProps {}

export const Cat1868Responsables: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const mCatResponsables = useSelector((state: RootState) => state.Cat1868Responsables.Cat1868Responsables)
  const [ID, setID] = useState(0)
  const [Responsable, setResponsable] = useState('')
  const [Buscar, setBuscar] = useState('')
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')

  const colData = [
    {
      name: 'id',
      width: '10%',
      selector: (row: I1868CatResponsables) => row.id,
      sortable: true,
    },
    {
      name: 'responsable',
      width: '80%',
      selector: (row: I1868CatResponsables) => row.responsable,
    },
    {
      name: 'Edita',
      cell: (row: I1868CatResponsables) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setID(row.id)
            setResponsable(row.responsable)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const deleteItem = (id: number) => {
    alert('Delete item ' + id)
  }

  const save = () => {
    const data: I1868CatResponsables = {
      id: ID,
      responsable: Responsable ? Responsable : '',
    }
    FService.appendCatResponsables(data)
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        dispatch(updateCatResponsables(response.data))
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const cleanForm = () => {
    setID(0)
    setResponsable('')
  }

  return (
    <div>
      <Row>
        <Col xs={2}>
          <Button
            variant='warning'
            size='sm'
            onClick={() => {
              cleanForm()
            }}
          >
            <BsEraserFill />
            &nbsp;Limpiar
          </Button>
        </Col>
        <Col xs={2}>Responsable </Col>
        <Col xs={6}>
          <Form.Control
            type='text'
            id='Responsable'
            value={Responsable}
            size='sm'
            onChange={(e) => {
              setResponsable(e.target.value)
            }}
          />
        </Col>
        {/* <Col xs={0}></Col> */}
        <Col xs={1} style={{ textAlign: 'right' }}>
          <Button variant='primary' onClick={() => save()} size='sm'>
            Guardar
          </Button>
        </Col>
      </Row>
      <Row style={{ paddingTop: '5px' }}>
        <Col xs={12}>
          <Form.Control
            type='text'
            id='Buscar'
            value={Buscar}
            placeholder='Search...'
            size='sm'
            onChange={(e) => {
              setBuscar(e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            pagination
            highlightOnHover
            columns={colData}
            data={mCatResponsables.filter(function (row) {
              return row.responsable.toLowerCase().includes(Buscar.toLowerCase())
            })}
          />
        </Col>
      </Row>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

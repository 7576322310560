import * as React from 'react'
import { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import DTOPedimentosCruzadosListaCliente from '../../../DTO/Dashboard/DTOPedimentosCruzadosListaCliente'
import DTOPedimentosCruzadosData from '../../../DTO/Dashboard/DTOPedimentosCruzadosData'
import DashboardDS from '../../../Services/Dashboard/Dashboard.Direccion.Services'
import { Card, Form } from 'react-bootstrap'

export interface IRptPedimentosCruces06Props {}

interface IRechartsData {
  name: string
  uv: number
  pv: number
  amt: number
}

export default function RptPedimentosCruces06(
  props: IRptPedimentosCruces06Props
) {
  const [Clientes, setClientes] = useState<DTOPedimentosCruzadosListaCliente[]>(
    []
  )
  const [Data, setData] = useState<DTOPedimentosCruzadosData[]>([])
  const [Cliente, setCliente] = useState('')
  const [IDCliente, setIDCliente] = useState(0)
  const [Pedimentos, setPedimentos] = useState<number[]>([])
  const [Cruces, setCruces] = useState<number[]>([])
  const [data5, setData5] = useState([
    ['Mes', 'Pedimentos', 'Cruces'],
    ['2004/05', 165, 938],
    ['2005/06', 135, 1120],
    ['2006/07', 157, 1167],
    ['2007/08', 139, 1110],
    ['2008/09', 136, 691]
  ])
  const options5 = {
    title: '',
    vAxis: { title: '' },
    hAxis: { title: 'Meses' },
    seriesType: 'bars',
    series: { 1: { type: 'line' } },
    colors: ['#1850D0', '70D63E']
  }

  useEffect(() => {
    DashboardDS.GETPedimentosCruzadosListaCliente()
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        return
      })
  }, [])

  useEffect(() => {
    DashboardDS.GETPedimentosCruzadosData(IDCliente)
      .then((response) => {
        setData(response.data)
        let Pedimentos: number[] = []
        let Cruces: number[] = []
        let Categorias: string[] = []
        let RechartsData: IRechartsData[] = []
        let RGoogleChartData: Array<Array<string | number>> = []
        RGoogleChartData.push(['Mes', 'Pedimentos', 'Cruces'])
        response.data.forEach(function (item) {
          Pedimentos.push(item.pedimentos)
          Cruces.push(item.despachos)
          Categorias.push(item.sMes)
          RechartsData.push({
            name: item.sMes,
            pv: item.pedimentos,
            uv: item.despachos,
            amt: 1
          })
          RGoogleChartData.push([item.sMes, item.pedimentos, item.despachos])
        })
        setData5(RGoogleChartData)
        var arrClientes = Clientes.filter((item) => {
          return item.id == IDCliente
        })
        setCliente(arrClientes[0].razonSocial)
      })
      .catch((e: Error) => {
        return
      })
  }, [IDCliente])
  return (
    <div>
      <Card>
        <Card.Body>
          <Form.Control
            as="select"
            onChange={(e) => {
              setIDCliente(parseInt(e.target.value))
            }}
            className="form-select form-select-sm"
          >
            <option value="0">Seleccion el cliente</option>
            {Clientes
              ? Clientes.map((c) => {
                  return <option value={c.id}>{c.razonSocial}</option>
                })
              : null}
          </Form.Control>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Card.Title>
            {Cliente}
            <br />
            <br />
          </Card.Title>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="600px"
            data={data5}
            options={options5}
          />
        </Card.Body>
      </Card>
    </div>
  )
}

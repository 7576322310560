import React, { FC, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import { BsEraserFill, BsFillPencilFill } from 'react-icons/bs'
import { FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { updateCatDestinos } from '../../../../store/features/Clientes/1868/Cat1868DestinosSlice'
import { RootState } from '../../../../store/store'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import I1868CatDestinos from '../Interfaces/I1868CatDestinos'
import FService from '../Services/Facturas.Services'

interface IProps {}

export const Cat1868Destinos: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const mCatDestinos = useSelector((state: RootState) => state.Cat1868Destinos.Cat1868Destinos)
  const [ID, setID] = useState(0)
  const [Lugar, setLugar] = useState('')
  const [Direccion, setDireccion] = useState('')
  const [Buscar, setBuscar] = useState('')
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')

  const colData = [
    {
      name: 'id',
      width: '6%',
      selector: (row: I1868CatDestinos) => row.id,
      sortable: true,
    },
    {
      name: 'Lugar',
      width: '20%',
      selector: (row: I1868CatDestinos) => row.lugar,
    },
    {
      name: 'Direccion',
      width: '60%',
      selector: (row: I1868CatDestinos) => row.direccion,
    },
    {
      name: 'Edita',
      cell: (row: I1868CatDestinos) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setID(row.id)
            setLugar(row.lugar)
            setDireccion(row.direccion)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const deleteItem = (id: number) => {
    alert('Delete item ' + id)
  }

  const save = () => {
    const data: I1868CatDestinos = {
      id: ID,
      lugar: Lugar ? Lugar : '',
      direccion: Direccion ? Direccion : '',
    }
    FService.appendCatDestino(data)
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        dispatch(updateCatDestinos(response.data))
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const cleanForm = () => {
    setID(0)
    setLugar('')
    setDireccion('')
  }

  return (
    <div>
      <Row>
        <Col xs={2}>Lugar</Col>
        <Col xs={4}>
          <Form.Control
            type='text'
            id='Lugar'
            value={Lugar}
            size='sm'
            onChange={(e) => {
              setLugar(e.target.value)
            }}
          />
        </Col>
        <Col xs={2}>
          <Button
            variant='warning'
            size='sm'
            onClick={() => {
              cleanForm()
            }}
          >
            <BsEraserFill />
            &nbsp;Limpiar
          </Button>
        </Col>
        <Col xs={2}></Col>
        <Col xs={1} style={{ textAlign: 'right' }}>
          <Button variant='primary' onClick={() => save()} size='sm'>
            Guardar
          </Button>
        </Col>
      </Row>
      <Row style={{ paddingTop: '5px' }}>
        <Col xs={2}>Destino</Col>
        <Col xs={10}>
          <Form.Control
            type='text'
            id='Direccion'
            value={Direccion}
            size='sm'
            onChange={(e) => {
              setDireccion(e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: '5px' }}>
        <Col xs={12}>
          <Form.Control
            type='text'
            id='Buscar'
            value={Buscar}
            placeholder='Search...'
            size='sm'
            onChange={(e) => {
              setBuscar(e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            pagination
            highlightOnHover
            columns={colData}
            data={mCatDestinos.filter(function (row) {
              return (
                row.lugar.toLowerCase().includes(Buscar.toLowerCase()) ||
                row.direccion.toLowerCase().includes(Buscar.toLowerCase())
              )
            })}
          />
        </Col>
      </Row>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import * as React from 'react'
import { useEffect, useState } from 'react'
import { Card, Col, ProgressBar, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { FaAmazon } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import '../../css/generic01.css'
import DashboardDataService from '../../Services/Dashboard/Dashboard.Clasificacion.Services'

export interface IDashboardTraficoProps {}

export default function DashboardTrafico(props: IDashboardTraficoProps) {
  const [Terminadas, setTerminadas] = useState(0)
  const [Pendientes, setPendientes] = useState(0)
  const [Total, setTotal] = useState(0)

  useEffect(() => {
    DashboardDataService.DashboardInvoices(3)
      .then((response) => {
        setPendientes(response.data.total)
      })
      .catch((e: Error) => {
        return
      })
    DashboardDataService.DashboardInvoices(4)
      .then((response) => {
        setTerminadas(response.data.total)
      })
      .catch((e: Error) => {
        return
      })
  }, [])

  useEffect(() => {
    setTotal(Pendientes + Terminadas)
  }, [Pendientes, Terminadas])

  return (
    <>
      <Col>
        <Card
          style={{ width: '18rem' }}
          className="blueCardBorder dashboardComponentFont"
        >
          <Card.Header style={{ backgroundColor: '#1D6DC2', color: '#ffffff' }}>
            Trafico
          </Card.Header>
          <Card.Body>
            <Card.Title>
              Facturas{' '}
              <IconContext.Provider value={{ color: '#F6C34F', size: '30%' }}>
                <FaAmazon />
              </IconContext.Provider>
            </Card.Title>
            <Row>
              <Col xs={6} style={{}}>
                <Link
                  to="../RptAmazonLinkInvoice2Traffic"
                  style={{
                    textDecoration: 'none',
                    float: 'left',
                    paddingRight: '10px'
                  }}
                >
                  Terminadas
                </Link>
              </Col>
              <Col xs={6} className="text-center">
                <Link
                  to="../RptAmazonPendingTraficInvoice"
                  style={{
                    textDecoration: 'none',
                    float: 'right',
                    paddingRight: '10px'
                  }}
                >
                  Pendientes
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ProgressBar style={{ height: '25px' }}>
                  <ProgressBar
                    striped
                    animated
                    variant="success"
                    now={(Terminadas / Total) * 100}
                    key={1}
                  />
                  <ProgressBar
                    striped
                    variant="warning"
                    now={(Pendientes / Total) * 100}
                    key={2}
                  />
                </ProgressBar>
              </Col>
            </Row>
            <Row>
              <Col xs={6} style={{ fontSize: '6em' }} className="text-center">
                {Terminadas}
              </Col>
              <Col xs={6} style={{ fontSize: '6em' }} className="text-center">
                {Pendientes}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Link
                  to="../RptConsultaTrafico"
                  style={{ textDecoration: 'none', float: 'right' }}
                >
                  Consultas Amazon
                </Link>
              </Col>
            </Row>
          </Card.Body>
          {/*  <Card.Footer
              style={{ paddingRight: '5px', paddingBottom: '5px' }}
            ></Card.Footer> */}
        </Card>
      </Col>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { IRptOperacionesRemesa } from '../../Interfaces/Reportes/RptOperacionesRemesa'
import reportsDataService from '../../Services/Reportes/reportes.services'

export default function RptPedimentosPagados() {
  const [InfoLoaded, setInfoLoaded] = useState(0)
  const [Data, setData] = useState<Array<IRptOperacionesRemesa>>()

  useEffect(() => {
    reportsDataService
      .getRptOperacionesRemesa()
      .then((response) => {
        setData(response.data)
      })
      .catch((e: Error) => {
        console.log(e)
      })
  }, [InfoLoaded])

  return (
    <div>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Cliente</th>
            <th>Referencia</th>
            <th>Num. aduana</th>
            <th>Patente</th>
            <th>Num pedimento</th>
            <th>Tipo operacion</th>
            <th>Clave</th>
            <th>R1 Clave anterior</th>
            <th>Regimen</th>
          </tr>
        </thead>
        <tbody>
          {Data
            ? Data.map((d) => (
                <tr key={d.id}>
                  <td>{d.id}</td>
                  <td>{d.cliente}</td>
                  <td>{d.referencia}</td>
                  <td>{d.numAduana}</td>
                  <td>{d.patente}</td>
                  <td>{d.numPedimento}</td>
                  <td>{d.tipoOperación}</td>
                  <td>{d.clave}</td>
                  <td>{d.r1ClaveAnterior}</td>
                  <td>{d.regimen}</td>
                </tr>
              ))
            : ''}
        </tbody>
      </Table>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import DTOPedimentosCruzadosListaCliente from '../../../DTO/Dashboard/DTOPedimentosCruzadosListaCliente'
import DTOPedimentosCruzadosData from '../../../DTO/Dashboard/DTOPedimentosCruzadosData'
import DashboardDS from '../../../Services/Dashboard/Dashboard.Direccion.Services'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

export interface IRtpPedimentosCruces02Props {}

export default function RtpPedimentosCruces02(
  props: IRtpPedimentosCruces02Props
) {
  const [Clientes, setClientes] = useState<DTOPedimentosCruzadosListaCliente[]>(
    []
  )
  const [Data, setData] = useState<DTOPedimentosCruzadosData[]>([])
  const [Cliente, setCliente] = useState('')
  const [IDCliente, setIDCliente] = useState(0)
  const [Pedimentos, setPedimentos] = useState<number[]>([])
  const [Cruces, setCruces] = useState<number[]>([])
  const [series, setSeries] = useState([
    {
      type: 'line', //render a line chart for this data
      name: 'Pedimentos',
      data: [19, 22, 20, 26]
    },
    {
      type: 'column', //use column chart here.
      name: 'Cruces',
      data: [103, 105, 98, 83]
    }
  ])
  const [options, setOptions] = useState<ApexOptions>({
    xaxis: {
      categories: ['2022', '20022', '2023', '2023']
    },
    chart: {
      height: 100,
      type: 'line',
      zoom: {
        enabled: false
      }
    }
  })

  useEffect(() => {
    DashboardDS.GETPedimentosCruzadosListaCliente()
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        return
      })
  }, [])

  useEffect(() => {
    DashboardDS.GETPedimentosCruzadosData(IDCliente)
      .then((response) => {
        setData(response.data)
        let Pedimentos: number[] = []
        let Cruces: number[] = []
        let Categorias: string[] = []
        response.data.forEach(function (item) {
          Pedimentos.push(item.pedimentos)
          Cruces.push(item.despachos)
          Categorias.push(item.sMes)
        })
        setSeries([
          {
            type: 'line', //render a line chart for this data
            name: 'Pedimentos',
            data: Pedimentos
          },
          {
            type: 'column', //use column chart here.
            name: 'Cruces',
            data: Cruces
          }
        ])
        setOptions({
          xaxis: {
            categories: Categorias
          },
          chart: {
            height: 100,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: true,
            dropShadow: {
              enabled: true,
              left: 2,
              top: 2,
              opacity: 0.5
            }
          },
          grid: {
            position: 'front',
            borderColor: '#AAA',
            strokeDashArray: 3
          },
          legend: {
            show: true,
            position: 'top'
          }
        })
        var arrClientes = Clientes.filter((item) => {
          return item.id == IDCliente
        })
        setCliente(arrClientes[0].razonSocial)
      })
      .catch((e: Error) => {
        return
      })
  }, [IDCliente])

  return (
    <div>
      <Card>
        <Card.Body>
          <Form.Control
            as="select"
            onChange={(e) => {
              setIDCliente(parseInt(e.target.value))
            }}
            className="form-select form-select-sm"
          >
            <option value="0">Seleccion el cliente</option>
            {Clientes
              ? Clientes.map((c) => {
                  return <option value={c.id}>{c.razonSocial}</option>
                })
              : null}
          </Form.Control>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Card.Title>
            {Cliente}
            <br />
            <br />
          </Card.Title>
          <ReactApexChart
            options={options}
            series={series}
            width="100%"
            height="600px"
          />
        </Card.Body>
      </Card>
    </div>
  )
}

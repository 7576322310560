import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Form,
  FormCheck,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { MsgInformativo } from '../../../../../Utils/Toast/msgInformativo'
import I2096Detail from '../../../Interfaces/I2096Detail'
import I2096Headers from '../../../Interfaces/I2096Header'
import DSAmazon from '../../../Services/Amazon.Services'
// Redux
import { RootState } from '../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { updateInvoice } from '../../../../../../store/features/Clientes/2096/AmazonInvoices'
import * as CurrencyFormat from 'react-currency-format'
import { BsFillChatRightTextFill } from 'react-icons/bs'
import DTO2096SightLine from '../../../DTO/DTO2096SightLine'

export interface IRptAmazonPendingTraficInvoiceDetailProps {
  IDMaster: number
  detail: I2096Detail
  Habilitado: boolean
}

export default function RptAmazonPendingTraficInvoiceDetail(
  props: IRptAmazonPendingTraficInvoiceDetailProps
) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [ShowModal, setShowModal] = useState(false)
  const [EstatusSwith, setEstatusSwitch] = useState(props.detail.sightLine)
  const [UserChanged, setUserChanged] = useState(false)
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  //const [Show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [Comentarios, setComentarios] = useState(
    props.detail.comentariosSightLine ? props.detail.comentariosSightLine : ''
  )
  const msgColor = 'primary'
  const openLink = (item: string) => {
    window.open('https://www.amazon.com/dp/' + item)
  }
  //const target = React.useRef(null)

  const UpdateInfo = (Invoice: I2096Headers, Detail: I2096Detail[]) => {
    const updatedInvoice: I2096Headers = {
      id: props.IDMaster,
      referencia: Invoice.referencia,
      commercialInvoiceDate: Invoice.commercialInvoiceDate,
      invoiceNumber: Invoice.invoiceNumber,
      name: Invoice.name,
      trailerNumber: Invoice.trailerNumber,
      trailerId: Invoice.trailerId,
      porOfLoading: Invoice.porOfLoading,
      portOfEntry: Invoice.portOfEntry,
      paymentsTerms: Invoice.paymentsTerms,
      incoterms: Invoice.incoterms,
      hawb: Invoice.hawb,
      totalInvoiceQuantityUnitOfMeasure:
        Invoice.totalInvoiceQuantityUnitOfMeasure,
      totalInvoiceQuantity: Invoice.totalInvoiceQuantity,
      totalInvoiceWeightUnitOfMeasure: Invoice.totalInvoiceWeightUnitOfMeasure,
      totalInvoiceWeight: Invoice.totalInvoiceWeight,
      totalInvoiceValueChargeOrAllowance:
        Invoice.totalInvoiceValueChargeOrAllowance,
      totalInvoiceValueCurrencyISOCode:
        Invoice.totalInvoiceValueCurrencyISOCode,
      totalInvoiceValueMonetaryAmount: Invoice.totalInvoiceValueMonetaryAmount,
      footNote: Invoice.footNote,
      archivo: Invoice.archivo,
      fCreacion: Invoice.fCreacion,
      clasificador: Invoice.clasificador,
      noVuelta: Invoice.noVuelta,
      estatus: Invoice.estatus,
      max: true,
      urgente: Invoice.urgente,
      detail: Detail
    }
    dispatch(updateInvoice(updatedInvoice))
  }

  const ToggleSightLine = (id: number) => {
    const data: DTO2096SightLine = {
      id: id,
      sightLine: EstatusSwith,
      comentariosSightLine: Comentarios
    }
    DSAmazon.SightLineSET(data)
      .then((response) => {
        console.log(response.data)
        //editArray(id, !props.detail.autorizado)
        setShowModal(false)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error, no se guardo la informacion en BD')
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    if (UserChanged) setShowModal(true)
  }, [EstatusSwith, UserChanged])

  return (
    <>
      <tr>
        <th
          style={{
            width: '50px',
            backgroundColor: '#FFFFFF'
          }}
          className="DetailData"
        >
          &nbsp;
        </th>
        <td
          style={{
            textAlign: 'center',
            cursor: 'pointer'
          }}
          className="DetailData"
          onClick={() => openLink(props.detail.itemId)}
        >
          {props.detail.itemId}
        </td>
        <td
          style={{
            textAlign: 'right'
          }}
          className="DetailData"
        >
          {props.detail.itemDescription}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          <CurrencyFormat
            value={props.detail.fraccionGEMCO ? props.detail.fraccionGEMCO : ''}
            disabled={true}
            format={'####.##.####'}
            displayType={'input'}
            style={{
              fontSize: '17px',
              backgroundColor:
                props.detail.validaFraccionOriginal === 0
                  ? '#FFFAF2 '
                  : '#E5F7B6',
              border:
                props.detail.validaFraccionOriginal === 0
                  ? '2px solid #F9A721'
                  : '2px solid green',
              color:
                props.detail.validaFraccionOriginal === 0
                  ? '#5923F6'
                  : '#000000',
              width: '135px',
              textAlign: 'right',
              borderRadius: '10px'
            }}
          />
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.descripcionGEMCO}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.countryOfOrigin}
        </td>
        <td
          style={{
            textAlign: 'right'
          }}
          className="DetailData"
        >
          {props.detail.productGroup}
        </td>
        <td
          style={{
            textAlign: 'right'
          }}
          className="DetailData"
        >
          {props.detail.brand}
        </td>
        <td
          style={{
            textAlign: 'right'
          }}
          className="DetailData"
        >
          {props.detail.model}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.itemQuantityUnitOfMeasure}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.quantity}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.weightValue}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.netWeightUnitOfMeasure}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.actualUnitCostMonetaryAmount}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.totalUnitValueMonetaryAmount}
        </td>
        <td
          style={{
            textAlign: 'center',
            paddingLeft: '30px'
          }}
          className="DetailData"
        >
          <FormCheck
            className="form-check form-switch form-control-lg"
            id="switchEnabled"
            type="switch"
            checked={EstatusSwith}
            onChange={() => {
              setEstatusSwitch(!EstatusSwith)
              setUserChanged(true)
              setShowModal(!EstatusSwith)
            }}
            label=""
          />
        </td>
        <td
          style={{
            visibility: props.detail.sightLine ? 'hidden' : 'visible'
          }}
        >
          {' '}
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">{Comentarios}</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <Button variant="light" {...triggerHandler}>
                <IconContext.Provider
                  value={{
                    color: 'blue',
                    size: '25px'
                  }}
                >
                  <BsFillChatRightTextFill />
                </IconContext.Provider>
                <span className="ms-1"></span>
              </Button>
            )}
          </OverlayTrigger>
        </td>
      </tr>

      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
        backdrop="static"
        disableEscapeKeyDown={true}
      >
        <Modal.Body>
          <div style={{ height: '150px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={3}>Comentarios</Col>
              <Col xs={8}>
                {' '}
                <Form.Control
                  as="textarea"
                  id="Comentarios"
                  size="sm"
                  value={Comentarios.length ? Comentarios : ''}
                  onChange={(e) => setComentarios(e.target.value)}
                />
              </Col>
              <Col>&nbsp;</Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setEstatusSwitch(true)
                    setShowModal(false)
                  }}
                >
                  &nbsp;&nbsp;No&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    ToggleSightLine(props.detail.id)
                  }}
                >
                  &nbsp;&nbsp;Si&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </>
  )
}

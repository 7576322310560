import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICat2096CatConceptosConsulta from '../../../../Components/Clientes/Amazon/Interfaces/I2096CatConceptosConsulta'
const CatConcetosConsulta: ICat2096CatConceptosConsulta[] = []
const initialState = { CatConcetosConsulta }

export const CatConceptosConsultaSlice = createSlice({
  name: 'CatConceptosConsulta',
  initialState: initialState,
  reducers: {
    initializeCatConcetosConsulta: (state, action: PayloadAction<ICat2096CatConceptosConsulta[]>) => {
      state.CatConcetosConsulta = action.payload
    },
    populateCatConcetosConsultas: (state, action: PayloadAction<ICat2096CatConceptosConsulta[]>) => {
      action.payload.forEach((newItem) => {
        var Existe = state.CatConcetosConsulta.find(function (onMemoryItem) {
          return onMemoryItem.id === newItem.id
        })
        if (!Existe) state.CatConcetosConsulta.push(newItem)
      })
    },
    addCatConcetosConsulta: (state, action: PayloadAction<ICat2096CatConceptosConsulta>) => {
      var Existe = state.CatConcetosConsulta.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatConcetosConsulta.push(action.payload)
    },
    updateCatConcetosConsulta: (state, action: PayloadAction<ICat2096CatConceptosConsulta>) => {
      const i = state.CatConcetosConsulta.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.CatConcetosConsulta[i] = action.payload
      else state.CatConcetosConsulta.push(action.payload)
    },
    updateCatConcetosConsultaDetail: (state, action: PayloadAction<ICat2096CatConceptosConsulta>) => {
      const i = state.CatConcetosConsulta.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.CatConcetosConsulta[i] = action.payload
      else state.CatConcetosConsulta.push(action.payload)
    },
    deleteCatConcetosConsulta: (state, action: PayloadAction<number>) => {
      const newArr = state.CatConcetosConsulta.filter((data) => data.id != action.payload)
      state.CatConcetosConsulta = newArr
    }
  }
})

export const { initializeCatConcetosConsulta, addCatConcetosConsulta, populateCatConcetosConsultas, updateCatConcetosConsulta, deleteCatConcetosConsulta } =
  CatConceptosConsultaSlice.actions
export default CatConceptosConsultaSlice.reducer

import React, { FC, useEffect, useState } from 'react'
import { Alert, Card, Col, Row } from 'react-bootstrap'
import QRCode from 'react-google-qrcode'
import Header from '../../../images/AvisoCruceHeader.png'
import Footer from '../../../images/AvisoCruceFooter.png'
import PrintComponents from 'react-print-components'

export interface IProps {}

export default function AvisoCruce (props: IProps) {
  const [AvisoCruce, setAvisoCruce] = useState('')
  const [Validacion, setValidacion] = useState('')
  const [Aduana, setAduana] = useState('')
  const [Emision, setEmision] = useState('')
  const [TipoOperacion, setTipoOperacion] = useState('')
  const [TipoDocumento, setTipoDocumento] = useState('')
  const [InformacionTipoDocumento, setInformacionTipoDocumento] = useState('')
  const [ModalidadCruce, setModalidadCruce] = useState('')
  const [FechaEmision, setFechaEmision] = useState('')
  const [Contenedor, setContenedor] = useState('')
  const [Candado, setCandado] = useState('')
  const [URL, setURL] = useState<string>()

  useEffect(() => {
    setURL('https://sea-avc.anam.gob.mx/show/show/' + AvisoCruce)
  }, [AvisoCruce])

  function QRCodeBuilder(url: string) {
    return <QRCode data={url} size={130} frame />
  }

  const showFile1 = (e: any) => {
    e.preventDefault()
    setAvisoCruce('')
    setAduana('')
    setEmision('')
    setTipoOperacion('')
    setTipoDocumento('')
    setInformacionTipoDocumento('')
    setModalidadCruce('')
    setFechaEmision('')
    setContenedor('')
    setCandado('')
    setURL('')
    const reader = new FileReader()
    reader.onload = async (e: any) => {
      const text = e.target.result
      const arr: string[] = text.split(/\r?\n/)
      console.log(arr)
      let Contenedores = '',
        Candados = ''
      for (var i = 0; i < arr.length; i++) {
        // console.log(arr[i])
        if (arr[i].includes('Aviso de cruce:')) {
          setAvisoCruce(arr[i].replace('Aviso de cruce:', ''))
        }
        if (arr[i].includes('Emisión:')) setEmision(arr[i].replace('Emisión:', ''))
        if (arr[i].includes('Aduana')) setAduana(arr[i].replace('Aduana:', ''))
        if (arr[i].includes('Tipo de operación:')) setTipoOperacion(arr[i].replace('Tipo de operación:', ''))
        if (arr[i].includes('Tipo de documento:')) setTipoDocumento(arr[i].replace('Tipo de documento:', ''))
        if (arr[i].includes('|')) setInformacionTipoDocumento(arr[i])
        if (arr[i].includes('Modalidad')) setModalidadCruce(arr[i])
        if (arr[i].includes('Fecha de Emisi')) setFechaEmision(arr[i])
        if (arr[i].includes('Contenedor-caja:')) {
          Contenedores = ''
          for (var ii = i; ii < arr.length; ii++) {
            console.log('valor de caja contenedor ' + arr[ii])
            if (arr[ii].length > 0) {
              Contenedores += ',' + arr[ii]
              console.log('Contenedores ' + Contenedores)
            } else {
              i = ii
              Contenedores = Contenedores.replace(',Contenedor-caja:,', '')
              setContenedor(Contenedores)
              console.log('........................................Caja Contenedor value:' + Contenedores)
              break
            }
          }
        }
        if (arr[i].includes('Candados:')) {
          Candados = ''
          for (var ii = i; ii < arr.length; ii++) {
            console.log('valor de candados ' + arr[ii])
            if (arr[ii].length > 0) {
              Candados += ',' + arr[ii]
              console.log('Candados ' + Candado)
            } else {
              i = ii
              Candados = Candados.replace(',Candados:,', '')
              setCandado(Candados)
              console.log('........................................Candado value:' + Candados)
              break
            }
          }
        }
      }
    }
    reader.readAsText(e.target.files[0])
  }

  const showFile2 = (e: any) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e: any) => {
      const text = e.target.result
      console.log(text)
      setValidacion(text.replace('"', ''))
    }
    reader.readAsText(e.target.files[0])
  }

  const generatePDF = () => {}

  return (
    <div className='allScreen'>
      {AvisoCruce === '' || Validacion === '' ? (
        <div>
          <Row>
            <Col xs={12}>&nbsp;</Col>
          </Row>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Alert variant='secondary'></Alert>
                  Proporcione el archivo AVC
                </Col>
                <Col>
                  <Alert variant='secondary'>
                    <input type='file' onChange={(e) => showFile1(e)} />
                  </Alert>
                </Col>
                <Col xs={3}></Col>
                <Col>Proporcione el archivo de validacion</Col>
                <Col>
                  <input type='file' onChange={(e) => showFile2(e)} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div>
          <Row>
            <Col xs={12}>&nbsp;</Col>
          </Row>
          {/*           <Row>
            <Col xs={8}>
              <Card style={{ textAlign: 'center', width: '1000px', height: '100%' }}>
                <Card.Img variant='top' src={Header} />
              </Card>
            </Col>
            <Col xs={4}></Col>
          </Row> */}
          <Row>
            <Col xs={12}>&nbsp;</Col>
          </Row>
          <Row>
            <Col xs={8}>
              <PrintComponents trigger={<button>Print</button>}>
                <table className='tableAvisoCruce allScreen'>
                  <tr>
                    <th colSpan={4} style={{ textAlign: 'center' }} className='tableAvisoCruceHeader'>
                      AVISO DE CRUCE
                    </th>
                  </tr>
                  <tr className='tableAvisoCruceHeader'>
                    <th colSpan={3}>FOLIO AVISO DE CRUCE</th>
                    <td>FECHA DE EMISIÓN</td>
                  </tr>
                  <tr>
                    <th colSpan={3} className='tableAvisoCrucefNormal'>
                      {AvisoCruce}
                    </th>
                    <td>{Emision}</td>
                  </tr>
                  <tr className='tableAvisoCruceHeader'>
                    <td colSpan={2}>ADUANA</td>
                    <td>TIPO OPERACIÓN</td>
                    <td>TIPO DE DOCUMENTO</td>
                  </tr>
                  <tr>
                    <td colSpan={2} rowSpan={3}>
                      {Aduana}
                    </td>
                    <td>{TipoOperacion}</td>
                    <td>{TipoDocumento}</td>
                  </tr>
                  <tr className='tableAvisoCruceHeader'>
                    <td>MODALIDAD DE CRUCE</td>
                    <td>TAG-GAFETE ÚNICO</td>
                  </tr>
                  <tr>
                    <td>VEHICULAR</td>
                    <td></td>
                  </tr>
                  <tr className='tableAvisoCruceHeader'>
                    <th colSpan={3}>CONTENEDOR-CAJA</th>
                    <td>CANDADOS</td>
                  </tr>
                  <tr>
                    <th colSpan={3} className='tableAvisoCrucefNormal'>
                      {Contenedor}
                    </th>
                    <td>{Candado}</td>
                  </tr>
                  <tr className='tableAvisoCruceHeader'>
                    <th colSpan={4}>INFORMACIÓN DEL TIPO DE DOCUMENTO</th>
                  </tr>
                  <tr>
                    <th colSpan={4} className='tableAvisoCrucefNormal'>
                      {InformacionTipoDocumento}
                    </th>
                  </tr>
                  <tr className='tableAvisoCruceHeader'>
                    <th colSpan={4}>FOLIO FISCAL DEL CFDI CON COMPLEMENTO CARTA PORTE</th>
                  </tr>
                  <tr>
                    <th colSpan={4} className='tableAvisoCrucefNormal'>
                      &nbsp;
                    </th>
                  </tr>
                  <tr className='tableAvisoCruceHeader'>
                    <th colSpan={3}>DATOS ADICIONALES</th>
                    <th colSpan={1}>CÓDIGO BIDIMENCIONAL QR</th>
                  </tr>
                  <tr>
                    <th colSpan={3} className='tableAvisoCrucefNormal'>
                      &nbsp;
                    </th>
                    <th colSpan={1}>
                      <Row>
                        <Col xs={3}>&nbsp;</Col>
                        <Col xs={6}>
                          <br />
                          <img src={'https://chart.googleapis.com/chart?cht=qr&chl=' + URL + '&chs=200x200'} />
                          <br />
                        </Col>
                        <Col xs={3}></Col>
                      </Row>
                    </th>
                  </tr>
                  <tr className='tableAvisoCruceHeader'>
                    <th colSpan={4}>FOLIÓ DE VALICACIÓN DE LA AGENCIA</th>
                  </tr>
                  <tr>
                    <th colSpan={4} className='tableAvisoCrucefNormal tdAvisoCruceWordWrap'>
                      {Validacion}
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={4}>
                      <img src={Footer} />
                    </th>
                  </tr>
                </table>
              </PrintComponents>
            </Col>
            <Col xs={4}></Col>
          </Row>
          {/*  <Row>
            <Col xs={12}>&nbsp;</Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Card style={{ textAlign: 'center', width: '1000px', height: '100%' }}>
                <Card.Img variant='top' src={Footer} />
              </Card>
            </Col>
            <Col xs={4}></Col>
          </Row> */}
        </div>
      )}
    </div>
  )
}

import http from "../common/http-common";
import ITabulador from "../../Interfaces/Catalogos/ITabulador";
import IRespuesta from "../../Interfaces/IRespuesta";


class Corresponsales_Tabuladores_DataService {
  Get(id: number) {
    return http.get<ITabulador>(`/Catalogos/Tabulador/get?id=${id}`);
  }
  GetAll() {
    return http.get<ITabulador[]>(`/Catalogos/Tabulador/getAll?id=0&IdCliente=0`);
  }
  GetByCustomer(IdCliente: number) {
    return http.get<ITabulador[]>(`/Catalogos/Tabulador/getAll?IdCliente=${IdCliente}`);
  }
  Append(data: ITabulador) {
    return http.post<ITabulador>("/Catalogos/Tabulador/Append", data);
  }
  Delete(id: number) {
    return http.delete<IRespuesta>(`/Catalogos/Tabulador/Delete/${id}`);
  }
}
export default new Corresponsales_Tabuladores_DataService();
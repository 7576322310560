import { FC, useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import {
  BsClipboardCheck,
  BsCloudArrowUpFill,
  BsFileEarmarkExcel,
  BsFillPencilFill,
  BsSearch
} from 'react-icons/bs'
import I325Pedidos from '../Interfaces/I325Pedidos'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import PedDataService from '../Services/CasaCuervo.Services'
import DataTable from 'react-data-table-component'
import DTO325AduanasPedidos from '../../../../DTO/Clientes/CasaCuervo/DTO325AduanasPedidos'
import * as XLSX from 'xlsx'
import { IconContext } from 'react-icons'
import FileManager from '../../../Utils/FileManager/FileManager'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import DTO325UpdateFromWeb from '../../../../DTO/Clientes/CasaCuervo/DTO325UpdateFromWeb'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FiFileText, FiVideo } from 'react-icons/fi'

interface IProps {}

interface IMedidaCaja {
  id: number
  Medida: string
}

export default function RptPedidos (props:IProps) {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [UserType, setUserType] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserType')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const queryParams = new URLSearchParams(window.location.search)
  const [Search, setSearch] = useState('')
  const [DoneColor, setDoneColor] = useState('#CCD1D1')
  const [CurrentTime2Number, setCurrentTime2Number] = useState(0)
  const [Data, setData] = useState<I325Pedidos[]>([])
  const [DataOriginal, setDataOriginal] = useState<I325Pedidos[]>([])
  const [Aduanas, setAduanas] = useState<DTO325AduanasPedidos[]>([])
  const [Aduana, setAduana] = useState('Aduanas...')
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-30))
  const [Fin, setFin] = useState(currentDate(0))
  const [filtro, setFiltro] = useState('')
  const [tipoReporte, setTipoReporte] = useState(0)
  const [msgColor, setMsgColor] = useState('primary')
  const [modalFileManager, setModalFileManager] = useState(false)
  const [modalFileManagerCorresponsal, setModalFileManagerCorresponsal] =
    useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [ComentarioGEMCO, setComentarioGEMCO] = useState('')
  const [fechaCompromiso, setFechaCompromiso] = useState<Date | null>()
  const [fechaCruce, setFechaCruce] = useState<Date | null>()
  const [DTOfechaCompromiso, setDTOFechaCompromiso] = useState<string>('')
  const [DTOfechaCruce, setDTOFechaCruce] = useState<string>('')
  const [PO, setPO] = useState('')
  const [Carrier, setCarrier] = useState('')
  const [IDPedido, setIDPedido] = useState(0)
  const [Factura, setFactura] = useState('')
  const [MedidaCaja, setMedidaCaja] = useState('')
  const [Sello1, setSello1] = useState('')
  const [Sello2, setSello2] = useState('')
  const [UUID, setUUID] = useState('')
  const [Trafico, setTrafico] = useState('')
  const [Pedimento, setPedimento] = useState('')
  const [Patente, setPatente] = useState('')
  const [ModalTmpPDF, setModalTmpPDF] = useState(false)
  const [NoPedido, setNoPedido] = useState('')
  const [NoCaja, setNoCaja] = useState('')

  const columnsConcepts = [
    {
      name: 'PO',
      width: '200px',
      selector: (row: I325Pedidos) => row.po,
      sortable: true
    },
    {
      name: 'Aduana',
      width: '200px',
      selector: (row: I325Pedidos) => row.aduana,
      sortable: true
    },
    {
      name: 'Destination',
      width: '200px',
      selector: (row: I325Pedidos) => row.destination,
      sortable: true
    },
    {
      name: 'Truck Number',
      width: '140px',
      selector: (row: I325Pedidos) => row.truckNumber,
      sortable: true
    },
    {
      name: 'Forwarder',
      width: '150px',
      selector: (row: I325Pedidos) => row.forwarder,
      sortable: true
    },
    {
      name: 'Carrier',
      width: '150px',
      selector: (row: I325Pedidos) => row.carrier,
      sortable: true
    },
    {
      name: 'Load Date',
      width: '170px',
      selector: (row: I325Pedidos) => row.loadDate,
      sortable: true
    },
    {
      name: 'Prioridad',
      width: '100px',
      selector: (row: I325Pedidos) => row.prioridad,
      sortable: true
    },
    {
      name: 'Estatus',
      width: '550px',
      selector: (row: I325Pedidos) => row.estatus,
      sortable: false
    },
    {
      name: 'Edita',
      width: '60px',
      cell: (row: I325Pedidos) => (
        <Button
          size="sm"
          variant="light"
          style={{
            textAlign: 'right',
            visibility: UserType === '4' ? 'hidden' : 'visible'
          }}
          onClick={() => {
            setIDPedido(row.id)
            setPO(row.po)
            setCarrier(row.carrier)
            setComentarioGEMCO(row.comentarioGEMCO)
            setFechaCompromiso(StrtoDate(row.fechaCompromiso))
            setFechaCruce(StrtoDate(row.fechaCruce))
            setFactura(row.factura)
            setMedidaCaja(row.medidaCaja)
            setSello1(row.sello1)
            setSello2(row.sello2)
            setUUID(row.uuid)
            setTrafico(row.trafico)
            setPedimento(row.pedimento)
            setPatente(row.patente)
            setModalEdit(true)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '15px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>{' '}
        </Button>
      )
    },
    {
      name: 'Comentario Gemco',
      width: '350px',
      selector: (row: I325Pedidos) => row.comentarioGEMCO,
      sortable: false
    },
    {
      name: 'Fecha Compromiso',
      width: '140px',
      cell: (row: I325Pedidos) => {
        if (row.fechaCompromiso) {
          if (row.fechaCompromiso.length > 0) {
            return row.fechaCompromiso.substring(0, 10)
          }
        }
      },
      sortable: false
    },
    {
      name: 'Fecha Cruce',
      width: '190px',
      cell: (row: I325Pedidos) => {
        return row.fechaCruce.length === 0 && row.modulacion.length > 0
          ? row.modulacion
          : row.fechaCruce
      },
      sortable: false
    },
    {
      name: 'Factura',
      width: '120px',
      selector: (row: I325Pedidos) => row.factura,
      sortable: true
    },
    {
      name: 'UUID',
      width: '300px',
      selector: (row: I325Pedidos) => row.uuid,
      sortable: true
    },
    {
      name: 'Trafico',
      width: '100px',
      selector: (row: I325Pedidos) => row.trafico,
      sortable: true
    },
    {
      name: 'Pedimento',
      width: '110px',
      selector: (row: I325Pedidos) => row.pedimento,
      sortable: true
    },
    {
      name: 'Patente',
      width: '90px',
      selector: (row: I325Pedidos) => row.patente,
      sortable: true
    },
    {
      name: 'Actualizacion',
      width: '180px',
      selector: (row: I325Pedidos) => row.actualizacion,
      sortable: true
    }
  ]

  const mCatMedidas: IMedidaCaja[] = [
    { id: 0, Medida: 'SELECCIONE' },
    { id: 1, Medida: 'Caja 53 pies' },
    { id: 2, Medida: 'Caja 48 pies' },
    { id: 3, Medida: 'Refrigerada 53 pies' },
    { id: 4, Medida: 'Pipa' }
  ]

  const conditionalRowStyles = [
    {
      when: (row: I325Pedidos) =>
        row.fechaCruce.length > 0 || row.modulacion.length > 0,
      style: {
        backgroundColor: DoneColor
      }
    }
  ]

  const adjustTimeZone = (str: string): string => {
    if (str) {
      const original: any = new Date(str)
      const real = new Date(original - original.getTimezoneOffset() * 60 * 1000)
      return real.toISOString()
    } else return ''
  }

  const StrtoDate = (str: string): Date | null => {
    if (str) {
      const original: any = new Date(str)
      //const real = new Date(original - original.getTimezoneOffset() * 60 * 1000)
      return original
    } else return null
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const generaReporte = () => {
    // alert(Aduanas[0].value)
    setTipoReporte(0)
    PedDataService.getAll(
      Inicio,
      Fin,
      UserType === '5' ? Aduanas[0].value : Aduana
    )
      .then((response) => {
        setData(response.data)
        setDataOriginal(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const populateAduanas = () => {
    PedDataService.getAduanas(UserId, UserType)
      .then((response) => {
        console.log(
          '...........................................Aduanas ' + response.data
        )
        setAduanas(response.data)
        setAduana(response.data[0].value)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    if (e.target.value.length > 0) {
      setData(
        Data.filter(function (row) {
          return (
            row.po.includes(e.target.value) ||
            row.aduana.includes(e.target.value) ||
            row.destination.includes(e.target.value) ||
            row.truckNumber.includes(e.target.value) ||
            row.forwarder.includes(e.target.value) ||
            row.carrier.includes(e.target.value) ||
            row.loadDate.includes(e.target.value) ||
            row.estatus.includes(e.target.value) ||
            row.comentarioGEMCO.includes(e.target.value) ||
            row.fechaCompromiso.includes(e.target.value) ||
            row.fechaCruce.includes(e.target.value) ||
            row.factura.includes(e.target.value) ||
            row.uuid.includes(e.target.value) ||
            row.trafico.includes(e.target.value) ||
            row.pedimento.includes(e.target.value) ||
            row.patente.includes(e.target.value)
          )
        })
      )
    } else {
      setData(DataOriginal)
    }
  }

  const uploadExcel = () => {
    const d1 = new Date()
    setCurrentTime2Number(d1.getTime())
    setModalFileManager(true)
  }

  const uploadExcelCorresponsal = () => {
    const d1 = new Date()
    setCurrentTime2Number(d1.getTime())
    setModalFileManagerCorresponsal(true)
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de pedidos de Casa Cuervo')
  }

  function exportExcel(jsonData: I325Pedidos[], fileName: string): void {
    let Heading = [
      [
        'PO',
        'Aduana',
        'Destination',
        'TruckNumber',
        'Forwarder',
        'Carrier',
        'LoadDate',
        'Prioridad',
        'Estatus',
        'Comentario GEMCO',
        'Fecha Compromiso',
        'Fecha Cruce',
        'Factura',
        'UUID',
        'Trafico',
        'Pedimento',
        'Patente',
        'Actualizacion'
      ]
    ]
    const dataOnly = jsonData.map(
      ({
        po,
        aduana,
        destination,
        truckNumber,
        forwarder,
        carrier,
        loadDate,
        prioridad,
        estatus,
        comentarioGEMCO,
        fechaCompromiso,
        fechaCruce,
        modulacion,
        factura,
        uuid,
        trafico,
        pedimento,
        patente,
        actualizacion
      }) => {
        return {
          po,
          aduana,
          destination,
          truckNumber,
          forwarder,
          carrier,
          loadDate,
          prioridad,
          estatus,
          comentarioGEMCO,
          fechaCompromiso,
          fechaCruce,
          modulacion,
          factura,
          uuid,
          trafico,
          pedimento,
          patente,
          actualizacion
        }
      }
    )
    dataOnly.forEach((row, index, array) => {
      if (row.fechaCruce.length === 0 && row.modulacion.length > 0) {
        dataOnly[index].fechaCruce = row.modulacion
      }
    })
    const Report = dataOnly.map(
      ({
        po,
        aduana,
        destination,
        truckNumber,
        forwarder,
        carrier,
        loadDate,
        prioridad,
        estatus,
        comentarioGEMCO,
        fechaCompromiso,
        fechaCruce,
        factura,
        uuid,
        trafico,
        pedimento,
        patente,
        actualizacion
      }) => {
        return {
          po,
          aduana,
          destination,
          truckNumber,
          forwarder,
          carrier,
          loadDate,
          prioridad,
          estatus,
          comentarioGEMCO,
          fechaCompromiso,
          fechaCruce,
          factura,
          uuid,
          trafico,
          pedimento,
          patente,
          actualizacion
        }
      }
    )
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, Report, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c + 1; C <= range.e.c + 1; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const uploadFile = (idFile: number, Process: number) => {
    PedDataService.Append(idFile, Process)
      .then((response) => {
        console.log(response.data)
        if (!response.data) {
          setHeader('Error')
          setMsg(
            'Ocurrio un error, favor de verificar el formato de la fecha, debe ser: MM/dd/yyyy '
          )
          setShowMsg(true)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    populateAduanas()
  }, [])

  useEffect(() => {
    if (fechaCompromiso) setDTOFechaCompromiso(fechaCompromiso.toString())
    if (fechaCruce) setDTOFechaCruce(fechaCruce.toString())
  }, [fechaCompromiso, fechaCruce])

  let handleColor = (time: Date) => {
    return time.getHours() > 12 ? 'text-success' : 'text-error'
  }

  const saveInfo = (mode: number) => {
    const data: DTO325UpdateFromWeb = {
      id: IDPedido,
      ComentarioGEMCO: ComentarioGEMCO,
      FechaCompromiso: fechaCompromiso
        ? adjustTimeZone(DTOfechaCompromiso)
        : '',
      FechaCruce: fechaCruce ? adjustTimeZone(DTOfechaCruce) : '',
      Factura: Factura,
      MedidaCaja: MedidaCaja,
      Sello1: Sello1,
      Sello2: Sello2,
      UUID: UUID,
      Trafico: Trafico,
      Pedimento: Pedimento,
      Patente: Patente
    }
    PedDataService.UpdateInfo(data)
      .then((response) => {
        const idx = Data.findIndex((obj) => obj.id == IDPedido)
        Data[idx].comentarioGEMCO = ComentarioGEMCO
        Data[idx].fechaCompromiso = fechaCompromiso
          ? adjustTimeZone(DTOfechaCompromiso)
          : ''
        Data[idx].fechaCruce = fechaCruce ? adjustTimeZone(DTOfechaCruce) : ''
        Data[idx].factura = Factura
        Data[idx].sello1 = Sello1
        Data[idx].sello2 = Sello2
        Data[idx].trafico = Trafico
        Data[idx].uuid = UUID
        Data[idx].pedimento = Pedimento
        Data[idx].patente = Patente
        setDataOriginal(Data)
        if (mode === 2) createPDF()
        setModalEdit(false)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        setModalEdit(false)
      })
  }

  useEffect(() => {
    setData(DataOriginal)
    if (tipoReporte === 0) setData(DataOriginal)
    else if (tipoReporte === 1) {
      setData(
        DataOriginal.filter(function (row) {
          if (row.fechaCruce.length === 0 && row.modulacion.length === 0)
            return row
        })
      )
    } else if (tipoReporte === 2) {
      setData(
        DataOriginal.filter(function (row) {
          if (row.fechaCruce.length > 0 || row.modulacion.length > 0) return row
        })
      )
    }
  }, [tipoReporte])

  const createPDF = () => {
    PedDataService.GeneratePDF4CC(IDPedido)
      .then((response: any) => {
        if (response.status === 200) {
          console.log(response.data)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado PDF')
        setShowMsg(true)
        return
      })
  }

  const generateTempPDF = () => {
    PedDataService.GenerateTempPDF(
      NoPedido ? NoPedido : '',
      NoCaja ? NoCaja : '',
      MedidaCaja ? MedidaCaja : '0',
      Factura ? Factura : '',
      Sello1 ? Sello1 : '.',
      Sello2 ? Sello2 : '.',
      Carrier ? Carrier : '.'
    )
      .then((response: any) => {
        if (response.status === 200) {
          console.log(response.data)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg(e.message.toString())
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className="row">
            <div className="col-md-1">
              <Form.Control
                defaultValue={Inicio}
                type="date"
                name="Inicio"
                placeholder="Inicio"
                title="Inicio"
                alt="Inicio"
                data-date-format="YYYY-mm-dd"
                onChange={(e) => setInicio(e.target.value)}
                size="sm"
              />
            </div>
            <div className="col-md-1">
              <Form.Control
                defaultValue={Fin}
                type="date"
                name="Fin"
                placeholder="Fin"
                title="Fin"
                alt="Fin"
                onChange={(e) => setFin(e.target.value)}
                size="sm"
              />
            </div>
            <div className="col-md-2">
              <Form.Control
                as="select"
                onChange={(e) => {
                  setAduana(e.target.value)
                }}
                className="form-select form-select-sm"
              >
                {Aduanas
                  ? Aduanas.map((a) => {
                      return (
                        <option value={a.id} key={a.id}>
                          {a.value}
                        </option>
                      )
                    })
                  : null}
              </Form.Control>
            </div>
            <div className="col-md-1">
              <Form.Control
                as="select"
                value={tipoReporte}
                onChange={(e) => {
                  setTipoReporte(parseInt(e.target.value))
                }}
                className="form-select form-select-sm"
              >
                <option value="0">TODO</option>
                <option value="1">PENDIENTES</option>
                <option value="2">CRUZADOS</option>
              </Form.Control>
            </div>
            <div className="col-md-3">
              <Form.Control
                type="text"
                size="sm"
                placeholder="Search..."
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className="col-md-1 right-label">
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
            <div className="col">
              <Button
                size="sm"
                variant="success"
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp; Descarga
              </Button>
            </div>
            <div className="col">
              {UserType === '4' || UserType === '2' ? (
                <Button
                  size="sm"
                  variant="uploadExcel"
                  style={{ textAlign: 'left' }}
                  onClick={() => {
                    uploadExcel()
                  }}
                >
                  <IconContext.Provider
                    value={{ color: '#A7DDD1', size: '15px' }}
                  >
                    <BsCloudArrowUpFill />
                  </IconContext.Provider>
                </Button>
              ) : (
                ''
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {UserType === '5' || UserType === '2' ? (
                <Button
                  size="sm"
                  variant="uploadExcelCorresponsal"
                  style={{ textAlign: 'right' }}
                  onClick={() => {
                    uploadExcelCorresponsal()
                  }}
                >
                  <IconContext.Provider
                    value={{ color: '#A7DDD1', size: '15px' }}
                  >
                    <BsCloudArrowUpFill />
                  </IconContext.Provider>{' '}
                </Button>
              ) : (
                ''
              )}
            </div>
            <div className="col">
              {UserType === '2' ? (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setModalTmpPDF(true)
                  }}
                >
                  <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                    <FiFileText />
                  </IconContext.Provider>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className="ag-theme-alpine" style={{ height: 500, width: '100%' }}>
        {
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            striped={true}
            dense={true}
            paginationPerPage={10}
            pagination
            highlightOnHover
            columns={columnsConcepts}
            data={Data}
            conditionalRowStyles={conditionalRowStyles}
            pointerOnHover
          />
        }
      </div>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
      <Modal
        show={ShowModal}
        /*   onHide={() => setShowModal(false)} */
        size="sm"
        dialogClassName="modal-90w modal-innerDiv"
        disableEscapeKeyDown={true}
      >
        <Modal.Body>
          <div></div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalFileManager}
        onHide={() => setModalFileManager(false)}
        size="lg"
      >
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={5}
            IdFile={CurrentTime2Number}
            IDUser={UserId}
            FileName={''}
            canDelete={false}
            FileType={['xlsx', 'xls']}
            Leyenda={
              'Estimado cliente: CASA CUERVO, favor de seleccionar el archivo excel, arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...'
            }
            onAppendFM={function (idFile: number): void {
              uploadFile(idFile, 5)
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button
                variant="secondary"
                onClick={() => setModalFileManager(false)}
                size="sm"
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalFileManagerCorresponsal}
        onHide={() => setModalFileManagerCorresponsal(false)}
        size="lg"
      >
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={6}
            IdFile={CurrentTime2Number}
            IDUser={UserId}
            FileName={''}
            canDelete={false}
            FileType={['xlsx', 'xls']}
            Leyenda={
              'Estimado Sr. Corresponsal, favor de eleccionar el archivo excel, arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...'
            }
            onAppendFM={function (idFile: number): void {
              uploadFile(idFile, 6)
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button
                variant="secondary"
                onClick={() => setModalFileManagerCorresponsal(false)}
                size="sm"
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal show={modalEdit} onHide={() => setModalEdit(false)} size="lg">
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Alert variant="primary">
                <h5>
                  Favor de proporcionar la siguiente informacion para
                  complementar el reporte <br />
                </h5>
                <Row>
                  <Col xs={3}>PO: {PO}</Col>
                  <Col xs={9} style={{ textAlign: 'right' }}>
                    Carrier: {Carrier}
                  </Col>
                </Row>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>Comentarios GEMCO</Col>
            <Col xs={10}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={ComentarioGEMCO}
                  onChange={(e) => {
                    setComentarioGEMCO(e.target.value)
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={3} style={{ textAlign: 'right' }}>
              Fecha compromiso
            </Col>
            <Col xs={3}>
              <DatePicker
                selected={fechaCompromiso}
                valueDefault={fechaCompromiso}
                onChange={(date: any) => setFechaCompromiso(date)}
                dateFormat="MM/dd/yyyy"
                isClearable
                placeholderText="No definido"
              />
            </Col>
            <Col xs={2} style={{ textAlign: 'right' }}>
              Fecha cruce
            </Col>
            <Col xs={4}>
              <DatePicker
                selected={fechaCruce}
                onChange={(date: any) => setFechaCruce(date)}
                showTimeSelect
                timeClassName={handleColor}
                dateFormat="MM/dd/yyyy h:mm aa"
                placeholderText="No definido"
                isClearable
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: '10px' }}>
            <Col xs={2}>Medida caja</Col>
            <Col xs={3}>
              <Form.Control
                as="select"
                value={MedidaCaja}
                onChange={(e) => {
                  setMedidaCaja(e.target.value)
                }}
                className="form-select form-select-sm"
              >
                {mCatMedidas
                  ? mCatMedidas.map((a) => {
                      return (
                        <option value={a.id} key={a.id}>
                          {a.Medida}
                        </option>
                      )
                    })
                  : null}
              </Form.Control>
            </Col>
            <Col xs={2}></Col>
            <Col xs={2}>No Factura</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={Factura}
                onChange={(e) => {
                  setFactura(e.target.value)
                }}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: '10px' }}>
            <Col xs={2}>Sello1</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={Sello1}
                onChange={(e) => {
                  setSello1(e.target.value)
                }}
              />
            </Col>
            <Col xs={2}></Col>
            <Col xs={2}>Sello2</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={Sello2}
                onChange={(e) => {
                  setSello2(e.target.value)
                }}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: '10px' }}>
            <Col xs={2}>Pedimento</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={Pedimento}
                onChange={(e) => {
                  setPedimento(e.target.value)
                }}
              />
            </Col>
            <Col xs={2}></Col>
            <Col xs={2}>Trafico</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={Trafico}
                onChange={(e) => {
                  setTrafico(e.target.value)
                }}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: '10px' }}>
            <Col xs={1}>UUID</Col>
            <Col xs={5}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={UUID}
                onChange={(e) => {
                  setUUID(e.target.value)
                }}
              />
            </Col>
            <Col xs={1}></Col>

            <Col xs={2}>Patente</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={Patente}
                onChange={(e) => {
                  setPatente(e.target.value)
                }}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: '30px' }}>
            <Col xs={3} style={{ textAlign: 'left' }}>
              <Button variant="warning" onClick={() => saveInfo(2)} size="sm">
                Guardar e imprimir
              </Button>
            </Col>
            <Col xs={7}>&nbsp;</Col>
            <Col xs={2} style={{ textAlign: 'right' }}>
              <Button variant="primary" onClick={() => saveInfo(1)} size="sm">
                Guardar
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal show={ModalTmpPDF} onHide={() => setModalTmpPDF(false)} size="lg">
        <Modal.Body>
          <Row style={{ paddingTop: '10px' }}>
            <Col xs={2}>No Pedido</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={NoPedido}
                onChange={(e) => {
                  setNoPedido(e.target.value)
                }}
              />
            </Col>
            <Col xs={2}></Col>
            <Col xs={2}>No Caja</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={NoCaja}
                onChange={(e) => {
                  setNoCaja(e.target.value)
                }}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: '10px' }}>
            <Col xs={2}>Medida caja</Col>
            <Col xs={3}>
              <Form.Control
                as="select"
                value={MedidaCaja}
                onChange={(e) => {
                  setMedidaCaja(e.target.value)
                }}
                className="form-select form-select-sm"
              >
                {mCatMedidas
                  ? mCatMedidas.map((a) => {
                      return (
                        <option value={a.id} key={a.id}>
                          {a.Medida}
                        </option>
                      )
                    })
                  : null}
              </Form.Control>
            </Col>
            <Col xs={2}></Col>
            <Col xs={2}>No Factura</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={Factura}
                onChange={(e) => {
                  setFactura(e.target.value)
                }}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: '10px' }}>
            <Col xs={2}>Sello1</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={Sello1}
                onChange={(e) => {
                  setSello1(e.target.value)
                }}
              />
            </Col>
            <Col xs={2}></Col>
            <Col xs={2}>Sello2</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={Sello2}
                onChange={(e) => {
                  setSello2(e.target.value)
                }}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: '10px' }}>
            <Col xs={2} style={{ textAlign: 'left' }}>
              Carrier
            </Col>
            <Col xs={7}>
              <Form.Control
                type="text"
                size="sm"
                placeholder=""
                value={Carrier}
                onChange={(e) => {
                  setCarrier(e.target.value)
                }}
              />
            </Col>
            <Col xs={3} style={{ textAlign: 'right' }}>
              <Button
                variant="warning"
                onClick={() => generateTempPDF()}
                size="sm"
              >
                Imprimir
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}

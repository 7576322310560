import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Col,
  FormCheck,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsChevronDown, BsChevronRight } from 'react-icons/bs'
import I2096Headers from '../../../Interfaces/I2096Header'
import DSAmazon from '../../../Services/Amazon.Services'
import { MsgInformativo } from '../../../../../Utils/Toast/msgInformativo'
import { RootState } from '../../../../../../store/store'
import {
  initializeInvoice,
  populateInvoices,
  updateInvoice
} from '../../../../../../store/features/Clientes/2096/AmazonInvoices'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import RtpAmazonPendingTraficInvoiceDetail from './RptAmazonPendingTraficInvoiceDetail'
import { FaAmazon } from 'react-icons/fa'

export interface IRptAmazonPendingTraficInvoiceProps {}

export default function RptAmazonPendingTraficInvoice(
  props: IRptAmazonPendingTraficInvoiceProps
) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [header, setHeader] = useState('')
  const [IDFactura, setIDFactura] = useState(0)
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [msg, setMsg] = useState('')
  const msgColor = 'primary'

  const loadReport = () => {
    DSAmazon.AmazonPendingTraficInvoiceGET()
      .then((response) => {
        dispatch(initializeInvoice([]))
        dispatch(populateInvoices(response.data))
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  useEffect(() => {
    loadReport()
  }, [])

  const InvoiceToggle = (row: I2096Headers) => {
    let Invoice: I2096Headers[] = mInvoices.filter((item) => item.id === row.id)
    const Obj = { ...Invoice[0] }
    Obj.max = !Obj.max
    dispatch(updateInvoice(Obj))
  }

  const finishInvoice = (id: number) => {
    DSAmazon.SePuedeTerminarXTraficoFactura(id)
      .then((response) => {
        if (response.data.respuesta.includes('lista')) {
          DSAmazon.TerminaXTraficoFactura(id)
            .then((response) => {
              loadReport()
            })
            .catch((e: Error) => {})
          setShowModal(false)
        } else {
          setIDFactura(0)
          setHeader('Informativo')
          setMsg(response.data.respuesta)
          setShowMsg(true)
          return
        }
      })
      .catch((e: Error) => {
        setIDFactura(0)
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  function getReceiver(Cadena: string, char1: string[1], char2: string[1]) {
    return Cadena.slice(
      Cadena.indexOf(char1) + 1,
      Cadena.lastIndexOf(char2),
    );
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={4}>
              <Card.Title>
                <IconContext.Provider value={{ color: '#F6C34F', size: '8%' }}>
                  <FaAmazon />
                </IconContext.Provider>{' '}
                TRAFICO : facturas pendientes
              </Card.Title>
            </Col>
            <Col xs={4}></Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}></Col>
        <Col xs={2} className="totalLabel"></Col>
      </Row>
      <Card>
        <Card.Body>
          <div className="MDcontainer">
            <Table className="MDTable" hover>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr>
                  <th></th>
                  <th style={{ width: '50px' }}>id</th>
                  <th style={{ width: '50px' }}></th>
                  <th style={{ width: '80px' }}>Commercial Invoice Date</th>
                  <th style={{ width: '150px' }}>Invoice Number</th>
                  <th style={{ width: '50px' }}>Receiver</th>
                  <th style={{ width: '100px' }}>Incoterms</th>
                  <th>Qty Unit of measure</th>
                  <th>Tot Qty</th>
                  <th>Weight unit of measure</th>
                  <th>Tot weight</th>
                  <th>Tot Charge or allowance</th>
                  <th>Tot currency ISO code</th>
                  <th>Tot Monetary amount</th>
                  <th style={{ width: '500px' }}>Total de partidas</th>
                </tr>
              </thead>
              <tbody>
                {mInvoices
                  ? mInvoices.map((MasterData) => {
                      return (
                        <>
                          <tr
                            style={{ cursor: 'pointer' }}
                            className={
                              MasterData.max === true
                                ? 'masterSelected'
                                : 'normalSelected'
                            }
                          >
                            <td
                              style={{ textAlign: 'left' }}
                              className={
                                MasterData.max === true
                                  ? 'masterSelected'
                                  : 'normalSelected'
                              }
                              key={MasterData.id}
                              onClick={() => {
                                InvoiceToggle(MasterData)
                              }}
                            >
                              {MasterData.max === true ? (
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '15px' }}
                                >
                                  <BsChevronDown />
                                </IconContext.Provider>
                              ) : (
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '15px' }}
                                >
                                  <BsChevronRight />
                                </IconContext.Provider>
                              )}
                            </td>
                            <td>{MasterData.id}</td>
                            <td>
                              <FormCheck
                                className="form-check form-switch form-control-sm"
                                id="switchEnabled"
                                type="switch"
                                checked={false}
                                onChange={() => {
                                  /* facturaTerminada(MasterData) */
                                  setIDFactura(MasterData.id)
                                  setShowModal(true)
                                }}
                                label=""
                              />
                            </td>
                            <td style={{ width: '80px', textAlign: 'center' }}>
                              {MasterData.commercialInvoiceDate}
                            </td>
                            <td style={{ width: '150px' }}>
                              {MasterData.invoiceNumber}
                            </td>
                            <td style={{ width: '100px', textAlign: 'center', fontWeight: 'bold', fontSize: '13px' }}>
                              {mInvoices
                                .filter((value) => value.id === MasterData.id)
                                .map((row) => {
                                  return getReceiver(row.detail[0].amazonShipmentReferenceId,'_','_')
                                })}
                            </td>
                            <td style={{ width: '100px', textAlign: 'center' }}>
                              {MasterData.incoterms}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantityUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantity}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeightUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeight}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueChargeOrAllowance}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueCurrencyISOCode}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueMonetaryAmount}
                            </td>
                            <td
                              style={{
                                fontWeight: 'bold',
                                textAlign: 'center'
                              }}
                            >
                              {mInvoices
                                .filter((value) => value.id === MasterData.id)
                                .map((row) => {
                                  return row.detail.length
                                })}
                            </td>
                          </tr>
                          {MasterData.max ? (
                            <tr
                              className={
                                MasterData.max === true
                                  ? 'masterSelected2'
                                  : 'normalSelected2'
                              }
                            >
                              <th colSpan={15}>
                                <table
                                  className="childTable"
                                  style={{ width: '1450px' }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          width: '50px',
                                          backgroundColor: '#FFFFFF',
                                          color: '#346288'
                                        }}
                                      ></th>
                                      <th
                                        style={{
                                          width: '100px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Item Id
                                      </th>
                                      <th
                                        style={{
                                          width: '400px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Item Description
                                      </th>
                                      <th
                                        style={{
                                          width: '120px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Fraccion GEMCO
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Descripcion GEMCO
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Country of Origin
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        ProductGroup
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Brand
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Model
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Measure
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Qty Shipped
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit of measure
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Net Weight
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Cost
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Total value
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Sideline
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Comentarios
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {mInvoices.map((MD) => {
                                      return (
                                        <>
                                          {MD.detail
                                            .filter(
                                              (detail) =>
                                                MasterData.id ===
                                                detail.idHeader
                                            )
                                            .map((detail) => {
                                              return (
                                                <RtpAmazonPendingTraficInvoiceDetail
                                                  IDMaster={MasterData.id}
                                                  detail={detail}
                                                  Habilitado={
                                                    MasterData.estatus <= 1
                                                  }
                                                />
                                              )
                                            })}
                                        </>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          ) : null}
                        </>
                      )
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '180px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={1}></Col>
              <Col xs={10}>
                <Alert key={'danger'} variant={'danger'}>
                  La factura esta lista para terminarse, desea terminarla?
                </Alert>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  &nbsp;&nbsp;No&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    finishInvoice(IDFactura)
                  }}
                >
                  &nbsp;&nbsp;Si&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

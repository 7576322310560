import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import ICatFraccionesVulnerables from '../../../Interfaces/Catalogos/ICatFraccionesVulnerables'
import FraccionesVulnerables from '../../../Services/Catalogos/FraccionesVulnerables'
import { useDispatch, useSelector } from 'react-redux'
import { BsFileEarmarkExcel, BsFillPencilFill, BsSearch } from 'react-icons/bs'
import DataTable from 'react-data-table-component'
import * as XLSX from 'xlsx'
import { FaEraser } from 'react-icons/fa'
import { FormControl } from 'react-bootstrap'
import { AiFillCloseCircle } from 'react-icons/ai'
import { AiOutlineSave } from 'react-icons/ai'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'

// import { kMaxLength } from 'buffer'
// import { isNull, set } from 'cypress/types/lodash'
// import { number } from 'yup'
// import { parse } from 'path'

// Define types for the component props and table row data
interface IProps {}

export default function CatFraccionesVulnerables(props: IProps) {
  const [UserType, setUserType] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserType')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const dispatch = useDispatch()
  const [Data, setData] = useState<ICatFraccionesVulnerables[]>([])

  const [tipoReporte, setTipoReporte] = useState(0)
  // const [filtro, setFiltro] = useState('')
  const [DataOriginal, setDataOriginal] = useState<ICatFraccionesVulnerables[]>(
    []
  )
  // const mFraccionesVulnerables = useSelector((state: RootState) => state.CatFraccionesVulnerables.CatFraccionesVulnerables);
  const [header, setHeader] = useState<string>('') // Specify the type of 'header'
  // const [msgColor, setMsgColor] = useState<string>('primary'); // Specify the type of 'msgColor'
  const [show, setShowMsg] = useState(false) // Specify the type of 'show'
  const [msg, setMsg] = useState<string>('') // Specify the type of 'msg'
  const [id, setId] = useState<number>(0) // Specify the type of 'id'
  const [Fraccion, setFraccion] = useState<number>(0) // Specify the type of 'Fraccion'
  const [Nico, setNico] = useState<string>('0') // Specify the type of 'Nico'
  const [Activo, setActivo] = useState<number>(0) // Specify the type of 'Activo'
  const [showModal, setShowModal] = useState<boolean>(false)
  const [editMode, setEditMode] = useState(false) // Nuevo estado para controlar el modo de edición
  const [selectedRow, setSelectedRow] =
    useState<ICatFraccionesVulnerables | null>(null) // Nuevo estado para almacenar la fila seleccionada
  // const [fraccionesVulnerables, setFraccionesVulnerables] = useState([]);
  const [modalFileManager, setModalFileManager] = useState(false)
  const msgColor = 'primary'

  const columnsFraccionesVulnerables = [
    {
      name: 'Id',
      width: '14%',
      selector: (row: ICatFraccionesVulnerables) => row.id,
      sortable: true
    },
    {
      name: 'Fraccion',
      width: '20%',

      selector: (row: ICatFraccionesVulnerables) => row.fraccion,
      sortable: true
    },
    {
      name: 'Nico',
      width: '15%',

      selector: (row: ICatFraccionesVulnerables) => row.nico,
      sortable: true
    },
    {
      name: 'Activo',
      width: '15%',
      selector: (row: ICatFraccionesVulnerables) =>
        row.activo === 1 ? 'Sí' : 'No',
      sortable: true
    },
    {
      name: 'Edita',
      width: '15%',

      cell: (row: ICatFraccionesVulnerables) => (
        <Button
          size="sm"
          variant="light"
          style={{
            textAlign: 'right',
            visibility: UserType === '4' ? 'hidden' : 'visible'
          }}
          onClick={() => loadRow(row)} // Cambia esta línea para pasar el objeto 'row' a la función 'loadRow'
        >
          <IconContext.Provider value={{ color: 'blue', size: '20px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>{' '}
        </Button>
      )
    },
    {
      name: 'Elimina',
      width: '15%',
      cell: (row: ICatFraccionesVulnerables) => (
        <Button
          size="sm"
          variant="light"
          style={{
            textAlign: 'right',
            visibility: UserType === '4' ? 'hidden' : 'visible'
          }}
          onClick={() => deleteRow(row.id)} // Llama a la función deleteRow pasando el ID de la fila
        >
          <IconContext.Provider value={{ color: 'red', size: '20px' }}>
            <AiFillCloseCircle />
          </IconContext.Provider>{' '}
        </Button>
      )
    }
  ]

  const loadRow = (data: ICatFraccionesVulnerables): void => {
    setSelectedRow(data) // Al hacer clic en el botón de edición, establecemos la fila seleccionada en el estado
    setEditMode(true) // Cambiamos a modo de edición
    setId(data.id) // Establecemos el id de la fila seleccionada
    setFraccion(data.fraccion) // Cargamos los valores de fracción y nico para edición
    setNico(data.nico)
  }

  // const truncatedValue = inputValue.substring(0, 8)
  // setFraccion(truncatedValue)

  const generaReporte = () => {
    setTipoReporte(0)
    FraccionesVulnerables.getAll()
      .then((response) => {
        setData(response.data)
        setDataOriginal(response.data)
        // uploadAllRows(response.data)

        setHeader('Informativo')
        setMsg('Se encontro la siguiente informacion...')
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const uploadAllRows = (data: ICatFraccionesVulnerables[]) => {
    // Iteramos sobre los datos y realizamos la petición para cada fila
    data.forEach((row) => {
      uploadFile(row)
    })
  }

  useEffect(() => {
    // console.log(id) // Esto imprimirá el valor actualizado de id después de que cambie
  }, [id])

  const handleCreateNewRecord = () => {
    // console.log('se han borrod los texbox ')

    setSelectedRow(null)
    setFraccion(0)
    setNico('0')
    setActivo(0) // Establecer a 0 (No)
    setEditMode(true)
    setId(0)
    setShowModal(false)
  }

  function deleteRow(id: number): void {
    FraccionesVulnerables.Delete(id)
      .then((response) => {
        setData((prevData) =>
          prevData.map((item) =>
            item.id === id ? { ...item, activo: 0 } : item
          )
        )

        console.log(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrió un error: ' + e)

        return
      })
  }

  const uploadFile = (data: ICatFraccionesVulnerables): Promise<any> => {
    return new Promise((resolve, reject) => {
      FraccionesVulnerables.Append(data)
        .then((response) => {
          // console.log(response.data) // Mensaje de éxito
          resolve(response.data)
        })
        .catch((e: Error) => {
          console.error('Error durante la inserción:', e) // Mensaje de error
          reject(e)
        })
    })
  }
  //Aqui agregamos la validacion de los digitos de las fracciones -------------------------------------------------------
  const handleFraccionKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const fraccionAsString = Fraccion.toString()
    if (e.key.length === 1 && fraccionAsString.length >= 8) {
      e.preventDefault() // Evitar agregar más caracteres si ya hay 8
    }
  }

  const handleFraccionBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const fraccionAsString = Fraccion.toString()
    if (fraccionAsString.length !== 8) {
      console.log('La fracción debe tener 8 dígitos.')

      e.currentTarget.focus() // Mantener el foco en el campo
    }
  }

  // aqui agregamos las validaciones de los digitos del nico---------------------------------------------------------

  const handleNicoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.length <= 2) {
      setNico(value)
    }
  }

  const handleNicoKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key.length === 1 && Nico.length >= 2) {
      e.preventDefault() // Evitar agregar más caracteres si ya hay 2
    }
  }

  const handleNicoBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (Nico.length !== 2) {
      console.log('El Nico debe tener 2 dígitos.')
      e.currentTarget.focus() // Mantener el foco en el campo
    }
  }

  // Guardar --------------------------------------------------------------

  const handleSaveChanges = async () => {
    if (id === 0) {
      const fraccionAsString = Fraccion.toString()

      if (fraccionAsString.length !== 8 || Nico.length !== 2) {
        setHeader('Error')

        if (fraccionAsString.length !== 8 && Nico.length !== 2) {
          setMsg(
            'La fraccion debe tener 8 dígitos y el nico debe tener 2 dígitos.'
          )
        } else if (fraccionAsString.length !== 8) {
          setMsg('La fracción debe tener 8 dígitos.')
        } else {
          setMsg('El nico debe tener 2 dígitos.')
        }

        setShowMsg(true)
        return // Esto podría ser necesario para evitar una ejecución adicional
      }

      const newRecord = {
        id: id,
        fraccion: Fraccion,
        nico: Nico,
        activo: Activo
      }

      try {
        const response = await uploadFile(newRecord)
        const newId = response.id

        // Actualizar el estado local con el nuevo registro que incluye el nuevo ID
        const updatedRecord = { ...newRecord, id: newId }
        setData((prevData) => [...prevData, updatedRecord])

        // console.log('este el newId' + newId)
        // console.log('este el id normal ' + id)

        // Restablecer valores de los campos
        handleCreateNewRecord()
      } catch (error) {
        console.error('Error durante la inserción:', error)
      }
    } else {
      // Aquí actualizamos el registro existente

      const fraccionAsString = Fraccion.toString()

      if (fraccionAsString.length !== 8) {
        setHeader('Error')
        setMsg('La fraccion debe tener 8 digitos.')
        setShowMsg(true)
        return
      }

      if (Nico.length !== 2) {
        setHeader('Error')
        setMsg('El Nico debe tener 2 digitos.')
        setShowMsg(true)
        return
      }

      const updatedData = Data.map((row) =>
        row.id === selectedRow?.id
          ? { ...row, fraccion: Fraccion, nico: Nico, activo: Activo }
          : row
      )

      setData(updatedData)
      const updatedRow = updatedData.find((row) => row.id === selectedRow?.id)
      if (updatedRow) {
        uploadFile(updatedRow) // Llamada a uploadFile para actualizar el registro
      }
      setEditMode(false)
      setShowModal(false)
    }
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Fracciones Vulnerables')
  }

  function exportExcel(
    jsonData: ICatFraccionesVulnerables[],
    fileName: string
  ): void {
    let Heading = [['Id', 'Fraccion', 'Nico', 'Activo']]
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)

    // Transformar los valores de 'activo' en 'Si' o 'No' antes de agregarlos a la hoja de cálculo
    const transformedData = jsonData.map((row) => ({
      ...row,
      activo: row.activo === 1 ? 'Si' : 'No'
    }))

    XLSX.utils.sheet_add_json(ws, transformedData, {
      origin: 'A2',
      skipHeader: true
    })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')

    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1'
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const handleFraccionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value)
    setFraccion(isNaN(value) ? 0 : value)
  }

  // Función para manejar el cambio en el campo de entrada de la fracción

  return (
    <div className="mt-2">
      <Card>
        <Card.Body>
          <div className="container">
            <div
              className="row mb-4   "
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '1vh'
              }}
            >
              <Card
                style={{
                  paddingTop: '10px',
                  paddingBottom: '5px',
                  width: '900px',
                  height: '50px'
                }}
              >
                <Row>
                  <Col xs={12}></Col>
                  <Col xs={12}>
                    <Row className="align-items-center">
                      <Col xs={1}>
                        <Form.Label className="mb-0">Fracción</Form.Label>
                      </Col>
                      <Col xs={1}>
                        <FormControl
                          type="text"
                          value={Fraccion}
                          onChange={handleFraccionChange}
                          onKeyDown={handleFraccionKeyDown}
                          onBlur={handleFraccionBlur}
                          inputMode="numeric"
                          className="orm-select mx-2"
                          maxLength={8}
                          minLength={8}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            padding: '4px',
                            width: '80px',
                            height: '30px'
                          }}
                        />
                      </Col>
                      <Col
                        xs={1}
                        style={{ textAlign: 'right', width: '100px' }}
                      >
                        <Form.Label className="mb-0  ">Nico</Form.Label>
                      </Col>
                      <Col xs={1}>
                        <FormControl
                          type="text"
                          value={Nico}
                          // onChange={(e) => setNico(e.target.value)}
                          onChange={handleNicoChange}
                          onKeyDown={handleNicoKeyDown}
                          onBlur={handleNicoBlur}
                          // inputMode="numeric"
                          className="orm-select mx-2"
                          maxLength={2}
                          minLength={2}
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            padding: '4px',
                            width: '30px',
                            height: '30px'
                          }}
                        />
                      </Col>
                      <Col xs={2} style={{ textAlign: 'right', width: '50px' }}>
                        <Form.Label className="mb-0">Activo</Form.Label>
                      </Col>
                      <Col xs={2}>
                        <Form.Select
                          value={Activo}
                          onChange={(e) => setActivo(Number(e.target.value))}
                          className="form-select mx-2"
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            padding: '4px',
                            width: '60px',
                            height: '30px'
                          }}
                        >
                          <option value={1}>Si</option>
                          <option value={0}>No</option>
                        </Form.Select>
                      </Col>
                      <Col xs={0} style={{ width: '100px' }}>
                        <Button
                          size="sm"
                          variant="light"
                          onClick={handleCreateNewRecord}
                          style={{
                            backgroundColor: 'orange',
                            color: 'white',
                            padding: '4px',
                            width: '50px',
                            height: '32px'
                          }}
                        >
                          <FaEraser />
                        </Button>
                      </Col>

                      <Col xs={0} style={{ width: '110px' }}>
                        <Button
                          style={{
                            backgroundColor: 'blue',
                            color: 'white',
                            padding: '2px',
                            width: '55px',
                            height: '30px'
                          }}
                          variant="primary"
                          size="sm"
                          onClick={() => {
                            generaReporte()
                          }}
                        >
                          <BsSearch />
                        </Button>
                      </Col>
                      <Col xs={0} style={{ width: '110px' }}>
                        <Button
                          style={{
                            backgroundColor: 'blue',
                            color: 'white',
                            padding: '0px',
                            width: '55px',
                            height: '30px'
                          }}
                          variant="primary"
                          onClick={handleSaveChanges}
                        >
                          <AiOutlineSave />
                        </Button>
                      </Col>
                      <Col xs={0} style={{ width: '110px' }}>
                        <Button
                          size="sm"
                          variant="success"
                          onClick={() => {
                            downloadExcel()
                          }}
                          style={{
                            backgroundColor: 'green',
                            color: 'white',
                            padding: '1px',
                            width: '55px',
                            height: '30px'
                          }}
                        >
                          <BsFileEarmarkExcel />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={1}></Col>
                </Row>
              </Card>
            </div>
            <Row>
              <Col xs={3}></Col>
              <Col xs={6}>
                <Card>
                  <DataTable
                    noHeader
                    defaultSortFieldId={'id'}
                    defaultSortAsc={true}
                    striped={true}
                    dense={true}
                    paginationPerPage={10}
                    pagination
                    highlightOnHover
                    columns={columnsFraccionesVulnerables}
                    data={Data}
                    pointerOnHover
                  />
                </Card>
              </Col>
              <Col xs={1}></Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

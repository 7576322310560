import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  FormCheck,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsChevronDown, BsChevronRight } from 'react-icons/bs'
import { FaAmazon, FaFileExcel } from 'react-icons/fa'
import I2096Headers from '../../../Interfaces/I2096Header'
import DSAmazon from '../../../Services/Amazon.Services'
import { MsgInformativo } from '../../../../../Utils/Toast/msgInformativo'
import { RootState } from '../../../../../../store/store'
import {
  initializeInvoice,
  populateInvoices,
  updateInvoice
} from '../../../../../../store/features/Clientes/2096/AmazonInvoices'
import loadingImg from '../../../../../../images/ajaxloader.gif'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import RtpPendingAnswerFromAmazonDetail from './RptPendingAnswerFromAmazonDetail'
import { AiFillFileUnknown, AiTwotoneFileExcel } from 'react-icons/ai'
import DTO2096PerfilesParecidos from '../../../DTO/DTO2096PerfilesParecidos'
import { MFileManager } from '../../../../../Utils/MFileManager/MFileManager'
import { setEmitFlags } from 'typescript'
import DTO2096RELParteFactura from '../../../DTO/DTO2096RELParteFactura'
import IFileManager from '../../../../../../Interfaces/Utils/IFileManager'
import { populateCatConcetosConsultas } from '../../../../../../store/features/Clientes/2096/AmazonCatConceptosConsulta'
export interface IRptPendingAnswerFromAmazonProps {}

export default function RptPendingAnswerFromAmazon(
  props: IRptPendingAnswerFromAmazonProps
) {
  const mExceptionCode = useSelector(
    (state: RootState) => state.AmazonCatConceptosConsulta.CatConcetosConsulta
  )
  const [Usuario, setUsuario] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Data, setData] = useState<DTO2096PerfilesParecidos[]>([])
  const [ResumeData, setResumeData] = useState<DTO2096RELParteFactura[]>([])
  const [AmazonAnswerFile, setAmazonAnswerFile] = useState<IFileManager[]>([])
  const [DistinctExceptionCode, setDistinctExceptionCode] = useState<number[]>(
    []
  )
  const [header, setHeader] = useState('')
  const [IDFactura, setIDFactura] = useState(0)
  const [Factura, setFactura] = useState('')
  const [IDProcess, setIDProcess] = useState(25)
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [WaitingDialog, setWaitingDialog] = useState(false)
  const [WaitingMessage, setWaitingMessage] = useState('Generando...')
  const [ShowModalAnswerFile, setShowModalAnswerFile] = useState(false)
  const [ConfirmApplyAnswerFile, setConfirmApplyAnswerFile] = useState(false)
  const [msg, setMsg] = useState('')
  const msgColor = 'primary'

  const loadReport = () => {
    DSAmazon.InvoicePendingFromAmazonGET()
      .then((response) => {
        dispatch(initializeInvoice([]))
        dispatch(populateInvoices(response.data))
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const loadProfiles = () => {
    DSAmazon.PerfilesParecidosGet('Clasificador')
      .then((response) => {
        setData(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const loadDistinctExceptionCode = (id: number) => {
    DSAmazon.InvoiceDistinctExceptionCodeGet(id)
      .then((response) => {
        setDistinctExceptionCode(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const getExceptionCode = (id: number) => {
    // console.log(mExceptionCode)
    let code = mExceptionCode.filter((a) => {
      if (a.id == id) {
        return a
      }
    })
    return code.length ? code[0].siglas : ''
  }

  const loadExceptionCodeCatalog = () => {
    DSAmazon.CatConceptosConsultaGET()
      .then((response) => {
        dispatch(populateCatConcetosConsultas(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const generateResume = (id: number) => {
    DSAmazon.RELParteFacturasGET(id)
      .then((response) => {
        setResumeData(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const downloadExceptionsFile = (
    id: number,
    Factura: string,
    ExceptionCode: number
  ) => {
    //setWaitingDialog(true)
    /* loadDistinctExceptionCode(id)
    generateResume(id) */
    //setFactura(Factura)
    DSAmazon.ExceptionsFileAmazonGET(id, ExceptionCode)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'Excepciones ' +
              Factura +
              '_' +
              getExceptionCode(ExceptionCode) +
              '.xlsx'
          )
          document.body.appendChild(link)
          link.click()
        }
        setWaitingMessage('')
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const loadInvoiceData = (id: number) => {
    setWaitingDialog(true)
    loadDistinctExceptionCode(id)
    generateResume(id)
  }

  useEffect(() => {
    loadReport()
    loadProfiles()
    loadExceptionCodeCatalog()
  }, [])

  const check4Updates = (IDInvoice: number) => {
    DSAmazon.SETInvoiceDetailRevalidateParts(IDInvoice)
      .then((response) => {
        DSAmazon.AmazonInvoiceGET(IDInvoice)
          .then((response) => {
            //console.log(JSON.stringify(response.data))
            dispatch(updateInvoice(response.data[0]))
            let Invoice: I2096Headers[] = response.data.filter(
              (item) => item.id === IDInvoice
            )
            const Obj = { ...Invoice[0] }
            Obj.max = !Obj.max
            dispatch(updateInvoice(Obj))
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error')
            //setShowModalDeleteInvoice(false)
            return
          })
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        //setShowModalDeleteInvoice(false)
        return
      })
  }

  const toggleExpandRow = (row: I2096Headers) => {
    if (!row.max) check4Updates(row.id)
    let Invoice: I2096Headers[] = mInvoices.filter((item) => item.id === row.id)
    const Obj = { ...Invoice[0] }
    Obj.max = !Obj.max
    dispatch(updateInvoice(Obj))
  }

  const getUser = (Id: number) => {
    const NoAsignado = 'NO ASIGNADO'
    if (Id === 0) return NoAsignado
    else if (Data.length) {
      const Usuario = Data.filter((item) => item.idUsuario === Id)
      return Usuario[0] ? Usuario[0].nombre : NoAsignado
    }
    // return NoAsignado
  }

  const existsExeptions = (IDMaster: number) => {
    const Exceptions = mInvoices
      .filter((element) =>
        element.detail.some((sub) => sub.idHeader === IDMaster)
      )
      .map((element) => {
        let newElt = Object.assign({}, element) // copies element
        return newElt.detail.filter(
          (child) => child.idHeader === IDMaster && child.autorizado
        )
      })

    return Exceptions.length ? Exceptions[0].length : 0
  }

  const TerminadeClasificar = () => {
    DSAmazon.TerminaClasificarFactura(IDFactura)
      .then((response) => {
        loadReport()
        setShowModal(false)
      })
      .catch((e: Error) => {
        setIDFactura(0)
        setHeader('Error')
        setMsg('Ocurrio un error ' + e.toString())
        setShowMsg(true)
        return
      })
  }

  const sePuedeTerminarFactura = (id: number) => {
    setIDFactura(id)
    DSAmazon.SeRecibioRespuestaDeAmazon(id)
      .then((response) => {
        if (response.data.estatus === 0) {
          setHeader('Error')
          setMsg(
            'Verifique que todas las partidas esten checadas por usted y que cuente con el archivo de respuesta'
          )
          setShowMsg(true)
          return
        } else if (response.data.estatus === 1) {
          //TerminadeClasificar()
          setShowModal(true)
        } else {
          setIDFactura(0)
          setHeader('Informativo')
          setMsg(response.data.respuesta)
          setShowMsg(true)
          return
        }
      })
      .catch((e: Error) => {
        setIDFactura(0)
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const ApplyAnswerFiles2Invoice = () => {
    setWaitingMessage('Generando...')
    const Filenames = AmazonAnswerFile.map((element) => element.nombreArchivo)
    setWaitingDialog(true)
    DSAmazon.ApplyAnswerFile2Invoice(Filenames)
      .then((response) => {
        loadReport()
        setAmazonAnswerFile([])
        setWaitingDialog(false)
        setShowModal(false)
      })
      .catch((e: Error) => {
        setAmazonAnswerFile([])
        setIDFactura(0)
        setHeader('Error')
        setMsg('Ocurrio un error ' + e.toString())
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={5}>
              <Card.Title>
                <IconContext.Provider value={{ color: '#F6C34F', size: '8%' }}>
                  <FaAmazon />
                </IconContext.Provider>
                CLASIFICACION : Facturas&nbsp;
                <span
                  style={{
                    fontStyle: 'italic',
                    textDecoration: 'underline',
                    textDecorationColor: 'red'
                  }}
                >
                  con Excepciones
                </span>
              </Card.Title>
            </Col>
            <Col xs={4}></Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}></Col>
        <Col xs={2} className="totalLabel"></Col>
      </Row>
      <Card>
        <Card.Body>
          <div className="MDcontainer">
            <Table className="MDTable" hover>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr>
                  <th style={{ width: '50px' }}></th>
                  <th style={{ width: '50px' }}>id</th>
                  <th style={{ width: '50px' }}></th>
                  <th style={{ width: '50px' }}></th>
                  <th style={{ width: '50px' }}></th>
                  <th style={{ width: '80px' }}>Commercial Invoice Date</th>
                  <th style={{ width: '300px' }}>Invoice Number</th>
                  <th style={{ width: '50px' }}>Receiver</th>
                  <th style={{ width: '100px' }}>Incoterms</th>
                  <th style={{ width: '100px' }}>Qty Unit of measure</th>
                  <th style={{ width: '100px' }}>Tot Qty</th>
                  <th style={{ width: '100px' }}>Weight unit of measure</th>
                  <th style={{ width: '100px' }}>Tot weight</th>
                  <th style={{ width: '100px' }}>Tot Charge or allowance</th>
                  <th style={{ width: '100px' }}>Tot currency ISO code</th>
                  <th style={{ width: '100px' }}>Tot Monetary amount</th>
                  <th style={{ width: '500px' }}>Total de partidas</th>
                  <th style={{ width: '100px' }}>Clasificador</th>
                </tr>
              </thead>
              <tbody>
                {mInvoices
                  ? mInvoices
                      .filter((a) => {
                        if (
                          a.clasificador === parseInt(Usuario) &&
                          Perfil !== 'Clasificador lider'
                        ) {
                          return a
                        } else if (
                          [
                            'Clasificador lider',
                            'Administrador',
                            'Sistemas'
                          ].includes(Perfil)
                        ) {
                          return a
                        }
                      })
                      .map((MasterData) => {
                        return (
                          <>
                            <tr
                              style={{ cursor: 'pointer' }}
                              className={
                                MasterData.max === true
                                  ? 'masterSelected'
                                  : 'normalSelected'
                              }
                            >
                              <td
                                style={{ textAlign: 'left' }}
                                className={
                                  MasterData.max === true
                                    ? 'masterSelected'
                                    : 'normalSelected'
                                }
                                key={MasterData.id}
                                onClick={() => {
                                  toggleExpandRow(MasterData)
                                }}
                              >
                                {MasterData.max === true ? (
                                  <IconContext.Provider
                                    value={{ color: 'blue', size: '15px' }}
                                  >
                                    <BsChevronDown />
                                  </IconContext.Provider>
                                ) : (
                                  <IconContext.Provider
                                    value={{ color: 'blue', size: '15px' }}
                                  >
                                    <BsChevronRight />
                                  </IconContext.Provider>
                                )}
                              </td>
                              <td>{MasterData.id}</td>
                              <td>
                                {existsExeptions(MasterData.id) > 0 ? (
                                  <div
                                    onClick={() => {
                                      /*  downloadExceptionsFile(
                                        MasterData.id,
                                        MasterData.invoiceNumber
                                      ) */
                                      setIDFactura(MasterData.id)
                                      setFactura(MasterData.invoiceNumber)
                                      loadInvoiceData(MasterData.id)
                                      //setWaitingDialog(true)
                                    }}
                                    title="Archivo de excepciones"
                                  >
                                    <IconContext.Provider
                                      value={{ color: 'orange', size: '20px' }}
                                    >
                                      <FaFileExcel />
                                    </IconContext.Provider>
                                  </div>
                                ) : null}
                              </td>
                              <td>
                                <FormCheck
                                  className="form-check form-switch form-control-sm"
                                  id="switchEnabled"
                                  type="switch"
                                  checked={false}
                                  onChange={() => {
                                    sePuedeTerminarFactura(MasterData.id)
                                  }}
                                  label=""
                                />
                              </td>
                              <td>
                                <div
                                  onClick={() => {
                                    setIDFactura(MasterData.id)
                                    setShowModalAnswerFile(!ShowModalAnswerFile)
                                  }}
                                  title="Archivo de respuesta"
                                >
                                  <IconContext.Provider
                                    value={{ color: 'red', size: '23px' }}
                                  >
                                    <AiFillFileUnknown />
                                  </IconContext.Provider>
                                </div>
                              </td>
                              <td
                                style={{ width: '80px', textAlign: 'center' }}
                              >
                                {MasterData.commercialInvoiceDate}
                              </td>
                              <td style={{ width: '150px' }}>
                                {MasterData.invoiceNumber}
                              </td>
                              <td
                                style={{ width: '50px', textAlign: 'center' }}
                              >
                                {MasterData.name}
                              </td>
                              <td
                                style={{ width: '100px', textAlign: 'center' }}
                              >
                                {MasterData.incoterms}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceQuantityUnitOfMeasure}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceQuantity}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceWeightUnitOfMeasure}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceWeight}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceValueChargeOrAllowance}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceValueCurrencyISOCode}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceValueMonetaryAmount}
                              </td>
                              <td
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: 'center'
                                }}
                              >
                                {mInvoices
                                  .filter((value) => value.id === MasterData.id)
                                  .map((row) => {
                                    return row.detail.length
                                  })}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {getUser(MasterData.clasificador)}
                              </td>
                            </tr>
                            {MasterData.max ? (
                              <tr
                                className={
                                  MasterData.max === true
                                    ? 'masterSelected2'
                                    : 'normalSelected2'
                                }
                              >
                                <th colSpan={17}>
                                  <table
                                    className="childTable"
                                    style={{ width: '1450px' }}
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          style={{
                                            width: '50px',
                                            backgroundColor: '#FFFFFF',
                                            color: '#346288'
                                          }}
                                        ></th>
                                        <th
                                          style={{
                                            width: '100px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Item Id
                                        </th>
                                        <th
                                          style={{
                                            width: '400px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Item Description
                                        </th>
                                        <th
                                          style={{
                                            width: '120px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Pedimento Description
                                        </th>
                                        <th
                                          style={{
                                            width: '200px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Destination HTS Code
                                        </th>
                                        <th
                                          style={{
                                            width: '120px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Valida
                                        </th>
                                        <th
                                          style={{
                                            width: '120px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Confirme fraccion
                                        </th>
                                        <th
                                          style={{
                                            width: '120px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Fraccion GEMCO
                                        </th>
                                        <th
                                          style={{
                                            width: '120px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Confirme descripcion
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Descripcion GEMCO
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Country of Origin
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          ProductGroup
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Brand
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Model
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Unit Measure
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Qty Shipped
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Unit Of measure
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Unit Net Weight
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Unit Cost
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Autorizado
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          RN
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Comm
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {mInvoices.map((MD) => {
                                        return (
                                          <>
                                            {MD.detail
                                              .filter(
                                                (detail) =>
                                                  MasterData.id ===
                                                  detail.idHeader
                                              )
                                              .map((detail) => {
                                                return (
                                                  <RtpPendingAnswerFromAmazonDetail
                                                    IDMaster={MasterData.id}
                                                    detail={detail}
                                                    Habilitado={
                                                      MasterData.estatus <= 1
                                                    }
                                                  />
                                                )
                                              })}
                                          </>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            ) : null}
                          </>
                        )
                      })
                  : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>

      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '180px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={1}></Col>
              <Col xs={10}>
                <Alert key={'danger'} variant={'danger'}>
                  La factura esta lista para terminarse, desea terminarla?
                </Alert>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  &nbsp;&nbsp;No&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    //downloadExcel(IDFactura)
                    TerminadeClasificar()
                  }}
                >
                  &nbsp;&nbsp;Si&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ShowModalAnswerFile}
        onHide={() => {
          setShowModalAnswerFile(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '330px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={12}>
                <Alert
                  key={'danger'}
                  variant={'danger'}
                  style={{ textAlign: 'center' }}
                >
                  <h4>Proporcione el archivo de respuesta de Amazon</h4>
                </Alert>
              </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col></Col>
              <Col xs={10} style={{ textAlign: 'center' }}>
                <MFileManager
                  IDTrafico={IDFactura}
                  Proceso={IDProcess}
                  showPreview={3}
                  canEdit={true}
                  Leyenda="Sube archivos de respuesta de Amazon"
                  onAppendMFM={function (Files: IFileManager[]): void {
                    setAmazonAnswerFile(Files)
                    setConfirmApplyAnswerFile(true)
                  }}
                />
              </Col>
              <Col></Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ConfirmApplyAnswerFile}
        onHide={() => {
          setConfirmApplyAnswerFile(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '330px' }}>
            <Row style={{ paddingTop: '50px' }}>
              <Col xs={12}>
                <Alert
                  key={'danger'}
                  variant={'danger'}
                  style={{ textAlign: 'center' }}
                >
                  <h4>Desea aplicar el archivo de respuesta de Amazon?</h4>
                </Alert>
              </Col>
            </Row>
            <Row style={{ paddingTop: '30px' }}>
              <Col xs={12}>&nbsp;</Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={() => {
                    setShowModalAnswerFile(false)
                    setConfirmApplyAnswerFile(false)
                  }}
                >
                  &nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  size="lg"
                  onClick={() => {
                    setShowModalAnswerFile(false)
                    ApplyAnswerFiles2Invoice()
                    setConfirmApplyAnswerFile(false)
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Si&nbsp;&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={WaitingDialog}
        onHide={() => {
          setWaitingDialog(false)
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        dialogClassName={'modal-50w'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*  <img
            src={loadingImg}
            style={{
              height: WaitingMessage === 'Generando...' ? '150px' : '0px',
              width: WaitingMessage === 'Generando...' ? '150px' : '0px'
            }}
            alt="proccessing"
          />
          {WaitingMessage} */}
          <div>
            <Row>
              {DistinctExceptionCode
                ? DistinctExceptionCode.map((code) => {
                    return (
                      <Col style={{ textAlign: 'center' }}>
                        <Badge
                          bg="primary"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            downloadExceptionsFile(IDFactura, Factura, code)
                          }}
                        >
                          <h6>{getExceptionCode(code)}</h6>
                        </Badge>
                      </Col>
                    )
                  })
                : ''}
            </Row>
          </div>
          <div
            style={{ overflow: 'auto', height: '500px', paddingTop: '15px' }}
          >
            <table>
              <tr>
                <td style={{ backgroundColor: '#6095BF', color: '#FFFFFF' }}>
                  Parte
                </td>
                <td style={{ backgroundColor: '#6095BF', color: '#FFFFFF' }}>
                  Lista de facturas
                </td>
              </tr>
              {ResumeData
                ? ResumeData.map((data) => {
                    return (
                      <>
                        <tr>
                          <td>{data.parte}</td>
                          <td>{data.facturas.replaceAll(Factura, '')}</td>
                        </tr>
                      </>
                    )
                  })
                : ''}
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

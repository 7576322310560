import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Form,
  FormCheck,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { MsgInformativo } from '../../../../../Utils/Toast/msgInformativo'
import { ComboDescripcion } from '../../../Components/ComboSelect/ComboDescripcion'
import { ComboFraccion } from '../../../Components/ComboSelect/ComboFraccion'
import I2096Detail from '../../../Interfaces/I2096Detail'
import I2096Headers from '../../../Interfaces/I2096Header'
import DSAmazon from '../../../Services/Amazon.Services'
// Redux
import { RootState } from '../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { updateInvoice } from '../../../../../../store/features/Clientes/2096/AmazonInvoices'
import * as CurrencyFormat from 'react-currency-format'
import { AiOutlineFileSearch } from 'react-icons/ai'
import { BsFillChatRightTextFill } from 'react-icons/bs'
import DTO2096FraccionDescripcion from '../../../DTO/DTO2096FraccionDescripcion'

export interface IRptPendingAnswerFromAmazonDetailProps {
  IDMaster: number
  detail: I2096Detail
  Habilitado: boolean
}

export default function RptPendingAnswerFromAmazonDetail(
  props: IRptPendingAnswerFromAmazonDetailProps
) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [ShowModal, setShowModal] = useState(false)
  const [EstatusSwith, setEstatusSwitch] = useState(props.detail.autorizado)
  const [Fraccion, setFraccion] = useState(props.detail.fraccionGEMCO)
  const [TipoConsulta, setTipoConsulta] = useState(props.detail.tipoConsulta)
  const [UserChanged, setUserChanged] = useState(false)
  const [DescripcionGEMCO, setDescripcionGEMCO] = useState(
    props.detail.descripcionGEMCO
  )
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  //const [Show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [CumplimientoNormativo, setCumplimientoNormativo] = useState(
    props.detail.cumplimientoNormativo ? props.detail.cumplimientoNormativo : ''
  )
  const [Comentarios, setComentarios] = useState(
    props.detail.comentarios
      ? props.detail.comentarios
      : 'Clasificacion Incorrecta'
  )
  const msgColor = 'primary'
  const openLink = (item: string) => {
    window.open('https://www.amazon.com/dp/' + item)
  }

  const UpdateInfo = (Invoice: I2096Headers, Detail: I2096Detail[]) => {
    const updatedInvoice: I2096Headers = {
      id: props.IDMaster,
      referencia: Invoice.referencia,
      commercialInvoiceDate: Invoice.commercialInvoiceDate,
      invoiceNumber: Invoice.invoiceNumber,
      name: Invoice.name,
      trailerNumber: Invoice.trailerNumber,
      trailerId: Invoice.trailerId,
      porOfLoading: Invoice.porOfLoading,
      portOfEntry: Invoice.portOfEntry,
      paymentsTerms: Invoice.paymentsTerms,
      incoterms: Invoice.incoterms,
      hawb: Invoice.hawb,
      totalInvoiceQuantityUnitOfMeasure:
        Invoice.totalInvoiceQuantityUnitOfMeasure,
      totalInvoiceQuantity: Invoice.totalInvoiceQuantity,
      totalInvoiceWeightUnitOfMeasure: Invoice.totalInvoiceWeightUnitOfMeasure,
      totalInvoiceWeight: Invoice.totalInvoiceWeight,
      totalInvoiceValueChargeOrAllowance:
        Invoice.totalInvoiceValueChargeOrAllowance,
      totalInvoiceValueCurrencyISOCode:
        Invoice.totalInvoiceValueCurrencyISOCode,
      totalInvoiceValueMonetaryAmount: Invoice.totalInvoiceValueMonetaryAmount,
      footNote: Invoice.footNote,
      archivo: Invoice.archivo,
      fCreacion: Invoice.fCreacion,
      clasificador: Invoice.clasificador,
      noVuelta: Invoice.noVuelta,
      estatus: Invoice.estatus,
      max: true,
      urgente: Invoice.urgente,
      detail: Detail
    }
    dispatch(updateInvoice(updatedInvoice))
  }

  const editArray = (id: number, valor: boolean) => {
    const Invoice = mInvoices
      .filter((el) => el.id === props.IDMaster)
      .map((el) => {
        return el
      })
    const newDetail = Invoice[0].detail.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          fraccionGEMCO: Fraccion,
          autorizado: valor
        }
      }
      return el
    })
    UpdateInfo(Invoice[0], newDetail)
  }

  const autoriceItem = (id: number) => {
    if (EstatusSwith) {
      if (Comentarios.length === 0) {
        setHeader('Error')
        setMsg(
          'Antes de poder guardar la informacion es necesario que proporcione los comentarios'
        )
        setShowMsg(true)
        return
      }
      if (DescripcionGEMCO.length === 0) {
        setHeader('Error')
        setMsg(
          'Antes de poder guardar la informacion es necesario que proporcione la descripcion GEMCO'
        )
        setShowMsg(true)
        return
      }
      DSAmazon.ValidaFraccionGET(Fraccion)
        .then((response) => {
          if (response.data.respuesta === 'Si') {
            const data: DTO2096FraccionDescripcion = {
              id: id,
              fraccion: Fraccion,
              tipoConsulta: TipoConsulta,
              cumplimientoNormativo: CumplimientoNormativo,
              comentarios: Comentarios,
              descripcionGEMCO: DescripcionGEMCO
            }
            DSAmazon.AutorizaItemFacturaAmazon(data)
              .then((response) => {
                editArray(id, !props.detail.autorizado)
                setShowModal(false)
              })
              .catch((e: Error) => {})
          } else {
            alert(
              'Esa fraccion no existe, favor de verificarla antes de guardarla'
            )
            return
          }
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error' + e.message.toString())
          setShowMsg(true)
          return
        })
    }
  }

  useEffect(() => {
    if (UserChanged && EstatusSwith && !props.detail.fraccionGEMCO)
      autoriceItem(props.detail.id)
  }, [EstatusSwith, UserChanged])

  /* const ColorSET = (value: number, mode: number) => {
    switch (mode) {
      case 1: {
        // Background color
        if (value === 1) return '#FFFAF2'
        else if (value === 2) return '#E5F7B6'
        else if (value === 3) return '#E9F3FC'
        break
      }
      case 2: {
        // Border color
        if (value === 1) return '2px solid #F9A721'
        else if (value === 2) return '2px solid green'
        else if (value === 3) return '2px solid blue'
        break
      }
      case 3: {
        // Color
        if (value === 1) return '#5923F6'
        if (value === 2) return '#000000'
        if (value === 3) return 'blue'
        break
      }
    }
  } */

  return (
    <>
      {props.detail.confirmaFraccion === 2 ? (
        <tr>
          <th
            style={{
              width: '50px',
              backgroundColor: '#FFFFFF'
            }}
            className="DetailData"
          >
            &nbsp;
          </th>
          <td
            style={{
              textAlign: 'center',
              cursor: 'pointer'
            }}
            className="DetailData"
            onClick={() => openLink(props.detail.itemId)}
          >
            {props.detail.itemId}
          </td>
          <td
            style={{
              textAlign: 'right'
            }}
            className="DetailData"
          >
            {props.detail.itemDescription}
          </td>
          <td
            style={{
              textAlign: 'right'
            }}
            className="DetailData"
          >
            {props.detail.pedimentoDescription}
          </td>
          <td
            style={{
              textAlign: 'center',
              fontSize: '18px'
            }}
            className="DetailData"
          >
            &nbsp;&nbsp;{props.detail.destinationHTSCode}
          </td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          ></td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          >
            <ComboFraccion
              IDInvoice={props.IDMaster}
              IDDetail={props.detail.id}
              FraccionOriginal={props.detail.destinationHTSCode}
              ConfirmaFraccion={props.detail.confirmaFraccion}
              Deshabilitado={true}
              Detail={props.detail}
            />
          </td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          >
            <CurrencyFormat
              value={
                props.detail.fraccionGEMCO ? props.detail.fraccionGEMCO : ''
              }
              disabled={true}
              format={'####.##.####'}
              displayType={'input'}
              style={{
                fontSize: '17px',
                backgroundColor:
                  props.detail.validaFraccionOriginal === 0
                    ? '#FFFAF2 '
                    : '#E5F7B6',
                border: '2px solid #000000',
                color: '@000000',
                width: '135px',
                textAlign: 'right',
                borderRadius: '10px'
              }}
            />
          </td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          >
            <ComboDescripcion
              IDInvoice={props.IDMaster}
              IDDetail={props.detail.id}
              DescripcionOriginal={props.detail.pedimentoDescription}
              ConfirmaDescripcion={props.detail.confirmaDescripcion}
              Deshabilitado={false}
              Detail={props.detail}
            />
          </td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          >
            {props.detail.descripcionGEMCO}
          </td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          >
            {props.detail.countryOfOrigin}
          </td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          >
            {props.detail.productGroup}
          </td>
          <td
            style={{
              textAlign: 'right'
            }}
            className="DetailData"
          >
            {props.detail.brand}
          </td>
          <td
            style={{
              textAlign: 'right'
            }}
            className="DetailData"
          >
            {props.detail.model}
          </td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          >
            {props.detail.itemQuantityUnitOfMeasure}
          </td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          >
            {props.detail.quantity}
          </td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          >
            {props.detail.weightValue}
          </td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          >
            {props.detail.netWeightUnitOfMeasure}
          </td>
          <td
            style={{
              textAlign: 'center'
            }}
            className="DetailData"
          >
            {props.detail.actualUnitCostMonetaryAmount}
          </td>
          <td className="DetailData">
            <FormCheck
              className="form-check form-switch form-control-lg"
              id="switchEnabled"
              type="switch"
              disabled={false}
              checked={EstatusSwith && props.detail.autorizado}
              onChange={() => {
                setEstatusSwitch(!EstatusSwith)
                setUserChanged(true)
                setShowModal(!EstatusSwith)
              }}
              label=""
            />
          </td>
          <td>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">{CumplimientoNormativo}</Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <Button variant="light" {...triggerHandler}>
                  <IconContext.Provider
                    value={{
                      color: 'blue',
                      size: '25px'
                    }}
                  >
                    <AiOutlineFileSearch />
                  </IconContext.Provider>
                  <span className="ms-1"></span>
                </Button>
              )}
            </OverlayTrigger>
          </td>
          <td>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip-2">{Comentarios}</Tooltip>}
            >
              {({ ref, ...triggerHandler }) => (
                <Button variant="light" {...triggerHandler}>
                  <IconContext.Provider
                    value={{
                      color: 'green',
                      size: '25px'
                    }}
                  >
                    <BsFillChatRightTextFill />
                  </IconContext.Provider>
                  <span className="ms-1"></span>
                </Button>
              )}
            </OverlayTrigger>
          </td>
        </tr>
      ) : (
        ''
      )}

      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '380px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={12}>
                <Alert key={'danger'} variant={'danger'}>
                  <Row>
                    <Col
                      xs={3}
                      style={{ fontWeight: 'bold', cursor: 'pointer' }}
                      onClick={() => openLink(props.detail.itemId)}
                    >
                      ({props.detail.itemId})
                    </Col>
                    <Col xs={9}>
                      ¿Esta seguro que esta fraccion ya la autorizo Amazon?
                    </Col>
                  </Row>
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>Fraccion GEMCO</Col>
              <Col xs={3}>
                <CurrencyFormat
                  onValueChange={(values: any) => {
                    const { value } = values
                    setFraccion(value)
                  }}
                  format={'####.##.####'}
                  value={Fraccion}
                  displayType={'input'}
                  style={{
                    fontSize: '18px',
                    backgroundColor: '#F1EEF9',
                    border: '2px solid #5923F6',
                    color: '#5923F6',
                    width: '120px',
                    textAlign: 'right',
                    borderRadius: '10px'
                  }}
                />
              </Col>
              <Col xs={6}></Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={3}>Cumplimiento normativo</Col>
              <Col xs={8}>
                {' '}
                <Form.Control
                  as="textarea"
                  id="RequerimientoNormativo"
                  size="sm"
                  value={CumplimientoNormativo}
                  onChange={(e) => setCumplimientoNormativo(e.target.value)}
                />
              </Col>
              <Col>&nbsp;</Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={3}>Comentarios</Col>
              <Col xs={8}>
                {' '}
                <Form.Control
                  as="textarea"
                  id="Comentarios"
                  size="sm"
                  value={Comentarios}
                  onChange={(e) => setComentarios(e.target.value)}
                />
              </Col>
              <Col>&nbsp;</Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={3}>Descripcion GEMCO</Col>
              <Col xs={8}>
                <Form.Control
                  as="textarea"
                  id="PedimentoDescripcion"
                  size="sm"
                  value={DescripcionGEMCO}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setEstatusSwitch(!EstatusSwith)
                    setShowModal(false)
                  }}
                >
                  &nbsp;&nbsp;No&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    autoriceItem(props.detail.id)
                  }}
                >
                  &nbsp;&nbsp;Si&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </>
  )
}

import * as React from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import logo from '../../images/GEMCO.png'
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import AuthDS from '../../Services/Auth/Auth.Service'
import { useState } from 'react'
import { DTOPINUsuario } from '../../DTO/DTOPINUsuario'
import { useNavigate } from 'react-router-dom'

export interface IPINProps {}

export default function PIN(props: IPINProps) {
  const navigate = useNavigate()
  const [User, setUser] = useState(() => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Usuario, setUsuario] = useState(User)
  const [PIN, setPIN] = useState('')
  const [show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = useState('primary')

  const validatePIN = () => {
    if (!PIN.length) {
      setShow(true)
      setMsg('El campo PIN no puede estar vacio')
      setHeader('Error')
      return false
    }
    const data: DTOPINUsuario = {
      pin: parseInt(PIN),
      usuario: Usuario
    }
    console.log(JSON.stringify(data))
    AuthDS.validatePIN(data)
      .then((response: any) => {
        localStorage.removeItem('User')
        localStorage.setItem('PIN', PIN)
        navigate('/Reset')
      })
      .catch((e: any) => {
        setShow(true)
        setMsg('PIN invalido!')
        setHeader('Error')
        return false
      })
  }

  return (
    <div>
      <Row style={{ paddingTop: '6rem' }}>
        <Col xs={12}></Col>
      </Row>
      <Row>
        <Col xs={5}>&nbsp;</Col>
        <Col xs={2}>
          <div className="shadow-lg bg-white rounded">
            <Card style={{ width: '135%' }} className="Color1">
              <Card.Header>
                <Row>
                  <Col xs={4}></Col>
                  <Col xs={4}>
                    <img
                      src={logo}
                      alt="GEMCO"
                      style={{ width: '80%', textAlign: 'center' }}
                    />
                  </Col>
                  <Col xs={4}></Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col xs={12}>&nbsp;</Col>
                  </Row>
                  <Form id="login-form" className="form">
                    <Row>
                      <Col xs={12}>
                        <Form.Control
                          id="PIN"
                          className="form-control"
                          placeholder="PIN"
                          value={PIN}
                          onChange={(e) => {
                            setPIN(e.target.value)
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>&nbsp;</Col>
                    </Row>
                  </Form>
                </Card.Text>
                <div className="d-grid gap-1"></div>
                <div className="d-grid gap-1">
                  <Button
                    variant="customColor"
                    size="lg"
                    onClick={() => {
                      validatePIN()
                    }}
                  >
                    Continuar
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col xs={5}>&nbsp;</Col>
      </Row>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        time={2000}
        closeToast={function (arg: boolean): void {
          setShow(false)
        }}
      />
    </div>
  )
}

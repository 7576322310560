import React, { useEffect, useState } from 'react'
// Bootstraop components
import { Button, Card, Form } from 'react-bootstrap'
import { BsCloudDownload, BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
// Interfaces
import { IRptPedimentosPagados } from '../../Interfaces/Reportes/IRptPedimentosPagados'
import IClientes from '../../Interfaces/Catalogos/IClientes'
import IArchivoElectronico from '../../Interfaces/IArchivoElectronico'
// DTOs
import DTOgetThesePedimentos from '../../DTO/DTOgetThesePedimentos'
import DTOReporte from '../../DTO/DTOReporte'
// Services
import reportsDataService from '../../Services/Reportes/reportes.services'
import ClientesDataService from '../../Services/Catalogos/Clientes.Services'
import AEODataServices from '../../Services/Catalogos/ArchivoElectronico.Services'
// Redux
import { useDispatch } from 'react-redux'
import { setArchivoElectronico } from '../../store/features/Reportes/ArchivoElectronico'
// GUI components
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import { AgGridReact } from 'ag-grid-react'
import { VerDocumentos } from './customCells/verDocumentos'
import { verPDF } from './customCells/verPDF'
import { RowClickedEvent } from 'ag-grid-community'
import { TargetURL } from '../../Constants/TargetURL'
// Other
import moment from 'moment'
import * as XLSX from 'xlsx'
const URL = new TargetURL()

export default function RptPedimentosPagados() {
  const [curURL, setCurURL] = useState(URL.get())
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Data, setData] = useState<Array<IRptPedimentosPagados>>([])
  const [filteredData, setFilteredData] = useState<Array<IRptPedimentosPagados>>([])
  const [Inicio, setInicio] = useState(currentDate())
  const [Fin, setFin] = useState(currentDate())
  const [TipoOperacion, setTipoOperacion] = useState(1)
  const [Cliente, setCliente] = useState(0)
  const [Clientes, setClientes] = useState<Array<IClientes>>()
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [filtro, setFiltro] = useState('')
  const gridRef = React.useRef<any>(null)
  const [columnaVerPDF, setcolumnaVerPDF] = useState(false)
  const dispatch = useDispatch()
  const [columnDefs] = useState([
    { field: 'referencia', sortable: true, filter: true },
    { field: 'ver pedimento', sortable: true, cellRenderer: verPDF },
    {
      field: 'pedimentoLargo',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      sortable: true,
      filter: true,
    },
    { field: 'ver documentos', sortable: true, cellRenderer: VerDocumentos },
    { field: 'aduanaSeccionEntrada', sortable: true, filter: true },
    { field: 'aduanaSeccionDespacho', sortable: true, filter: true },
    { field: 'clavePedimento', sortable: true, filter: true },
    { field: 'regimen', sortable: true, filter: true },
    { field: 'tipoOperacionDesc', sortable: true, filter: true },
    { field: 'fechaEntradaPresentacion', sortable: true, filter: true },
    { field: 'fechadePago', sortable: true, filter: true },
    {
      field: 'pesoBruto',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return formatNumber(params.value)
      },
    },
    { field: 'tipodeCambiodePedimento', sortable: true, filter: true },
    {
      field: 'valorFacturaME',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'valorFacturaDolares',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'valorFacturaMonedaNacional',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'valorAduana',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'embalajes',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'fletes',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'seguros',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'otros',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'decrementables',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    { field: 'fletesFactura', sortable: true, filter: true },
    { field: 'segurosFactura', sortable: true, filter: true },
    { field: 'embalajesFactura', sortable: true, filter: true },
    { field: 'otrosFactura', sortable: true, filter: true },
    {
      field: 'importeDTAFP1',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'importeDTAFP2',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'importeADV1',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    { field: 'aDVFP1', sortable: true, filter: true },
    {
      field: 'importeADV2',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    { field: 'aDVFP2', sortable: true, filter: true },
    {
      field: 'importeIVA1',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    { field: 'iVAFP1', sortable: true, filter: true },
    {
      field: 'importeIVA2',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    { field: 'iVAFP2', sortable: true, filter: true },
    {
      field: 'importeCC',
      sortable: true,
      filter: true,
    },
    {
      field: 'importePRV',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'ivaprv',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    {
      field: 'totalPagado',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return '$' + formatNumber(params.value)
      },
    },
    { field: 'fechaprimeraSeleccion', sortable: true, filter: true },
    { field: 'primeraSeleccion', sortable: true, filter: true },
    { field: 'contenedores', sortable: true, filter: true },
    { field: 'tipoContenedor', sortable: true, filter: true },
    { field: 'facturas', sortable: true, filter: true },
    { field: 'pedidos', sortable: true, filter: true },
    { field: 'numIntegracionDODA', sortable: true, filter: true },
    { field: 'numTransaccionDODA', sortable: true, filter: true },
    { field: 'lineaCaptura', sortable: true, filter: true },
    { field: 'covesFactura', sortable: true, filter: true },
  ])

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const closeToast = (show: boolean): void => {
    setShowMsg(false)
  }

  const generaReporte = () => {
    if (Cliente === 0) {
      setHeader('Error')
      setMsg('Seleccion el cliente')
      setShowMsg(true)
      return
    }
    const data: DTOReporte = {
      Inicio: moment(Inicio).format('YYYY-MM-DD'),
      Fin: moment(Fin).format('YYYY-MM-DD'),
      TipoOperacion: TipoOperacion,
      NoCliente: Cliente,
      IdUsuario: UserId,
    }
    reportsDataService
      .getRptPedimentosPagados(data)
      .then((response) => {
        setData(response.data)
        setFilteredData(response.data)
        setHeader('Informativo')
        setMsg('Se encontro la siguiente informacion...')
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    ClientesDataService.getAllClientes(parseInt(UserId))
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }, [])

  const downloadExcel = () => {
    exportExcel(Data, 'Pedimentos Pagados')
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    let Heading = [
      [
        'Referencia',
        'Pedimento Largo',
        'Aduana Seccion Entrada',
        'Aduana Seccion Despacho',
        'Clave Pedimento',
        'Regimen',
        'Tipo Operacion Desc',
        'Fecha Entrada Presentacion',
        'Fecha de Pago',
        'Peso Bruto',
        'Tipo de Cambio de Pedimento',
        'Valor Factura ME',
        'Valor Factura Dolares',
        'Valor Factura Moneda Nacional',
        'Valor Aduana',
        'Embalajes',
        'Fletes',
        'Seguros',
        'Otros',
        'Decrementables',
        'Fletes Factura',
        'Seguros Factura',
        'Embalajes Factura',
        'Otros Factura',
        'Importe DTA FP1',
        'Importe DTA FP2',
        'Importe ADV1',
        'ADV FP1',
        'Importe ADV2',
        'ADV FP2',
        'Importe IVA1',
        'IVA FP1',
        'Importe IVA2',
        'IVA FP2',
        'Importe CC',
        'Importe PRV',
        'IVA PRV',
        'Total Pagado',
        'Fecha Primera Seleccion',
        'Primera Seleccion',
        'Contenedores',
        'Tipo Contenedor',
        'Facturas',
        'Pedidos',
        'Num Integracion DODA',
        'Num Transaccion DODA',
        'Linea Captura',
        'Coves Factura',
      ],
    ]
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    gridRef.current.api.setQuickFilter(e.target.value)
  }

  function getParams(e: RowClickedEvent) {
    const data: IArchivoElectronico = {
      Referencia: e.data.referencia,
      PedimentoLargo: e.data.pedimentoLargo,
      NoCliente: Cliente,
      IdUsuario: 0,
      Archivo: '',
    }
    dispatch(setArchivoElectronico(data))
    if (columnaVerPDF) {
      let P: string = e.data.pedimentoLargo
      P = P.substring(6)
      P = P.replace(' ', e.data.aduanaSeccionEntrada)
      window.open(
        `${curURL}/ArchivoElectronico/getFile?Referencia=${data.Referencia}&PedimentoLargo=${P}&Nocliente=${Cliente}&IdUsuario=${UserId}`
      )
    }
  }

  const downloadPDFs = () => {
    if (Cliente === 0) {
      setMsg('Seleccione el cliente')
      setHeader('Para poder continuar, favor de: ')
      setShowMsg(true)
    }
    let selectedNodes = gridRef.current.api.getSelectedNodes()
    let selectedData = selectedNodes.map((node: any) => node.data)
    const Pedimentos = selectedData.map((item: IRptPedimentosPagados) => item.pedimentoLargo)
    const data: DTOgetThesePedimentos = {
      Inicio: Inicio,
      Fin: Fin,
      NoCliente: Cliente,
      IdUsuario: UserId,
      TipoOperacion: TipoOperacion,
      Pedimentos: Pedimentos,
    }
    AEODataServices.getThesePDFs(data)
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Pedimentos.zip')
        document.body.appendChild(link)
        link.click()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  async function getAE(e: any) {
    if (e.column.colId === 'ver pedimento') {
      setcolumnaVerPDF(true)
    } else {
      setcolumnaVerPDF(false)
    }
  }

  const formatNumber = (number: string) => {
    number = parseFloat(number).toFixed(2)
    let value = Number(number).toLocaleString('en')
    if (!value.indexOf('NaN')) return '0.00'
    return value
  }

  const currencyFormatter = (params: any) => {
    return '$' + formatNumber(params.value)
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-2'>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-2'>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1 right-label'>
              <Form.Label>Tipo oper</Form.Label>
            </div>
            <div className='col-md-1'>
              <Form.Control
                as='select'
                onChange={(e) => setTipoOperacion(parseInt(e.target.value))}
                className='form-select form-select-sm'
              >
                <option value='1'>Importacion</option>
                <option value='2'>Exportacion</option>
              </Form.Control>
            </div>
            <div className='col-md-1 right-label'>
              <Form.Label>Cliente</Form.Label>
            </div>
            <div className='col-md-4'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  setCliente(parseInt(e.target.value))
                }}
                className='form-select form-select-sm'
              >
                <option value='0'>-SELECCIONE-</option>
                {Clientes
                  ? Clientes.map((c) => {
                      return c.agrupado === 1 ? <option value={c.sClave}>{c.sRazonSocial}</option> : ''
                    })
                  : null}
              </Form.Control>
            </div>
            <div className='col-md-1 right-label'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
          </div>
          <div className='row' style={{ paddingTop: 5 }}>
            <div className='col'>&nbsp;</div>
            <div className='col-4'>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className='col'>
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel /> Excel
              </Button>
              &nbsp; &nbsp;
              <Button
                variant='danger'
                size='sm'
                onClick={() => {
                  downloadPDFs()
                }}
              >
                <BsCloudDownload />
                &nbsp;PDF
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        <AgGridReact
          rowData={filteredData}
          columnDefs={columnDefs}
          pagination={true}
          paginationAutoPageSize={true}
          ref={gridRef}
          rowSelection={'multiple'}
          rowMultiSelectWithClick={true}
          onCellClicked={(e) => getAE(e)}
          onRowClicked={(e) => getParams(e)}
        ></AgGridReact>
      </div>
      <MsgInformativo show={show} msg={msg} header={header} msgColor={msgColor} closeToast={closeToast} />
    </div>
  )
}

import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsChevronDown, BsChevronRight, BsSearch } from 'react-icons/bs'
import { FaAmazon, FaFileExcel } from 'react-icons/fa'
import I2096Headers from '../../../Interfaces/I2096Header'
import DSAmazon from '../../../Services/Amazon.Services'
import { MsgInformativo } from '../../../../../Utils/Toast/msgInformativo'
import { RootState } from '../../../../../../store/store'
import {
  initializeInvoice,
  populateInvoices,
  updateInvoice
} from '../../../../../../store/features/Clientes/2096/AmazonInvoices'
import RtpAmazonFinishedClasificationInvoicesDetail from './RptAmazonFinishedClasificationInvoicesDetail'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import DTO2096PerfilesParecidos from '../../../DTO/DTO2096PerfilesParecidos'
import loadingImg from '../../../../../../images/ajaxloader.gif'

export interface IRptAmazonFinishedClasificationInvoicesProps {}

export default function RptAmazonFinishedClasificationInvoices(
  props: IRptAmazonFinishedClasificationInvoicesProps
) {
  const [Usuario, setUsuario] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [Data, setData] = useState<DTO2096PerfilesParecidos[]>([])
  const [Accion, setAccion] = useState(0)
  const [Estatus, setEstatus] = useState(0)
  const [IDInvoice, setIDInvoice] = useState(0)
  const [IDClasificador, setIDClasificador] = useState(0)
  const [Clasificador, setClasificador] = useState('')
  const [ShowModal, setShowModal] = useState(false)
  const [ShowModalQuestion, setShowModalQuestion] = useState(false)
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [WaitingDialog, setWaitingDialog] = useState(false)
  const [WaitingMessage, setWaitingMessage] = useState('Plantilla de Partes')
  const msgColor = 'primary'

  const loadReport = () => {
    DSAmazon.AmazonFinishedClasificationInvoiceGET()
      .then((response) => {
        dispatch(initializeInvoice([]))
        dispatch(populateInvoices(response.data))
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const downloadExcel = (id: number, Factura: string) => {
    setWaitingDialog(true)
    DSAmazon.NoPartesAmazon2SIRGET(id)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'Plantilla de partes ' + Factura + '.xls'
          )
          document.body.appendChild(link)
          link.click()
          setWaitingDialog(false)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const loadProfiles = () => {
    DSAmazon.PerfilesParecidosGet('Clasificador')
      .then((response) => {
        setData(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  useEffect(() => {
    loadReport()
    loadProfiles()
  }, [])

  const getUser = (Id: number) => {
    const NoAsignado = 'NO ASIGNADO'
    if (Id === 0) return NoAsignado
    else if (Data) {
      const Usuario = Data.filter((item) => item.idUsuario === Id)
      console.log(Usuario)
      return Usuario.length>0 ? Usuario[0].nombre : NoAsignado
    }
    return NoAsignado
  }

  useEffect(() => {
    if (ShowModal) {
      setClasificador(getUser(IDClasificador))
    }
  }, [ShowModal])

  const changeToggle = (row: I2096Headers) => {
    let Invoice: I2096Headers[] = mInvoices.filter((item) => item.id === row.id)
    const Obj = { ...Invoice[0] }
    Obj.max = !Obj.max
    dispatch(updateInvoice(Obj))
  }

 const TerminadeClasificar = () => {
    DSAmazon.TerminaClasificarFactura(IDInvoice)
      .then((response) => {
        loadReport()
        setShowModalQuestion(false)
      })
      .catch((e: Error) => {
        setIDInvoice(0)
        setHeader('Error')
        setMsg('Ocurrio un error ' + e.toString())
        setShowMsg(true)
        return
      })
  }

  const returnTO = () => {
    DSAmazon.InvoiceReturnState(IDInvoice, 0)
      .then((response) => {
        loadReport()
        setShowModal(false)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  function getReceiver(Cadena: string, char1: string[1], char2: string[1]) {
    return Cadena.slice(
      Cadena.indexOf(char1) + 1,
      Cadena.lastIndexOf(char2),
    );
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={5}>
              <Card.Title>
                <IconContext.Provider value={{ color: '#F6C34F', size: '8%' }}>
                  <FaAmazon />
                </IconContext.Provider>{' '}
                CLASIFICACION : facturas terminadas
              </Card.Title>
            </Col>
            <Col xs={4}></Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}></Col>
        <Col xs={2} className="totalLabel"></Col>
      </Row>
      <Card>
        <Card.Body>
          <div className="MDcontainer">
            <Table className="MDTable" hover>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr>
                  <th></th>
                  <th style={{ width: '50px' }}>id</th>
                  <th style={{ width: '50px' }}></th>
                  <th style={{ width: '50px' }}></th>
                  <th style={{ width: '80px' }}>Commercial Invoice Date</th>
                  <th style={{ width: '300px' }}>Invoice Number</th>
                  <th style={{ width: '80px' }}>Receiver</th>
                  <th style={{ width: '100px' }}>Incoterms</th>
                  <th style={{ textAlign: 'center' }}>HAWB</th>
                  <th>Qty Unit of measure</th>
                  <th>Tot Qty</th>
                  <th>Weight unit of measure</th>
                  <th>Tot weight</th>
                  <th>Tot Charge or allowance</th>
                  <th>Tot currency ISO code</th>
                  <th>Tot Monetary amount</th>
                  <th>Tot partidas</th>
                  <th>Clasificador</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {mInvoices
                  ? mInvoices .filter((a) => {
                    if (
                      a.clasificador === parseInt(Usuario) &&
                      Perfil !== 'Clasificador lider'
                    ) {
                      return a
                    } else if (
                      ['Clasificador lider', 'Clasificador', 'Administrador'].includes(
                        Perfil
                      )
                    ) {
                      return a
                    }
                  }).map((MasterData) => {
                      return (
                        <>
                          <tr
                            style={{ cursor: 'pointer' }}
                            className={
                              MasterData.max === true
                                ? 'masterSelected'
                                : 'normalSelected'
                            }
                          >
                            <td
                              style={{ textAlign: 'left' }}
                              className={
                                MasterData.max === true
                                  ? 'masterSelected'
                                  : 'normalSelected'
                              }
                              key={MasterData.id}
                              onClick={() => {
                                changeToggle(MasterData)
                              }}
                            >
                              {MasterData.max === true ? (
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '15px' }}
                                >
                                  <BsChevronDown />
                                </IconContext.Provider>
                              ) : (
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '15px' }}
                                >
                                  <BsChevronRight />
                                </IconContext.Provider>
                              )}
                            </td>
                            <td>{MasterData.id}</td>
                            <td>
                              {MasterData.estatus === 2 ? (
                                <div
                                  onClick={() =>
                                    downloadExcel(
                                      MasterData.id,
                                      MasterData.invoiceNumber
                                    )
                                  }
                                >
                                  <IconContext.Provider
                                    value={{ color: 'green', size: '20px' }}
                                  >
                                    <FaFileExcel />
                                  </IconContext.Provider>
                                </div>
                              ) : null}
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                              <FormCheck
                                className="form-check form-switch form-control-sm"
                                id="switchEnabled"
                                type="switch"
                                checked={false}
                                onChange={() => {
                                  setIDInvoice(MasterData.id)
                                  setShowModalQuestion(true)
                                 // sePuedeTerminarFactura(MasterData.id)
                                }}
                                label=""
                              />
                            </td>
                            <td style={{ width: '80px', textAlign: 'center' }}>
                              {MasterData.commercialInvoiceDate}
                            </td>
                            <td style={{ width: '300px' }}>
                              {MasterData.invoiceNumber}
                            </td>
                            <td style={{ width: '80px', textAlign:'center' }}>
                            {mInvoices
                                .filter((value) => value.id === MasterData.id)
                                .map((row) => {
                                  return getReceiver(row.detail[0].amazonShipmentReferenceId,'_','_')
                                })}
                            </td>
                            <td style={{ width: '100px', textAlign: 'center' }}>
                              {MasterData.incoterms}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.hawb}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantityUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceQuantity}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeightUnitOfMeasure}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceWeight}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueChargeOrAllowance}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueCurrencyISOCode}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {MasterData.totalInvoiceValueMonetaryAmount}
                            </td>
                            <td
                              style={{
                                fontWeight: 'bold',
                                textAlign: 'center'
                              }}
                            >
                              {mInvoices
                                .filter((value) => value.id === MasterData.id)
                                .map((row) => {
                                  return row.detail.length
                                })}
                            </td>
                            <td>{getUser(MasterData.clasificador)}</td>
                            <td>
                              <Form.Control
                                as="select"
                                className="form-select form-select-sm"
                                onChange={(e) => {
                                  setIDInvoice(MasterData.id)
                                  setAccion(parseInt(e.target.value))
                                  setIDClasificador(MasterData.clasificador)
                                  setEstatus(MasterData.estatus)
                                  setShowModal(true)
                                }}
                              >
                                <option value="0">Ok</option>
                                <option value="1">Regresa</option>
                              </Form.Control>
                            </td>
                          </tr>
                          {MasterData.max ? (
                            <tr
                              className={
                                MasterData.max === true
                                  ? 'masterSelected2'
                                  : 'normalSelected2'
                              }
                            >
                              <th colSpan={18}>
                                <table
                                  className="childTable"
                                  style={{ width: '1450px' }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          width: '50px',
                                          backgroundColor: '#FFFFFF',
                                          color: '#346288'
                                        }}
                                      ></th>
                                      <th
                                        style={{
                                          width: '100px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Item Id
                                      </th>
                                      <th
                                        style={{
                                          width: '400px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Item Description
                                      </th>
                                      <th
                                        style={{
                                          width: '120px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Pedimento Description
                                      </th>
                                      <th
                                        style={{
                                          width: '120px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Destination HTS Code
                                      </th>
                                      <th
                                        style={{
                                          width: '120px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Valida
                                      </th>
                                      <th
                                        style={{
                                          width: '120px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Confirme fraccion
                                      </th>
                                      <th
                                        style={{
                                          width: '120px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Fraccion GEMCO
                                      </th>
                                      <th
                                        style={{
                                          width: '120px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Confirme descripcion
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Descripcion GEMCO
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Country of Origin
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        ProductGroup
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Brand
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Model
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Measure
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Qty Shipped
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit of measure
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Net Weight
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Unit Cost
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288'
                                        }}
                                      >
                                        Total value
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {mInvoices.map((MD) => {
                                      return (
                                        <>
                                          {MD.detail
                                            .filter(
                                              (detail) =>
                                                MasterData.id ===
                                                detail.idHeader
                                            )
                                            .map((detail) => {
                                              return (
                                                <RtpAmazonFinishedClasificationInvoicesDetail
                                                  IDMaster={MasterData.id}
                                                  detail={detail}
                                                  Deshabilitado={
                                                    MasterData.estatus >= 3
                                                  }
                                                />
                                              )
                                            })}
                                        </>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          ) : null}
                        </>
                      )
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>

      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '150px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={1}></Col>
              <Col xs={10}>
                <Alert key={'danger'} variant={'danger'}>
                  ¿Esta seguro de regresarle esta factura a: {Clasificador}?
                </Alert>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  &nbsp;&nbsp;No&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    returnTO()
                  }}
                >
                  &nbsp;&nbsp;Si&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={ShowModalQuestion}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '150px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={1}></Col>
              <Col xs={10}>
                <Alert key={'danger'} variant={'danger'}>
                  ¿Esta seguro de terminar la factura en este proceso de clasificacion?
                </Alert>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModalQuestion(false)
                  }}
                >
                  &nbsp;&nbsp;No&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    TerminadeClasificar()
                  }}
                >
                  &nbsp;&nbsp;Si&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={WaitingDialog}
        onHide={() => {
          setWaitingDialog(false)
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        dialogClassName={'modal-50w'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={loadingImg} style={{ height: '150px' }} alt="proccessing" />
          Generando {WaitingMessage}...
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { MsgInformativo } from '../../../../Utils/Toast/msgInformativo'
import DTO2096Descripcion from '../../DTO/DTO2096Descripcion'
import { RootState } from '../../../../../store/store'
import {
  populateInvoices,
  addInvoice,
  updateInvoice,
  deleteInvoice
} from '../../../../../store/features/Clientes/2096/AmazonInvoices'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import DSAmazon from '../../Services/Amazon.Services'
import I2096Headers from '../../Interfaces/I2096Header'
import I2096Detail from '../../Interfaces/I2096Detail'

export interface IComboDescripcionProps {
  IDInvoice: number
  IDDetail: number
  Prioridad?: boolean
  DescripcionOriginal: string
  Deshabilitado: boolean
  ConfirmaDescripcion: number
  Detail: I2096Detail
}

export function ComboDescripcion(props: IComboDescripcionProps) {
  const dispatch = useDispatch()
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [Validacion, setValidacion] = useState(props.ConfirmaDescripcion)
  const [UserChanged, setUserChanged] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [Descripcion, setDescripcion] = useState(props.DescripcionOriginal)
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const msgColor = 'primary'

  const changeValidacion = (e: number) => {
    setValidacion(e)
    setUserChanged(true)
  }

  const saveDescripcion = () => {
    const data: DTO2096Descripcion = {
      id: props.IDDetail,
      descripcion: Descripcion,
      confirmaDescripcion: Validacion
    }
    DSAmazon.DescripcionSET(data)
      .then((response) => {
        UpdateInfo()
        setShowModal(false)
        return
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const refreshStore = (Invoice: I2096Headers, Detail: I2096Detail[]) => {
    const updatedInvoice: I2096Headers = {
      id: props.IDInvoice,
      referencia: Invoice.referencia,
      commercialInvoiceDate: Invoice.commercialInvoiceDate,
      invoiceNumber: Invoice.invoiceNumber,
      name: Invoice.name,
      trailerNumber: Invoice.trailerNumber,
      trailerId: Invoice.trailerId,
      porOfLoading: Invoice.porOfLoading,
      portOfEntry: Invoice.portOfEntry,
      paymentsTerms: Invoice.paymentsTerms,
      incoterms: Invoice.incoterms,
      hawb: Invoice.hawb,
      totalInvoiceQuantityUnitOfMeasure:
        Invoice.totalInvoiceQuantityUnitOfMeasure,
      totalInvoiceQuantity: Invoice.totalInvoiceQuantity,
      totalInvoiceWeightUnitOfMeasure: Invoice.totalInvoiceWeightUnitOfMeasure,
      totalInvoiceWeight: Invoice.totalInvoiceWeight,
      totalInvoiceValueChargeOrAllowance:
        Invoice.totalInvoiceValueChargeOrAllowance,
      totalInvoiceValueCurrencyISOCode:
        Invoice.totalInvoiceValueCurrencyISOCode,
      totalInvoiceValueMonetaryAmount: Invoice.totalInvoiceValueMonetaryAmount,
      footNote: Invoice.footNote,
      archivo: Invoice.archivo,
      fCreacion: Invoice.fCreacion,
      clasificador: Invoice.clasificador,
      noVuelta: Invoice.noVuelta,
      estatus: Invoice.estatus,
      max: true,
      urgente: Invoice.urgente,
      detail: Detail
    }
    dispatch(updateInvoice(updatedInvoice))
  }

  const UpdateInfo = () => {
    const Invoice = mInvoices
      .filter((el) => el.id === props.IDInvoice)
      .map((el) => {
        return el
      })
    const newDetail = Invoice[0].detail.map((el) => {
      if (el.id === props.IDDetail) {
        return {
          ...el,
          descripcionGEMCO: Descripcion
        }
      }
      return el
    })
    refreshStore(Invoice[0], newDetail)
  }

  useEffect(() => {
    if (UserChanged) {
      if (Validacion === 2) {
        setDescripcion('')
        setShowModal(true)
      } else if (Validacion === 1) {
        setDescripcion(props.DescripcionOriginal)
        const data: DTO2096Descripcion = {
          id: props.IDDetail,
          descripcion: Descripcion,
          confirmaDescripcion: Validacion
        }
        setDescripcion(props.DescripcionOriginal)
        DSAmazon.DescripcionSET(data)
          .then((response) => {
            UpdateInfo()
            setShowModal(false)
            /*   setHeader('Confirmacion')
          setMsg('La descripcion se ha guardado correctamente')
          setShowMsg(true) */
            return
          })
          .catch((e: Error) => {
            alert('Ocurrio un error' + e.message.toString())
          })
      }
    }
  }, [Validacion, UserChanged])

  return (
    <div>
      <Form.Control
        as="select"
        disabled={
          ((props.Deshabilitado || props.Detail.validaFraccionOriginal === 3) &&
            Perfil !== 'Clasificador Lider') ||
          (props.Detail.validaFraccionOriginal === 4 && !props.Prioridad)
        }
        onChange={(e) => changeValidacion(parseInt(e.target.value))}
        value={Validacion}
      >
        {props.Detail.validaFraccionOriginal <= 1 ? (
          <option value="0"> </option>
        ) : (
          ''
        )}
        {props.Detail.confirmaDescripcion <= 1 ? (
          <option value="1"> = </option>
        ) : (
          ''
        )}
        <option value="2"> != </option>
        {props.Detail.validaFraccionOriginal === 3 ? (
          <option value="3"> Ok </option>
        ) : (
          ''
        )}
        {props.Detail.validaFraccionOriginal === 5 ? (
          <option value="5">BOT</option>
        ) : (
          ''
        )}
      </Form.Control>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <Row>
            <Col xs={2}>Descripcion GEMCO</Col>
            <Col xs={8}>
              <Form.Control
                type="text"
                as="textarea"
                placeholder="Proporcione la descripcion correcta..."
                id="ComentariosRechazo"
                size="sm"
                value={Descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
              />
            </Col>
            <Col xs={1}>
              <Button
                onClick={() => {
                  saveDescripcion()
                }}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Form,
  FormCheck,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { MsgInformativo } from '../../../../../Utils/Toast/msgInformativo'
import I2096Detail from '../../../Interfaces/I2096Detail'
import DSAmazon from '../../../Services/Amazon.Services'
// Redux
import { RootState } from '../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import * as CurrencyFormat from 'react-currency-format'
import { AiOutlineFileSearch, AiTwotoneEdit } from 'react-icons/ai'
import { BsFillChatRightTextFill, BsFillPencilFill } from 'react-icons/bs'
import DTO2096SightLine from '../../../DTO/DTO2096SightLine'
import { updateInvoice } from '../../../../../../store/features/Clientes/2096/AmazonInvoices'
import DTO2096TraficoFieldsUpdate from '../../../DTO/DTO2096TraficoFieldsUpdate'
import I2096Headers from '../../../Interfaces/I2096Header'
import I2096PaisClave from '../../../Interfaces/I2096PaisClave'

export interface IRtpAmazonInvoiceTrafficDetailProps {
  IDMaster: number
  Paises: I2096PaisClave[]
  detail: I2096Detail
  Habilitado: boolean
  onDataChange: (IDMaster: number) => void
}

export default function RtpAmazonInvoice2TrafficDetail(
  props: IRtpAmazonInvoiceTrafficDetailProps
) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [ShowModal, setShowModal] = useState(false)
  const [EstatusSwith, setEstatusSwitch] = useState(props.detail.sightLine)
  const [UserChanged, setUserChanged] = useState(false)
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  //const [Show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [CumplimientoNormativo, setCumplimientoNormativo] = useState(
    props.detail.cumplimientoNormativo ? props.detail.cumplimientoNormativo : ''
  )
  const [Comentarios, setComentarios] = useState(
    props.detail.comentariosSightLine ? props.detail.comentariosSightLine : ''
  )
  const [ShowModalEdit, setShowModalEdit] = useState(false)
  const [UnitMeasure, setUnitMeasure] = useState('')
  const [UnitOfMeasure, setUnitOfMeasure] = useState('')
  const [UnitCost, setUnitCost] = useState('')
  const [Quantity, setQuantity] = useState(0)
  const [ItemId, setItemId] = useState('')
  const [ItemDescription, setItemDescription] = useState('')
  const [FraccionGEMCO, setFraccionGEMCO] = useState('')
  const [DescripcionGEMCO, setDescripcionGEMCO] = useState('')
  const [CountryOfOrigin, setCountryOfOrigin] = useState('')
  const msgColor = 'primary'
  const openLink = (item: string) => {
    window.open('https://www.amazon.com/dp/' + item)
  }
  const UpdateInvoiceDetail = (
    Invoice: I2096Headers,
    Detail: I2096Detail[]
  ) => {
    const updatedInvoice: I2096Headers = {
      id: props.IDMaster,
      referencia: Invoice.referencia,
      commercialInvoiceDate: Invoice.commercialInvoiceDate,
      invoiceNumber: Invoice.invoiceNumber,
      name: Invoice.name,
      trailerNumber: Invoice.trailerNumber,
      trailerId: Invoice.trailerId,
      porOfLoading: Invoice.porOfLoading,
      portOfEntry: Invoice.portOfEntry,
      paymentsTerms: Invoice.paymentsTerms,
      incoterms: Invoice.incoterms,
      hawb: Invoice.hawb,
      totalInvoiceQuantityUnitOfMeasure:
        Invoice.totalInvoiceQuantityUnitOfMeasure,
      totalInvoiceQuantity: Invoice.totalInvoiceQuantity,
      totalInvoiceWeightUnitOfMeasure: Invoice.totalInvoiceWeightUnitOfMeasure,
      totalInvoiceWeight: Invoice.totalInvoiceWeight,
      totalInvoiceValueChargeOrAllowance:
        Invoice.totalInvoiceValueChargeOrAllowance,
      totalInvoiceValueCurrencyISOCode:
        Invoice.totalInvoiceValueCurrencyISOCode,
      totalInvoiceValueMonetaryAmount: Invoice.totalInvoiceValueMonetaryAmount,
      footNote: Invoice.footNote,
      archivo: Invoice.archivo,
      fCreacion: Invoice.fCreacion,
      clasificador: Invoice.clasificador,
      noVuelta: Invoice.noVuelta,
      estatus: Invoice.estatus,
      max: true,
      urgente: Invoice.urgente,
      detail: Detail
    }
    dispatch(updateInvoice(updatedInvoice))
  }

  const editInvoice = (id: number) => {
    const Invoice = mInvoices
      .filter((el) => el.id === props.IDMaster)
      .map((el) => {
        return el
      })
    const newDetails = Invoice[0].detail.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          countryOfOrigin: CountryOfOrigin,
          itemQuantityUnitOfMeasure: UnitMeasure,
          weightValue: UnitOfMeasure,
          actualUnitCostMonetaryAmount: UnitCost,
          totalUnitValueMonetaryAmount: (
            el.quantity * parseFloat(UnitCost)
          ).toString()
        }
      }
      return el
    })
    UpdateInvoiceDetail(Invoice[0], newDetails)
  }

  const ToggleSightLine = (id: number) => {
    const data: DTO2096SightLine = {
      id: id,
      sightLine: EstatusSwith,
      comentariosSightLine: Comentarios
    }
    DSAmazon.SightLineSET(data)
      .then((response) => {
        setShowModal(false)
        props.onDataChange(props.IDMaster)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error, no se guardo la informacion en BD')
        setShowMsg(true)
        return
      })
  }

  const saveFields = () => {
    const data: DTO2096TraficoFieldsUpdate = {
      id: props.detail.id,
      UnitMeasure: UnitMeasure,
      UnitOfMeasure: parseFloat(UnitOfMeasure),
      UnitCost: parseFloat(UnitCost),
      CountryOfOrigin: CountryOfOrigin
    }
    DSAmazon.TrafficFieldsUpdate(data)
      .then((response) => {
        setUnitMeasure(response.data.itemQuantityUnitOfMeasure)
        setUnitOfMeasure(response.data.weightValue)
        setUnitCost(response.data.actualUnitCostMonetaryAmount)
        setCountryOfOrigin(response.data.countryOfOrigin)
        editInvoice(props.detail.id)
        setHeader('Informativo')
        setMsg('La informacion se guardo exitosamente')
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    if (UserChanged) setShowModal(true)
  }, [EstatusSwith, UserChanged])

  return (
    <>
      <tr>
        <th
          style={{
            width: '50px',
            backgroundColor: '#FFFFFF',
            textAlign: 'right',
            fontWeight: 'bold'
          }}
          className="DetailData"
        >
          {props.detail.partida}
        </th>
        <td
          style={{
            textAlign: 'center',
            cursor: 'pointer'
          }}
          className="DetailData"
          onClick={() => openLink(props.detail.itemId)}
        >
          {props.detail.itemId}
        </td>
        <td
          style={{
            textAlign: 'left'
          }}
          className="DetailData"
        >
          {props.detail.itemDescription}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          <CurrencyFormat
            value={props.detail.fraccionGEMCO ? props.detail.fraccionGEMCO : ''}
            disabled={true}
            format={'####.##.####'}
            displayType={'input'}
            style={{
              fontSize: '17px',
              backgroundColor:
                props.detail.validaFraccionOriginal === 0
                  ? '#FFFAF2 '
                  : '#E5F7B6',
              border:
                props.detail.validaFraccionOriginal === 0
                  ? '2px solid #F9A721'
                  : '2px solid green',
              color:
                props.detail.validaFraccionOriginal === 0
                  ? '#5923F6'
                  : '#000000',
              width: '135px',
              textAlign: 'right',
              borderRadius: '10px'
            }}
          />
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.descripcionGEMCO}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.countryOfOrigin}
        </td>
        <td
          style={{
            textAlign: 'left',
            paddingLeft: '15px'
          }}
          className="DetailData"
        >
          {props.detail.productGroup}
        </td>
        <td
          style={{
            textAlign: 'left',
            paddingLeft: '15px'
          }}
          className="DetailData"
        >
          {props.detail.brand}
        </td>
        <td
          style={{
            textAlign: 'right'
          }}
          className="DetailData"
        >
          {props.detail.model}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.itemQuantityUnitOfMeasure}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.quantity}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.weightValue}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.netWeightUnitOfMeasure}
        </td>

        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.actualUnitCostMonetaryAmount}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.totalUnitValueMonetaryAmount}
        </td>
        <td
          style={{
            textAlign: 'center',
            paddingLeft: '30px'
          }}
          className="DetailData"
        >
          <FormCheck
            className="form-check form-switch form-control-lg"
            id="switchEnabled"
            type="switch"
            checked={EstatusSwith}
            onChange={() => {
              setEstatusSwitch(!EstatusSwith)
              setUserChanged(true)
              setShowModal(!EstatusSwith)
            }}
          />
        </td>
        <td
          style={{
            visibility:
              CumplimientoNormativo.length === 0 ? 'hidden' : 'visible'
          }}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">{CumplimientoNormativo}</Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <Button variant="light" {...triggerHandler}>
                <IconContext.Provider
                  value={{
                    color: 'blue',
                    size: '25px'
                  }}
                >
                  <AiOutlineFileSearch />
                </IconContext.Provider>
                <span className="ms-1"></span>
              </Button>
            )}
          </OverlayTrigger>
        </td>
        <td
          style={{
            visibility:
              CumplimientoNormativo.length === 0 ? 'hidden' : 'visible'
          }}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">{Comentarios}</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <Button variant="light" {...triggerHandler}>
                <IconContext.Provider
                  value={{
                    color: 'green',
                    size: '25px'
                  }}
                >
                  <BsFillChatRightTextFill />
                </IconContext.Provider>
                <span className="ms-1"></span>
              </Button>
            )}
          </OverlayTrigger>
        </td>
        <td>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setItemId(props.detail.itemId)
              setItemDescription(props.detail.itemDescription)
              setDescripcionGEMCO(props.detail.descripcionGEMCO)
              setUnitMeasure(props.detail.itemQuantityUnitOfMeasure)
              setUnitOfMeasure(props.detail.weightValue)
              setUnitCost(props.detail.actualUnitCostMonetaryAmount)
              setFraccionGEMCO(props.detail.fraccionGEMCO)
              setCountryOfOrigin(props.detail.countryOfOrigin)
              setShowModalEdit(true)
            }}
          >
            <IconContext.Provider
              value={{
                color: 'blue',
                size: '25px'
              }}
            >
              <BsFillPencilFill />
            </IconContext.Provider>
          </div>
        </td>
      </tr>
      <Modal
        show={ShowModalEdit}
        onHide={() => {
          setShowModalEdit(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '260px' }}>
            <Row>
              <Col xs={12}>
                <Alert key="warning" variant="warning">
                  <span style={{ fontWeight: 'bold' }}>No Parte:</span> {ItemId}{' '}
                  <br />
                  <span style={{ fontWeight: 'bold' }}>
                    Fraccion GEMCO:
                  </span>{' '}
                  {FraccionGEMCO} <br />
                  <span style={{ fontWeight: 'bold' }}>
                    Descripcion GEMCO:
                  </span>{' '}
                  {DescripcionGEMCO}
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>Country of origin:</Col>
              <Col xs={4}>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setCountryOfOrigin(e.target.value)
                  }}
                  value={CountryOfOrigin}
                  className="form-select form-select-sm"
                  style={{ fontSize: '15px' }}
                >
                  <option value="0">-SELECCIONE-</option>
                  {props.Paises
                    ? props.Paises.map((p) => {
                        return (
                          <option value={p.clave_a3} key={p.clave_a3}>
                            {p.paisNom} - ({p.clave_a3})
                          </option>
                        )
                      })
                    : null}
                </Form.Control>
              </Col>
            </Row>
            <Row style={{ paddingTop: '5px' }}>
              <Col xs={3}>Unit Measure:</Col>
              <Col xs={3}>
                <Form.Control
                  type="text"
                  id="Search"
                  size="sm"
                  value={UnitMeasure}
                  onChange={(e) => setUnitMeasure(e.target.value)}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: '5px' }}>
              <Col xs={3}>Unit of Measure:</Col>
              <Col xs={3}>
                <CurrencyFormat
                  onValueChange={(values: any) => {
                    const { value } = values
                    setUnitOfMeasure(value)
                  }}
                  /* format={'########.##'} */
                  value={UnitOfMeasure}
                  displayType={'input'}
                  style={{
                    fontSize: '18px',
                    backgroundColor: '#F1EEF9',
                    border: '2px solid #5923F6',
                    color: '#5923F6',
                    width: '120px',
                    textAlign: 'right',
                    borderRadius: '10px'
                  }}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: '5px' }}>
              <Col xs={3}>Unit Cost:</Col>
              <Col xs={3}>
                <CurrencyFormat
                  onValueChange={(values: any) => {
                    const { value } = values
                    setUnitCost(value)
                  }}
                  /*  format={'########.##'} */
                  value={UnitCost}
                  displayType={'input'}
                  style={{
                    fontSize: '18px',
                    backgroundColor: '#F1EEF9',
                    border: '2px solid #5923F6',
                    color: '#5923F6',
                    width: '120px',
                    textAlign: 'right',
                    borderRadius: '10px'
                  }}
                />
              </Col>
              <Col xs={4}></Col>
              <Col xs={2}>
                <Button
                  variant="primary"
                  onClick={() => {
                    saveFields()
                  }}
                >
                  &nbsp;&nbsp;Guardar&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '150px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={3}>Comentarios</Col>
              <Col xs={8}>
                {' '}
                <Form.Control
                  as="textarea"
                  id="Comentarios"
                  size="sm"
                  value={Comentarios.length ? Comentarios : ''}
                  onChange={(e) => setComentarios(e.target.value)}
                />
              </Col>
              <Col>&nbsp;</Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setEstatusSwitch(true)
                    setShowModal(false)
                  }}
                >
                  &nbsp;&nbsp;No&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    ToggleSightLine(props.detail.id)
                  }}
                >
                  &nbsp;&nbsp;Si&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </>
  )
}

import { useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import IConsolidadosSinFirmaBanco from '../../../../Interfaces/Facturacion/Anexo/IConsolidadosSinFirmaBanco'
import DSOperaciones from '../../../../Services/Operaciones/Operaciones.Services'
import * as XLSX from 'xlsx'
import { BsFileEarmarkExcel } from 'react-icons/bs'

export interface IRtpConsolidadosSinFirmaBancoProps {}

export default function RtpConsolidadosSinFirmaBanco(
  props: IRtpConsolidadosSinFirmaBancoProps
) {
  const [Data, setData] = useState<IConsolidadosSinFirmaBanco[]>([])
  const [Total, setTotal] = useState(0)
  const columnsConcepts = [
    {
      name: 'Referencia',
      width: '150px',
      cell: (row: IConsolidadosSinFirmaBanco) => row.referencia,
      sortable: true
    },
    {
      name: 'Fecha Pago',
      width: '250px',
      cell: (row: IConsolidadosSinFirmaBanco) => row.fechaPago,
      sortable: true
    },
    {
      name: 'Fecha Validacion',
      width: '250px',
      cell: (row: IConsolidadosSinFirmaBanco) => row.firmaValidacion,
      sortable: true
    },
    {
      name: 'Firma Banco',
      with: '250px',
      cell: (row: IConsolidadosSinFirmaBanco) => row.firmaBanco,
      sortable: true
    }
  ]

  const downloadExcel = () => {
    exportExcel(Data, 'Consolidados sin firma de banco')
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    let Heading = [
      ['Referencia', 'FechaPago', 'Fecha Validacion', 'Firma Banco']
    ]
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const generaReporte = () => {
    DSOperaciones.OperacionesSinFirmaBancoGet()
      .then((response) => {
        setData(response.data)
        setTotal(response.data.length)
      })
      .catch((e: Error) => {
        console.log(e)
      })
  }

  useEffect(() => {
    generaReporte()
  }, [])

  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>
            <Row>
              <Col xs={3}>Consolidados sin firma banco : {Total} registros</Col>
              <Col xs={8}></Col>
              <Col xs={1} className="colAlignRight">
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => {
                    downloadExcel()
                  }}
                >
                  <BsFileEarmarkExcel />
                  &nbsp;Excel
                </Button>
              </Col>
            </Row>
          </Card.Title>
          <Row>
            <Col xs={12}>
              <DataTable
                dense
                noHeader
                defaultSortFieldId={'cliente'}
                defaultSortAsc={true}
                fixedHeaderScrollHeight="550px"
                striped={true}
                fixedHeader
                /*                 paginationPerPage={10}
                pagination */
                highlightOnHover
                columns={columnsConcepts}
                data={Data}
                pointerOnHover
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

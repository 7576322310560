import { useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { BsFileEarmarkExcel, BsSearch } from 'react-icons/bs'
import '../../../../css/generic01.css'
import * as XLSX from 'xlsx'
import DTOAnexoFacturacionMission from '../../../../DTO/Facturacion/Anexo/DTOAnexoFacturacionMission'
import IAnexoFacturacionAlen from '../../../../Interfaces/Facturacion/Anexo/IAnexoFacturacionAlen'
import DSAnexoFacturacion from '../../../../Services/Facturacion/Anexo/Anexo.Facturacion.Services'
import DTOAnexoFacturacionAlen from '../../../../DTO/Facturacion/Anexo/DTOAnexoFacturacionAlen'
import * as CurrencyFormat from 'react-currency-format'

export interface IRptRelacionAlenProps {}

export default function RptRelacionAlen(props: IRptRelacionAlenProps) {
  const [Referencia, setReferencia] = useState('')
  const [Data, setData] = useState<IAnexoFacturacionAlen[]>([])
  const [Coordinacion, setCoordinacion] = useState(0)
  const [Validacion, setValidacion] = useState(0)
  const [Prevalidacion, setPrevalidacion] = useState(0)
  const [Honorarios, setHonorarios] = useState(0)
  const [Total, setTotal] = useState(0)
  const columnsConcepts = [
    {
      name: 'Referencia',
      width: '110px',
      cell: (row: IAnexoFacturacionAlen) => row.sReferencia,
      sortable: true
    },
    {
      name: 'Remesa',
      width: '90px',
      cell: (row: IAnexoFacturacionAlen) => row.remesa,
      sortable: true
    },
    {
      name: 'Entrega',
      width: '200px',
      cell: (row: IAnexoFacturacionAlen) => row.entrega,
      sortable: true
    },
    {
      name: 'Numero',
      width: '180px',
      cell: (row: IAnexoFacturacionAlen) => row.sNumero,
      sortable: true
    },
    {
      name: 'Fecha',
      width: '100px',
      cell: (row: IAnexoFacturacionAlen) => row.dFecha,
      sortable: true
    },
    {
      name: 'eDocument',
      width: '140px',
      cell: (row: IAnexoFacturacionAlen) => row.sEDocument,
      sortable: true
    },
    {
      name: 'Val factura',
      width: '105px',
      cell: (row: IAnexoFacturacionAlen) => row.valorFactura,
      sortable: true
    },
    {
      name: 'Pedimento',
      width: '110px',
      cell: (row: IAnexoFacturacionAlen) => row.sPedimento,
      sortable: true
    },
    {
      name: 'Cruce',
      width: '150px',
      cell: (row: IAnexoFacturacionAlen) => row.cruce,
      sortable: true
    },
    {
      name: 'Semaforo',
      width: '125px',
      cell: (row: IAnexoFacturacionAlen) => row.semaforo,
      sortable: true
    },
    {
      name: 'Fecha Cta Corresponsal',
      width: '125px',
      cell: (row: IAnexoFacturacionAlen) => row.fechaCtaCorresponsal,
      sortable: true
    },
    {
      name: 'Honorarios',
      width: '110px',
      cell: (row: IAnexoFacturacionAlen) =>
        (Math.round(row.honorarios * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'Maniobras',
      width: '120px',
      cell: (row: IAnexoFacturacionAlen) =>
        (Math.round(row.maniobras * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'Validacion',
      width: '120px',
      cell: (row: IAnexoFacturacionAlen) =>
        (Math.round(row.validacion * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'Prevalidacion',
      width: '120px',
      cell: (row: IAnexoFacturacionAlen) =>
        (Math.round(row.prevalidacion * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'Coordinacion',
      width: '120px',
      cell: (row: IAnexoFacturacionAlen) =>
        (Math.round(row.coordinacion * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'IVA',
      width: '120px',
      cell: (row: IAnexoFacturacionAlen) =>
        (Math.round(row.iva * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'Total MN',
      width: '120px',
      cell: (row: IAnexoFacturacionAlen) =>
        (Math.round(row.totalMN * 100) / 100).toFixed(2),
      sortable: true
    }
  ]

  const downloadExcel = () => {
    exportExcel(Data, 'Relacion Alen ' + Referencia)
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    let Heading = [
      [
        'Referencia',
        'Remesa',
        'Entrega',
        'Numero',
        'Fecha',
        'eDocument',
        'Valor factura',
        'Pedimento',
        'Cruce',
        'Semaforo',
        'Fecha Cta Corresponsal',
        'Honorarios',
        'Maniobras',
        'Validacion',
        'Prevalidacion',
        'Coordinacion',
        'IVA',
        'Total MN'
      ]
    ]
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const generaReporte = () => {
    const data: DTOAnexoFacturacionAlen = {
      sReferencia: Referencia,
      nHonorarios: Honorarios,
      nValidacion: Validacion,
      nPrevalidacion: Prevalidacion,
      nCoordinacion: Coordinacion
    }
    DSAnexoFacturacion.GetAlen(data)
      .then((response) => {
        setData(response.data)
        setTotal(response.data.length)
      })
      .catch((e: Error) => {
        console.log(e)
      })
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col xs={3}>
                    Relacion Alen : {Total > 0 ? Total - 1 : Total} registros
                  </Col>
                  <Col xs={8}></Col>
                  <Col xs={1} className="colAlignRight">
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => {
                        downloadExcel()
                      }}
                    >
                      <BsFileEarmarkExcel />
                      &nbsp;Excel
                    </Button>
                  </Col>
                </Row>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <Row>
                  <Col className="colAlignRight">Referencia: </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      size="sm"
                      placeholder="Referencia"
                      onChange={(e: any) => {
                        setReferencia(e.target.value)
                      }}
                    />
                  </Col>
                  <Col className="colAlignRight">Honorarios:</Col>
                  <Col>
                    {/* <Form.Control
                      type='number'
                      size='sm'
                      placeholder='Honorarios'
                      onChange={(e: any) => {
                        setHonorarios(e.target.value)
                      }}
                    /> */}
                    <CurrencyFormat
                      value={Honorarios}
                      displayType={'input'}
                      thousandSeparator={true}
                      decimalScale={2}
                      onValueChange={(values: any) => {
                        const { value } = values
                        setHonorarios(value)
                      }}
                      style={{
                        fontSize: '18px',
                        backgroundColor: '#F5FFED',
                        border: '2px solid #25D05B',
                        width: '100px',
                        textAlign: 'right',
                        borderRadius: '10px'
                      }}
                    />
                  </Col>
                  <Col className="colAlignRight">Validacion:</Col>
                  <Col>
                  {/*   <Form.Control
                      type="number"
                      size="sm"
                      placeholder="Validacion"
                      onChange={(e: any) => {
                        setValidacion(e.target.value)
                      }}
                    /> */}
                     <CurrencyFormat
                      value={Validacion}
                      displayType={'input'}
                      thousandSeparator={true}
                      decimalScale={2}
                      onValueChange={(values: any) => {
                        const { value } = values
                        setValidacion(value)
                      }}
                      style={{
                        fontSize: '18px',
                        backgroundColor: '#F5FFED',
                        border: '2px solid #25D05B',
                        width: '100px',
                        textAlign: 'right',
                        borderRadius: '10px'
                      }}
                      />
                  </Col>
                  <Col className="colAlignRight">Prevalidacion:</Col>
                  <Col>
                    {/* <Form.Control
                      type="number"
                      size="sm"
                      placeholder="Prevalidacion"
                      onChange={(e: any) => {
                        setPrevalidacion(e.target.value)
                      }}
                    /> */}
                     <CurrencyFormat
                      value={Prevalidacion}
                      displayType={'input'}
                      thousandSeparator={true}
                      decimalScale={2}
                      onValueChange={(values: any) => {
                        const { value } = values
                        setPrevalidacion(value)
                      }}
                      style={{
                        fontSize: '18px',
                        backgroundColor: '#F5FFED',
                        border: '2px solid #25D05B',
                        width: '100px',
                        textAlign: 'right',
                        borderRadius: '10px'
                      }}
                      />
                  </Col>
                  <Col className="colAlignRight">Coordinacion:</Col>
                  <Col>
                   {/*  <Form.Control
                      type="number"
                      size="sm"
                      placeholder="Coordinacion"
                      onChange={(e: any) => {
                        setCoordinacion(e.target.value)
                      }}
                    /> */}
                     <CurrencyFormat
                      value={Coordinacion}
                      displayType={'input'}
                      thousandSeparator={true}
                      decimalScale={2}
                      onValueChange={(values: any) => {
                        const { value } = values
                        setCoordinacion(value)
                      }}
                      style={{
                        fontSize: '18px',
                        backgroundColor: '#F5FFED',
                        border: '2px solid #25D05B',
                        width: '100px',
                        textAlign: 'right',
                        borderRadius: '10px'
                      }}
                      />
                  </Col>
                  <Col>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        generaReporte()
                      }}
                    >
                      Genera reporte
                    </Button>
                  </Col>
                </Row>
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DataTable
            dense
            noHeader
            defaultSortFieldId={'cliente'}
            defaultSortAsc={true}
            fixedHeaderScrollHeight="550px"
            striped={true}
            fixedHeader
            /*                 paginationPerPage={10}
          pagination */
            highlightOnHover
            columns={columnsConcepts}
            data={Data}
            pointerOnHover
          />
        </Col>
      </Row>
    </div>
  )
}

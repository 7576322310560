import { FC, useState } from 'react'
// Bootstrap components
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap'
// Interfaces
import IFotosBodega from '../../Interfaces/IFotosBodega'
//Services
import archivoElectronicoServices from '../../Services/Catalogos/ArchivoElectronico.Services'
// GUI components
import '../../css/imageModal.css'
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import { TargetURL } from '../../Constants/TargetURL'
const URL = new TargetURL()

interface IProps {}
export default function FotosBodega(props: IProps) {
  const [API, setAPI] = useState(URL.get() + '/ArchivoElectronico/getPictureContent?Nombre=')
  const [Referencia, setReferencia] = useState('')
  const [imageFullSize, setImageFullSize] = useState('')
  const [show, setShow] = useState(false)
  const [FileList, setFileList] = useState<Array<IFotosBodega>>([])
  const [MsgShow, setMsgShow] = useState(false)
  const [MsgTxt, setMsgTxt] = useState('')
  const [MsgAlerta, setMsgAlerta] = useState('Alerta')
  const [MsgColor, setMsgColor] = useState('primary')

  function getPictureInfo() {
    if (Referencia.length === 0) {
      setMsgTxt('Proporcione la referencia por favor...')
      setMsgShow(true)
      return false
    }
    const data: IFotosBodega = {
      id: 0,
      nombre: null,
      referencia: Referencia,
      registrado: null,
      proceso: null,
      creado: null,
      usuario: null,
      comentarios: null,
      activo: 1,
    }
    archivoElectronicoServices
      .getInfoPicturesFromWarehouse(data)
      .then((response) => {
        setFileList(response.data)
        console.log(response.data)
      })
      .catch((e: Error) => {
        console.log(e)
        return
      })
  }

  function showFullSize(picture: any) {
    setImageFullSize(picture)
    setShow(true)
  }

  function getProcessName(proc: any): string {
    let tipoProceso = ''
    if (proc == 1) tipoProceso = 'CAJA LLEGO'
    if (proc == 2) tipoProceso = 'RECIBO ASI LLEGO'
    if (proc == 3) tipoProceso = 'REVISION'
    if (proc == 4) tipoProceso = 'ASI SE FUE'
    if (proc == 5) tipoProceso = 'DAÑOS SIN GEMCO'
    if (proc == 6) tipoProceso = 'ETIQUETADO'
    if (proc == 7) tipoProceso = 'MANIOBRAS ARCOSA'
    return tipoProceso
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-2'>
              <Form.Control
                type='text'
                name='Referencia'
                placeholder='Proporcione la referencia'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setReferencia(e.target.value)}
              />
            </div>
            <div className='col'>
              <Button
                variant='primary'
                onClick={() => {
                  getPictureInfo()
                }}
              >
                Ver fotos
              </Button>
            </div>
            <div className='col-md-4'></div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div>
        <Container>
          <Row xs={1} md={3} className='g-4'>
            {FileList
              ? FileList.map((picture, index) => (
                  <Col>
                    <Card style={{ width: '18rem' }}>
                      <Card.Header className='text-center'>{picture.creado}</Card.Header>
                      <Card.Body>
                        <Card.Img
                          variant='top'
                          src={`${API + picture.nombre}`}
                          onClick={() => showFullSize(picture.nombre)}
                        />
                        <h6 className='text-center'>{picture.comentarios}</h6>
                      </Card.Body>
                      <Card.Footer className='text-center' as='h5'>
                        {getProcessName(picture.proceso)}
                      </Card.Footer>
                    </Card>
                  </Col>
                ))
              : null}
          </Row>
        </Container>
      </div>
      <Modal show={show} centered={true} animation={true} onHide={() => setShow(false)} contentClassName='image-modal'>
        <Modal.Header closeButton>
          <Modal.Title id='example-custom-modal-styling-title'></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={`${API + imageFullSize}`} width='750' height='1000' className='justify-content-center' />
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={MsgShow}
        msg={MsgTxt}
        header={MsgAlerta}
        msgColor={MsgColor}
        closeToast={function (arg: boolean): void {
          setMsgShow(false)
        }}
      />
    </div>
  )
}

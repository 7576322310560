import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Dropdown,
  FloatingLabel,
  Form,
  Modal,
  Row
} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { idText } from 'typescript'
import INotificacionesContactos from '../../../Interfaces/Utils/INotificacionesContactos'
import INotificacionesGrupos from '../../../Interfaces/Utils/INotificacionesGrupos'
import { MsgInformativo } from '../Toast/msgInformativo'
import NotificacionesDS from '../../../Services/Utils/Notificaciones/Notificaciones.Services'
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlineFileSearch,
  AiOutlineProfile,
  AiOutlineTeam,
  AiOutlineUsergroupAdd
} from 'react-icons/ai'
import { IconContext } from 'react-icons'
import { HiCurrencyDollar } from 'react-icons/hi'
import DTONotificacionesContactoGrupo from '../../../DTO/Utils/Notificaciones/DTONotificacionesContactoGrupo'
import { number } from 'yup/lib/locale'
import INotificacionesConceptos from '../../../Interfaces/Utils/INotificacionesConceptos'
import INotificacionesLog from '../../../Interfaces/Utils/INotificacionesLog'
import { FaCaretSquareDown, FaCaretSquareLeft } from 'react-icons/fa'

export interface INotificacionesProps {}

export default function Notificaciones(props: INotificacionesProps) {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [selectedRows, setSelectedRows] = React.useState([])
  const [Log, setLog] = useState<INotificacionesLog[]>([])
  const [Grupos, setGrupos] = useState<INotificacionesGrupos[]>([])
  const [Contactos, setContactos] = useState<INotificacionesContactos[]>([])
  const [CatConceptos, setCatConceptos] = useState<INotificacionesConceptos[]>(
    []
  )
  const [ContactosdelGrupo, setContactosdelGrupo] = useState<
    INotificacionesContactos[]
  >([])
  const [Filtro, setFiltro] = useState('')
  const [FiltroEnGrupo, setFiltroEnGrupo] = useState('')
  const [Grupo, setGrupo] = useState('')
  const [IdGrupo, setIdGrupo] = useState(0)
  const [Concepto, setConcepto] = useState('')
  const [IdConcepto, setIdConcepto] = useState(0)
  const [IdContacto, setIdContacto] = useState(0)
  const [Nombre, setNombre] = useState('')
  const [Puesto, setPuesto] = useState('')
  const [Pais, setPais] = useState('+52')
  const [Celular, setCelular] = useState('')
  const [Empresa, setEmpresa] = useState('')
  const [Activo, setActivo] = useState(1)
  const [ShowModal, setShowModal] = useState(false)
  const [ShowModalG, setShowModalG] = useState(false)
  const [ShowModalC, setShowModalC] = useState(false)
  const [ShowModalLog, setShowModalLog] = useState(false)
  const [SelectedConcepto, setSelectedConcepto] = useState(true)
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const msgColor = 'primary'

  const colContactos = [
    {
      name: 'id',
      width: '60px',
      cell: (row: INotificacionesContactos) => (
        <div style={{ width: '100%' }} onClick={() => {}}>
          {row.id}
        </div>
      ),
      sortable: true
    },
    {
      name: 'Celular',
      width: '130px',
      cell: (row: INotificacionesContactos) => (
        <div style={{ width: '100%' }} onClick={() => {}}>
          {(row.pais ? row.pais : '') + row.celular}
        </div>
      ),
      sortable: true
    },
    {
      name: 'Nombre',
      width: '150px',
      cell: (row: INotificacionesContactos) => (
        <div style={{ width: '100%' }} onClick={() => {}}>
          {row.nombre}
        </div>
      ),
      sortable: true
    },
    {
      name: 'Empresa',
      width: '200px',
      cell: (row: INotificacionesContactos) => (
        <div style={{ width: '100%' }} onClick={() => {}}>
          {row.empresa}
        </div>
      ),
      sortable: true
    },
    {
      name: 'Puesto',
      width: '200px',
      cell: (row: INotificacionesContactos) => (
        <div style={{ width: '100%' }} onClick={() => {}}>
          {row.puesto}
        </div>
      ),
      sortable: true
    },
    {
      name: 'Activo',
      width: '125px',
      cell: (row: INotificacionesContactos) => (
        <div style={{ width: '100%' }} onClick={() => {}}>
          {row.activo == 1 ? 'Activo' : 'Inactivo'}
        </div>
      ),
      sortable: true
    }
  ]

  const colLog = [
    {
      name: 'id',
      width: '60px',
      cell: (row: INotificacionesLog) => <div>{row.id}</div>,
      sortable: true
    },
    {
      name: 'Envia',
      width: '130px',
      cell: (row: INotificacionesLog) => <div>{row.envia}</div>,
      sortable: true
    },
    {
      name: 'Concepto',
      width: '300px',
      cell: (row: INotificacionesLog) => <div>{row.concepto}</div>,
      sortable: true
    },
    {
      name: 'Enviado a...',
      width: '600px',
      cell: (row: INotificacionesLog) => <div>{row.contactos}</div>,
      sortable: true
    },
    {
      name: 'Fecha / hora',
      width: '200px',
      cell: (row: INotificacionesLog) => <div>{row.fhCreacion}</div>,
      sortable: true
    }
  ]

  const saveContact = () => {
    if (!Nombre) {
      setHeader('Error')
      setMsg('Favor de proporcionar el nombre')
      setShowMsg(true)
      return
    }
    const data: INotificacionesContactos = {
      id: IdContacto,
      nombre: Nombre,
      puesto: Puesto,
      pais: Pais,
      celular: Celular,
      empresa: Empresa,
      activo: Activo
    }
    NotificacionesDS.ContactosAppend(data)
      .then((response) => {
        setHeader('Informativo')
        setMsg('Se encontro la siguiente informacion...')
        setShowMsg(true)
        loadAllContacts()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveGroup = () => {
    if (!Grupo) {
      setHeader('Error')
      setMsg('Favor de proporcionar el nombre')
      setShowMsg(true)
      return
    }
    const data: INotificacionesGrupos = {
      id: IdContacto,
      grupo: Grupo,
      activo: 1
    }
    NotificacionesDS.GruposAppend(data)
      .then((response) => {
        setHeader('Informativo')
        setMsg('Se encontro la siguiente informacion...')
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveConcept = () => {
    if (!Concepto) {
      setHeader('Error')
      setMsg('Favor de proporcionar el concepto')
      setShowMsg(true)
      return
    }
    const data: INotificacionesConceptos = {
      id: IdConcepto,
      concepto: Concepto,
      activo: 1
    }
    NotificacionesDS.Conceptos$Append(data)
      .then((response) => {
        setHeader('Informativo')
        setMsg('Se encontro la siguiente informacion...')
        loadAllConcepts()
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadAllContacts = () => {
    NotificacionesDS.ContactosGet()
      .then((response) => {
        setContactos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadAllConcepts = () => {
    NotificacionesDS.Conceptos$Get()
      .then((response) => {
        setCatConceptos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadGroupContacts = () => {
    NotificacionesDS.ContactosdelGrupoGet(IdGrupo)
      .then((response) => {
        setContactosdelGrupo(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadGroups = () => {
    NotificacionesDS.GruposGet()
      .then((response) => {
        setGrupos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    loadAllContacts()
    loadGroups()
    loadAllConcepts()
  }, [])

  useEffect(() => {
    if (ShowModalLog) {
      NotificacionesDS.Log$Get()
        .then((response) => {
          setLog(response.data)
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }
  }, [ShowModalLog])

  useEffect(() => {
    if (ShowModal) {
      if (selectedRows.length > 0) {
        const listaContactos: INotificacionesContactos[] = selectedRows
        setIdContacto(listaContactos[0].id)
        setNombre(listaContactos[0].nombre)
        setPuesto(listaContactos[0].puesto)
        setEmpresa(listaContactos[0].empresa)
        setCelular(listaContactos[0].celular)
        setActivo(listaContactos[0].activo)
      }
    }
  }, [ShowModal])

  useEffect(() => {
    loadGroupContacts()
  }, [IdGrupo])

  useEffect(() => {
    if (Concepto.length === 0) {
      setIdConcepto(0)
    }
  }, [Concepto])

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows)
  }, [])

  const asignaContactoAlGrupo = (accion: number) => {
    if (IdGrupo === 0) {
      setHeader('Error')
      setMsg(`Antes de asignar un usuario a un grupo, seleccione el grupo`)
      setShowMsg(true)
      return
    }
    const listaContactos: INotificacionesContactos[] = selectedRows
    var contactos: number[] = []
    listaContactos.forEach((row) => {
      contactos.push(row.id)
    })
    const data: DTONotificacionesContactoGrupo = {
      idgrupo: IdGrupo,
      accion,
      contactos
    }
    NotificacionesDS.AsignacionContactosGrupos(data)
      .then((response) => {
        setHeader('Informativo')
        setMsg(
          accion === 1
            ? `Se agregaron los contactos al grupo`
            : `Se eliminaron los contactos del grupo`
        )
        setShowMsg(true)
        loadGroupContacts()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const sendWhattsapp = () => {
    if (IdConcepto === 0) {
      setHeader('Error')
      setMsg('Para enviar un concepto primero debe guardarlo en el catalogo')
      setShowMsg(true)
      return
    }
    if (IdGrupo === 0) {
      setHeader('Error')
      setMsg('Para enviar un mensaje primero debe seleccionar el grupo')
      setShowMsg(true)
      return
    }
    NotificacionesDS.SendWhattsapp(UserId, Concepto, IdGrupo)
      .then((response) => {
        setHeader('Informativo')
        setMsg(`El mensaje se ha enviado satisfactoriamente`)
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 40) {
      console.log('arrow down')
    }
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>
            Notificaciones{' '}
            <span
              style={{ paddingLeft: '5px', cursor: 'pointer' }}
              onClick={() => {
                setShowModalLog(!ShowModalLog)
              }}
            >
              <IconContext.Provider value={{ color: '#5923F6', size: '25px' }}>
                <AiOutlineFileSearch />
              </IconContext.Provider>
            </span>
          </Card.Title>
          <Row>
            <Col xs={1}>
              Mensaje
              <span
                style={{ paddingLeft: '5px', cursor: 'pointer' }}
                onClick={() => {
                  setShowModalC(!ShowModalC)
                }}
              >
                <IconContext.Provider
                  value={{ color: '#23A0F6', size: '25px' }}
                >
                  <AiOutlineProfile />
                </IconContext.Provider>
              </span>
            </Col>
            <Col xs={5}>
              <div className="mainDiv">
                <Form.Group className="typeahead-form-group">
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    id="Concepto"
                    value={Concepto}
                    size="sm"
                    className="pull-right"
                    onChange={(e) => {
                      setConcepto(e.target.value)
                      setSelectedConcepto(false)
                    }}
                    onKeyDown={(e) => {
                      handleKeyDown(e)
                    }}
                  />
                  <Dropdown
                    className="d-inline mx-6"
                    show={!SelectedConcepto}
                  >
                    <Dropdown.Menu>
                      {CatConceptos.filter((result) =>
                        result.concepto
                          .toLocaleLowerCase()
                          .includes(Concepto.toLocaleLowerCase())
                      ).map((result) => (
                        <Dropdown.Item
                          onClick={() => {
                            setIdConcepto(result.id)
                            setConcepto(result.concepto)
                            setSelectedConcepto(true)
                          }}
                        >
                          {result.concepto}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
            </Col>
            <Col style={{ paddingLeft: '0px' }}>
              <div onClick={() => setSelectedConcepto(!SelectedConcepto)}>
                {!SelectedConcepto ? (
                  <IconContext.Provider value={{ color: '#8AA2B8', size: '30px' }}>
                    <FaCaretSquareDown />
                  </IconContext.Provider>
                ) : (
                  <IconContext.Provider value={{ color: '#8AA2B8', size: '30px' }}>
                    <FaCaretSquareLeft />
                  </IconContext.Provider>
                )}{' '}
              </div>
            </Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              Grupo
              <span
                style={{ paddingLeft: '5px', cursor: 'pointer' }}
                onClick={() => {
                  setShowModalG(!ShowModalG)
                }}
              >
                <IconContext.Provider
                  value={{ color: '#31C50D', size: '25px' }}
                >
                  <AiOutlineTeam />
                </IconContext.Provider>
              </span>
            </Col>
            <Col xs={3}>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setIdGrupo(parseInt(e.target.value))
                }}
                className="form-select form-select-sm"
              >
                <option value="0">-SELECCIONE-</option>
                {Grupos
                  ? Grupos.map((c) => {
                      return (
                        <option value={c.id} key={c.id}>
                          {c.grupo}
                        </option>
                      )
                    })
                  : null}
              </Form.Control>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  sendWhattsapp()
                }}
              >
                Enviar
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>&nbsp;</Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    <Row>
                      <Col xs={3}>
                        Contactos
                        <span
                          style={{ paddingLeft: '10px', cursor: 'pointer' }}
                          onClick={() => {
                            setShowModal(!ShowModal)
                          }}
                        >
                          <IconContext.Provider
                            value={{ color: '#F6A323', size: '25px' }}
                          >
                            <AiOutlineUsergroupAdd />
                          </IconContext.Provider>
                        </span>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder="Search..."
                          size="sm"
                          onChange={(e) => {
                            setFiltro(e.target.value)
                            console.log(Filtro)
                          }}
                        />
                      </Col>
                    </Row>
                  </Card.Title>
                  <Row>
                    <Col xs={12}>
                      <DataTable
                        noHeader
                        defaultSortFieldId={'id'}
                        defaultSortAsc={true}
                        striped={true}
                        dense={true}
                        paginationPerPage={10}
                        pagination
                        highlightOnHover
                        selectableRows
                        columns={colContactos}
                        data={Contactos.filter(
                          (obj1) =>
                            !ContactosdelGrupo.some(
                              (obj2) => obj1.id === obj2.id
                            )
                        ).filter(function (row) {
                          return (
                            row.celular.includes(Filtro) ||
                            row.nombre.includes(Filtro) ||
                            row.puesto.includes(Filtro) ||
                            row.empresa.includes(Filtro)
                          )
                        })}
                        pointerOnHover
                        onSelectedRowsChange={handleRowSelected}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col style={{ textAlign: 'center' }}>
                  <Button
                    title="Presione aqui para asignar usuario al grupo"
                    onClick={() => {
                      asignaContactoAlGrupo(1)
                    }}
                  >
                    <AiFillCaretRight />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col style={{ textAlign: 'center' }}>
                  <Button>
                    <AiFillCaretLeft
                      title="Presione aqui para desasignar usuario del grupo"
                      onClick={() => {
                        asignaContactoAlGrupo(0)
                      }}
                    />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={5}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    <Row>
                      <Col xs={5}>Contactos en el grupo</Col>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder="Search..."
                          size="sm"
                          onChange={(e) => {
                            setFiltroEnGrupo(e.target.value)
                          }}
                        />
                      </Col>
                    </Row>
                  </Card.Title>
                  <Row>
                    <Col xs={12}>
                      <DataTable
                        noHeader
                        defaultSortFieldId={'id'}
                        defaultSortAsc={true}
                        striped={true}
                        dense={true}
                        paginationPerPage={10}
                        pagination
                        highlightOnHover
                        selectableRows
                        columns={colContactos}
                        data={ContactosdelGrupo.filter(function (row) {
                          return (
                            row.celular.includes(FiltroEnGrupo) ||
                            row.nombre.includes(FiltroEnGrupo) ||
                            row.puesto.includes(FiltroEnGrupo) ||
                            row.empresa.includes(FiltroEnGrupo)
                          )
                        })}
                        pointerOnHover
                        onSelectedRowsChange={handleRowSelected}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
        closeButton
      >
        <Modal.Body>
          <div style={{ height: '270px', paddingTop: '50px' }}>
            <Card>
              <Row>
                <Col xs={2}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="id"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="id"
                      value={IdContacto}
                      style={{ height: '45px' }}
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={10}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nombre"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Nombre del contacto"
                      value={Nombre}
                      style={{ height: '45px' }}
                      onChange={(e: any) => {
                        setNombre(e.target.value)
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={7}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Puesto"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Puesto"
                      value={Puesto}
                      style={{ height: '45px' }}
                      onChange={(e: any) => {
                        setPuesto(e.target.value)
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={2}>
                  <FloatingLabel controlId="floatingSelect" label="LADA">
                    <Form.Select
                      aria-label="Floating label select example"
                      style={{ height: '55px' }}
                      value={Pais}
                      onChange={(e: any) => {
                        setPais(e.target.value)
                      }}
                    >
                      <option value="+52">MEX</option>
                      <option value="+1">USA</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Celular"
                    className=""
                  >
                    <Form.Control
                      type="text"
                      placeholder="Celular"
                      value={Celular}
                      maxLength={10}
                      style={{ height: '45px' }}
                      onChange={(e: any) => {
                        setCelular(e.target.value)
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={8}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Empresa"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Empresa"
                      value={Empresa}
                      onChange={(e: any) => {
                        setEmpresa(e.target.value)
                      }}
                      style={{ height: '45px' }}
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={2}>
                  <FloatingLabel controlId="floatingSelect" label="Activo?">
                    <Form.Select
                      aria-label="Floating label select example"
                      style={{ height: '55px' }}
                    >
                      <option value="1">Si</option>
                      <option value="0">No</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col xs={2} style={{ paddingTop: `10px` }}>
                  <Button
                    onClick={() => {
                      saveContact()
                    }}
                  >
                    Guardar
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ShowModalG}
        onHide={() => {
          setShowModalG(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
        closeButton
      >
        <Modal.Body>
          <div style={{ height: '70px', paddingTop: '10px' }}>
            <Row>
              <Col xs={10}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Grupo"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Grupo"
                    value={Grupo}
                    onChange={(e: any) => {
                      setGrupo(e.target.value)
                    }}
                    style={{ height: '45px' }}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={2}>
                <Button
                  onClick={() => {
                    saveGroup()
                  }}
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ShowModalC}
        onHide={() => {
          setShowModalC(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
        closeButton
      >
        <Modal.Body>
          <div style={{ height: '70px', paddingTop: '10px' }}>
            <Row>
              <Col xs={10}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Concepto"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Concepto"
                    value={Concepto}
                    onChange={(e: any) => {
                      setConcepto(e.target.value)
                    }}
                    style={{ height: '45px' }}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={2}>
                <Button
                  onClick={() => {
                    saveConcept()
                  }}
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ShowModalLog}
        onHide={() => {
          setShowModalLog(false)
        }}
        size={'sm'}
        dialogClassName={'modal-90w'}
        closeButton
      >
        <Modal.Body>
          <div style={{ height: '600px', paddingTop: '10px' }}>
            <Row>
              <Col xs={12}>
                <DataTable
                  noHeader
                  defaultSortFieldId={'id'}
                  defaultSortAsc={true}
                  striped={true}
                  dense={true}
                  paginationPerPage={10}
                  pagination
                  highlightOnHover
                  columns={colLog}
                  data={Log}
                  pointerOnHover
                  onSelectedRowsChange={handleRowSelected}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import http from '../common/http-common'
import { IRptPedimentosPagados } from '../../Interfaces/Reportes/IRptPedimentosPagados'
import { IRptPedimentosPagadosImpuestos } from '../../Interfaces/Reportes/IRptPedimentosPagadosImpuestos'
import { IRptDetalleAnexo24 } from '../../Interfaces/Reportes/RptDetalleAnexo24'
import { IRptOperacionesRemesa } from '../../Interfaces/Reportes/RptOperacionesRemesa'
import IRptBattery from '../../Interfaces/Reportes/IRptBattery'
import DTOReporte from '../../DTO/DTOReporte'
import DTOAEPeriodo from '../../DTO/DTOAEPeriodo'
import IRpArchivoElectronicoOficial from '../../Interfaces/Reportes/IRptArchivoElectronicoOficial'
import IRptPedimentosConsolidados from '../../Interfaces/Reportes/IRptPedimentosConsolidados'
import DTOCorresponsalTrafico from '../../DTO/Corresponsales/DTOCorresponsalTrafico'
import DTORptCorresponsalesTraficos from '../../DTO/Corresponsales/DTORptCorresponsalesTraficos'
import ICorresponsalTrafico from '../../Interfaces/Corresponsales/ICorresponsalTrafico'
import IRptOperaciones from '../../Interfaces/Reportes/IRptOperaciones'
import IRptOperacionesConsolidadas from '../../Interfaces/Reportes/IRptOperacionesConsolidadas'
import IRptOperacionesDiarias from '../../Interfaces/Reportes/IRptOperacionesDiarias'
import { IRptPedimentosPagadosFacturasCruzadas } from '../../Interfaces/Reportes/IRptPedimentosPagadosFacturasCruzadas'
import {  IRptMonitoreoFacturaPagadasAmazon  } from '../../Interfaces/Reportes/IRptMonitoreoFacturasPagadasAmazon'
import DTORPTMonitorFacturasPagAmazon from '../../DTO/Utils/DTORPTMonitorFacturasPagAmazon'
import { IRptFacturasFraccion } from '../../Interfaces/Reportes/IRptContsFacturasFraccion'
import { data } from 'cypress/types/jquery'
import IRptMision from '../../Interfaces/Reportes/IRptOperacionesPagadasMisionFood'
import IRptOperacionesPagadasMissionFood from '../../Interfaces/Reportes/IRptOperacionesPagadasMisionFood'
import IRptFacturasPedimentosPagados from '../../Interfaces/Reportes/IRptFacturasPedimentosPagados'
import IRptArcosa from '../../Interfaces/Reportes/IRptArcosa'
class reportsDataService {
  getRptPedimentosPagados(data: DTOReporte) {
    return http.post<Array<IRptPedimentosPagados>>(
      '/reportes/RptPedimentosPagados',
      data
    )
  }
  getRptPedimentosPagadosImpuestos(data: DTOReporte) {
    return http.post<Array<IRptPedimentosPagadosImpuestos>>(
      '/reportes/RptPedimentosPagadosImpuestos',
      data
    )
  }
  getRptDetalleAnexo24(data: DTOReporte) {
    return http.post<Array<IRptDetalleAnexo24>>(
      '/reportes/RptDetalleAnexo24',
      data
    )
  }
  getRptOperacionesRemesa() {
    return http.get<Array<IRptOperacionesRemesa>>(
      '/reportes/RptOperacionesRemesa'
    )
  }
  getRptPedimentosConsolidados(data: DTOReporte) {
    return http.post<Array<IRptPedimentosConsolidados>>(
      `/reportes/RptPedimentosConsolidados`,
      data
    )
  }
  getRptBattery(data: DTOReporte) {
    return http.get<Array<IRptBattery>>(
      `/Battery/getWarehouseReport?Inicio=${data.Inicio}&Fin=${data.Fin}&TipoOperacion=${data.TipoOperacion}`
    )
  }
  getRptArchivoElectronicoOficial(data: DTOAEPeriodo) {
    return http.get<Array<IRpArchivoElectronicoOficial>>(
      `/ArchivoElectronico/RptArchivoElectronicoOficial?Anio=${data.Anio}&Mes=${data.Mes}&TipoOperacion=${data.TipoOperacion}&NoCliente=${data.NoCliente}`
    )
  }
  getRptCorresponsalesTraficos(data: DTORptCorresponsalesTraficos) {
    return http.get<ICorresponsalTrafico[]>(
      `/reportes/RptCorresponsalesTraficos?Inicio=${data.Inicio}&Fin=${data.Fin}&TipoOperacion=${data.TipoOperacion}&NoCliente=${data.NoCliente}&IdCorresponsal=${data.IdCorresponsal}&Proceso=${data.Proceso}&Modo=${data.Modo}`
    )
  }
  getRptOperaciones(data: DTOReporte) {
    return http.get<IRptOperaciones[]>(
      `/reportes/RptOperaciones?Inicio=${data.Inicio}&Fin=${data.Fin}&TipoOperacion=${data.TipoOperacion}&NoCliente=${data.NoCliente}`
    )
  }
  getRptOperacionesConsolidadadas(data: DTOReporte) {
    return http.get<IRptOperacionesConsolidadas[]>(
      `/reportes/RptOperacionesConsolidadas?Inicio=${data.Inicio}&Fin=${data.Fin}&TipoOperacion=${data.TipoOperacion}&NoCliente=${data.NoCliente}`
    )
  }
  GenerateRptControlDocumentosClientes() {
    return http
      .get<Boolean>(`/reportes/RptGenerateControlDocumentosClientes`, {
        responseType: 'arraybuffer'
      })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  GetRptControlDocumentosClientes() {
    return http
      .get<ArrayBuffer>(`/reportes/RptGetCtrlDocumentosClientes`, {
        responseType: 'arraybuffer'
      })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  getRptOperacionesDiarias(data: DTOReporte) {
    return http.get<IRptOperacionesDiarias[]>(
      `/reportes/RptOperacionesDiarias?Inicio=${data.Inicio}&Fin=${data.Fin}&TipoOperacion=${data.TipoOperacion}&NoCliente=${data.NoCliente}`
    )
  }
  getRptPedimentosPagadosFacturasCruzadas(data: DTOReporte) {
    return http.post<Array<IRptPedimentosPagadosFacturasCruzadas>>(
      '/reportes/RptPedimentosPagadosFacturasCruzadas',
      data
    )
  }

  getRptMonitoreoFacturasPagadasAmazon(data: DTORPTMonitorFacturasPagAmazon) {
    return http.post<Array<IRptMonitoreoFacturaPagadasAmazon>>(
      '/reportes/RptMonitoreoFacturasPagadasAmazon',
      data
    )
  }

  getRptContsFacturasFraccion(Referencia: string)  
  {
    return http.get<Array<IRptFacturasFraccion>>(
      `/reportes/RptContsFacturasConFraccion?Referencia=${Referencia}`
    );
    
  }

  getRptOPeracionesPagadasMisionFood(data: DTOReporte) {
    return http.post<Array<IRptOperacionesPagadasMissionFood>>(
      '/reportes/RptOperacionesPagadasMissionFood', data
    ); 
  }

 getRptFacturasPedimentosPagados(data: DTOReporte){
  return http.post<Array<IRptFacturasPedimentosPagados>>(
    '/reportes/RptFacturasPedimetosPagados', data 
  )
 }
  
 getRptArcosa(data: DTOReporte){
  return http.post<Array<IRptArcosa>>(
    '/reportes/RptArcosa', data 
  ); 
 }
  

}
export default new reportsDataService()

import React, { FC, useEffect, useState } from 'react'
// Bootstrap components
import { Alert, Button, Card, Form, Modal } from 'react-bootstrap'
import { BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
// Interfaces
import IClientes from '../../Interfaces/Catalogos/IClientes'
// Services
import reportsDataService from '../../Services/Reportes/reportes.services'
import ClientesDataService from '../../Services/Catalogos/Clientes.Services'
// GUI components
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import { AgGridReact } from 'ag-grid-react'
import * as XLSX from 'xlsx'
import loadingImg from '../../images/ajaxloader.gif'
import IRptOperaciones from '../../Interfaces/Reportes/IRptOperaciones'
import DTOReporte from '../../DTO/DTOReporte'
import moment from 'moment'
import { VerPDFv2 as verPDF } from './customCells/VerPDFv2'

interface IProps {}

export default function RptOperaciones (props: IProps) {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Inicio, setInicio] = useState(currentDate())
  const [Fin, setFin] = useState(currentDate())
  const [Clientes, setClientes] = useState<Array<IClientes>>()
  const [Data, setData] = useState<IRptOperaciones[]>([])
  const [TipoOperacion, setTipoOperacion] = useState(1)
  const [Cliente, setCliente] = useState(0)
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [filtro, setFiltro] = useState('')
  const [showDialog, setShowDialog] = useState(false)
  const gridRef = React.useRef<any>(null)
  const [columnDefs] = useState([
    { field: 'trafico', sortable: true, filter: true },
    { field: 'ver pedimento', sortable: true, cellRenderer: verPDF },
    {
      field: 'fechaAltaTrafico',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.value ? params.value.substring(0, 10) : ''
      },
    },
    {
      field: 'fechaRecepcionFactura',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.value ? params.value.substring(0, 10) : ''
      },
    },
    { field: 'patioNuevoLaredo', sortable: true, filter: true },
    { field: 'numeroCaja', sortable: true, filter: true },
    { field: 'referencia', sortable: true, filter: true },
    { field: 'pedimento', sortable: true, filter: true },
    { field: 'factura', sortable: true, filter: true },
    { field: 'uuid', sortable: true, filter: true },
    { field: 'pedido', sortable: true, filter: true },
    {
      field: 'fechaPagoPedimento',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.value ? params.value.substring(0, 10) : ''
      },
    },
    { field: 'descripcionMercancia', sortable: true, filter: true },
    {
      field: 'fechaEnvioDODA',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.value ? params.value.substring(0, 10) : ''
      },
    },
    {
      field: 'fechaModulacion',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.value ? params.value.substring(0, 10) : ''
      },
    },
    { field: 'resultadoModulacion', sortable: true, filter: true },
    { field: 'observaciones', sortable: true, filter: true },
  ])

  const handleClose = () => setShowDialog(false)

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const generaReporte = () => {
    setShowDialog(true)
    const data: DTOReporte = {
      Inicio: moment(Inicio).format('YYYY-MM-DD'),
      Fin: moment(Fin).format('YYYY-MM-DD'),
      TipoOperacion: TipoOperacion,
      NoCliente: Cliente,
      IdUsuario: UserId,
    }
    reportsDataService
      .getRptOperaciones(data)
      .then((response) => {
        setData(response.data)
        setShowDialog(false)
        setHeader('Informativo')
        setMsg('Se encontro la siguiente informacion...')
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setShowDialog(false)
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de operaciones')
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    let Heading = [
      [
        'Trafico',
        'Fecha alta trafico',
        'Fecha recepcion de facturas',
        'Patio Nuevo Laredo',
        'Numero de caja',
        'Pedimento',
        'Factura',
        'UUID',
        'Pedido',
        'Fecha pago de pedimento',
        'Descripcion de mercancia',
        'Fecha envio DODA',
        'Fecha modulacion',
        'Resultado modulacion',
        'Observaciones',
      ],
    ]
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    gridRef.current.api.setQuickFilter(e.target.value)
  }

  useEffect(() => {
    ClientesDataService.getAllClientes(parseInt(UserId))
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        console.log(e)
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }, [])

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-2'>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-2'>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1 right-label'>
              <Form.Label size='sm'>Tipo oper</Form.Label>
            </div>
            <div className='col-md-1'>
              <Form.Control
                as='select'
                onChange={(e) => setTipoOperacion(parseInt(e.target.value))}
                className='form-select form-select-sm'
              >
                <option value='1'>Importacion</option>
                <option value='2'>Exportacion</option>
              </Form.Control>
            </div>
            <div className='col-md-1 right-label'>
              <Form.Label size='sm'>Cliente</Form.Label>
            </div>
            <div className='col-md-4'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  setCliente(parseInt(e.target.value))
                }}
                className='form-select form-select-sm'
              >
                <option value='0'>-SELECCIONE-</option>
                {Clientes
                  ? Clientes.map((c) => {
                      return c.agrupado === 1 ? <option value={c.sClave}>{c.sRazonSocial}</option> : ''
                    })
                  : null}
              </Form.Control>
            </div>
            <div className='col-md-1 right-label'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
          </div>
          <div className='row' style={{ paddingTop: 5 }}>
            <div className='col'>&nbsp;</div>
            <div className='col-4'>
              <Form.Control
                type='text'
                placeholder='Search...'
                size='sm'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className='col'>
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp;Excel
              </Button>
              &nbsp; &nbsp;
            </div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        <AgGridReact
          rowData={Data}
          columnDefs={columnDefs}
          pagination={true}
          paginationAutoPageSize={true}
          ref={gridRef}
          rowMultiSelectWithClick={false}
        ></AgGridReact>
      </div>
      <MsgInformativo show={show} msg={msg} header={header} msgColor={msgColor} closeToast={() => setShowMsg(false)} />
      <Modal show={showDialog} onHide={handleClose} backdrop='static' keyboard={false} size='sm' centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert>
            <img src={loadingImg} style={{ width: '25%', height: '25%' }} alt='proccessing' />
            Espere, por favor...
          </Alert>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  )
}

import React, { FC } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsFillExclamationSquareFill, BsXOctagonFill } from 'react-icons/bs'

interface IProps {
  show: boolean
  msg: string
  header: string
  msgColor: string
  time?: number
  closeToast: (arg: boolean) => void
}

export const MsgInformativo: FC<IProps> = (props) => {
  return (
    <div>
      <ToastContainer position={'middle-center'} style={{zIndex:'99999 !important'}} >
        <Toast 
          show={props.show}
          delay={props.time}
          bg={props.msgColor}
          autohide
          onClose={() => {
            props.closeToast(false)
          }}
        >
          <Toast.Header>
            <strong className='me-auto'>
              {props.header.toString().includes('Error') ? (
                <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                  <BsXOctagonFill />
                </IconContext.Provider>
              ) : null}
              {props.header.toString().includes('Informativo') ? (
                <IconContext.Provider value={{ color: 'blue', size: '20px' }}>
                  <BsFillExclamationSquareFill />
                </IconContext.Provider>
              ) : null}
              &nbsp;
              <span style={{ color: props.header == 'Error' ? 'red' : '' }}>{props.header}</span>
            </strong>
          </Toast.Header>
          <Toast.Body style={{ backgroundColor: '#FFFFFF' }}>
            <div className={props.msgColor == 'warning' ? 'alert alert-warning' : ''}>{props.msg}</div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  )
}

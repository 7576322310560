import axios from 'axios'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import '../../../css/generic01.css'
import FileManagerDataService from '../../../Services/Utils/FileManager.Services'
import { TargetURL } from '../../../Constants/TargetURL'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsFillXCircleFill } from 'react-icons/bs'
import IFileManager from '../../../Interfaces/Utils/IFileManager'
import { MsgInformativo } from '../Toast/msgInformativo'

interface IProps {
  IDProcess: number
  IDUser: number
  IdFile: number
  FileName: string
  Prefijo?: string
  Leyenda: string
  canDelete: boolean
  FileType: string[]
  width: number
  height: number
  onDelete?: (val: boolean) => void
  setBackgroundColor?: number
  onAppendFM: (idFile: number) => void
}

export const FileManager: FC<IProps> = (props) => {
  const [Archivo, setArchivo] = useState<IFileManager>()
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  // const [BackgroundColor, setBackgroundColor] = useState('#000000')
  const [header, setHeader] = useState('')
  //const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const URL = new TargetURL()
  const msgColor = 'primary'

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        /*  if (!file.name.toLowerCase().endsWith(props.FileType)) {
        return false
      } */
        var ext = file.name.substr(file.name.lastIndexOf('.') + 1)
        if (!props.FileType.includes(ext)) {
          return false
        }
        const reader = new FileReader()
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result
          console.log(binaryStr)
          if (binaryStr) {
            const formData = new FormData()
            formData.append('name', file.name)
            formData.append('file', file)
            axios
              .post(
                URL.get() +
                  `/FileManager/AppendFileByProcess?IdUsuario=${props.IDUser}&Proceso=${props.IDProcess}&Tags=${props.IdFile}&crud=1`,
                formData
              )
              .then((res) => {
                if (res.data.id > 0) {
                  setArchivo(res.data)
                  props.onAppendFM(res.data.id)
                } else {
                  setHeader('Error')
                  setMsg('No se guardo el archivo, favor de verificar que no este vacio')
                  setShowMsg(true)
                  return
                }
              })
              .catch((err) => alert('File Upload Error ' + err))
            console.log(JSON.stringify(file))
          }
        }
        reader.readAsArrayBuffer(file)
        // }
      })
    },
    [props]
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  useEffect(() => {
    if (props.IdFile > 0) {
      FileManagerDataService.getFileInfoFromProcess(props.IdFile, props.IDProcess)
        .then((response) => {
          setArchivo(response.data)
          //console.log(JSON.stringify(response.data) + ' ' + props.IdFile)
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }
  }, [props])

  const getFileContent = () => {
    /* let Empty: IFileManager = {
      id: 0,
      idUsuario: props.IDUser,
      proceso: props.IDProcess,
      nombreArchivo: '',
      fechaRegistro: '',
      tags: '',
      size: 0,
    } */
    FileManagerDataService.getFileContent(props.IdFile, props.IDProcess)
      .then((response: any) => {
        if (response.status === 200) {
          if (Archivo?.nombreArchivo.toLowerCase().endsWith('.pdf')) {
            console.log(response.data)
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else if (Archivo?.nombreArchivo.toLowerCase().endsWith('.pdf')) {
            console.log(response.data)
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', Archivo?.nombreArchivo ? Archivo?.nombreArchivo : 'Archivo.zip')
            document.body.appendChild(link)
            link.click()
          }
        } else {
          setHeader('Error')
          setMsg('A este concepto no se le ha anexado archivo')
          setShowMsg(true)
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A este concepto no se le ha anexado archivo')
        setShowMsg(true)
        return
      })
  }

  const deleteFile = () => {
    let id = Archivo?.id ? Archivo?.id : 0
    FileManagerDataService.DeleteFile(id)
      .then((response: any) => {
        if (response.status === 200) {
          setArchivo({ id: 0, idUsuario: 0, proceso: 0, nombreArchivo: '', fechaRegistro: '', tags: '', size: 0 })
          setHeader('Informativo')
          setMsg(response.data.respuesta)
          setShowMsg(true)
        } else {
          setHeader('Error')
          setMsg('Algo sucedio, no se pudo eliminar el archivo!')
          setShowMsg(true)
          return
        }
      })
      .catch((e: Error) => {})
  }

  const getRGB = (color: number) => {
    const base = 255 - color * 1
    // const baseR = 255 - base * 0
    const baseG = 255 - base * 2
    // const baseB = 255 - base * 5
    // const R = baseR.toString(16)
    const G = baseG.toString(16)
    // const B = baseB.toString(16)
    const newBackcolor = '#F1' + G.padStart(2, '0').replace('-', '').substring(0, 2) + 'FE'
    return newBackcolor.replace('-', '').substring(0, 7)
  }

  return (
    <>
      <div>
        <div
          {...getRootProps()}
          className={Archivo?.nombreArchivo ? 'hideDiv' : 'dropzoneFileManager'}
          style={{
            width: props.width,
            backgroundColor: props.setBackgroundColor ? getRGB(props.setBackgroundColor) : '#D2F9FC',
          }}
        >
          <input {...getInputProps()} />
          <p>{!Archivo?.nombreArchivo ? props.Leyenda : ''}</p>
        </div>
      </div>
      <Container className='labelSize13px'>
        {Archivo?.nombreArchivo ? (
          <Alert variant='primary'>
            <Row>
              <Col xs={11}>
                <span style={{ fontWeight: 'bold' }}>{props.Prefijo ? props.Prefijo : 'Archivo'}</span> : &nbsp;
                <a
                  href='#'
                  onClick={() => {
                    getFileContent()
                  }}
                >
                  {Archivo?.nombreArchivo}
                </a>
              </Col>
              <Col xs={1} style={{ visibility: props.canDelete ? 'visible' : 'hidden' }}>
                <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                  <BsFillXCircleFill
                    onClick={() => {
                      deleteFile()
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </IconContext.Provider>
              </Col>
            </Row>
          </Alert>
        ) : (
          ''
        )}
      </Container>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      ></MsgInformativo>
    </>
  )
}

export default FileManager

import http from '../../../../Services/common/http-common'
import I2096Headers from '../Interfaces/I2096Header'
import IRespuesta from '../../../../Interfaces/IRespuesta'
import DTO2096Fraccion from '../DTO/DTO2096Fraccion'
import I2096Detail from '../Interfaces/I2096Detail'
import DTO2096Descripcion from '../DTO/DTO2096Descripcion'
import I2096NoParte from '../Interfaces/I2096NoParte'
import I2096PaisClave from '../Interfaces/I2096PaisClave'
import DTORespuesta from '../../ZincInternacional/DTO/DTORespuesta'
import DTO2096SightLine from '../DTO/DTO2096SightLine'
import DTO2096FraccionDescripcion from '../DTO/DTO2096FraccionDescripcion'
import DTO2096PerfilesParecidos from '../DTO/DTO2096PerfilesParecidos'
import DTO2096LinkInvoice2Reference from '../DTO/DTO2096LinkInvoice2Reference'
import DTO2096Estatus from '../DTO/DTO2096Estatus'
import DTO2096NoParte from '../DTO/DTO2096NoParte'
import I2096NoParteLog from '../Interfaces/I2096NoParteLog'
import DTO2096TraficoFieldsUpdate from '../DTO/DTO2096TraficoFieldsUpdate'
import DTO2096RELParteFactura from '../DTO/DTO2096RELParteFactura'
import DTO2096RptPayedOperations from '../DTO/DTO2096RptPayedOperations'
import DTO2096NoParteVerificacion from '../DTO/DTO2096NoParteVerificacion'
import DTO2096InvoiceStatus from '../DTO/DTO2096InvoiceStatus'
import IFileManager from '../../../../Interfaces/Utils/IFileManager'
import DTO2096RptFacturasPagadas from '../DTO/DTO2096RptFacturasPagadas'
import DTO2096TraficoConsultaAmazonDetail from '../DTO/DTO2096TraficoConsultaAmazonDetail'
import DTO2096TraficoConsultaAmazonHeader from '../DTO/DTO2096TraficoConsultaAmazonHeader'
import DTO2096DataTraficoConsulta from '../DTO/DTO2096DataTraficoConsulta'
import I2096CatGrupoRegulatorio from '../Interfaces/I2096CatGrupoRegulatorio'
import DTOAmazonSegragacion from '../DTO/DTOAmazonSegragacion'
import ICat2096CatConceptosConsulta from '../Interfaces/I2096CatConceptosConsulta'

class AmazonDataService {
  AmazonPendingClasificationInvoiceGET() {
    return http.get<I2096Headers[]>(`/AmazonInvoice/AmazonPendingClasificationInvoiceGet`)
  }
  AmazonInvoiceGET(id: number) {
    return http.get<I2096Headers[]>(`/AmazonInvoice/AmazonInvoiceGET?id=${id}`)
  }
  InvoicePendingFromAmazonGET() {
    return http.get<I2096Headers[]>(`/AmazonInvoice/InvoicePendingFromAmazonGET`)
  }
  AmazonFinishedClasificationInvoiceGET() {
    return http.get<I2096Headers[]>(`/AmazonInvoice/AmazonFinishedClasificationInvoiceGet`)
  }
  SePuedeTerminardeClasificarFactura(id: number) {
    return http.get<DTO2096Estatus>(`/AmazonInvoice/SePuedeTerminardeClasificarFactura?id=${id}`)
  }
  SeRecibioRespuestaDeAmazon(id: number) {
    return http.get<DTO2096Estatus>(`/AmazonInvoice/SeRecibioRespuestaDeAmazon?id=${id}`)
  }
  EliminaFactura(id: number) {
    return http.delete<boolean>(`/AmazonInvoice/AmazonInvoiceDelete?id=${id}`)
  }
  EnviaEsperaRespuestaAmazon(id: number) {
    return http.get<DTORespuesta>(`/AmazonInvoice/EnviaEsperaRespuestaAmazon?id=${id}`)
  }
  ArchivoRespuestaAmazon(id: number, idFile: number) {
    return http.put<DTORespuesta>(`/AmazonInvoice/ArchivoRespuestaAmazon?id=${id}&idFile=${idFile}`)
  }
  TerminaClasificarFactura(id: number) {
    return http.get<boolean>(`/AmazonInvoice/TerminaClasificarFactura?id=${id}`)
  }
  CheckDetail(id: number) {
    return http.put<I2096Detail>(`/AmazonInvoice/C2096CheckDetail?id=${id}`)
  }
  InvoiceReturnState(IdFactura: number, Estatus: number) {
    return http.put<I2096Detail>(`/AmazonInvoice/InvoiceReturnState?IdFactura=${IdFactura}&Estatus=${Estatus}`)
  }

  
  AmazonPendingTraficInvoiceGET() {
    return http.get<I2096Headers[]>(`/AmazonInvoice/AmazonPendingTraficInvoiceGet`)
  }
  AmazonLinkInvoice2TrafficGet() {
    return http.get<I2096Headers[]>(`/AmazonInvoice/AmazonLinkInvoice2TrafficGet`)
  }
  SePuedeTerminarXTraficoFactura(id: number) {
    return http.get<DTORespuesta>(`/AmazonInvoice/SePuedeTerminarXTraficoFactura?id=${id}`)
  }
  TerminaXTraficoFactura(id: number) {
    return http.get<boolean>(`/AmazonInvoice/TerminaXTraficoFactura?id=${id}`)
  }


  AmazonPayedOperationsGET(Inicio: string, Fin: string) {
    return http.get<DTO2096RptPayedOperations[]>(`/AmazonInvoice/AmazonPayedOperationsGET?Inicio=${Inicio}&Fin=${Fin}`)
  }
  AmazonFacturasPagadasGET(Inicio: string, Fin: string) {
    return http.get<DTO2096RptFacturasPagadas[]>(`/AmazonInvoice/AmazonFacturasPagadasGET?Inicio=${Inicio}&Fin=${Fin}`)
  }
  AmazonInvoiceStatusGET(Invoice: string , Inicio: string, Fin: string) {
    return http.get<DTO2096InvoiceStatus[]>(`/AmazonInvoice/AmazonInvoiceStatusGET?Invoice=${Invoice}&Inicio=${Inicio}&Fin=${Fin}`)
  }
  AmazonInvoicePrioritySET(Id: number, Prioridad: boolean) {
    return http.get<I2096Headers[]>(`/AmazonInvoice/AmazonInvoicePrioritySET?id=${Id}&prioridad=${Prioridad}`)
  }



  RELParteFacturasGET(id: number) {
    return http.get<DTO2096RELParteFactura[]>(`/AmazonInvoice/RELParteFacturasGET?id=${id}`)
  }

  ValidaFraccionGET(Fraccion: string) {
    return http.get<IRespuesta>(`/AmazonInvoice/C2096ValidaFraccion?Fraccion=${Fraccion}`)
  }
  SETFraccionDescripcion(data: DTO2096FraccionDescripcion) {
    return http.put<I2096Detail>(`/AmazonInvoice/C2096FraccionDescripcionSET`, data)
  }
  FraccionSET(data: DTO2096Fraccion) {
    return http.post<I2096Detail>(`/AmazonInvoice/C2096FraccionSet`, data)
  }
  DescripcionSET(data: DTO2096Descripcion) {
    return http.post<I2096Detail>(`/AmazonInvoice/C2096DescripcionSet`, data)
  }
  AutorizaItemFacturaAmazon(data: DTO2096FraccionDescripcion) {
    return http.put<I2096Detail>(`/AmazonInvoice/C2096AutorizaItemFactura`, data)
  }
  SightLineSET(data: DTO2096SightLine) {
    return http.put<DTO2096SightLine>(`/AmazonInvoice/AmazonSightLineSET`, data)
  }
  DataTraficoConsultaSET(data: DTO2096DataTraficoConsulta) {
    return http.put<DTO2096DataTraficoConsulta>(`/AmazonInvoice/DataTraficoConsultaSET`, data)
  }
  
  PaisClavesGET() {
    return http.get<I2096PaisClave[]>(`/AmazonInvoice/PaisClavesGet`)
  }
  NoPartesAmazon2SIRGET(id: number) {
    return http.get<ArrayBuffer>(`/AmazonInvoice/GetNoPartesAmazon2SIR?id=${id}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  }
  NoPartesAmazonFinal(id: number) {
    return http.get<ArrayBuffer>(`/AmazonInvoice/GetNoPartesAmazonFinal?id=${id}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  }
  ExceptionsFileAmazonGET(id: number, code: number) {
    return http.get<ArrayBuffer>(`/AmazonInvoice/ExceptionsFileAmazonGET?id=${id}&code=${code}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  }
  PDFAmazonInvoiceGET(id: number) {
    return http.get<ArrayBuffer>(`/AmazonInvoice/PDFAmazonInvoiceGET?id=${id}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  }
  GETAmazonPartidasByReference(Referencia: string) {
    return http.get<ArrayBuffer>(`/AmazonInvoice/GETAmazonPartidasByReference?referencia=${Referencia}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  }
  GETAmazonFacturasByReference(Referencia: string) {
    return http.get<ArrayBuffer>(`/AmazonInvoice/getAmazonFacturasByReference?referencia=${Referencia}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  }
  GETACKFileById(id: number) {
    return http.get<ArrayBuffer>(`/AmazonInvoice/GETACKFileById?IDArchivoACK=${id}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
       return error
     })
  }
  GETReporteSegregados(Inicio: string, Fin: string) {
    return http.get<ArrayBuffer>(`/AmazonInvoice/GETAmazonReporteSegregados?Inicio=${Inicio}&Fin=${Fin}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
       return error
     })
  }


  GETReportesSegregadoss(Inicio: string, Fin: string){
    return http.get<DTOAmazonSegragacion[]>(`/AmazonInvoice/GETAmazonReportesSegregadoss?Inicio=${Inicio}&Fin=${Fin}`)

  }









  TraficoConsultasAmazonGenerateExcel(id: number) {
    return http.get<ArrayBuffer>(`/AmazonInvoice/TraficoConsultaAmazonGenerateExcel?id=${id}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
       return error
     })
  }
  CreateACKFile(referencia: string) {
    return http.get<Boolean>(`/AmazonInvoice/createACKFile?Referencia=`+referencia)
  }
  UploadACKFile2Amazon(referencia: string) {
    return http.get<Boolean>(`/AmazonInvoice/UploadACKFile2Amazon?Referencia=`+referencia)
  }
  AsignaFacturasReferencia(data: DTO2096LinkInvoice2Reference) {
    return http.post<Boolean>(`/AmazonInvoice/AsignaFacturasReferencia`, data)
  }
  TrafficFieldsUpdate(data: DTO2096TraficoFieldsUpdate) {
    return http.put<I2096Detail>(`/AmazonInvoice/TrafficFieldsUpdate`, data)
  }



  NoPartesGet(Search: string) {
    return http.get<I2096NoParte[]>(`/AmazonInvoice/NoPartesGet?Search=${Search}`)
  }
  NoPartesUpdate(data: DTO2096NoParte) {
    return http.put<I2096NoParte>(`/AmazonInvoice/NoPartesUpdate`, data)
  }
  NoPartesLogGET(id: number) {
    return http.get<I2096NoParteLog[]>(`/AmazonInvoice/NoPartesLogGET?id=${id}`)
  }
  NoParteVerificacionGET(Parte: string) {
    return http.get<DTO2096NoParteVerificacion[]>(`/AmazonInvoice/NoParteVerificacionGET?Parte=${Parte}`)
  }
  PerfilesParecidosGet(Perfil: string) {
    return http.get<DTO2096PerfilesParecidos[]>(`/Usuarios/Catalogo/Usuarios/PerfilesParecidos?Perfil=${Perfil}`)
  }
  AsignaFacturaClasificador(IdUsuario: number, IdFactura: number) {
    return http.put<DTO2096PerfilesParecidos[]>(`/AmazonInvoice/AsignaFactura?IdUsuario=${IdUsuario}&IdFactura=${IdFactura}`)
  }
  UploadAndSyncAmazonInvoicesInFilePath() {
    return http.get<boolean>(`/AmazonInvoice/SyncInvoicesInFilePath`)
  }
  UploadAndSyncAmazonInvoicesByFTP() {
    return http.get<boolean>(`/AmazonInvoice/SyncInvoicesByFTP`)
  }
  SETInvoiceDetail2Unchecked(id: number) {
    return http.get<I2096Headers>(`/AmazonInvoice/SETInvoiceDetail2Unchecked?id=${id}`)
  }
  SETInvoiceDetailRevalidateParts(id: number) {
    return http.get<Boolean>(`/AmazonInvoice/SETInvoiceDetailRevalidateParts?id=${id}`)
  }
  ApplyAnswerFile2Invoice(Files: string[]) {
    return http.post<Boolean>(`/AmazonInvoice/ApplyAnswerFile2Invoice`, Files)
  }
  TransporteConsultaAmazon() {
    return http.get<DTO2096TraficoConsultaAmazonHeader[]>(`/AmazonInvoice/TraficoConsultasAmazon`)
  }
  CatGrupoRegulatorioGET() {
    return http.get<I2096CatGrupoRegulatorio[]>(`/AmazonInvoice/CatGrupoRegulatorioGET`)
  }
  TraficoConsultasAmazonById(id: number) {
    return http.get<DTO2096TraficoConsultaAmazonHeader>(`/AmazonInvoice/TraficoConsultasAmazonById?id=${id}`)
  }
  ClasificacionBOT(id: number) {
    return http.get<DTO2096TraficoConsultaAmazonHeader>(`/AmazonInvoice/Bot/Clasificacion?id=${id}`)
  }
  DeleteNoParte(id: number) {
    return http.delete<Boolean>(`/AmazonInvoice/DeleteNoParte?id=${id}`)
  }
  CatConceptosConsultaGET() {
    return http.get<ICat2096CatConceptosConsulta[]>(`/AmazonInvoice/Catalogos/CatConceptosConsultaGET`)
  }
  CatConceptosConsultaAppend(data: ICat2096CatConceptosConsulta) {
    return http.post<ICat2096CatConceptosConsulta>(`/AmazonInvoice/Catalogos/CatConceptosConsultaAppend`, data)
  }
  InvoiceDistinctExceptionCodeGet(id: number) {
    return http.get<number[]>(`/AmazonInvoice/Invoice/DistinctExceptionCodeGet?id=${id}`)
  }
}
export default new AmazonDataService()

import React, { FC, useState } from 'react'
import { MsgInformativo } from '../Toast/msgInformativo'

interface IProps {
  id: number
  value: number
  disabled?: boolean
  postCost: (id: number, value: number) => void
}

export const ControlledInput: FC<IProps> = (props) => {
  const [id, setid] = useState(props.id)
  const [value, setValue] = useState(props.value)
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')

  const onChange = (event: any) => {
    setValue(event.target.value)
  }
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      if (isNaN(event.target.value)) {
        alert('Valor no valido!')
        return
      }
      props.postCost(id, event.target.value)
    }
  }
  return (
    <>
      <input
        value={value}
        onChange={onChange}
        disabled={props.disabled}
        onKeyDown={(e) => handleKeyDown(e)}
        style={{ width: '70px' }}
      />
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </>
  )
}

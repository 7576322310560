import { ICellRendererParams } from 'ag-grid-community'
import { FC, useState } from 'react'
import DocsDataService from '../../../Services/Catalogos/ArchivoElectronico.Services'
import { Button } from 'react-bootstrap'
import IArchivoElectronico from '../../../Interfaces/IArchivoElectronico'

interface IProps {
  value: number
}

export const VerPDFv2: FC<ICellRendererParams> = (props: ICellRendererParams) => {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [NoCliente, setNoCliente] = useState(props.data.noCliente)
  const [Pedimento, setPedimento] = useState(props.data.pedimento)
  const [Referencia, setReferencia] = useState(props.data.referencia)
  const showPDF = () => {
    var arr = Pedimento.split(' ')
    const data: IArchivoElectronico = {
      Referencia: Referencia,
      PedimentoLargo: arr[2] + arr[1] + '0' + arr[3],
      NoCliente: NoCliente,
      IdUsuario: UserId,
      Archivo: '',
    }
    DocsDataService.getPDF(data)
      .then((response: any) => {
        const file = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      })
      .catch((e: Error) => {
        alert(e)
        return
      })
  }

  return (
    <div>
      <span>
        {props.data.pedimento ? (
          <Button onClick={() => showPDF()} variant='primary'>
            ver Pedimento
          </Button>
        ) : (
          ''
        )}
      </span>
    </div>
  )
}

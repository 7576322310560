import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Form,
  FormCheck,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip
} from 'react-bootstrap'
import { MsgInformativo } from '../../../../Utils/Toast/msgInformativo'
import DSAmazon from '../../Services/Amazon.Services'
// Redux
import { RootState } from '../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import * as CurrencyFormat from 'react-currency-format'
import DTO2096TraficoConsultaAmazonDetail from '../../DTO/DTO2096TraficoConsultaAmazonDetail'
import DTODataTraficoConsulta from '../../DTO/DTO2096DataTraficoConsulta'
import I2096CatGrupoRegulatorio from '../../Interfaces/I2096CatGrupoRegulatorio'

export interface IRptConsultaTraficoDetalleProps {
  IDMaster: number
  detail: DTO2096TraficoConsultaAmazonDetail
  Habilitado: boolean
  Catalogo: I2096CatGrupoRegulatorio[]
  onDataChange: (IDMaster: number) => void
}

export default function RptConsultaTraficoDetalle(
  props: IRptConsultaTraficoDetalleProps
) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [ShowModal, setShowModal] = useState(false)
  const [EstatusSwith, setEstatusSwitch] = useState(props.detail.swConsulta)
  const [UserChanged, setUserChanged] = useState(false)
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [RegulacionConsulta, setRegulacionConsulta] = useState(
    props.detail.regulacionConsulta ? props.detail.regulacionConsulta : ''
  )
  const [ComentariosConsulta, setComentariosConsulta] = useState(
    props.detail.comentariosConsulta ? props.detail.comentariosConsulta : ''
  )
  const [ShowModalEdit, setShowModalEdit] = useState(false)
  const [IDGrupoRegulatorio, setIDGrupoRegulatorio] = useState(
    props.detail.grupoRegulatorio
  )
  const msgColor = 'primary'

  const saveData = () => {
    const data: DTODataTraficoConsulta = {
      id: props.detail.id,
      swConsulta: EstatusSwith,
      grupoRegulatorio: IDGrupoRegulatorio,
      regulacionConsulta: RegulacionConsulta,
      comentariosConsulta: ComentariosConsulta
    }
    DSAmazon.DataTraficoConsultaSET(data)
      .then((response) => {
        setShowModal(false)
        props.onDataChange(props.IDMaster)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error, no se guardo la informacion en BD')
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    if (UserChanged) {
      setShowModal(true)
    }
  }, [EstatusSwith, UserChanged])

  return (
    <>
      <tr>
        <th
          style={{
            width: '50px',
            backgroundColor: '#FFFFFF',
            textAlign: 'right',
            fontWeight: 'bold'
          }}
          className="DetailData"
        >
          {props.detail.partida}
        </th>
        <td
          style={{
            textAlign: 'center',
            cursor: 'pointer'
          }}
          className="DetailData"
        >
          {props.detail.itemId}
        </td>
        <td
          style={{
            textAlign: 'left'
          }}
          className="DetailData"
        >
          {props.detail.itemDescription}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          <CurrencyFormat
            value={props.detail.fraccionGEMCO ? props.detail.fraccionGEMCO : ''}
            disabled={true}
            format={'####.##.####'}
            displayType={'input'}
            style={{
              fontSize: '17px',
              backgroundColor: '#E5F7B6',
              border: '2px solid green',
              color: '#000000',
              width: '135px',
              textAlign: 'right',
              borderRadius: '10px'
            }}
          />
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.descripcionGEMCO}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.countryOfOrigin}
        </td>
        <td
          style={{
            textAlign: 'left',
            paddingLeft: '15px'
          }}
          className="DetailData"
        >
          {props.detail.productGroup}
        </td>
        <td
          style={{
            textAlign: 'left',
            paddingLeft: '15px'
          }}
          className="DetailData"
        >
          {props.detail.brand}
        </td>
        <td
          style={{
            textAlign: 'right'
          }}
          className="DetailData"
        >
          {props.detail.model}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.itemQuantityUnitOfMeasure}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.quantity}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.weightValue}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.netWeightUnitOfMeasure}
        </td>

        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.actualUnitCostMonetaryAmount}
        </td>
        <td
          style={{
            textAlign: 'center'
          }}
          className="DetailData"
        >
          {props.detail.totalUnitValueMonetaryAmount}
        </td>
        <td
          style={{
            textAlign: 'center',
            paddingLeft: '30px'
          }}
          className="DetailData"
        >
          <FormCheck
            className="form-check form-switch form-control-lg"
            id="switchEnabled"
            type="switch"
            checked={EstatusSwith}
            onChange={() => {
              setEstatusSwitch(!EstatusSwith)
              setUserChanged(true)
              setShowModal(!EstatusSwith)
            }}
          />
        </td>
        {/*         <td
          style={{
            visibility:
              CumplimientoNormativo.length === 0 ? 'hidden' : 'visible'
          }}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">{CumplimientoNormativo}</Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <Button variant="light" {...triggerHandler}>
                <IconContext.Provider
                  value={{
                    color: 'blue',
                    size: '25px'
                  }}
                >
                  <AiOutlineFileSearch />
                </IconContext.Provider>
                <span className="ms-1"></span>
              </Button>
            )}
          </OverlayTrigger>
        </td>
        <td
          style={{
            visibility:
              CumplimientoNormativo.length === 0 ? 'hidden' : 'visible'
          }}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">{Comentarios}</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <Button variant="light" {...triggerHandler}>
                <IconContext.Provider
                  value={{
                    color: 'green',
                    size: '25px'
                  }}
                >
                  <BsFillChatRightTextFill />
                </IconContext.Provider>
                <span className="ms-1"></span>
              </Button>
            )}
          </OverlayTrigger>
        </td>
        <td>
        </td> */}
      </tr>
      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Header>
          <Modal.Title className="primary">Consulta Amazon {IDGrupoRegulatorio}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: '230px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={3}>Grupo Regulatorio</Col>
              <Col xs={8}>
                <Form.Control
                  as="select"
                  defaultValue={IDGrupoRegulatorio}
                  onChange={(e) => {
                    setIDGrupoRegulatorio(parseInt(e.target.value))
                  }}
                  className="form-select form-select-sm"
                >
                  <option value="0">-SELECCIONE-</option>
                  {props.Catalogo
                    ? props.Catalogo.map((c) => {
                        return <option value={c.id}>{c.descripcion}</option>
                      })
                    : null}
                </Form.Control>
              </Col>
              <Col>&nbsp;</Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={3}>Regulacion</Col>
              <Col xs={8}>
                <Form.Control
                  as="textarea"
                  id="Regulacion"
                  size="sm"
                  value={RegulacionConsulta.length ? RegulacionConsulta : ''}
                  onChange={(e) => setRegulacionConsulta(e.target.value)}
                />
              </Col>
              <Col>&nbsp;</Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={3}>Comentarios</Col>
              <Col xs={8}>
                <Form.Control
                  as="textarea"
                  id="Comentarios"
                  size="sm"
                  value={ComentariosConsulta.length ? ComentariosConsulta : ''}
                  onChange={(e) => setComentariosConsulta(e.target.value)}
                />
              </Col>
              <Col>&nbsp;</Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setEstatusSwitch(true)
                    setShowModal(false)
                  }}
                >
                  &nbsp;&nbsp;Cancelar&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    saveData()
                  }}
                >
                  &nbsp;&nbsp;Guardar&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </>
  )
}

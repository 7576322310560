import { useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { BsFileEarmarkExcel, BsSearch } from 'react-icons/bs'
import '../../../../css/generic01.css'
import * as XLSX from 'xlsx'
import * as CurrencyFormat from 'react-currency-format'
import DTOAnexoFacturacionMission from '../../../../DTO/Facturacion/Anexo/DTOAnexoFacturacionMission'
import IAnexoFacturacionMission from '../../../../Interfaces/Facturacion/Anexo/IAnexoFacturacionMission'
import DSAnexoFacturacion from '../../../../Services/Facturacion/Anexo/Anexo.Facturacion.Services'

export interface IRptRelacionMissionProps {}

export default function RptRelacionMission(props: IRptRelacionMissionProps) {
  const [Referencia, setReferencia] = useState('')
  const [Data, setData] = useState<IAnexoFacturacionMission[]>([])
  const [CoordinacionCruce, setCoordinacionCruce] = useState(0)
  const [CoordinacionFlete, setCoordinacionFlete] = useState(0)
  const [Servicio, setServicio] = useState(0)
  const [Contraprestacion, setContraprestacion] = useState(0)
  const [Honorarios, setHonorarios] = useState(0)
  const [Total, setTotal] = useState(0)
  const columnsConcepts = [
    {
      name: 'Remesa',
      width: '90px',
      cell: (row: IAnexoFacturacionMission) => row.sRemesa,
      sortable: true
    },
    {
      name: 'Pedimento',
      width: '110px',
      cell: (row: IAnexoFacturacionMission) => row.sNumPedimento,
      sortable: true
    },
    {
      name: 'Referencia',
      width: '120px',
      cell: (row: IAnexoFacturacionMission) => row.sReferencia,
      sortable: true
    },
    {
      name: 'Num Pedido',
      width: '120px',
      cell: (row: IAnexoFacturacionMission) => row.sNumPedido,
      sortable: true
    },
    {
      name: 'Transportista',
      width: '250px',
      cell: (row: IAnexoFacturacionMission) => row.transportista,
      sortable: true
    },
    {
      name: 'Num Caja',
      width: '150px',
      cell: (row: IAnexoFacturacionMission) => row.numCaja,
      sortable: true
    },
    {
      name: 'Ctr',
      width: '80px',
      cell: (row: IAnexoFacturacionMission) =>
        (Math.round(row.contraprestacion * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'Cruce',
      width: '100px',
      cell: (row: IAnexoFacturacionMission) =>
        (Math.round(row.cruce * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'Flete',
      width: '100px',
      cell: (row: IAnexoFacturacionMission) =>
        (Math.round(row.flete * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'Otros Serv.',
      width: '125px',
      cell: (row: IAnexoFacturacionMission) =>
        (Math.round(row.otrosServicios * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'Tot Comp',
      width: '125px',
      cell: (row: IAnexoFacturacionMission) =>
        (Math.round(row.totComp * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'Honorarios',
      width: '125px',
      cell: (row: IAnexoFacturacionMission) =>
        (Math.round(row.honorarios * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'IVA',
      width: '80px',
      cell: (row: IAnexoFacturacionMission) =>
        (Math.round(row.iva * 100) / 100).toFixed(2),
      sortable: true
    },
    {
      name: 'Total',
      width: '120px',
      cell: (row: IAnexoFacturacionMission) =>
        (Math.round(row.total * 100) / 100).toFixed(2),
      sortable: true
    }
  ]

  const downloadExcel = () => {
    exportExcel(Data, 'Relacion Mission ' + Referencia)
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    let Heading = [
      [
        'Remesa',
        'Pedimento',
        'Referencia',
        'Num Pedido',
        'Transportista',
        'Num caja',
        'Contraprestacion',
        'Cruce',
        'Flete',
        'Otros servicios',
        'Tot comp',
        'Honorarios',
        'IVA',
        'Total'
      ]
    ]
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const generaReporte = () => {
    const data: DTOAnexoFacturacionMission = {
      sReferencia: Referencia,
      cordCruce: CoordinacionCruce,
      cordFlete: CoordinacionFlete,
      nServicio: Servicio,
      nContraprestacion: Contraprestacion,
      nHonorario: Honorarios
    }
    DSAnexoFacturacion.GetMission(data)
      .then((response) => {
        setData(response.data)
        setTotal(response.data.length)
      })
      .catch((e: Error) => {
        console.log(e)
      })
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col xs={3}> Relacion Mission : {Total} registros</Col>
                  <Col xs={8}></Col>
                  <Col xs={1} className="colAlignRight">
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => {
                        downloadExcel()
                      }}
                    >
                      <BsFileEarmarkExcel />
                      &nbsp;Excel
                    </Button>
                  </Col>
                </Row>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                <Row>
                  <Col className="colAlignRight">Referencia: </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      size="sm"
                      placeholder="Referencia"
                      onChange={(e: any) => {
                        setReferencia(e.target.value)
                      }}
                    />
                  </Col>
                  <Col className="colAlignRight">Coord de cruce:</Col>
                  <Col>
                    {/*  <Form.Control
                      type='number'
                      size='sm'
                      placeholder='Coordinacion cruce'
                      onChange={(e: any) => {
                        setCoordinacionCruce(e.target.value)
                      }}
                    /> */}
                    <CurrencyFormat
                      value={CoordinacionCruce}
                      displayType={'input'}
                      thousandSeparator={true}
                      decimalScale={2}
                      onValueChange={(values: any) => {
                        const { value } = values
                        setCoordinacionCruce(value)
                      }}
                      style={{
                        fontSize: '18px',
                        backgroundColor: '#F5FFED',
                        border: '2px solid #25D05B',
                        width: '100px',
                        textAlign: 'right',
                        borderRadius: '10px'
                      }}
                    />
                  </Col>
                  <Col className="colAlignRight">Coord de flete:</Col>
                  <Col>
                    {/* <Form.Control
                      type='number'
                      size='sm'
                      placeholder='Coordinacion flete'
                      onChange={(e: any) => {
                        setCoordinacionFlete(e.target.value)
                      }}
                    /> */}
                    <CurrencyFormat
                      value={CoordinacionFlete}
                      displayType={'input'}
                      thousandSeparator={true}
                      decimalScale={2}
                      onValueChange={(values: any) => {
                        const { value } = values
                        setCoordinacionFlete(value)
                      }}
                      style={{
                        fontSize: '18px',
                        backgroundColor: '#F5FFED',
                        border: '2px solid #25D05B',
                        width: '100px',
                        textAlign: 'right',
                        borderRadius: '10px'
                      }}
                    />
                  </Col>
                  <Col>&nbsp;</Col>
                </Row>
              </Card.Subtitle>
              <Row>
                <Col className="colAlignRight">Servicio:</Col>
                <Col>
                  {/*   <Form.Control
                    type='number'
                    size='sm'
                    placeholder='Servicio'
                    onChange={(e: any) => {
                      setServicio(e.target.value)
                    }}
                  /> */}
                  <CurrencyFormat
                    value={Servicio}
                    displayType={'input'}
                    thousandSeparator={true}
                    decimalScale={2}
                    onValueChange={(values: any) => {
                      const { value } = values
                      setServicio(value)
                    }}
                    style={{
                      fontSize: '18px',
                      backgroundColor: '#F5FFED',
                      border: '2px solid #25D05B',
                      width: '100px',
                      textAlign: 'right',
                      borderRadius: '10px'
                    }}
                  />
                </Col>
                <Col className="colAlignRight">Contraprestacion:</Col>
                <Col>
                  {/*   <Form.Control
                    type='number'
                    size='sm'
                    placeholder='Contraprestacion'
                    onChange={(e: any) => {
                      setContraprestacion(e.target.value)
                    }}
                  /> */}
                  <CurrencyFormat
                    value={Contraprestacion}
                    displayType={'input'}
                    thousandSeparator={true}
                    decimalScale={2}
                    onValueChange={(values: any) => {
                      const { value } = values
                      setContraprestacion(value)
                    }}
                    style={{
                      fontSize: '18px',
                      backgroundColor: '#F5FFED',
                      border: '2px solid #25D05B',
                      width: '100px',
                      textAlign: 'right',
                      borderRadius: '10px'
                    }}
                  />
                </Col>
                <Col className="colAlignRight">Honorarios:</Col>
                <Col>
                  {/* <Form.Control
                    type='number'
                    size='sm'
                    placeholder='Honorarios'
                    onChange={(e: any) => {
                      setHonorarios(e.target.value)
                    }}
                  /> */}
                  <CurrencyFormat
                    value={Honorarios}
                    displayType={'input'}
                    thousandSeparator={true}
                    decimalScale={2}
                    onValueChange={(values: any) => {
                      const { value } = values
                      setHonorarios(value)
                    }}
                    style={{
                      fontSize: '18px',
                      backgroundColor: '#F5FFED',
                      border: '2px solid #25D05B',
                      width: '100px',
                      textAlign: 'right',
                      borderRadius: '10px'
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      generaReporte()
                    }}
                  >
                    Genera reporte
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            striped={true}
            dense={true}
            paginationPerPage={10}
            pagination
            highlightOnHover
            columns={columnsConcepts}
            data={Data}
            pointerOnHover
          />
        </Col>
      </Row>
    </div>
  )
}

import React, { FC, useEffect, useState } from "react";
import ReportesDS from '../../Services/Reportes/reportes.services'
import { MsgInformativo } from "../Utils/Toast/msgInformativo";
import loadingImg from '../../images/ajaxloader.gif'
import { Modal } from "react-bootstrap";

interface IProps {};

const RptControlDocumentosClientes:FC<IProps> = (props) => {
    const [show, setShowMsg] = useState(false)
    const [ShowModal, setShowModal] = useState(false)
    const [ShowDialog, setShowDialog] = useState(true)
    const [header, setHeader] = useState('')
    const [msg, setMsg] = useState('')
    const [msgColor, setMsgColor] = useState('primary')

    useEffect(() => {
        ReportesDS.GenerateRptControlDocumentosClientes()
        .then((response: any) => {
            if (response.status === 200) {
              ReportesDS.GetRptControlDocumentosClientes()
              .then((response: any) => {
                  if (response.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'ReporteDocumentosClientes.csv')
                    document.body.appendChild(link)
                    link.click()
                    setShowDialog(false)
                  }
                })
                .catch((e: Error) => {
                  setShowDialog(false)
                  setHeader('Error')
                  setMsg('Ocurrio un error, no se pudo generar documento')
                  setShowMsg(true)
                  return
                })
            }
          })
          .catch((e: Error) => {
            setShowDialog(false)
            setHeader('Error')
            setMsg('Ocurrio un error, no se pudo generar documento')
            setShowMsg(true)
            return
          })

      }, [])

      
    return <div>
        <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
       <Modal
        show={ShowDialog}
        onHide={() => {
          setShowDialog(false)
        }}
        backdrop='static'
        keyboard={false}
        size='sm'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={loadingImg} style={{ width: '25%', height: '25%' }} alt='proccessing' />
          Generando documento...
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
};

export default RptControlDocumentosClientes;
import http from '../../../../Services/common/http-common';
import I2206EmbarquesMensual from '../Interfaces/I2206EmbarquesMensual';
import I2206EmbarquesPorImportar from '../Interfaces/I2206EmbarquesPorImportar';
import I2206ExpedienteDigital from '../Interfaces/I2206ExpedienteDigital';
import I2206FacturasTxt from '../Interfaces/I2206FacturasTxt';

class C1868HeinekenDataService {
  GetEmbarquesPorImportar() {
    return http.get<I2206EmbarquesPorImportar[]>(`/C2206Reportes/Rpt2206EmbarquesPorImportar`);
  }
  GetEmbarquesPorMes() {
    return http.get<I2206EmbarquesMensual[]>(`/C2206Reportes/Rpt2206EmbarquesPorMes`);
  }
  GetExpedienteDigital(Ocurrencia: string) {
    return http.get<I2206ExpedienteDigital[]>(`/C2206Reportes/Rpt2206ExpedienteDigital?Ocurrencia=${Ocurrencia}`);
  }
  GetAllFiles(id: number) {
    return http.get<I2206ExpedienteDigital[]>(`/C2206Reportes/Rpt2206ExpedienteDigital?Ocurrencia=${id}`);
  }
  GetAllFilesById(id: number) {
    return http.get<ArrayBuffer>(`/C2206Reportes/Get2206AllFiles?id=${id}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  } 
  GetFacturasTxt(Inicio: string, Fin: string) {
    return http.get<I2206FacturasTxt[]>(`/C2206Reportes/Rpt2206FacturasTxt?Inicio=${Inicio}&Fin=${Fin}`);
  } 
}
export default new C1868HeinekenDataService();
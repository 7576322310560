import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
/* import './css/home.css' */
/* require (`./css/${process.env.REACT_APP_ENVIRONMENT}-home.css`) */
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { Login } from './Components/Login/login'

import AmazonNoPartes from './Components/Clientes/Amazon/Reportes/AmazonNoPartes'
import AvisoCruce from './Components/AgenciaAduanal/AvisoCruce/AvisoCruce'
import CatTabuladores from './Components/Catalogos/CatTabulador/CatTabulador'

import DashboardCorresponsales from './Components/Dashboard/DashboardCorresponsales'
import Dashboard from './Components/Dashboard/Dashboard'
import DashboardDireccion from './Components/Dashboard/DashboardDireccion'
import Logout from './Components/Logout/logout'
import ResetCredentials from './Components/Login/ResetCredentials'
import Register from './Components/Register/register'
import RegisterPermisoClientes from './Components/registerPermisoClientes/registerPermisoClientes'
import FotosBodega from './Components/Bodega/FotosBodega'
import FormatoRevision from './Components/Clientes/CasaCuervo/PDFs/FormatoRevision'
import RtpAmazonPendingClasificationInvoices from './Components/Clientes/Amazon/Reportes/Clasification/Pending/RptAmazonPendingClasificationInvoices'
import RtpAmazonFinishedClasificationInvoices from './Components/Clientes/Amazon/Reportes/Clasification/Finished/RptAmazonFinishedClasificationInvoices'
import RptArchivoElectronicoOficial from './Components/Reportes/RptArchivoElectronicoOficial'
import RptBattery from './Components/Reportes/RptBattery'
import RptControlDocumentosClientes from './Components/Reportes/RptControlDocumentosClientes'
import RptConsolidadosSinCerrar from './Components/GEMCO/Facturacion/Anexo/RptConsolidadosSinCerrar'
import RtpConsolidadosSinFirmaBanco from './Components/GEMCO/Facturacion/Anexo/RptConsolidadosSinFirmaBanco'
import RptDetalleAnexo24 from './Components/Reportes/RptDetalleAnexo24'
import RptFacturasTxtHeineken from './Components/Reportes/RptFacturasTxtHeineken'
import Notificaciones from './Components/Utils/Notificaciones/Notificaciones'
import Rpt1868Facturas from './Components/Clientes/ZincInternacional/Rpt1868Facturas'
import Rpt1868Transportista from './Components/Clientes/ZincInternacional/Transportistas/Rtp1868Transportista'
import RptAmazonPendingTraficInvoice from './Components/Clientes/Amazon/Reportes/Traffic/Pending/RtpAmazonPendingTraficInvoice'
import RptAmazonLinkInvoice2Traffic from './Components/Clientes/Amazon/Reportes/Traffic/Linking/RptAmazonLinkInvoice2Traffic'
import RptPedimentosPagados from './Components/Reportes/RptPedimentosPagados'
import RptPedimentosPagadosImpuestos from './Components/Reportes/RptPedimentosPagadosImpuestos'
import RptAmazonPayedOperations from './Components/Clientes/Amazon/Reportes/Payed/RptAmazonPayedOperations'
import RptPedidos from './Components/Clientes/CasaCuervo/Reportes/RptPedidos'
import RptPedimentosConsolidados from './Components/Reportes/RptPedimentosConsolidados'
import RptOperacionesConsolidadas from './Components/Reportes/RptOperacionesConsolidadas'
import RptOperacionesDiarias from './Components/Reportes/RptOperacionesDiarias'
import RptOperacionesRemesa from './Components/Reportes/RptOperacionesRemesa'
import RptPendingAnswerFromAmazon from './Components/Clientes/Amazon/Reportes/Clasification/WaitingAnswerFromAmazon/RptPendingAnswerFromAmazon'
import RptOperaciones from './Components/Reportes/RptOperaciones'
import RptRelacionMission from './Components/GEMCO/Facturacion/Anexo/RptRelacionMission'
import RptRelacionAlen from './Components/GEMCO/Facturacion/Anexo/RptRelacionAlen'
import RptCasaCuervoCOVEs from './Components/Clientes/CasaCuervo/Reportes/RptCasaCuervoCOVEs'
import RptAmazonInvoiceStatus from './Components/Clientes/Amazon/Reportes/Estatus/RtpAmazonInvoiceStatus'
import ForgotPassword from './Components/Login/ForgotPassword'
import PIN from './Components/Login/PIN'
import RptAmazonFacturasPagadas from './Components/Clientes/Amazon/Reportes/FacturasPagadas/RptAmazonFacturasPagadas'
import RptPedimentosPagadosFacturasCruzadas from './Components/Reportes/RptPedimentosPagadosFacturasCruzadas'
import RptPedimentosCruces01 from './Components/Reportes/Direccion/RptPedimentosCruces01'
import RptPedimentosCruces02 from './Components/Reportes/Direccion/RptPedimentosCruces02'
import RptPedimentosCruces03 from './Components/Reportes/Direccion/RptPedimentosCruces03'
import RptPedimentosCruces04 from './Components/Reportes/Direccion/RptPedimentosCruces04'
import RptPedimentosCruces05 from './Components/Reportes/Direccion/RptPedimentosCruces05'
import RptPedimentosCruces06 from './Components/Reportes/Direccion/RptPedimentosCruces06'
import RptSegregacion from './Components/Clientes/Amazon/Reportes/Segregacion/RptSegregacion'
import RptConsultaTrafico from './Components/Clientes/Amazon/Reportes/ConsultaTrafico/RptConsultaTrafico'
import RptMonitoreoFacturasPagadasAmazon from './Components/Reportes/RptMonitoreoFacturasPagadasAmazon'
import CatFraccionesVulnerables from './Components/Catalogos/CatFraccionesVulnerables/CatFraccionesVulnerables'
import RptFacturasFraccion from './Components/Reportes/RptContsFacturasFraccion'
import CatConceptosConsulta from './Components/Clientes/Amazon/Catalogos/CatConceptosConsulta'
import RptOperacionesPagadasMisionFood from './Components/Reportes/RptOperacionesPagadasMisionFood'
import RptFacturasPedimentosPagados from './Components/Reportes/RptFacturasPedimentosPagados'
import RptArcosa from './Components/Reportes/RptArcosa'

require(`./css/${process.env.REACT_APP_ENVIRONMENT}-home.css`)

function PageNotFound() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '300px' }}>
      <h2>Sorry, no matching page</h2>
    </div>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <HashRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="/login" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/PIN" element={<PIN />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/reset" element={<ResetCredentials />} />
            <Route path="/register" element={<Register />} />
            <Route path="/permisos" element={<RegisterPermisoClientes />} />
            <Route
              path="/rptPedimentosPagados"
              element={<RptPedimentosPagados />}
            />
            <Route
              path="/rptPedimentosPagadosImpuestos"
              element={<RptPedimentosPagadosImpuestos />}
            />
            <Route path="/rptDetalleAnexo24" element={<RptDetalleAnexo24 />} />
            <Route
              path="/rptOperacionesRemesa"
              element={<RptOperacionesRemesa />}
            />
            <Route path="/FotosBodega" element={<FotosBodega />} />
            <Route path="/rptBattery" element={<RptBattery />} />
            <Route
              path="/RptArchivoElectronicoOficial"
              element={<RptArchivoElectronicoOficial />}
            />
            <Route
              path="/RptPedimentosConsolidados"
              element={<RptPedimentosConsolidados />}
            />
            <Route
              path="/RptOperacionesConsolidadas"
              element={<RptOperacionesConsolidadas />}
            />
            <Route
              path="/DashboardCorresponsales"
              element={<DashboardCorresponsales />}
            />
            <Route
              path="/DashboardDireccion"
              element={<DashboardDireccion />}
            />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/RptOperaciones" element={<RptOperaciones />} />
            <Route
              path="/RptControlDocumentosClientes"
              element={<RptControlDocumentosClientes />}
            />
            <Route
              path="/RptRelacionMission"
              element={<RptRelacionMission />}
            />
            <Route path="/RptRelacionAlen" element={<RptRelacionAlen />} />
            <Route
              path="/RptConsolidadosSinCerrar"
              element={<RptConsolidadosSinCerrar />}
            />
            <Route
              path="/RptConsolidadosSinFirmaBanco"
              element={<RtpConsolidadosSinFirmaBanco />}
            />

            <Route
              path="/RptOperacionesDiarias"
              element={<RptOperacionesDiarias />}
            />
            <Route
              path="/RptFacturasTxtHeineken"
              element={<RptFacturasTxtHeineken />}
            />
            <Route path="/CatTabuladores" element={<CatTabuladores />} />
            <Route path="/RptPedidos" element={<RptPedidos />} />
            <Route path="/Rpt1868Facturas" element={<Rpt1868Facturas />} />
            <Route
              path="/CatFraccionesVulnerables"
              element={<CatFraccionesVulnerables />}
            />

            <Route
              path="/CatConceptosConsulta"
              element={<CatConceptosConsulta />}
            />

            <Route
              path="/Rpt1868Transportista"
              element={<Rpt1868Transportista />}
            />
            <Route path="/AvisoCruce" element={<AvisoCruce />} />
            <Route path="/Notificaciones" element={<Notificaciones />} />
            <Route
              path="/FormatoRevision/:id"
              element={<FormatoRevision />}
            ></Route>
            <Route
              path="/RptAmazonPendingClasificationInvoices"
              element={<RtpAmazonPendingClasificationInvoices />}
            />
            <Route
              path="/RptAmazonFinishedClasificationInvoices"
              element={<RtpAmazonFinishedClasificationInvoices />}
            />
            <Route
              path="/RptPendingAnswerFromAmazon"
              element={<RptPendingAnswerFromAmazon />}
            />
            <Route path="/AmazonNoPartes" element={<AmazonNoPartes />} />
            <Route
              path="/RptAmazonPendingTraficInvoice"
              element={<RptAmazonPendingTraficInvoice />}
            />
            <Route
              path="/RptAmazonLinkInvoice2Traffic"
              element={<RptAmazonLinkInvoice2Traffic />}
            />
            <Route
              path="/RptConsultaTrafico"
              element={<RptConsultaTrafico />}
            />
            <Route
              path="/RptAmazonPayedOperations"
              element={<RptAmazonPayedOperations />}
            />
            <Route
              path="/RptAmazonFacturasPagadas"
              element={<RptAmazonFacturasPagadas />}
            />
            <Route path="/RptSegregacion" element={<RptSegregacion />} />
            <Route
              path="/RptCasaCuervoCOVEs"
              element={<RptCasaCuervoCOVEs />}
            />
            <Route
              path="/RptAmazonInvoiceStatus"
              element={<RptAmazonInvoiceStatus />}
            />
            <Route
              path="/RptPedimentosPagadosFacturasCruzadas"
              element={<RptPedimentosPagadosFacturasCruzadas />}
            />

            <Route
              path="/RptOperacionesPagadasMisionFood"
              element={<RptOperacionesPagadasMisionFood />}
            />

            <Route
              path="/RptMonitoreoFacturasPagadasAmazon"
              element={<RptMonitoreoFacturasPagadasAmazon />}
            />
            <Route
              path="/RptPedimentosCruces01"
              element={<RptPedimentosCruces01 />}
            />
            <Route
              path="/RptPedimentosCruces02"
              element={<RptPedimentosCruces02 />}
            />
            <Route
              path="/RptPedimentosCruces03"
              element={<RptPedimentosCruces03 />}
            />
            <Route
              path="/RptPedimentosCruces04"
              element={<RptPedimentosCruces04 />}
            />
            <Route
              path="/RptPedimentosCruces05"
              element={<RptPedimentosCruces05 />}
            />
            <Route
              path="/RptPedimentosCruces06"
              element={<RptPedimentosCruces06 />}
            />
            <Route
              path="/RptConsFacturasFraccion"
              element={<RptFacturasFraccion />}
            />

            <Route
              path="/RptFacturasPedimentosPagados"
              element={<RptFacturasPedimentosPagados />}
            />
            <Route path="/RptArcosa" element={<RptArcosa />} />

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </HashRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)

reportWebVitals()

import http from '../common/http-common'
import IConsolidadosSinCerrar from '../../Interfaces/Facturacion/Anexo/IConsolidadosSinCerrar'
import DTOAnexoFacturacionMission from '../../DTO/Facturacion/Anexo/DTOAnexoFacturacionMission'
import IConsolidadosSinFirmaBanco from '../../Interfaces/Facturacion/Anexo/IConsolidadosSinFirmaBanco'


class OperacionesDataService {
  Get() {
    return http.post<IConsolidadosSinCerrar[]>(
      `/AnexoFacturacion/GetRptConsolidadosSinCerrar`
    )
  }
  
  OperacionesSinFirmaBancoGet(){
      return http.post<IConsolidadosSinFirmaBanco[]>(
        `/AnexoFacturacion/GetRptConsolidadosSinFirmaBanco`
      )
  }




}





export default new OperacionesDataService()

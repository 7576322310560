import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import {
  BsChevronDown,
  BsChevronRight,
  BsFillXCircleFill
} from 'react-icons/bs'
import { FaAmazon } from 'react-icons/fa'
import I2096Headers from '../../../Interfaces/I2096Header'
import DSAmazon from '../../../Services/Amazon.Services'
import { MsgInformativo } from '../../../../../Utils/Toast/msgInformativo'
import { RootState } from '../../../../../../store/store'
import {
  initializeInvoice,
  populateInvoices,
  updateInvoice
} from '../../../../../../store/features/Clientes/2096/AmazonInvoices'
import loadingImg from '../../../../../../images/ajaxloader.gif'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import RtpAmazonPendingClasificationInvoicesDetail from './RptAmazonPendingClasificationInvoicesDetail'
import ClasificatorAssign from './ClasificatorAssign'
import { FcQuestions, FcSettings } from 'react-icons/fc'
import { MFileManager } from '../../../../../Utils/MFileManager/MFileManager'
import { AiFillWarning } from 'react-icons/ai'
import { PrioritySelector } from '../PrioritySelector/PrioritySelector'
import IFileManager from '../../../../../../Interfaces/Utils/IFileManager'
import { VscHubot } from 'react-icons/vsc'
import { populateCatConcetosConsultas } from '../../../../../../store/features/Clientes/2096/AmazonCatConceptosConsulta'

export interface IRtpAmazonPendingInvoicesProps {}

export default function RtpAmazonPendingInvoices(
  props: IRtpAmazonPendingInvoicesProps
) {
  const [Usuario, setUsuario] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [header, setHeader] = useState('')
  const [IDFactura, setIDFactura] = useState(0)
  const [Factura, setFactura] = useState('')
  const [show, setShowMsg] = useState(false)
  const [ShowModalDeleteInvoice, setShowModalDeleteInvoice] = useState(false)
  const [ShowModalInvoiceFile, setShowModalInvoiceFile] = useState(false)
  const [IDProcess, setIDProcess] = useState(30)
  const [WaitingDialog, setWaitingDialog] = useState(false)
  const [WaitingMessage, setWaitingMessage] = useState('Generado...')
  const [ShowModal, setShowModal] = useState(false)
  const [ShowModalW, setShowModalW] = useState(false)
  const [BOTEnabled, setBOTEnabled] = useState(false)
  const [msg, setMsg] = useState('')
  const msgColor = 'primary'

  const loadReport = async () => {
    DSAmazon.AmazonPendingClasificationInvoiceGET()
      .then((response) => {
        //console.log(response.data)
        dispatch(initializeInvoice([]))
        dispatch(populateInvoices(response.data))
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  const loadCatalogoTipoConsultas = async () => {
    DSAmazon.CatConceptosConsultaGET()
      .then((response) => {
        dispatch(populateCatConcetosConsultas(response.data))
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  useEffect(() => {
    loadReport()
    loadCatalogoTipoConsultas()
  }, [])

  const check4Updates = (IDInvoice: number) => {
    DSAmazon.SETInvoiceDetail2Unchecked(IDInvoice)
      .then((response) => {
        DSAmazon.AmazonInvoiceGET(IDInvoice)
          .then((response) => {
            dispatch(updateInvoice(response.data[0]))
            let Invoice: I2096Headers[] = response.data.filter(
              (item) => item.id === IDInvoice
            )
            const Obj = { ...Invoice[0] }
            Obj.max = !Obj.max
            dispatch(updateInvoice(Obj))
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error')
            setShowModalDeleteInvoice(false)
            return
          })
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowModalDeleteInvoice(false)
        return
      })
  }

  const toggleExpandRow = (row: I2096Headers) => {
    if (!row.max) check4Updates(row.id)
    let Invoice: I2096Headers[] = mInvoices.filter((item) => item.id === row.id)
    const Obj = { ...Invoice[0] }
    Obj.max = !Obj.max
    dispatch(updateInvoice(Obj))
  }

  const descargaArchivoExcepciones = () => {
    setShowModalW(false)
    setWaitingMessage('Archivo de excepciones')
    /*   setWaitingDialog(true)*/
    /*  DSAmazon.ExceptionsFileAmazonGET(IDFactura)
      .then((response: any) => {
        if (response.status === 200) {*/
    DSAmazon.EnviaEsperaRespuestaAmazon(IDFactura)
      .then((response) => {
        loadReport()
        setWaitingDialog(false)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg(
          'Ocurrio un error, no se pudo actualizar informacion' +
            e.message.toString()
        )
        setShowMsg(true)
        return
      })
    //  }
    /* })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      }) */
  }

  const descargaPantillaPartes = () => {
    let Invoice: I2096Headers[] = mInvoices.filter(
      (item) => item.id === IDFactura
    )
    let TotalPartidas = Invoice[0].detail.length
    let TotalFraccionesPreviamenteRegistradas = Invoice[0].detail.filter(
      (item) => item.confirmaFraccion === 3
    ).length
    if (TotalPartidas === TotalFraccionesPreviamenteRegistradas) {
      setShowModal(false)
      DSAmazon.TerminaClasificarFactura(IDFactura)
        .then((response) => {
          loadReport()
          setWaitingDialog(false)
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg(
            'Ocurrio un error, no se pudo actualizar informacion' +
              e.message.toString()
          )
          setShowMsg(true)
          return
        })
    } else {
      setShowModal(false)
      setWaitingMessage('Plantilla de partes')
      setWaitingDialog(true)
      DSAmazon.NoPartesAmazon2SIRGET(IDFactura)
        .then((response: any) => {
          if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              'Plantilla de partes ' + Factura + '.xls'
            )
            document.body.appendChild(link)
            link.click()
            DSAmazon.TerminaClasificarFactura(IDFactura)
              .then((response) => {
                loadReport()
                setWaitingDialog(false)
              })
              .catch((e: Error) => {
                setHeader('Error')
                setMsg(
                  'Ocurrio un error, no se pudo actualizar informacion' +
                    e.message.toString()
                )
                setShowMsg(true)
                return
              })
          }
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error')
          setShowMsg(true)
          return
        })
    }
  }

  const facturaTerminada = (row: I2096Headers) => {
    DSAmazon.SePuedeTerminardeClasificarFactura(row.id).then((response) => {
      if (response.data.estatus === 0) {
        setIDFactura(0)
        setFactura('')
        setHeader('Error')
        setMsg(response.data.respuesta)
        setShowMsg(true)
        return
      } else if (response.data.estatus === 1) {
        setIDFactura(row.id)
        setFactura(row.invoiceNumber)
        setShowModalW(true)
      } else if (response.data.estatus === 2) {
        setIDFactura(row.id)
        setFactura(row.invoiceNumber)
        setShowModal(true)
      }
    })
  }

  const eliminaFactura = () => {
    DSAmazon.EliminaFactura(IDFactura)
      .then((response) => {
        if (response.data) {
          setIDFactura(0)
          setFactura('')
          setShowModalDeleteInvoice(false)
          loadReport()
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowModalDeleteInvoice(false)
        return
      })
  }

  const syncInvoicesInFilePath = () => {
    setWaitingDialog(true)
    DSAmazon.UploadAndSyncAmazonInvoicesInFilePath()
      .then((response) => {
        if (response.data) {
          setIDFactura(0)
          setFactura('')
          setShowModalInvoiceFile(false)
          setWaitingDialog(false)
          loadReport()
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowModalDeleteInvoice(false)
        setWaitingDialog(false)
        return
      })
  }

  const syncInvoicesByFTP = () => {
    setWaitingDialog(true)
    DSAmazon.UploadAndSyncAmazonInvoicesByFTP()
      .then((response) => {
        if (response.data) {
          setIDFactura(0)
          setFactura('')
          setShowModalInvoiceFile(false)
          setWaitingDialog(false)
          loadReport()
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowModalDeleteInvoice(false)
        setWaitingDialog(false)
        return
      })
  }

  const RunBOT = (row: I2096Headers) => {
    if (!BOTEnabled || row.max) {
      toggleExpandRow(row)
      return false
    }
    if (BOTEnabled) {
      setWaitingMessage('BOT trabajando...')
      setWaitingDialog(true)
      DSAmazon.ClasificacionBOT(row.id)
        .then((response) => {
          if (response.data) {
            setIDFactura(0)
            setFactura('')
            setShowModalInvoiceFile(false)
            setWaitingDialog(false)
            //loadReport()
            toggleExpandRow(row)
            return
          }
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error')
          setShowModalDeleteInvoice(false)
          setWaitingDialog(false)
          return
        })
    }
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={5}>
              <Card.Title>
                <IconContext.Provider value={{ color: '#F6C34F', size: '8%' }}>
                  <FaAmazon />
                </IconContext.Provider>
                CLASIFICACION : facturas pendientes
              </Card.Title>
            </Col>
            <Col xs={3}></Col>
            <Col xs={2}>
              {/* <Row>
                <Col xs={4} style={{ textAlign: 'right' }}>
                  <IconContext.Provider
                    value={{ color: 'green', size: '65px' }}
                  >
                    <VscHubot />
                  </IconContext.Provider>
                </Col>
                <Col xs={8} style={{ textAlign: 'left', paddingTop: '25px' }}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="¿Aplica BOT?"
                    checked={BOTEnabled}
                    onChange={() => {
                      setBOTEnabled(!BOTEnabled)
                    }}
                  />
                </Col>
              </Row> */}
              <Form></Form>
            </Col>
            <Col xs={2}>
              <Alert
                variant="primary"
                style={{
                  visibility: [
                    'Clasificador lider',
                    'Administrador',
                    'Sistemas'
                  ].includes(Perfil)
                    ? 'visible'
                    : 'hidden'
                }}
              >
                <span
                  style={{ cursor: 'pointer' }}
                  title="Sube facturas enviadas por correo electronico"
                  onClick={() => {
                    setWaitingMessage(
                      'Actualizando informacion, espere, por favor...'
                    )
                    setShowModalInvoiceFile(true)
                  }}
                >
                  <IconContext.Provider value={{ color: 'blue', size: '35px' }}>
                    <FcQuestions />
                  </IconContext.Provider>
                </span>

                <span
                  style={{
                    cursor: 'pointer',
                    paddingLeft: '40px',
                    visibility: 'hidden'
                  }}
                  title="Descarga y sincroniza facturas en este momento"
                  onClick={() => {
                    syncInvoicesByFTP()
                  }}
                >
                  <IconContext.Provider value={{ color: 'blue', size: '35px' }}>
                    <FcSettings />
                  </IconContext.Provider>
                </span>
              </Alert>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}></Col>
        <Col xs={2} className="totalLabel"></Col>
      </Row>
      <Card>
        <Card.Body>
          <div className="MDcontainer">
            <Table className="MDTable" hover>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr>
                  <th style={{ width: '50px' }}></th>
                  <th style={{ width: '50px' }}>id</th>
                  <th style={{ width: '50px', paddingRight: '20px' }}>
                    <IconContext.Provider
                      value={{ color: 'yellow', size: '20px' }}
                    >
                      <AiFillWarning />
                    </IconContext.Provider>
                  </th>
                  <th style={{ width: '50px' }}></th>
                  <th style={{ width: '80px' }}>Commercial Invoice Date</th>
                  <th style={{ width: '300px' }}>Invoice Number</th>
                  <th style={{ width: '50px' }}>Receiver</th>
                  <th style={{ width: '100px' }}>Incoterms</th>
                  <th style={{ width: '100px' }}>Qty Unit of measure</th>
                  <th style={{ width: '100px' }}>Tot Qty</th>
                  <th style={{ width: '100px' }}>Weight unit of measure</th>
                  <th style={{ width: '100px' }}>Tot weight</th>
                  <th style={{ width: '100px' }}>Tot Charge or allowance</th>
                  <th style={{ width: '100px' }}>Tot currency ISO code</th>
                  <th style={{ width: '100px' }}>Tot Monetary amount</th>
                  <th style={{ width: '250px' }}>Total de partidas</th>
                  <th style={{ width: '250px' }}>Asignado</th>
                </tr>
              </thead>
              <tbody>
                {mInvoices
                  ? mInvoices
                      .filter((a) => {
                        if (
                          a.clasificador === parseInt(Usuario) &&
                          Perfil !== 'Clasificador lider'
                        ) {
                          return a
                        } else if (
                          [
                            'Clasificador lider',
                            'Administrador',
                            'Sistemas'
                          ].includes(Perfil)
                        ) {
                          return a
                        }
                      })
                      .map((MasterData) => {
                        return (
                          <>
                            <tr
                              style={{ cursor: 'pointer' }}
                              className={
                                MasterData.max === true
                                  ? 'masterSelected'
                                  : 'normalSelected'
                              }
                            >
                              <td
                                style={{ textAlign: 'left' }}
                                className={
                                  MasterData.max === true
                                    ? 'masterSelected'
                                    : 'normalSelected'
                                }
                                key={MasterData.id}
                                onClick={() => {
                                  //toggleExpandRow(MasterData)
                                  RunBOT(MasterData)
                                }}
                              >
                                {MasterData.max === true ? (
                                  <IconContext.Provider
                                    value={{ color: 'blue', size: '15px' }}
                                  >
                                    <BsChevronDown />
                                  </IconContext.Provider>
                                ) : (
                                  <IconContext.Provider
                                    value={{ color: 'blue', size: '15px' }}
                                  >
                                    <BsChevronRight />
                                  </IconContext.Provider>
                                )}
                              </td>
                              <td>{MasterData.id}</td>
                              <td style={{ textAlign: 'left' }}>
                                <PrioritySelector
                                  IDInvoice={MasterData.id}
                                  Priority={MasterData.urgente}
                                  canChange={[
                                    'Clasificador lider',
                                    'Administrador'
                                  ].includes(Perfil)}
                                />
                              </td>
                              <td>
                                <FormCheck
                                  id="switchTerminaFactura"
                                  type="switch"
                                  checked={false}
                                  onChange={() => {
                                    facturaTerminada(MasterData)
                                  }}
                                  label=""
                                />
                              </td>
                              <td
                                style={{ width: '80px', textAlign: 'center' }}
                              >
                                {MasterData.commercialInvoiceDate}
                              </td>
                              <td style={{ width: '150px' }}>
                                {MasterData.invoiceNumber}
                                <span
                                  style={{
                                    paddingLeft: '5px',
                                    visibility: [
                                      'Clasificador lider',
                                      'Administrador',
                                      'Sistemas'
                                    ].includes(Perfil)
                                      ? 'visible'
                                      : 'hidden'
                                  }}
                                  onClick={() => {
                                    setIDFactura(MasterData.id)
                                    setFactura(MasterData.invoiceNumber)
                                    setShowModalDeleteInvoice(true)
                                  }}
                                >
                                  <IconContext.Provider
                                    value={{ color: '#DD0E0E' }}
                                  >
                                    <BsFillXCircleFill />
                                  </IconContext.Provider>
                                </span>
                              </td>
                              <td
                                style={{ width: '50px', textAlign: 'center' }}
                              >
                                {MasterData.name}
                              </td>
                              <td
                                style={{ width: '100px', textAlign: 'center' }}
                              >
                                {MasterData.incoterms}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceQuantityUnitOfMeasure}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceQuantity}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceWeightUnitOfMeasure}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceWeight}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceValueChargeOrAllowance}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceValueCurrencyISOCode}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {MasterData.totalInvoiceValueMonetaryAmount}
                              </td>
                              <td
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: 'center'
                                }}
                              >
                                {mInvoices
                                  .filter((value) => value.id === MasterData.id)
                                  .map((row) => {
                                    return row.detail.length
                                  })}
                              </td>
                              <td>
                                <ClasificatorAssign
                                  Disabled={Perfil !== 'Clasificador lider'}
                                  Invoice={MasterData}
                                />
                              </td>
                            </tr>
                            {MasterData.max ? (
                              <tr
                                className={
                                  MasterData.max === true
                                    ? 'masterSelected2'
                                    : 'normalSelected2'
                                }
                              >
                                <th colSpan={18}>
                                  <table
                                    className="childTable"
                                    style={{ width: '1450px' }}
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          style={{
                                            width: '50px',
                                            backgroundColor: '#FFFFFF',
                                            color: '#346288'
                                          }}
                                        ></th>
                                        <th
                                          style={{
                                            width: '100px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Item Id
                                        </th>
                                        <th
                                          style={{
                                            width: '400px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Item Description
                                        </th>
                                        <th
                                          style={{
                                            width: '120px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Pedimento Description
                                        </th>
                                        <th
                                          style={{
                                            width: '200px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Destination HTS Code
                                        </th>
                                        <th
                                          style={{
                                            width: '120px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Valida
                                        </th>
                                        <th
                                          style={{
                                            width: '120px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Confirme
                                          &nbsp;&nbsp;fraccion&nbsp;&nbsp;
                                        </th>
                                        <th
                                          style={{
                                            width: '120px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Fraccion GEMCO
                                        </th>
                                        <th
                                          style={{
                                            width: '120px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Confirme descripcion
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Descripcion GEMCO
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Country of Origin
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          ProductGroup
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Brand
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Model
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Unit Measure
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Qty Shipped
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Unit Of measure
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Unit Net Weight
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Unit Cost
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Total value
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          RN
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Comm
                                        </th>
                                        <th
                                          style={{
                                            width: '70px',
                                            backgroundColor: '#D3E0EA',
                                            color: '#346288'
                                          }}
                                        >
                                          Chk
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {mInvoices.map((MD) => {
                                        return (
                                          <>
                                            {MD.detail
                                              .filter(
                                                (detail) =>
                                                  MasterData.id ===
                                                  detail.idHeader
                                              )
                                              .map((detail) => {
                                                return (
                                                  <RtpAmazonPendingClasificationInvoicesDetail
                                                    IDMaster={MasterData.id}
                                                    Prioridad={
                                                      MasterData.urgente
                                                    }
                                                    detail={detail}
                                                    Deshabilitado={
                                                      detail.destinationHTSCode
                                                        .replaceAll('.', '')
                                                        .trim() ===
                                                      detail.fraccionGEMCO
                                                    }
                                                  />
                                                )
                                              })}
                                          </>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </th>
                              </tr>
                            ) : null}
                          </>
                        )
                      })
                  : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>

      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '180px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={1}></Col>
              <Col xs={10}>
                <Alert key={'danger'} variant={'danger'}>
                  ¿La factura esta lista para terminarse y pasarse al siguiente
                  proceso?
                </Alert>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  &nbsp;&nbsp;No&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    descargaPantillaPartes()
                  }}
                >
                  &nbsp;&nbsp;Si&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ShowModalW}
        onHide={() => {
          setShowModalW(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '180px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={1}></Col>
              <Col xs={10}>
                <Alert key={'danger'} variant={'danger'}>
                  ¿La factura esta lista para enviarse al proceso de esperar
                  respuesta de Amazon?
                </Alert>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModalW(false)
                  }}
                >
                  &nbsp;&nbsp;No&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    descargaArchivoExcepciones()
                  }}
                >
                  &nbsp;&nbsp;Si&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={WaitingDialog}
        onHide={() => {
          setWaitingDialog(false)
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        dialogClassName={'modal-50w'}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={loadingImg} style={{ height: '150px' }} alt="proccessing" />
          {WaitingMessage.includes('BOT') ? (
            <IconContext.Provider value={{ color: 'green', size: '65px' }}>
              <VscHubot />
            </IconContext.Provider>
          ) : (
            ''
          )}
          {WaitingMessage}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={ShowModalDeleteInvoice}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '180px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={1}></Col>
              <Col xs={10}>
                <Alert key={'danger'} variant={'danger'}>
                  ¿Esta seguro de eliminar esta factura: <b>{Factura}</b>?
                </Alert>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModalDeleteInvoice(false)
                  }}
                >
                  &nbsp;&nbsp;Cerrar&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    eliminaFactura()
                  }}
                >
                  &nbsp;&nbsp;Elimina factura&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ShowModalInvoiceFile}
        onHide={() => {
          setShowModalInvoiceFile(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '330px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={12}>
                <Alert
                  key={'danger'}
                  variant={'danger'}
                  style={{ textAlign: 'center' }}
                >
                  Proporcione la(facturas) que llegaron por correo en el proceso
                  de Amazon
                </Alert>
              </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col></Col>
              <Col xs={10} style={{ textAlign: 'center' }}>
                <MFileManager
                  IDTrafico={IDFactura}
                  Proceso={IDProcess}
                  showPreview={0}
                  canEdit={true}
                  Leyenda="Seleccione las facturas de Amazon"
                  onAppendMFM={function (Files: IFileManager[]): void {}}
                />
              </Col>
              <Col></Col>
            </Row>
            <Row style={{ paddingTop: '80px' }}>
              <Col xs={10}></Col>
              <Col xs={2}>
                <Button
                  variant="primary"
                  onClick={() => {
                    syncInvoicesInFilePath()
                  }}
                >
                  &nbsp;&nbsp;Sincronize...&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip
} from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { FaCheckCircle, FaQuestionCircle } from 'react-icons/fa'
import { ImCross } from 'react-icons/im'
import { MsgInformativo } from '../../../../../Utils/Toast/msgInformativo'
import { ComboDescripcion } from '../../../Components/ComboSelect/ComboDescripcion'
import { ComboFraccion } from '../../../Components/ComboSelect/ComboFraccion'
import DTO2096ItemAutoriza from '../../../DTO/DTO2096ItemAutoriza'
import I2096Detail from '../../../Interfaces/I2096Detail'
import I2096Headers from '../../../Interfaces/I2096Header'
import DSAmazon from '../../../Services/Amazon.Services'
// Redux
import { RootState } from '../../../../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { updateInvoice } from '../../../../../../store/features/Clientes/2096/AmazonInvoices'
import * as CurrencyFormat from 'react-currency-format'
import { AiOutlineFileSearch } from 'react-icons/ai'
import { BsFillChatRightTextFill } from 'react-icons/bs'

export interface IRtpAmazonPendingInvoivesDetailProps {
  IDMaster: number
  Prioridad: boolean,
  detail: I2096Detail
  Deshabilitado: boolean
}

export default function RtpAmazonPendingInvoivesDetail(
  props: IRtpAmazonPendingInvoivesDetailProps
) {
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [ShowModal, setShowModal] = useState(false)
  const [Checked, setChecked] = useState(props.detail.checked)
  const [EstatusSwith, setEstatusSwitch] = useState(props.detail.autorizado)
  const [UserChanged, setUserChanged] = useState(false)
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  const [Show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [CumplimientoNormativo, setCumplimientoNormativo] = useState(
    props.detail.cumplimientoNormativo ? props.detail.cumplimientoNormativo : ''
  )
  const [Comentarios, setComentarios] = useState(
    props.detail.comentarios
      ? props.detail.comentarios
      : 'Clasificacion Incorrecta'
  )
  const msgColor = 'primary'
  const openLink = (item: string) => {
    window.open('https://www.amazon.com/dp/' + item)
  }
  //const target = React.useRef(null)

  const UpdateInvoiceDetail = (
    Invoice: I2096Headers,
    Detail: I2096Detail[]
  ) => {
    const updatedInvoice: I2096Headers = {
      id: props.IDMaster,
      referencia: Invoice.referencia,
      commercialInvoiceDate: Invoice.commercialInvoiceDate,
      invoiceNumber: Invoice.invoiceNumber,
      name: Invoice.name,
      trailerNumber: Invoice.trailerNumber,
      trailerId: Invoice.trailerId,
      porOfLoading: Invoice.porOfLoading,
      portOfEntry: Invoice.portOfEntry,
      paymentsTerms: Invoice.paymentsTerms,
      incoterms: Invoice.incoterms,
      hawb: Invoice.hawb,
      totalInvoiceQuantityUnitOfMeasure:
        Invoice.totalInvoiceQuantityUnitOfMeasure,
      totalInvoiceQuantity: Invoice.totalInvoiceQuantity,
      totalInvoiceWeightUnitOfMeasure: Invoice.totalInvoiceWeightUnitOfMeasure,
      totalInvoiceWeight: Invoice.totalInvoiceWeight,
      totalInvoiceValueChargeOrAllowance:
        Invoice.totalInvoiceValueChargeOrAllowance,
      totalInvoiceValueCurrencyISOCode:
        Invoice.totalInvoiceValueCurrencyISOCode,
      totalInvoiceValueMonetaryAmount: Invoice.totalInvoiceValueMonetaryAmount,
      footNote: Invoice.footNote,
      archivo: Invoice.archivo,
      fCreacion: Invoice.fCreacion,
      clasificador: Invoice.clasificador,
      noVuelta: Invoice.noVuelta,
      estatus: Invoice.estatus,
      max: true,
      urgente: Invoice.urgente,
      detail: Detail
    }
    dispatch(updateInvoice(updatedInvoice))
  }

  const editInvoice = (id: number, valor: boolean) => {
    const Invoice = mInvoices
      .filter((el) => el.id === props.IDMaster)
      .map((el) => {
        return el
      })
    const newDetails = Invoice[0].detail.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          checked: valor
        }
      }
      return el
    })
    UpdateInvoiceDetail(Invoice[0], newDetails)
  }

  const autoriceItem = (id: number) => {
    if (CumplimientoNormativo.length === 0 && EstatusSwith) {
      setHeader('Error')
      setMsg(
        'Antes de poder guardar la informacion es necesario que proporcione el reparto normativo'
      )
      setShowMsg(true)
      return
    }
    const data: DTO2096ItemAutoriza = {
      id: id,
      autorizado: EstatusSwith,
      cumplimientoNormativo: CumplimientoNormativo,
      comentarios: Comentarios
    }
    console.log(data)
  }

  const checkDetail = () => {
    DSAmazon.CheckDetail(props.detail.id)
      .then((response) => {
        setChecked(!Checked)
        editInvoice(props.detail.id, !Checked)
      })
      .catch((e: Error) => {})
  }

  const ColorSET = (value: number, mode: number) => {
    switch (mode) {
      case 1: {
        if (value === 3) return '#E9F3FC'
        break
      }
      case 2: {
        if (value === 3) return '2px solid blue'
        break
      }
      case 3: {
        if (value === 3) return 'blue'
        break
      }
    }
  }

  return (
    <>
      <tr>
        <th
          style={{
            width: '50px'
          }}
          className="DetailData"
        >
          &nbsp;
        </th>
        <td
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
          onClick={() => openLink(props.detail.itemId)}
        >
          {props.detail.itemId}
        </td>
        <td
          style={{
            textAlign: 'right',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.itemDescription}
        </td>
        <td
          style={{
            textAlign: 'right',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
        >
          {props.detail.pedimentoDescription}
        </td>
        <td
          style={{
            textAlign: 'center',
            fontSize: '18px',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
        >
          &nbsp;&nbsp;{props.detail.destinationHTSCode}
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          <ComboFraccion
            IDInvoice={props.IDMaster}
            IDDetail={props.detail.id}
            Prioridad={props.Prioridad}
            FraccionOriginal={props.detail.destinationHTSCode}
            ConfirmaFraccion={props.detail.confirmaFraccion}
            Deshabilitado={false}
            Detail={props.detail}
          />
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          <CurrencyFormat
            value={props.detail.fraccionGEMCO ? props.detail.fraccionGEMCO : ''}
            disabled={true}
            format={'####.##.####'}
            displayType={'input'}
            style={{
              fontSize: '17px',
              backgroundColor: ColorSET(props.detail.confirmaFraccion, 1),
              border: ColorSET(props.detail.confirmaFraccion, 2),
              color: ColorSET(props.detail.confirmaFraccion, 3),
              width: '135px',
              textAlign: 'right',
              borderRadius: '10px'
            }}
          />
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          <ComboDescripcion
            IDInvoice={props.IDMaster}
            IDDetail={props.detail.id}
            Prioridad={props.Prioridad}            
            DescripcionOriginal={props.detail.pedimentoDescription}
            ConfirmaDescripcion={props.detail.confirmaDescripcion}
            Deshabilitado={false}
            Detail={props.detail}
          />
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.descripcionGEMCO}
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.countryOfOrigin}
        </td>
        <td
          style={{
            textAlign: 'right',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.productGroup}
        </td>
        <td
          style={{
            textAlign: 'right',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.brand}
        </td>
        <td
          style={{
            textAlign: 'right',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.model}
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.itemQuantityUnitOfMeasure}
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.quantity}
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.weightValue}
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.netWeightUnitOfMeasure}
        </td>

        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.actualUnitCostMonetaryAmount}
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
          className="DetailData"
        >
          {props.detail.totalUnitValueMonetaryAmount}
        </td>
        <td
          style={{
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                {props.detail.confirmaFraccion === 2
                  ? props.detail.cumplimientoNormativo
                  : ''}
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                {...triggerHandler}
                style={{
                  visibility:
                    props.detail.confirmaFraccion === 2 ? 'visible' : 'hidden'
                }}
              >
                <IconContext.Provider
                  value={{
                    color: 'blue',
                    size: '25px'
                  }}
                >
                  <AiOutlineFileSearch />
                </IconContext.Provider>
                <span className="ms-1"></span>
              </Button>
            )}
          </OverlayTrigger>
        </td>
        <td
          style={{
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">{Comentarios}</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                {...triggerHandler}
                style={{
                  visibility:
                    props.detail.confirmaFraccion === 2 ? 'visible' : 'hidden'
                }}
              >
                <IconContext.Provider
                  value={{
                    color: 'green',
                    size: '25px'
                  }}
                >
                  <BsFillChatRightTextFill />
                </IconContext.Provider>
                <span className="ms-1"></span>
              </Button>
            )}
          </OverlayTrigger>
        </td>
        <td
          style={{
            textAlign: 'center',
            backgroundColor: props.detail.checked ? props.detail.confirmaFraccion==5 ? '#F7E2D2' : '#E6EEF5' : '#ffffff'
          }}
        >
          <Form>
            <Form.Check
              type="checkbox"
              id="Checkbox"
              disabled={((Perfil!=='Clasificador Lider')&&(props.detail.confirmaFraccion===0)||(props.detail.confirmaDescripcion===0))||
                ((Perfil==='Clasificador')&&(props.detail.validaFraccionOriginal===3))}
              onClick={() => {
                checkDetail()
              }}
              checked={Checked}
            />
          </Form>
        </td>
      </tr>

      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-50w'}
      >
        <Modal.Body>
          <div style={{ height: '280px' }}>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={1}></Col>
              <Col xs={10}>
                <Alert key={'danger'} variant={'danger'}>
                  Para poder continuar es necesario que proporcione la siguiente
                  informacion
                </Alert>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={3}>Cumplimiento normativo</Col>
              <Col xs={8}>
                <Form.Control
                  as="textarea"
                  id="RequerimientoNormativo"
                  size="sm"
                  value={CumplimientoNormativo}
                  onChange={(e) => setCumplimientoNormativo(e.target.value)}
                />
              </Col>
              <Col>&nbsp;</Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={3}>Comentarios</Col>
              <Col xs={8}>
                <Form.Control
                  as="textarea"
                  id="Comentarios"
                  size="sm"
                  value={
                    Comentarios.length
                      ? Comentarios
                      : 'Clasificacion incorrecta.'
                  }
                  onChange={(e) => setComentarios(e.target.value)}
                />
              </Col>
              <Col>&nbsp;</Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  &nbsp;&nbsp;No&nbsp;&nbsp;
                </Button>
              </Col>
              <Col xs={6} style={{ textAlign: 'center' }}>
                <Button
                  variant="danger"
                  onClick={() => {
                    autoriceItem(props.detail.id)
                  }}
                >
                  &nbsp;&nbsp;Si&nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </>
  )
}

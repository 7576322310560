import * as React from 'react'
import { useEffect, useState } from 'react'
import { RootState } from '../../../../../store/store'
import I2096Headers from '../../Interfaces/I2096Header'
import DSAmazon from '../../Services/Amazon.Services'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import {
  initializeInvoice,
  populateInvoices,
  updateInvoice
} from '../../../../../store/features/Clientes/2096/AmazonInvoices'
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import { MsgInformativo } from '../../../../Utils/Toast/msgInformativo'
import { AiFillFileExcel } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import { BsChevronDown, BsChevronRight, BsFilePdfFill } from 'react-icons/bs'
import { FaAmazon } from 'react-icons/fa'
import { BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
import loadingImg from '../../../../../images/ajaxloader.gif'
import { MFileManager } from '../../../../Utils/MFileManager/MFileManager'
import DTO2096RptPayedOperations from '../../DTO/DTO2096RptPayedOperations'
import { GoGear } from 'react-icons/go'
import IFileManager from '../../../../../Interfaces/Utils/IFileManager'
import DTO2096RptFacturasPagadas from '../../DTO/DTO2096RptFacturasPagadas'
import DataTable from 'react-data-table-component'
import * as XLSX from 'xlsx'
import DTOAmazonSegragacion from '../../DTO/DTOAmazonSegragacion'

export interface IRptSegregacionProps {}

export default function RptSegregacion(props: IRptSegregacionProps) {
  const dispatch = useDispatch()
  const mInvoices = useSelector(
    (state: RootState) => state.AmazonInvoices.Invoice
  )
  const [header, setHeader] = useState('')
  const [Referencia, setReferencia] = useState('')
  const [Inicio, setInicio] = useState(currentDate())
  const [Fin, setFin] = useState(currentDate())
  const [Data, setData] = useState<DTOAmazonSegragacion[]>([])
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [WaitingDialog, setWaitingDialog] = useState(false)
  const [WaitingMessage, setWaitingMessage] = useState('')
  const [msg, setMsg] = useState('')
  const [ShowModalAnswerFile, setShowModalAnswerFile] = useState(false)
  const [IDProcess, setIDProcess] = useState(25)
  const [IDFactura, setIDFactura] = useState(0)
  const [Invoices, setInvoices] = useState<number[]>([])
  const msgColor = 'primary'

  const columnsConcepts = [
    {
      name: 'VRID',
      width: '150px',
      selector: (row: DTOAmazonSegragacion) => row.vrid,
      sortable: true
    },
    {
      name: 'ASIN',
      width: '150px',
      selector: (row: DTOAmazonSegragacion) => row.asin,
      sortable: true
    },
    {
      name: 'Quantyty',
      width: '100px',
      selector: (row: DTOAmazonSegragacion) => row.quantity,
      sortable: true
    },
    {
      name: 'Descripcion',
      width: '250px',
      selector: (row: DTOAmazonSegragacion) => row.descripcion,
      sortable: true
    },
    {
      name: 'Motivo',
      width: '2100px',

      selector: (row: DTOAmazonSegragacion) => row.motivo,
      sortable: true
    },
    {
      name: 'FSegregacion',
      width: '160px',
      selector: (row: DTOAmazonSegragacion) => row.fSegregacion,
      sortable: true
    },
    {
      name: 'ValorMercancia',
      width: '160px',
      selector: (row: DTOAmazonSegragacion) => row.valorMercancia,
      sortable: true
    }
  ]

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const downloadReport = () => {
    DSAmazon.GETReporteSegregados(Inicio, Fin)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Segregrados.xls')
          document.body.appendChild(link)
          link.click()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const loadReport = () => {
    DSAmazon.GETReportesSegregadoss(Inicio, Fin)
      .then((response) => {
        setData(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={5}>
              <Card.Title>
                <IconContext.Provider value={{ color: '#F6C34F', size: '8%' }}>
                  <FaAmazon />
                </IconContext.Provider>
                Segregacion
              </Card.Title>
            </Col>
            <Col></Col>
            <Col style={{ textAlign: 'right' }}>Desde</Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Inicio}
                type="date"
                name="Inicio"
                placeholder="Inicio"
                title="Inicio"
                alt="Inicio"
                data-date-format="YYYY-mm-dd"
                onChange={(e) => setInicio(e.target.value)}
                size="sm"
              />
            </Col>
            <Col style={{ textAlign: 'right' }}>Hasta</Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Fin}
                type="date"
                name="Fin"
                placeholder="Fin"
                title="Fin"
                alt="Fin"
                onChange={(e) => setFin(e.target.value)}
                size="sm"
              />
            </Col>
            <Col
              style={{ textAlign: 'right' }}
              onClick={() => {
                downloadReport()
              }}
            >
              <Button variant="primary">
                <BsFileEarmarkExcel />
                Descargar
              </Button>
            </Col>

            <Col
              style={{ textAlign: 'center' }}
              onClick={() => {
                loadReport()
              }}
            >
              <Button variant="primary">
                {' '}
                <BsSearch />
                Buscar
              </Button>
            </Col>

            <Card>
              <Card.Body>
                <div
                  className="ag-theme-alpine"
                  style={{ height: 500, width: '100%' }}
                >
                  <DataTable
                    noHeader
                    defaultSortFieldId={''}
                    defaultSortAsc={true}
                    striped={true}
                    dense={true}
                    paginationPerPage={10}
                    pagination
                    highlightOnHover
                    columns={columnsConcepts}
                    data={Data}
                    pointerOnHover
                  />
                </div>
              </Card.Body>
            </Card>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

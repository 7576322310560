import { configureStore } from '@reduxjs/toolkit'
import userStatusReducer from './features/userStatusSlice/userStatusSlice'
import ArchivoElectronicoSlice  from './features/Reportes/ArchivoElectronico'
import CatProveedoresSlice from './features/CatProveedores/CatProveedoresSlice'
import CatCorresponsalesSlice from './features/CatCorresponsales/CatCorresponsalesSlice'
import CatFraccionesVulnerablesSlice from './features/CatFraccionesVulnerables/CatFraccionesVulnerablesSlice'
import CorresponsalesFacturasSlice from './features/Corresponsales/CorresponsalesFacturasSlice'
import CorresponsalesFacturasTercerosSlice from './features/Corresponsales/CorresponsalesFacturasTercerosSlice'
import CorresponsalesContenedoresSlice from './features/Corresponsales/CorresponsalesContenedoresSlice'
import CorresponsalesAnticipos from './features/Corresponsales/CorresponsalesAnticiposSlice'
import CorresponsalesGuiasSlice from './features/Corresponsales/CorresponsalesGuiasSlice'
import CorresponsalesPartidasSlice from './features/Corresponsales/CorresponsalesPartidasSlice'
import Cat1868DestinosSlice from './features/Clientes/1868/Cat1868DestinosSlice'
import Cat1868ResponsablesSlice from './features/Clientes/1868/Cat1868ResponsablesSlice'
import Cat1868ClaveSATSlice from './features/Clientes/1868/Cat1868ClaveSATSlice'
import Data1868FacturaDetalleSlice from './features/Clientes/1868/Data1868FacturaDetalleSlice'
import CorresponsalesCuentasComplementariasEstatusSlice from './features/Corresponsales/CorresponsalesCuentasComplementariasEstatusSlice'
import AmazonInvoicesSlice from './features/Clientes/2096/AmazonInvoices'
import AmazonNoPartesSlice from './features/Clientes/2096/AmazonNoPartes'
import AmazonTraficoConsultaSlice  from './features/Clientes/2096/AmazonTraficoConsulta'
import AmazonCatConceptosConsulta from './features/Clientes/2096/AmazonCatConceptosConsulta'



export const store = configureStore({
  reducer: {
    userStatus: userStatusReducer,
    ArchivoElectronico: ArchivoElectronicoSlice,
    CatProveedores: CatProveedoresSlice,
    CatCorresponsales: CatCorresponsalesSlice,
    CatFraccionesVulnerables : CatFraccionesVulnerablesSlice, 
    CFData: CorresponsalesFacturasSlice,
    CF3rosData: CorresponsalesFacturasTercerosSlice,
    CCData : CorresponsalesContenedoresSlice,
    CAnticipos: CorresponsalesAnticipos,
    CGuias: CorresponsalesGuiasSlice,
    CPartidas: CorresponsalesPartidasSlice,
    Cat1868Destinos: Cat1868DestinosSlice,
    Cat1868Responsables: Cat1868ResponsablesSlice,
    Cat1868ClaveSAT: Cat1868ClaveSATSlice,
    Data1868FacturaDetalle: Data1868FacturaDetalleSlice,
    CCueCompEstatus : CorresponsalesCuentasComplementariasEstatusSlice,
    AmazonInvoices : AmazonInvoicesSlice,
    AmazonNoPartes: AmazonNoPartesSlice,
    AmazonTraficoConsulta: AmazonTraficoConsultaSlice,
    AmazonCatConceptosConsulta: AmazonCatConceptosConsulta 
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import I1868CatClaveSAT from '../../../../Components/Clientes/ZincInternacional/Interfaces/I1868CatClaveSAT';
const Cat1868ClaveSAT: I1868CatClaveSAT[] = []
const initialState = { Cat1868ClaveSAT }

export const Cat1868ClaveSATSlice = createSlice({
  name: 'CatClaveSAT',
  initialState: initialState,
  reducers: {
    populateCatClaveSAT : (state, action: PayloadAction<I1868CatClaveSAT[]>) => {
    action.payload.forEach(newItem => {
      var Existe = state.Cat1868ClaveSAT.find(function(onMemoryItem) {
          return onMemoryItem.id === newItem.id;
        });
       if (!Existe) state.Cat1868ClaveSAT.push(newItem)
    })
    },
    addCatClaveSAT : (state, action: PayloadAction<I1868CatClaveSAT>) => {
      var Existe = state.Cat1868ClaveSAT.find(function(item) {
        return item.id === action.payload.id;
      });
     if (!Existe) state.Cat1868ClaveSAT.push(action.payload)
    },
    updateCatClaveSAT : (state, action: PayloadAction<I1868CatClaveSAT>) => {
      const i = state.Cat1868ClaveSAT.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.Cat1868ClaveSAT[i] = action.payload;
      else state.Cat1868ClaveSAT.push(action.payload);
    },
    deleteCatClaveSAT : (state, action: PayloadAction<number>) => {
      const newArr = state.Cat1868ClaveSAT.filter(data => data.id != action.payload);
      state.Cat1868ClaveSAT=newArr
    },
  },
})

export const { addCatClaveSAT, populateCatClaveSAT, updateCatClaveSAT, deleteCatClaveSAT } = Cat1868ClaveSATSlice.actions;
export default Cat1868ClaveSATSlice.reducer;

import React, { useEffect, useState } from 'react'
//boostrap componentes 
import { Button, Card, Form } from 'react-bootstrap'
import { BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
//interfaces 


import { IRptMonitoreoFacturaPagadasAmazon} from "../../Interfaces/Reportes/IRptMonitoreoFacturasPagadasAmazon";
//dtos 

//Services 
import reportsDataService from '../../Services/Reportes/reportes.services'
import ClientesDataService from '../../Services/Catalogos/Clientes.Services'

import { useDispatch } from 'react-redux'

// Gui Components
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import { AgGridReact } from 'ag-grid-react'
import { TargetURL } from '../../Constants/TargetURL'
//Other
import moment from 'moment'
import * as XLSX from 'xlsx'
import DTORPTMonitorFacturasPagAmazon from '../../DTO/Utils/DTORPTMonitorFacturasPagAmazon';

const URL = new TargetURL()

export default function RptMonitoreoFacturasPagadasAmazon(){
    
    const [UserId, setUserId] = useState(() => {
      const stickyValue = window.localStorage.getItem('UserId')
      return stickyValue !== null ? JSON.parse(stickyValue) : 0
    })
    const [Data, setData] = useState<Array<IRptMonitoreoFacturaPagadasAmazon>>([])
    const [filteredData, setFilteredData] = useState<Array<IRptMonitoreoFacturaPagadasAmazon>>([])
    const [Inicio, setInicio] = useState(currentDate())
    const [Fin, setFin] = useState(currentDate())
    
    // const [Clientes, setClientes] = useState<Array<IClientes>>()
    const [msgColor, setMsgColor] = React.useState('primary')
    const [show, setShowMsg] = useState(false)
    const [header, setHeader] = useState('')
    const [msg, setMsg] = useState('')
    const [filtro, setFiltro] = useState('')
    const gridRef = React.useRef<any>(null)
    const [columnaVerPDF, setcolumnaVerPDF] = useState(false)
    const dispatch = useDispatch()
    const [columnDefs] = useState([
      {field: 'referencia' , sortable: true , filter: true }, 
      { field: 'factura', sortable: true, filter: true },
      { field: 'clasificador', sortable: true, filter: true },
      { field: 'fechaFactura', sortable: true, filter: true },
      { field: 'fCreacion', sortable: true, filter: true },
      { field: 'fechaPago', sortable:true,  filter: true  }, 
      { field: 'cantidadDiasFactura', sortable:true,  filter: true  }, 
      { field: 'cantidadDiasPago', sortable:true,  filter: true  }, 
      { field : 'cantidadASIN' , sortable: true, filter: true   }, 
      {field: 'cantFracIguales' , sortable: true, filter: true }, 
      { field: 'cantFracDiferentes' ,  sortable: true, filter: true}, 
      { field: 'cantidadSideline', sortable: true, filter: true }
    ])
  
    function currentDate(): string {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
      var yyyy = today.getFullYear()
      return yyyy + '-' + mm + '-' + dd
    }
  
    const closeToast = (show: boolean): void => {
      setShowMsg(false)
    }
  
    const generaReporte = () => {
      
      const data: DTORPTMonitorFacturasPagAmazon = {
        Inicio: moment(Inicio).format('YYYY-MM-DD'),
        Fin: moment(Fin).format('YYYY-MM-DD')
       
       
      }
      reportsDataService
        .getRptMonitoreoFacturasPagadasAmazon(data)
        .then((response) => {
          setData(response.data)
          setFilteredData(response.data)
          setHeader('Informativo')
          setMsg('Se encontro la siguiente informacion...')
          setShowMsg(true)
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }
  
    useEffect(() => {
      ClientesDataService.getAllClientes(parseInt(UserId))
        .then((response) => {
          
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }, [])
  
    const downloadExcel = () => {
      console.log(Data)
      exportExcel(Data, 'MonitorFacturasPagadasAmazon')
    }
  
    function exportExcel(jsonData: IRptMonitoreoFacturaPagadasAmazon[], fileName: string): void {
      let Heading = [
        [
          'Referencia', 
          'Factura',
          'FechaFactura',
          'Clasificador',
          'FCreacion',
          'FechaPago',
          'CantidadDiasFactura',
          'CantidadDiasPago',
          'CantidadASIN', 
          'CantidadFraccionesIguales', 
          'CantidadFraccionesDiferentes', 
          'CantidadSideline'

          
        ],
      ]
     
      const wb = XLSX.utils.book_new()
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
      XLSX.utils.sheet_add_aoa(ws, Heading)
      XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, fileName + '.xlsx')
      var range = XLSX.utils.decode_range(ws['!ref?'])
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
        if (!ws[address]) continue
        ws[address].v = ws[address].v.toUpperCase()
      }
    }
    const filtraReporte = (e: any) => {
      setFiltro(e.target.value)
      gridRef.current.api.setQuickFilter(e.target.value)
    }
  
    const formatNumber = (number: string) => {
      number = parseFloat(number).toFixed(2)
      let value = Number(number).toLocaleString('en')
      if (!value.indexOf('NaN')) return '0.00'
      return value
    }
    return (
      <div>
        <Card>
          <Card.Body>
            <div className='row'>
              <div className='col-md-2'>
                <div className='d-flex align-items-center'>
                  <Form.Label></Form.Label>
                  <Form.Control
                    defaultValue={Inicio}
                    type='date'
                    name='Inicio'
                    placeholder='Inicio'
                    title='Inicio'
                    alt='Inicio'
                    data-date-format='YYYY-mm-dd'
                    onChange={(e) => setInicio(e.target.value)}
                    size='sm'
                  />
                </div>
              </div>
              <div className='col-md-2'>
                <div className='d-flex align-items-center'>
                  <Form.Label></Form.Label>
                  <Form.Control
                    defaultValue={Fin}
                    type='date'
                    name='Fin'
                    placeholder='Fin'
                    title='Fin'
                    alt='Fin'
                    onChange={(e) => setFin(e.target.value)}
                    size='sm'
                  />
                </div>
              </div>
              <div className='col-md-1 d-flex align-items-center'>
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => {
                    generaReporte();
                  }}
                >
                  <BsSearch /> Buscar
                </Button>
              </div>
              <div className='col-md-3 d-flex align-items-center'>
                <Form.Control
                  type='text'
                  size='sm'
                  placeholder='Search...'
                  onChange={(e) => {
                    filtraReporte(e);
                  }}
                />
              </div>
              <div className='col-md-1 d-flex align-items-center'>
                <Button
                  size='sm'
                  variant='success'
                  onClick={() => {
                    downloadExcel();
                  }}
                >
                  <BsFileEarmarkExcel /> Excel
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
        <br />
        <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
          <AgGridReact
            rowData={filteredData}
            columnDefs={columnDefs}
            pagination={true}
            paginationAutoPageSize={true}
            ref={gridRef}
            rowSelection={'multiple'}
            rowMultiSelectWithClick={true}
          ></AgGridReact>
        </div>
        <MsgInformativo show={show} msg={msg} header={header} msgColor={msgColor} closeToast={closeToast} />
      </div>
    );
    
   
  }